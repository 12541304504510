import React from "react";
import { View, Dimensions } from "react-native";
import Header from "./Header";
import { REACT_APP_URL_BASE_API_POSTULANTE } from "@env";
const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

export default function DocumentViewer({ user, route, navigation, setHeaderText }) {
  const { archivo } = route.params;
  const [url, setUrl] = React.useState();

  React.useEffect(() => {
    setUrl(
      `https://docs.google.com/a/umd.edu/viewer?url=${REACT_APP_URL_BASE_API_POSTULANTE}documentos/download/${archivo.idDocumento}&embedded=true`
    );
    setHeaderText(`Documento: ${archivo.fileName}`)
  }, [archivo.idDocumento]);

  return (
    <View>
      <div style={{ height: windowHeight - 56 }}>
        <iframe height="99.6%" width="99.7%" src={url}></iframe>
      </div>
    </View>
  );
}
