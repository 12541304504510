import React from "react";
import { Text, View } from "react-native";
import StepIndicator from "react-native-step-indicator";
import ColoresPineal from "../styles/ColoresPineal";
import "../styles/step-indicator.css";

export default function CustomStepIndicator({
  labels = [
    { label: "Paso 1", value: "Paso 1" },
    { label: "Paso 2", value: "Paso 2" },
    { label: "Paso 3", value: "Paso 3" },
    { label: "Paso 4", value: "Paso 4" },
    { label: "Paso 5", value: "Paso 5" },
  ],
  label = "Paso 1",
  postulantes = [],
  navigation,
  etapas = [],
}) {
  const obtienePosicionActual = (nombre, from = 0) => {
    let actual = labels.find((label) => label.value === nombre);
    if (actual === undefined) {
      return -1;
    }
    return labels.slice(from).indexOf(actual);
  };

  const obtieneTextos = (lista = []) => {
    let nombres = [];
    lista.forEach( dato => {
      nombres.push(dato.label);
    });
    return nombres;
  }

  React.useEffect(() => {
  })

  return (
    <View
      nativeID="step-indicator-id"
      style={{
        marginVertical: 20,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <View style={{ width: "20vw", justifyContent: "center" }}>
        <StepIndicator
          currentPosition={obtienePosicionActual(label)}
          renderStepIndicator={(position, stepStatus) => {
            let total = 0;
            let aux = postulantes.filter((postulante) => {
              let etapa = etapas.obtieneEtapa(labels[position.position].label);
              return (
                postulante.etapa == etapa.estado || position.position === 0
              );
            });
            total = aux.length;
            return <Text style={{ color: ColoresPineal.blanco }}>{total}</Text>;
          }}
          customStyles={{
            stepIndicatorSize: 50,
            currentStepIndicatorSize: 55,
            separatorStrokeWidth: 3,
            stepStrokeCurrentColor: ColoresPineal.anaranjado,
            currentStepLabelColor: ColoresPineal.azulOscuro,
            labelColor: ColoresPineal.azulOscuro,
            labelSize: 18,
            stepIndicatorCurrentColor: ColoresPineal.anaranjado,
            stepIndicatorFinishedColor: ColoresPineal.anaranjado,
            stepIndicatorUnFinishedColor: ColoresPineal.anaranjado,
            stepIndicatorLabelCurrentColor: ColoresPineal.azulOscuro,
            stepIndicatorLabelFinishedColor: ColoresPineal.azulOscuro,
            stepIndicatorLabelUnFinishedColor: ColoresPineal.azulOscuro,
            stepIndicatorLabelFontSize: 18,
            currentStepIndicatorLabelFontSize: 18,
            labelFontFamily: "font-weight: bold",
          }}
          stepCount={1}
          onPress={(index) => {
            if (navigation !== undefined) {
              navigation.navigate(labels[index].value);
            }
          }}
          labels={obtieneTextos([labels[0]])}
        />
      </View>
      <View style={{ width: "80vw", justifyContent: "center" }}>
        <StepIndicator
          currentPosition={obtienePosicionActual(label, 1)}
          renderStepIndicator={(position, stepStatus) => {
            let total = 0;
            let aux = postulantes.filter((postulante) => {
              let etapa = etapas.obtieneEtapa(labels[position.position + 1].label);
              return postulante.etapa == etapa.estado;
            });
            total = aux.length;
            return <Text style={{ color: ColoresPineal.blanco }}>{total}</Text>;
          }}
          customStyles={{
            stepIndicatorCurrentColor: ColoresPineal.anaranjado,
            stepStrokeCurrentColor: ColoresPineal.anaranjado,
            labelSize: 15,
            currentStepLabelColor: ColoresPineal.azulOscuro,
            labelColor: ColoresPineal.azulOscuro,
            stepIndicatorUnFinishedColor: "#849BB7",
            stepStrokeUnFinishedColor: "#849BB7",
            separatorUnFinishedColor: "#849BB7",
            stepIndicatorFinishedColor: "#849BB7",
            stepStrokeFinishedColor: "#849BB7",
            separatorFinishedColor: "#849BB7",
            stepIndicatorLabelCurrentColor: ColoresPineal.azulOscuro,
            stepIndicatorLabelFinishedColor: ColoresPineal.azulOscuro,
            stepIndicatorLabelUnFinishedColor: ColoresPineal.azulOscuro,
          }}
          stepCount={labels.slice(1).length}
          onPress={(index) => {
            if (navigation !== undefined) {
              navigation.navigate(labels[index + 1].value);
            }
          }}
          labels={obtieneTextos(labels.slice(1))}
        />
      </View>
    </View>
  );
}
