import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtieneListaDatos: (token, nombreLista) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}datosadicionales/${nombreLista}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listaItems;
          resolve(respuesta);
        });
    });
  },
};
