import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";
import { Platform } from "react-native";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  datosAdicionales: (token, nombreLista) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}datosadicionales/${nombreLista}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listaItems;
          resolve(respuesta);
        });
    });
  },
  obtieneEmpresasTodas: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}empresa`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listEmpresas;
          resolve(respuesta);
        });
    });
  },
  obtieneEmpresas: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}empresa/habilitadas`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listEmpresas;
          resolve(respuesta);
        });
    });
  },
  creaEmpresa: (token, empresa, archivo) => {
    let datos = new FormData();
    console.log(archivo);
    if (archivo !== null && archivo !== undefined) {
      datos.append("imagen", archivo);
    }
    datos.append(
      "empresa",
      new Blob([JSON.stringify(empresa)], { type: "application/json" })
    );
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: datos,
      redirect: "follow",
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}empresa`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  obtieneEmpresaId: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}empresa/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  actualizaEmpresa: (token, empresa, archivo) => {
    let datos = new FormData();
    datos.append("imagen", archivo);
    datos.append(
      "empresa",
      new Blob([JSON.stringify(empresa)], { type: "application/json" })
    );
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
      },
      body: datos,
      redirect: "follow",
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}empresa`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  eliminaEmpresa: (token, idEmpresa) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}empresa/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  enviaAlerta: (token, datosAlerta) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosAlerta),
    };

    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}alerta`, requestOptions)
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  obtienePreguntasEmpresa: (token, empresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}formularioEmpresa/${empresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listPreguntasCompletaTO;
          resolve(respuesta);
        });
    });
  },
  actualizaEstadoPregunta: (token, idEmpresa, idFormulario, habilita) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        activa: habilita,
        idEmpresa: idEmpresa,
        idFormulario: idFormulario,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}formularioEmpresa`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
  nuevaPreguntaFormulario: (token, formulario, url) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify(formulario),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}${url}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
  actualizaPreguntaFormulario: (token, formulario, url) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify(formulario),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}${url}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
  obtieneDatosProceso: (token, idprocesos = []) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({ listaIdProceso: idprocesos }),
    };
    return new Promise(async (resolve, reject) => {
      if (Platform.OS === "web") {
        fetch(
          `${REACT_APP_URL_BASE_API_EMPRESA}proceso/reporte/proceso/`,
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            resolve(respuesta);
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "Informe Postulantes.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
          });
      } else {
        const callback = (downloadProgress) => {
          const progress =
            downloadProgress.totalBytesWritten /
            downloadProgress.totalBytesExpectedToWrite;
          console.log(progress);
        };

        const downloadResumable = FileSystem.createDownloadResumable(
          `${REACT_APP_URL_BASE_API_EMPRESA}proceso/reporte/proceso/`,
          FileSystem.cacheDirectory + archivo.nombre,
          {},
          callback
        );

        try {
          const { uri } = await downloadResumable.downloadAsync();
          console.log("Finished downloading to ", uri);
          //Linking.openURL(uri);
          FileSystem.getContentUriAsync(uri).then((cUri) => {
            console.log(cUri);
            IntentLauncher.startActivityAsync("android.intent.action.VIEW", {
              data: cUri,
              flags: 1,
            });
          });
        } catch (e) {
          //console.error(e);
        }
      }
    });
  },
  obtieneFormularioCargoEmpresa: (token, uuidProceso) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}formulario/${uuidProceso}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
};
