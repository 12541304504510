const opcionesColumnChart = (titulo) => {
  return {
    fontSize: 15,
    bold: true,
    colors: ["#0a5dc1"],
    legend: { position: "top", fontSize: 15, alignment: "center" },
    hAxis: {
      title: titulo,
      direction: -1,
      slantedText: true,
      maxAlternation: 1,
      slantedTextAngle: 45, // here you can even use 180
    },
  };
};

const graficos = {
  postulantes: [
    {
      label: "Región",
      value: 1,
      type: "ColumnChart",
      tipoGrafico: 1,
      campoEjeX: "region",
      campoEjeY: "rut",
      nombreLlave: "Región",
      nombreValor: "Postulantes",
      options: opcionesColumnChart("Región"),
    },
    {
      label: "Ciudad",
      value: 2,
      type: "ColumnChart",
      tipoGrafico: 1,
      campoEjeX: "ciudad",
      campoEjeY: "rut",
      nombreLlave: "Ciudad",
      nombreValor: "Postulantes",
      options: opcionesColumnChart("Ciudad"),
    },
    {
      label: "Edad",
      value: 3,
      type: "Bar",
      tipoGrafico: 1,
      campoEjeX: "edad",
      campoEjeY: "rut",
      nombreLlave: "Edad",
      nombreValor: "Postulantes",
      groupBy: (datos) => {
        let datosAgrupados = [];
        for (var i = 0; i < 9; i++) {
          datosAgrupados[`${i * 10} a ${(i + 1) * 10 - 1}`] = 0;
        }
        Object.keys(datos).forEach((key) => {
          let llave =
            Object.keys(datosAgrupados)[Math.floor(parseInt(key) / 10)];
          if (llave !== undefined) {
            datosAgrupados[llave] = datosAgrupados[llave] + datos[key];
          }
        });
        return datosAgrupados;
      },
      options: {
        title: "Edad",
        fontSize: 15,
        bold: true,
        colors: ["#0a5dc1"],
        legend: { position: "top", fontSize: 15, bold: true },
      },
    },
    /*{
      label: "Edad",
      value: 4,
      type: "Bar",
      tipoGrafico: 1,
      campoEjeX: "edad",
      campoEjeY: "rut",
      nombreLlave: "Edad",
      nombreValor: "Postulantes",
      options: {
        title: "Edad",
        fontSize: 15,
        bold: true,
        colors: ["#0a5dc1"],
        legend: { position: "top", fontSize: 15, bold: true },
      },
    },*/
    {
      label: "Carrera",
      value: 5,
      type: "ColumnChart",
      tipoGrafico: 1,
      campoEjeX: "carrera",
      campoEjeY: "rut",
      nombreLlave: "Carrera",
      nombreValor: "Postulantes",
      options: opcionesColumnChart("Carrera"),
    },
    {
      label: "Expectativa Renta",
      value: 7,
      type: "ColumnChart",
      tipoGrafico: 1,
      campoEjeX: "expectativaRenta",
      campoEjeY: "rut",
      nombreLlave: "Expectativa Renta",
      nombreValor: "Postulantes",
      options: opcionesColumnChart("Expectativa Renta"),
    },
    {
      label: "Género",
      value: 6,
      type: "PieChart",
      tipoGrafico: 3,
      campoEjeX: "genero",
      campoEjeY: "rut",
      nombreLlave: "Género",
      nombreValor: "Postulantes",
      options: {
        title: "Género",
        fontSize: 15,
        bold: true,
        legend: { position: "left", bold: true, width: "400px" },
        pieSliceTextStyle: {
          color: "black",
          fontSize: 15,
        },
        slices: {
          0: { color: "#f6a623" },
          1: { color: "#fbdfb1" },
          2: { color: "#dcdcdc", offset: 0.1 },
        },
      },
    },
  ],
  procesos: [
    {
      label: "Pipeline",
      value: 1,
      type: "Funel",
      tipoGrafico: 4,
      options: {
        title: "Etapas del proceso",
        pallette: ["#0a5dc1", "#14487c", "#3169a0", "#5389be"],
      },
    },
    {
      label: "Tiempos Promedios del Proceso",
      value: 2,
      type: "Bar",
      tipoGrafico: 1,
      options: {
        title: "Fecha Postulacion",
        fontSize: 15,
        bold: true,
        colors: ["#0a5dc1"],
        legend: { position: "top", fontSize: 15, bold: true },
      },
    },
    {
      label: "Procesos x Empresa",
      value: 3,
      type: "ColumnChart",
      tipoGrafico: 1,
      campoEjeX: "empresa",
      campoEjeY: "proceso",
      nombreLlave: "Empresa",
      nombreValor: "Procesos",
      options: opcionesColumnChart("Empresa"),
    },
  ],
};

export default graficos;
