import * as DocumentPicker from "expo-document-picker";
import React from "react";
import { View } from "react-native";
import { Card, Text } from "react-native-paper";
import CustomButton from "../../components/CustomButton";
import CustomTextInput from "../../components/CustomTextInput";
import EmpresaService from "../../services/EmpresaService";
import styles from "../../styles/style";
import Alerta from "../modal/Alerta";

export default function Empresa({
  user,
  route,
  navigation,
  recargaEmpresas,
  setHeaderText,
}) {
  const { empresa } = route.params;
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [archivo, setArchivo] = React.useState();
  const [actualizar, setActualizar] = React.useState(false);

  const accionModal = () => {
    empresa["agregar"] = false;
    console.log(archivo);
    if (empresa.id === -1) {
      empresa["activa"] = true;
      delete empresa.id;
      EmpresaService.creaEmpresa(user.token, empresa, archivo?.file).then(
        (respuesta) => {
          if (respuesta.codigoRespuesta === 200) {
            setTipoAlerta("info");
            setMensaje(`Empresa ${empresa.nombre} creada exitosamente.`);
            empresa["agregar"] = true;
            recargaEmpresas();
          } else {
            setTipoAlerta("error");
            setMensaje(
              `Se ha producido un error al crear la empresa ${empresa.nombre}`
            );
          }
          setAlertaVisible(true);
        }
      );
    } else {
      EmpresaService.actualizaEmpresa(user.token, empresa, archivo?.file).then(
        (resp) => {
          if (resp.codigoRespuesta === 200) {
            setTipoAlerta("info");
            setMensaje(`Empresa ${empresa.nombre} actualizada exitosamente.`);
            recargaEmpresas();
          } else {
            setTipoAlerta("error");
            setMensaje(
              `Se ha producido un error al actualizar la empresa ${empresa.nombre}`
            );
          }
          setAlertaVisible(true);
        }
      );
    }
  };

  const selecionaImagen = async () => {
    let archivo = undefined;
    const pickDocument = async () => {
      let result = await DocumentPicker.getDocumentAsync({
        type: "image/*",
        multiple: false,
        copyToCacheDirectory: true,
      });
      return result;
    };
    archivo = await pickDocument();
    setArchivo(archivo);
  };

  const formularioCompleto = () => {
    if (
      empresa.nombre === undefined ||
      empresa.nombre === null ||
      empresa.nombre === ""
    ) {
      return false;
    }
    if (
      empresa.rut === undefined ||
      empresa.rut === null ||
      empresa.rut === ""
    ) {
      return false;
    }
    if (
      empresa.contactoEmpresa === undefined ||
      empresa.contactoEmpresa === null ||
      empresa.contactoEmpresa === ""
    ) {
      return false;
    }
    if (
      empresa.diasPostulacion === undefined ||
      empresa.diasPostulacion === null ||
      empresa.diasPostulacion === ""
    ) {
      return false;
    }
    if (
      empresa.diasTest === undefined ||
      empresa.diasTest === null ||
      empresa.diasTest === ""
    ) {
      return false;
    }
    if (
      empresa.diasEvaluacion === undefined ||
      empresa.diasEvaluacion === null ||
      empresa.diasEvaluacion === ""
    ) {
      return false;
    }
    if (
      empresa.diasContratacion === undefined ||
      empresa.diasContratacion === null ||
      empresa.diasContratacion === ""
    ) {
      return false;
    }
    /*if (archivo === undefined || archivo === null) {
      return false;
    }*/
    return true;
  };

  React.useEffect(() => {
    setHeaderText(empresa.id === -1 ? "Nueva Empresa" : "Editar Empresa");
  }, [empresa.id, archivo, actualizar]);

  return (
    <View style={{ padding: 20 }}>
      <CustomTextInput
        style={styles.inputEmpresa}
        label="Nombre Empresa"
        value={empresa.nombre === undefined ? "" : empresa.nombre}
        onChange={(text) => {
          empresa.nombre = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="rut"
        label="RUT Empresa"
        value={empresa.rut === undefined ? "" : empresa.rut}
        onChange={(text) => {
          empresa.rut = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
        maxLength={10}
      />
      <Card
        style={{
          backgroundColor: "rgb(242, 242, 242)",
          shadowColor: "rgb(242, 242, 242)",
        }}
      >
        <Card.Content>
          <CustomButton
            onPress={selecionaImagen}
            textoButton={archivo == null ? "Subir Imagen" : archivo.file.name}
          />
        </Card.Content>
        {empresa.imagen !== null && (
          <Card.Cover
            source={{ uri: `data:image/png;base64,${empresa.imagen}` }}
            style={{ height: 60 }}
            resizeMode="contain"
          />
        )}
        {empresa.imagen === null && (
          <Card.Content>
            <Text style={{ width: 100, height: 50 }}>Sin Imagen</Text>
          </Card.Content>
        )}
      </Card>
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="email"
        label="Mail Contacto Empresa"
        value={
          empresa.contactoEmpresa === undefined ? "" : empresa.contactoEmpresa
        }
        onChange={(text) => {
          empresa.contactoEmpresa = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="numeros"
        label="Días Postulación"
        value={
          empresa.diasPostulacion === undefined ? "" : empresa.diasPostulacion
        }
        onChange={(text) => {
          empresa.diasPostulacion = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="numeros"
        label="Días Test"
        value={empresa.diasTest === undefined ? "" : empresa.diasTest}
        onChange={(text) => {
          empresa.diasTest = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="numeros"
        label="Días Evaluación"
        value={
          empresa.diasEvaluacion === undefined ? "" : empresa.diasEvaluacion
        }
        onChange={(text) => {
          empresa.diasEvaluacion = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputEmpresa}
        valueType="numeros"
        label="Días Contratación"
        value={
          empresa.diasContratacion === undefined ? "" : empresa.diasContratacion
        }
        onChange={(text) => {
          empresa.diasContratacion = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomButton
        disabled={!formularioCompleto()}
        onPress={() => accionModal()}
        textoButton="Aceptar"
      />
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() =>
          navigation.navigate({
            name: "Mantenedor Empresa",
            params: { empresa: empresa },
            merge: true,
          })
        }
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
