import React from "react";
import { StyleSheet, Text } from "react-native";
import { DataTable, IconButton, Switch } from "react-native-paper";
import EmpresaService from "../services/EmpresaService";

const styles = StyleSheet.create({
  switch: {
    flex: 1,
    alignItems: "flex-end",
    margin: 20,
  },
});

export default function PreguntaEmpresa({
  user,
  alertaModal,
  onEdit,
  empresa,
  pregunta,
}) {
  const [isSwitchOn, setIsSwitchOn] = React.useState(pregunta.activo);

  const onToggleSwitch = () => {
    pregunta.activo = !pregunta.activo;
    pregunta["idEmpresa"] = empresa.id;
    EmpresaService.actualizaPreguntaFormulario(
      user.token,
      pregunta,
      "formularioEmpresa"
    ).then((resp) => {
      if (resp.entidad !== null && resp.entidad !== undefined) {
        alertaModal(
          "info",
          "El campo '" + pregunta.label + "' fue actualizado correctamente."
        );
        setIsSwitchOn(pregunta.activo);
      } else {
        pregunta.activo = !pregunta.activo;
        alertaModal(
          "error",
          "El campo '" + pregunta.label + "' no fue actualizado."
        );
      }
    });
  };

  React.useEffect(() => {}, [isSwitchOn, pregunta.activo]);

  return (
    <DataTable.Row>
      <DataTable.Cell>{pregunta.idFormulario}</DataTable.Cell>
      <DataTable.Cell>{pregunta.label}</DataTable.Cell>
      <DataTable.Cell>{pregunta.type}</DataTable.Cell>
      <DataTable.Cell>
        {pregunta.maxLength === undefined ? "N/A" : pregunta.maxLength}
      </DataTable.Cell>
      <DataTable.Cell>{pregunta.required ? "Si" : "No"}</DataTable.Cell>
      <DataTable.Cell>
        {pregunta.mask === undefined ? "N/A" : pregunta.mask}
      </DataTable.Cell>
      <DataTable.Cell>
        <Switch
          trackColor={{
            true: "#f5c16d",
            false: "#CACACA",
          }}
          thumbColor="#888888"
          color="#f6a623"
          value={isSwitchOn}
          onClick={onToggleSwitch}
        />
      </DataTable.Cell>
      <DataTable.Cell>
        <IconButton icon="pencil" size={20} onPress={() => onEdit()} />
      </DataTable.Cell>
    </DataTable.Row>
  );
}
