import { useRoute } from "@react-navigation/native";
import React from "react";
import { Image, View } from "react-native";
import { Appbar } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import Logos from "../utils/imagenes";

export default function Header({
  navigation,
  icon,
  onPress,
  back = false,
  name,
  onBack,
  imagen,
}) {
  const route = useRoute();

  const handlerButon = () => {
    if (back) {
      if (onBack !== undefined) {
        onBack();
      }
      navigation.goBack();
    } else {
      navigation.toggleDrawer();
    }
  };

  React.useEffect(() => {
  }, []);

  return (
    <Appbar.Header style={{ backgroundColor: ColoresPineal.azulRey, height: 80 }}>
      <Appbar.Action
        onPress={handlerButon}
        icon={back? "arrow-left" : "menu"}
      />
      <Appbar.Content title={name === undefined ? route.name : name} />
      <Appbar.Content
        titleStyle={{ textAlign: "right" }}
        title={
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {imagen !== null && (
              <Image
                source={{ uri: `data:image/png;base64,${imagen}` }}
                style={{
                  width: 60,
                  height: 60,
                }}
                resizeMode="cover"
              />
            )}
            <Image
              source={Logos.logoNuevo}
              style={{
                marginLeft: 20,
                width: 60,
                height: 60,
              }}
              resizeMode="contain"
            />
          </View>
        }
      />
    </Appbar.Header>
  );
}
