import React, { useEffect } from "react";
import { Dimensions, ScrollView, View } from "react-native";
import { Colors, List, TextInput } from "react-native-paper";

const windowHeight = Dimensions.get("window").height;

export default function SelectAutocompletar({
  lista = [],
  value = null,
  onSelect = () => {},
  nombreValue,
  nombreLabel,
  showIcon = true,
  comoponente = () => {},
  multiselect = false,
  maxHeight,
  marginBottom,
  recargar,
  seleccionadosPrimero,
}) {
  const [itemSelected, setItemSelected] = React.useState(value);
  const [text, setText] = React.useState("");

  const filtrarLista = () => {
    let listaAux = lista.filter((item) => {
      let valor = obtieneLabel(item);
      valor = valor === undefined || valor === null ? "" : valor;
      if (typeof value !== "number") {
        valor = valor.toString();
      }
      return text === undefined || text.length === 0
        ? true
        : valor.toLowerCase().includes(text.toLowerCase());
    });

    return listaAux;
  };

  const setItemSelectedHandler = (item) => {
    if (multiselect) {
      if (!isItemCheckMulti(item)) {
        setItemSelected([...itemSelected, item]);
        onSelect([...itemSelected, item]);
      } else {
        let items = [];
        itemSelected.forEach((itemSelec) => {
          if (nombreValue !== undefined) {
            if (item[nombreValue] !== itemSelec[nombreValue]) {
              items.push(itemSelec);
            }
          } else {
            if (item !== itemSelec) {
              items.push(itemSelec);
            }
          }
        });
        setItemSelected(items);
        onSelect(items);
      }
    } else {
      setItemSelected(item);
      onSelect(item);
    }
  };

  const ordenaLista = () => {
    let listaFiltrada = filtrarLista();
    listaFiltrada.sort((first, second) => {
      if (seleccionadosPrimero !== undefined) {
        let firstSelected = itemSelected.find(
          (item) => item[nombreValue] === first[nombreValue]
        );
        let secondSelected = itemSelected.find(
          (item) => item[nombreValue] === second[nombreValue]
        );

        if (firstSelected !== undefined && secondSelected === undefined) {
          return -1;
        }

        if (secondSelected !== undefined && firstSelected === undefined) {
          return 1;
        }
      }

      let fisrtLowerCase = obtieneLabel(first);
      if (fisrtLowerCase === null || fisrtLowerCase === undefined) {
        fisrtLowerCase = "";
      }
      fisrtLowerCase = fisrtLowerCase.toString().toLowerCase().trim();

      let secondLowerCase = obtieneLabel(second);
      if (secondLowerCase === null || secondLowerCase === undefined) {
        secondLowerCase = "";
      }
      secondLowerCase = secondLowerCase.toString().toLowerCase().trim();
      if (fisrtLowerCase < secondLowerCase) {
        return -1;
      }
      if (fisrtLowerCase > secondLowerCase) {
        return 1;
      }
      return 0;
    });

    return listaFiltrada;
  };

  const isItemCheck = (item) => {
    if (itemSelected !== null) {
      if (nombreValue !== undefined) {
        return itemSelected[nombreValue] === item[nombreValue];
      } else {
        return itemSelected === item;
      }
    }
    return false;
  };

  const isItemCheckMulti = (item) => {
    let existeItem = false;
    if (itemSelected !== null) {
      itemSelected.forEach((itemSelec) => {
        if (itemSelec !== undefined && itemSelec !== null) {
          if (nombreValue !== undefined) {
            if (itemSelec[nombreValue] === item[nombreValue]) {
              existeItem = true;
            }
          } else {
            if (itemSelec === item) {
              existeItem = true;
            }
          }
        }
      });
    }
    return existeItem;
  };

  const obtieneLabel = (item) => {
    let label = "";
    if (nombreLabel === undefined) {
      label = item;
    } else {
      if (nombreLabel instanceof Array) {
        nombreLabel.forEach((element) => {
          if (label.length > 0) {
            label = label + " ";
          }
          label = label + item[element];
        });
      } else {
        label = item[nombreLabel];
      }
    }
    return label;
  };

  useEffect(() => {
    if (value !== undefined && value instanceof Array && value.length > 0) {
      if (itemSelected.length !== value.length) {
        setItemSelected(value);
        onSelect(value);
      }
    }
  }, [value]);

  useEffect(() => {}, [itemSelected, recargar, text]);

  return (
    <View nativeID="select_autocompletar_contenedor">
      <TextInput
        right={<TextInput.Icon name="magnify" />}
        onChangeText={(text) => setText(text)}
      />
      <ScrollView
        style={{
          maxHeight:
            maxHeight === undefined
              ? (windowHeight * 4) / 5 - (multiselect ? 220 : 200)
              : maxHeight,
          backgroundColor: Colors.white,
          marginBottom: marginBottom === undefined ? 30 : marginBottom,
        }}
      >
        {ordenaLista().map((item, index) => {
          return (
            <div key={"item-" + index}>
              <List.Item
                onPress={() => setItemSelectedHandler(item)}
                key={index}
                //title={nombreLabel === undefined ? item : item[nombreLabel]}
                title={obtieneLabel(item)}
                left={(props) => {
                  if (showIcon) {
                    return (
                      <List.Icon
                        icon={
                          (
                            multiselect
                              ? isItemCheckMulti(item)
                              : isItemCheck(item)
                          )
                            ? "checkbox-marked-outline"
                            : "checkbox-blank-outline"
                        }
                      />
                    );
                  }
                }}
              />
              {comoponente(item)}
            </div>
          );
        })}
      </ScrollView>
    </View>
  );
}
