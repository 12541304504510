import React from "react";
import { ActivityIndicator, Button } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";

export default function CustomButton({
  onPress = () => {},
  textoButton = "Aceptar",
  loading = false,
  disabled = false,
  icon = "",
  style = {},
  contentStyle = {},
  oscuro = false,
  cargando = false,
}) {
  return (
    <Button
      testID="custom-button-id"
      mode="contained"
      style={
        disabled
          ? [
              styles.botton,
              style,
              oscuro
                ? { backgroundColor: ColoresPineal.azulOscuro }
                : { backgroundColor: ColoresPineal.anaranjadoDisabled },
            ]
          : [styles.botton, style]
      }
      onPress={() => onPress()}
      loading={loading}
      disabled={disabled}
      icon={icon}
      contentStyle={contentStyle}
    >
      {cargando && (
        <ActivityIndicator
          animating={true}
          color={ColoresPineal.anaranjado}
          size={20}
        />
      )}
      {textoButton}
    </Button>
  );
}
