import React from "react";
import { ScrollView, View } from "react-native";
import { DataTable, Subheading, Text } from "react-native-paper";
import CustomButton from "../../components/CustomButton";
import CustomDialog from "../../components/CustomDialog";
import CustomListSelect from "../../components/CustomListSelect";
import CustomTextInput from "../../components/CustomTextInput";
import CustomSwitch from "../../components/CustomSwitch";
import CargoService from "../../services/CargoService";
import EmpresaService from "../../services/EmpresaService";
import styles from "../../styles/style";
import Alerta from "../modal/Alerta";
import Spinner from "../modal/Spinner";
import Competencia from "./Competencia";
import UnidadesCargo from "./UnidadesCargo";
import ValorVariable from "./ValorVariable";
import ColoresPineal from "../../styles/ColoresPineal";

export default function Cargo({
  user,
  route,
  navigation,
  onUpdate = () => {},
  setHeaderText,
}) {
  const { cargo, empresas } = route.params;
  const [competencias, setCompetencias] = React.useState([]);
  const [datosVariables, setDatosVariables] = React.useState([]);
  const [gerarquias, setGerarquias] = React.useState([]);
  const [unidades, setUnidades] = React.useState([]);
  const [preguntasFormulario, setPreguntasFormulario] = React.useState([]);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [mensajeSpinner, setMensajeSpinner] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [dialofoFormulario, setDialofoFormulario] = React.useState(false);

  const obtieneListaDatos = (datos, setFunction) => {
    EmpresaService.datosAdicionales(user.token, datos).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setFunction(resp.entidad);
      }
    });
  };

  const obtienePreguntas = () => {
    CargoService.obtienePreguntas(user.token, cargo.idCargo).then((resp) => {
      console.log(resp);
      if (resp.codigoRespuesta === 200) {
        setPreguntasFormulario(resp.entidad);
        cargo["preguntasFormulario"] = [];
        resp.entidad.forEach((tipoPregunta) => {
          console.log(tipoPregunta);
          if (
            tipoPregunta["preguntas"] !== undefined &&
            tipoPregunta["preguntas"] !== null &&
            tipoPregunta["preguntas"].length > 0
          ) {
            cargo["preguntasFormulario"] = [
              ...cargo["preguntasFormulario"],
              ...tipoPregunta["preguntas"],
            ];
          }
        });
      }
    });
  };

  const accionModal = () => {
    cargo["idEmpresa"] = cargo.empresa.id;
    cargo["empresaNombre"] = cargo.empresa.nombre;
    if (cargo["listaCompetencias"] !== undefined) {
      cargo["listaCompetencias"].forEach((competencia) => {
        if (cargo["idCargo"] !== -1) {
          competencia["idCargo"] = cargo["idCargo"];
        }
        if (competencia !== null && competencia.value !== undefined) {
          competencia["idCompetencia"] = parseInt(competencia.value);
        }
      });
    }

    cargo["agregar"] = false;
    if (cargo.idCargo === -1) {
      setMensajeSpinner("Creando Cargo");
      setSpinnerVisible(true);
      cargo["activa"] = false;
      delete cargo.idCargo;
      CargoService.creaCargo(user.token, cargo).then((respuesta) => {
        setSpinnerVisible(false);
        if (respuesta.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Cargo ${cargo.nombre} creado exitosamente.`);
          cargo["agregar"] = true;
          onUpdate();
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al crear el cargo ${cargo.nombre}`
          );
        }
        setAlertaVisible(true);
      });
    } else {
      setMensajeSpinner("Actualizando Cargo");
      setSpinnerVisible(true);
      CargoService.actualizaCargo(user.token, cargo).then((resp) => {
        setSpinnerVisible(false);
        if (resp.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Cargo ${cargo.nombre} actualizada exitosamente.`);
          onUpdate();
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al actualizar la cargo ${cargo.nombre}`
          );
        }
        setAlertaVisible(true);
      });
    }
  };

  const eliminaItem = (nombreLista, indexIn) => {
    let listaAux = [];
    cargo[nombreLista].forEach((elment, index) => {
      if (index !== indexIn) {
        listaAux.push(elment);
      }
    });
    cargo[nombreLista] = listaAux;
    setActualizar(!actualizar);
  };

  React.useEffect(() => {
    setHeaderText(cargo.idCargo === -1 ? "Nuevo Cargo" : "Editar Cargo");
    if (competencias.length === 0) {
      obtieneListaDatos("competencias", setCompetencias);
    }
    if (datosVariables.length === 0) {
      obtieneListaDatos("variablescargo", setDatosVariables);
    }
    if (gerarquias.length === 0) {
      obtieneListaDatos("gerarquiacargo", setGerarquias);
    }
    if (unidades.length === 0) {
      obtieneListaDatos("unidadcargo", setUnidades);
    }
    console.log(cargo);
    if (cargo.recargar) {
      console.log(cargo);
      obtienePreguntas();
      cargo.recargar = false;
    }
  }, [
    cargo.idCargo,
    cargo.recargar,
    actualizar,
    alertaVisible,
    spinnerVisible,
  ]);

  return (
    <View style={{ padding: 20 }}>
      <CustomListSelect
        label="Empresa"
        nombreLista="Seleccione Empresa"
        nombreValue="id"
        nombreLabel="nombre"
        value={cargo.empresa === undefined ? [] : cargo.empresa}
        lista={empresas}
        onChange={(items) => {
          cargo["empresa"] = items[0];
          setActualizar(!actualizar);
        }}
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Nombre Cargo"
        value={cargo.nombre === undefined ? "" : cargo.nombre}
        onChange={(text) => {
          cargo["nombre"] = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomButton
        onPress={() => setDialofoFormulario(true)}
        textoButton="Formulario Postulante"
        style={{ marginTop: 30 }}
      />
      <div style={styles.inputCargo}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 50,
          }}
        >
          <Subheading>Competencias</Subheading>
          <CustomButton
            onPress={() => {
              if (
                cargo["listaCompetencias"] === undefined ||
                cargo["listaCompetencias"] === null
              ) {
                cargo["listaCompetencias"] = [];
              }
              cargo["listaCompetencias"].push({
                idCompetencia: 0,
                nombreCompetencia: "",
              });
              setActualizar(!actualizar);
            }}
            textoButton="Agregar"
          />
        </div>
        {cargo["listaCompetencias"] !== null &&
          cargo["listaCompetencias"] !== undefined &&
          cargo["listaCompetencias"].map((competencia, index) => {
            return (
              <Competencia
                key={index}
                competencia={competencia}
                competencias={competencias}
                removeItem={() => eliminaItem("listaCompetencias", index)}
              />
            );
          })}
      </div>
      <div style={styles.inputCargo}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 50,
          }}
        >
          <Subheading>Datos del Cargo</Subheading>
          <CustomButton
            onPress={() => {
              if (
                cargo["listaValorVariable"] === undefined ||
                cargo["listaValorVariable"] === null
              ) {
                cargo["listaValorVariable"] = [];
              }
              cargo["listaValorVariable"].push({
                nombre: "",
                idVariable: 0,
              });
              setActualizar(!actualizar);
            }}
            textoButton="Agregar"
          />
        </div>
        {cargo["listaValorVariable"] !== null &&
          cargo["listaValorVariable"] !== undefined &&
          cargo["listaValorVariable"].map((item, index) => {
            return (
              <ValorVariable
                key={index}
                item={item}
                lista={datosVariables}
                removeItem={() => eliminaItem("listaValorVariable", index)}
              />
            );
          })}
      </div>
      <div style={styles.inputCargo}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 50,
          }}
        >
          <Subheading>Unidad</Subheading>

          <CustomButton
            onPress={() => {
              if (
                cargo["listaUnidadCargo"] === undefined ||
                cargo["listaUnidadCargo"] === null
              ) {
                cargo["listaUnidadCargo"] = [];
              }
              cargo["listaUnidadCargo"].push({
                nombreGerarquia: "",
                gerarquia: 0,
              });
              setActualizar(!actualizar);
            }}
            textoButton="Agregar"
          />
        </div>
        {cargo["listaUnidadCargo"] !== null &&
          cargo["listaUnidadCargo"] !== undefined &&
          cargo["listaUnidadCargo"].map((item, index) => {
            return (
              <UnidadesCargo
                key={index}
                item={item}
                gerarquias={gerarquias}
                unidades={unidades}
                removeItem={() => eliminaItem("listaUnidadCargo", index)}
              />
            );
          })}
      </div>
      <CustomButton
        onPress={() => accionModal()}
        textoButton="Aceptar"
        style={{ marginTop: 30 }}
      />
      <CustomDialog
        visible={dialofoFormulario}
        titulo="Preguntas Formulario"
        onClose={() => setDialofoFormulario(false)}
        contenido={
          <ScrollView style={{ height: "calc(100vh - 350px)" }}>
            {preguntasFormulario.map((formulario, index) => {
              if (
                formulario.preguntas !== undefined &&
                formulario.preguntas.length > 0
              ) {
                return (
                  <DataTable key={`tipo-formulario-${index}`}>
                    <DataTable.Row
                      key={`pregunta-formulario-${index}`}
                      style={{ backgroundColor: ColoresPineal.azulRey }}
                    >
                      <DataTable.Cell>
                        <Text style={{ color: ColoresPineal.blanco }}>
                          {formulario.descripcion}
                        </Text>
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Header>
                      <DataTable.Title>Campo</DataTable.Title>
                      <DataTable.Title>Visible</DataTable.Title>
                      <DataTable.Title>Requerido</DataTable.Title>
                    </DataTable.Header>
                    {formulario.preguntas.map((pregunta, index) => {
                      return (
                        <DataTable.Row key={`pregunta-formulario-${index}`}>
                          <DataTable.Cell>{pregunta.nombre}</DataTable.Cell>
                          <DataTable.Cell>
                            <CustomSwitch
                              estado={pregunta.activo === 1 ? true : false}
                              onSwitch={() => {
                                pregunta.activo = pregunta.activo === 1 ? 0 : 1;
                                if (pregunta.activo === 0) {
                                  pregunta.requerido = 0;
                                }
                                setActualizar(!actualizar);
                              }}
                            />
                          </DataTable.Cell>
                          <DataTable.Cell>
                            <CustomSwitch
                              disabled={pregunta.activo === 0}
                              estado={pregunta.requerido === 1 ? true : false}
                              onSwitch={() => {
                                pregunta.requerido =
                                  pregunta.requerido === 1 ? 0 : 1;
                                setActualizar(!actualizar);
                              }}
                            />
                          </DataTable.Cell>
                        </DataTable.Row>
                      );
                    })}
                  </DataTable>
                );
              }
            })}
          </ScrollView>
        }
      />
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() =>
          navigation.navigate({
            name: "Mantenedor Cargo",
            params: { cargo: cargo },
            merge: true,
          })
        }
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
      <Spinner mensaje={mensajeSpinner} visible={spinnerVisible} />
    </View>
  );
}
