import React from "react";
import { DataTable, IconButton, TextInput } from "react-native-paper";

export default function InputLabelValue({
  onChangeValue,
  index,
  item,
  removeElementLista,
}) {
  const [label, setLabel] = React.useState(item.label);
  const [value, setValue] = React.useState(item.value);

  const handlerChange = (campo, valor) => {
    onChangeValue(index, campo, valor);
    if (campo === "label") {
      setLabel(valor);
    }
    if (campo === "value") {
      setValue(valor);
    }
  };

  React.useEffect(() => {
    console.log(item);
  }, []);

  return (
    <DataTable.Row>
      <DataTable.Cell>
        <TextInput
          mode="outlined"
          label="Label"
          value={label}
          onChangeText={(text) => handlerChange("label", text)}
        />
      </DataTable.Cell>
      <DataTable.Cell>
        <TextInput
          mode="outlined"
          label="Value"
          value={value}
          onChangeText={(text) => handlerChange("value", text)}
        />
      </DataTable.Cell>
      <DataTable.Cell>
        <IconButton
          icon="close-circle-outline"
          color="rgb(246, 166, 35)"
          size={20}
          onPress={() => removeElementLista(index)}
        />
      </DataTable.Cell>
    </DataTable.Row>
  );
}
