import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import CustomTextInput from "../../../components/CustomTextInput";

import ZoomService from "../../../services/ZoomService";

const useStyle = makeStyles({
  btnReunion: {
    color: "#fff",
    backgroundColor: "#20314A",
    variant: "outlined",
    fontSize: 16,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#20314A",
    },
    "&:focus": {
      color: "#fff",
    },
  },
  btnActivo2: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#20314A",
    variant: "outlined",
    fontSize: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover, &:focus": {
      color: "#f47C06",
    },
  },
  tablaTh: {
    color: "#20314A",
    backgroundColor: "#C7D4DC",
    fontSize: 12,
    textAlign: "left",
  },
  tablaTd: {
    color: "#20314A",
    backgroundColor: "#FFF",
    fontSize: 12,
    textAlign: "center",
  },
});

const Comentarios = ({
  children,
  NombreCompleto,
  DatProceso,
  DatIdPostulante,
  token,
  DatIdProceso,
  DatIdReunion,
  usuario,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const [textoComentario, setTextoComentario] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const guardarComentario = () => {
    var hoy = new Date();
    let comentario = {
      comentario: usuario + ": " + textoComentario + "\n",
      idPostulante: DatIdPostulante,
      idProceso: DatIdProceso,
      idReunion: DatIdReunion,
    };
    ZoomService.crearComentario(token, comentario).then((resp) => {
      setTextoComentario("");
      handleClose();
    });
  };

  return (
    <div>
      <Tooltip title="Los comentarios escritos en esta opción se podrán ver en los detalles del postulante, en comentarios Panel.">
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          className={classes.btnActivo2}
        >
          Escribir comentarios
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Comentarios sobre: {NombreCompleto}</DialogTitle>
        <DialogContent>
          <CustomTextInput onChange={(e) => setTextoComentario(e)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={guardarComentario}>Ingresar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Comentarios;
