import React from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import CustomFabGroup from "../../components/CustomFabGroup";
import CustomMuiDataTable from "../../components/CustomMuiDataTable";
import CustomSwitch from "../../components/CustomSwitch";
import LoadingIndicator from "../../components/LoadingIndicator";
import PostulanteService from "../../services/PostulanteService";
import ProcesoService from "../../services/ProcesosService";
import Alerta from "../modal/Alerta";

export default function MantenedorProceso({
  navigation,
  route,
  user,
  onUpdate,
}) {
  const [procesos, setProcesos] = React.useState([]);
  const [proceso, setProceso] = React.useState();
  const [empresas, setEmpresas] = React.useState([]);
  const [funcion] = React.useState({
    aceptaModal: () => {},
  });
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [confirmacion, setConfirmacion] = React.useState(false);
  const [alertaVisible, setAlertaVisible] = React.useState(false);

  const obtieneProcesos = () => {
    ProcesoService.obtieneProcesosTodosV2(user.token).then(
      async (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          if (respuesta.entidad.length > 0) {
            await respuesta.entidad.forEach((proceso) => {
              proceso["nombreEmpresa"] = proceso.empresa.nombre;
              proceso["nombreCargo"] = proceso.cargo.nombre;
              proceso["nombreRegion"] = proceso.region.nombre;
            });
            setProcesos(respuesta.entidad);
          }
        }
      }
    );
  };

  const eliminaProceso = (proceso, index) => {
    setProceso(undefined);
    ProcesoService.eliminaProceso(user.token, proceso.idProceso).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Proceso ${proceso.nombreCargo} eliminada exitosamente.`);
          let listaProcesos = procesos.filter((procesoItem) => {
            return procesoItem.idProceso !== proceso.idProceso;
          });
          setProcesos(listaProcesos);
          onUpdate();
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al eliminar la proceso ${proceso.nombreCargo}.`
          );
        }
        setAlertaVisible(true);
      }
    );
  };

  const actualizaProceso = (proceso) => {
    setProceso(undefined);
    proceso.activo = !proceso.activo;
    ProcesoService.actualizaProceso(user.token, proceso).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Proceso ${proceso.nombreCargo} actualizada exitosamente.`);
        onUpdate();
      } else {
        proceso.activo = !proceso.activo;
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al actualizar la proceso ${proceso.nombreCargo}`
        );
      }
      setConfirmacion(false);
      setAlertaVisible(true);
    });
  };

  const columnas = [
    {
      name: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
      },
    },
    {
      name: "nombreEmpresa",
      label: "Empresa",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Empresa: ${v}`,
        },
      },
    },
    {
      name: "nombreCargo",
      label: "Cargo",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Cargo: ${v}`,
        },
      },
    },
    {
      name: "nombreRegion",
      label: "Región",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Región: ${v}`,
        },
      },
    },
    {
      name: "fechaInicioProceso",
      label: "Fecha Proceso",
      type: "fecha",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Fecha Proceso: ${v}`,
        },
      },
    },
    {
      name: "fechaInicioPostulacion",
      label: "Fecha Postulacion",
      type: "fecha",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Fecha Postulacion: ${v}`,
        },
      },
    },
    {
      name: "fechaInicioTest",
      label: "Fecha Test",
      type: "fecha",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Fecha Test: ${v}`,
        },
      },
    },
    {
      name: "fechaInicioEvaluacion",
      label: "Fecha Evaluacion",
      type: "fecha",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Fecha Evaluacion: ${v}`,
        },
      },
    },
    {
      name: "fechaInicioContratacion",
      label: "Fecha Contratacion",
      type: "fecha",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Fecha Contratacion: ${v}`,
        },
      },
    },
    {
      name: "historico",
      label: "Historico",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Historico: ${v}`,
        },
        filterOptions: {
          names: ["SI", "NO"],
          logic: (location, filters, row) => {
            return row[9] !== (filters[0] === "SI");
          },
        },
        customBodyRenderLite: (dataIndex) => {
          let proceso = procesos[dataIndex];
          if (proceso !== undefined) {
            return proceso.historico ? "SI" : "NO";
          }
        },
      },
    },
    {
      name: "uuId",
      label: "Link",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          let proceso = procesos[dataIndex];
          if (proceso !== undefined) {
            return (
              <a
                href={PostulanteService.obtieneUrl(proceso.uuId)}
                target="_blank"
              >
                {proceso.uuId}
              </a>
            );
          }
        },
      },
    },
    {
      name: "activo",
      label: "Activa",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: ["Activada", "Desactivada"],
          logic: (location, filters, row) => {
            if (filters[0]) {
              return row[11] !== (filters[0] === "Activada");
            }
            return false;
          },
        },
        customFilterListOptions: {
          render: (v) => `Estado: ${v}`,
        },
        customBodyRenderLite: (dataIndex) => {
          let proceso = procesos[dataIndex];
          return (
            <CustomSwitch
              onSwitch={() => {
                setTipoAlerta("warn");
                setMensaje(
                  `¿Desea ${
                    proceso.activo ? "Desactivar" : "Acticar"
                  } el proceso: ${proceso.nombreCargo}?`
                );
                setProceso(proceso);
                funcion.aceptaModal = actualizaProceso;
                setConfirmacion(true);
                setAlertaVisible(true);
              }}
              texto={proceso.activo ? "Activada" : "Desactivada"}
              estado={proceso.activo}
            />
          );
        },
      },
    },
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let proceso = procesos[dataIndex];
          return (
            <View style={{ flexDirection: "row" }}>
              <IconButton
                icon="pencil"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  navigation.navigate("Proceso", {
                    proceso: proceso,
                  });
                }}
              />
              <IconButton
                icon="delete"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setTipoAlerta("warn");
                  setMensaje(
                    `¿Desea eliminar el proceso: ${proceso.nombreCargo}?`
                  );
                  setProceso(proceso);
                  funcion.aceptaModal = eliminaProceso;
                  setConfirmacion(true);
                  setAlertaVisible(true);
                }}
              />
            </View>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    if (procesos.length === 0) {
      obtieneProcesos();
    }
    if (route.params?.proceso) {
      obtieneProcesos();
    }
  }, [route.params?.proceso]);

  return (
    <View>
      {procesos.length === 0 && (
        <LoadingIndicator texto={"Cargando Procesos"} />
      )}
      {procesos.length > 0 && (
        <CustomMuiDataTable data={procesos} columnas={columnas} />
      )}
      <CustomFabGroup
        visible={procesos.length > 0}
        listaBotones={[
          {
            icon: "plus",
            label: "Nuevo proceso",
            onPress: () => {
              navigation.navigate("Proceso", {
                proceso: { 
                  idProceso: -1,
                  empresa:{
                    nombre: "Seleccione Empresa",
                    id: -1,
                  },
                  cargo: {
                    nombre: "Seleccione Cargo",
                    idCargo: -1,
                  }
                },
              });
            },
          },
          {
            icon: "refresh",
            label: "Recargar",
            onPress: () => {
              obtieneProcesos();
            },
          },
        ]}
      />
      <Alerta
        confirmacionModal={confirmacion}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          if (proceso !== undefined) {
            funcion.aceptaModal(proceso);
          }
          obtieneProcesos();
        }}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
