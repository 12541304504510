import React from "react";
import { Dimensions, View } from "react-native";
import {
  IconButton,
  List
} from "react-native-paper";
import CustomFabGroup from "../../components/CustomFabGroup";
import CustomMuiDataTable from "../../components/CustomMuiDataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import UserService from "../../services/UserService";
import Formato from "../../utils/Formato";
import Alerta from "../modal/Alerta";

const windowHeight = Dimensions.get("window").height;

export default function MantenedorUsuario({ navigation, route, user }) {
  const [usuarios, setUsuarios] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [usuario, setUsuario] = React.useState();
  const [confirmacion, setConfirmacion] = React.useState(false);
  const [funcion] = React.useState({
    aceptaModal: () => {},
  });
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [state, setState] = React.useState({ open: false });
  const { open } = state;

  const formato = new Formato();

  const onStateChange = ({ open }) => {
    setState({ open });
  };

  const obtieneUsuarios = () => {
    UserService.obtieneUsuarios(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          //console.table(respuesta.entidad);
          setUsuarios(respuesta.entidad);
        }
      } else {
        setUsuarios([]);
        setTipoAlerta("error");
        setMensaje(`Se ha producido un error al obtener los usuarios.`);
        setAlertaVisible(true);
      }
    });
  };

  const obtieneRoles = () => {
    UserService.obtieneRoles(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setRoles(respuesta.entidad);
        }
      } else {
        setRoles([]);
        setTipoAlerta("error");
        setMensaje(`Se ha producido un error al obtener los roles.`);
        setAlertaVisible(true);
      }
    });
  };

  const eliminaUsuario = (usuario) => {
    setUsuario(undefined);
    UserService.eliminaUsuario(user.token, usuario.id).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(
          `Usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM} eliminado exitosamente.`
        );
      } else {
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al eliminar el usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM}.`
        );
      }
      setAlertaVisible(true);
    });
  };

  const actualizaUsuario = (usuario) => {
    setUsuario(undefined);
    UserService.actualizaUsuario(user.token, usuario).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(
          `Usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM} actualizado exitosamente.`
        );
      } else {
        usuario.activa = !usuario.activa;
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al actualizar el usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM}`
        );
      }
      setAlertaVisible(true);
    });
  };

  const columnas = [
    {
      name: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
      },
    },
    {
      name: "firstName",
      label: "Nombre",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Nombre: ${v}`,
        },
      },
    },
    {
      name: "lastName",
      label: "Apellido Paterno",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Apellido Paterno: ${v}`,
        },
      },
    },
    {
      name: "lastNameM",
      label: "Apellido Materno",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Apellido Materno: ${v}`,
        },
      },
    },
    {
      name: "email",
      label: "Correo electrónico",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Correo electrónico: ${v}`,
        },
      },
    },
    /*{
      name: "Fecha Creación",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let usuario = usuarios[dataIndex];
          return usuario.created === null
            ? ""
            : formato.fecha_Texto(usuario.created);
        },
      },
    },
    {
      name: "Fecha Ultima Actualización",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let usuario = usuarios[dataIndex];
          return usuario.lastUpdate === null
            ? ""
            : formato.fecha_Texto(usuario.lastUpdate);
        },
      },
    },*/
    {
      name: "Roles",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let usuario = usuarios[dataIndex];
          return (
            <div>
              <List.Item
                style={{ padding: 0 }}
                title={usuario.userRole?.description}
              />
            </div>
          );
        },
      },
    },
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let usuario = usuarios[dataIndex];
          return (
            <View style={{ flexDirection: "row" }}>
              <IconButton
                icon="pencil"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  navigation.navigate("Usuario", {
                    usuario: usuario,
                    roles: roles,
                  });
                }}
              />
              <IconButton
                icon="delete"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setTipoAlerta("warn");
                  setMensaje(
                    `¿Desea eliminar el usuario: ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM}?`
                  );
                  setUsuario(usuario);
                  funcion.aceptaModal = eliminaUsuario;
                  setConfirmacion(true);
                  setAlertaVisible(true);
                }}
              />
            </View>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    if (usuarios === null) {
      obtieneUsuarios();
    }
    if (roles.length === 0) {
      obtieneRoles();
    }
    if (route.params?.usuario) {
      obtieneUsuarios();
    }
  }, [route.params?.usuario]);

  return (
    <View>
      {usuarios === null && <LoadingIndicator text={"Cargando Usuarios"} />}
      {usuarios !== null && usuarios.length > 0 && (
        <CustomMuiDataTable data={usuarios} columnas={columnas} />
      )}
      <CustomFabGroup
        listaBotones={[
          {
            icon: "plus",
            label: "Nueva usuario",
            onPress: () => {
              navigation.navigate("Usuario", {
                usuario: { id: -1 },
                roles: roles,
              });
            },
          },
          {
            icon: "refresh",
            label: "Recargar usuarios",
            onPress: () => {
              obtieneUsuarios();
            },
          },
        ]}
        visible={usuarios !== null && usuarios.length > 0}
      />
      <Alerta
        confirmacionModal={confirmacion}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          if (usuario !== undefined) {
            funcion.aceptaModal(usuario);
          }
          if (usuarios.length > 0) {
            obtieneUsuarios();
          }
        }}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
