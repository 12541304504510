import React from "react";
import { View } from "react-native";
import CustomButton from "../../components/CustomButton";
import CustomDatepicker from "../../components/CustomDatepicker";
import CustomListSelect from "../../components/CustomListSelect";
import CustomSwitch from "../../components/CustomSwitch";
import CustomTextInput from "../../components/CustomTextInput";
import EmpresaService from "../../services/EmpresaService";
import CargoService from "../../services/CargoService";
import ProcesosService from "../../services/ProcesosService";
import styles from "../../styles/style";
import Alerta from "../modal/Alerta";
import Spinner from "../modal/Spinner";

export default function Proceso({
  user,
  route,
  navigation,
  onUpdate,
  setHeaderText,
}) {
  const { proceso } = route.params;
  //const [historico, setHistorico] = React.useState(false);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [mensajeSpinner, setMensajeSpinner] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [regiones, setRegiones] = React.useState([]);
  const [empresas, setEmpresas] = React.useState([]);
  const [cargos, setCargos] = React.useState([]);

  const resetSelect = () => {
    delete proceso["fechaInicioProceso"];
    delete proceso["fechaInicioPostulacion"];
    delete proceso["fechaInicioTest"];
    delete proceso["fechaInicioEvaluacion"];
    delete proceso["fechaInicioContratacion"];
  };

  const accionModal = () => {
    proceso["agregar"] = false;
    if (proceso.idProceso === -1) {
      proceso["idCargo"] = proceso.cargo.idCargo;
      proceso["nombreEmpresa"] = proceso.empresa.nombre;
      proceso["nombreCargo"] = proceso.cargo.nombre;
      setMensajeSpinner("Creando Proceso");
      setSpinnerVisible(true);
      proceso["activo"] = false;
      delete proceso.idProceso;
      ProcesosService.creaProceso(user.token, proceso).then((respuesta) => {
        setSpinnerVisible(false);
        if (respuesta.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Proceso ${proceso.nombreCargo} creado exitosamente.`);
          proceso["agregar"] = true;
          resetSelect();
          onUpdate();
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al crear el proceso ${proceso.nombreCargo}`
          );
        }
        setAlertaVisible(true);
      });
    } else {
      setMensajeSpinner("Actualizando Proceso");
      setSpinnerVisible(true);
      if (proceso["historico"]) {
        proceso["activo"] = false;
      }
      ProcesosService.actualizaProceso(user.token, proceso).then((resp) => {
        setSpinnerVisible(false);
        if (resp.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(
            `Proceso ${proceso.nombreCargo} actualizada exitosamente.`
          );
          resetSelect();
          onUpdate();
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al actualizar la proceso ${proceso.nombreCargo}`
          );
        }
        setAlertaVisible(true);
      });
    }
  };

  const simulaFechas = (empresa) => {
    ProcesosService.simulaFechas(user.token, empresa).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        proceso["fechaInicioProceso"] = resp.entidad.fechaInicioProceso;
        proceso["fechaInicioPostulacion"] = resp.entidad.fechaFinPostulacion;
        proceso["fechaInicioTest"] = resp.entidad.fechaFinTest;
        proceso["fechaInicioEvaluacion"] = resp.entidad.fechaFinEvaluacion;
        proceso["fechaInicioContratacion"] = resp.entidad.fechaFinContratacion;
        setActualizar(!actualizar);
      } else {
        if (resp.codigoRespuesta === 210) {
          setMensaje(resp.mensaje);
          setTipoAlerta("warn");
        } else {
          setMensaje("Se produjo un error intentelo mas tarde.");
          setTipoAlerta("error");
        }
        setAlertaVisible(true);
      }
    });
  };

  const obtieneListaDatos = (datos, setFunction) => {
    EmpresaService.datosAdicionales(user.token, datos).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setFunction(resp.entidad);
      }
    });
  };

  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresasTodas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setEmpresas(respuesta.entidad);
        }
      }
    });
  };

  const obtieneCargos = (empresa) => {
    CargoService.obtieneCargos(user.token, empresa.id).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setCargos(respuesta.entidad);
        }
      }
    });
  };

  React.useEffect(() => {
    setHeaderText(
      proceso.idProceso === -1 ? "Nuevo Proceso" : "Editar Proceso"
    );
    if (regiones.length === 0) {
      obtieneListaDatos("region", setRegiones);
    }
    if (empresas.length === 0) {
      obtieneEmpresas();
    }
  }, [proceso.idProceso, actualizar]);

  return (
    <View style={{ padding: 20 }}>
      <CustomListSelect
        label="Empresa"
        nombreLista="Seleccione Empresa"
        nombreValue="id"
        nombreLabel="nombre"
        value={proceso.empresa}
        lista={empresas}
        onChange={(items) => {
          if (items[0] !== undefined) {
            proceso.empresa = items[0];
            obtieneCargos(items[0]);
            simulaFechas(items[0]);
          }
          proceso.cargo = {
            nombre: "Seleccione Cargo",
            idCargo: -1,
          };
          setActualizar(!actualizar);
        }}
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      <CustomListSelect
        label="Cargo"
        nombreLista="Seleccione Cargo"
        nombreValue="idCargo"
        nombreLabel="nombre"
        disabled={proceso.empresa.id === -1}
        value={proceso.cargo}
        lista={proceso.empresa.id !== -1 ? cargos : []}
        onChange={(items) => {
          if (items[0] !== undefined) {
            proceso.cargo = items[0];
          }
          setActualizar(!actualizar);
        }}
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      <CustomTextInput
        style={styles.inputProceso}
        label="Descripcion"
        disabled={proceso.cargo.idCargo === -1}
        value={
          proceso.descripcionProceso === undefined
            ? ""
            : proceso.descripcionProceso
        }
        onChange={(text) => (proceso["descripcionProceso"] = text)}
        requiered={true}
      />
      <CustomListSelect
        label="Region del Proceso"
        nombreLista="Seleccione Region"
        nombreValue="value"
        nombreLabel="label"
        disabled={proceso.cargo.idCargo === -1}
        value={
          proceso.region === undefined
            ? []
            : { value: proceso.region.idRegion, label: proceso.region.nombre }
        }
        lista={regiones}
        onChange={(items) => {
          if (items[0] !== undefined) {
            proceso["region"] = {
              idRegion: items[0].value,
              nombre: items[0].label,
            };
          }
          setActualizar(!actualizar);
        }}
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      <CustomTextInput
        numberOfLines={5}
        multiline={true}
        maxLength={2000}
        style={styles.inputCargo}
        label="Publicación del Proceso"
        disabled={proceso.cargo.idCargo === -1}
        value={
          proceso.descripcionCargo === undefined ? "" : proceso.descripcionCargo
        }
        onChange={(text) => {
          proceso["descripcionCargo"] = text;
          setActualizar(!actualizar);
        }}
        requiered={true}
      />
      <CustomDatepicker
        style={styles.inputProceso}
        disabled={proceso.empresa.id === -1}
        label="Fecha Inicio Proceso"
        value={
          proceso.fechaInicioProceso === undefined
            ? ""
            : proceso.fechaInicioProceso
        }
        onChange={(text) => (proceso.fechaInicioProceso = text)}
        requiered={true}
      />
      <CustomDatepicker
        style={styles.inputProceso}
        disabled={proceso.empresa.id === -1}
        label="Fecha Inicio Postulacion"
        value={
          proceso.fechaInicioPostulacion === undefined
            ? ""
            : proceso.fechaInicioPostulacion
        }
        onChange={(text) => (proceso.fechaInicioPostulacion = text)}
        requiered={true}
      />
      <CustomDatepicker
        style={styles.inputProceso}
        disabled={proceso.empresa.id === -1}
        label="Fecha Inicio Test"
        value={
          proceso.fechaInicioTest === undefined ? "" : proceso.fechaInicioTest
        }
        onChange={(text) => (proceso.fechaInicioTest = text)}
        requiered={true}
      />
      <CustomDatepicker
        style={styles.inputProceso}
        disabled={proceso.empresa.id === -1}
        label="Fecha Inicio Evaluacion"
        value={
          proceso.fechaInicioEvaluacion === undefined
            ? ""
            : proceso.fechaInicioEvaluacion
        }
        onChange={(text) => (proceso.fechaInicioEvaluacion = text)}
        requiered={true}
      />
      <CustomDatepicker
        style={styles.inputProceso}
        disabled={proceso.empresa.id === -1}
        label="Fecha Inicio Contratacion"
        value={
          proceso.fechaInicioContratacion === undefined
            ? ""
            : proceso.fechaInicioContratacion
        }
        onChange={(text) => (proceso.fechaInicioContratacion = text)}
        requiered={true}
      />
      <CustomSwitch
        texto={"Historico"}
        disabled={proceso.cargo.idCargo === -1}
        estado={proceso.historico}
        onSwitch={() => {
          proceso.historico = !proceso.historico;
          setActualizar(!actualizar);
        }}
      />
      <CustomButton textoButton="Aceptar" onPress={() => accionModal()} />
      <Spinner mensaje={mensajeSpinner} visible={spinnerVisible} />
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() =>
          navigation.navigate({
            name: "Mantenedor Proceso",
            params: { proceso: proceso },
            merge: true,
          })
        }
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
