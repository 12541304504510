import React from "react";
import { Dimensions, View } from "react-native";
import { Dialog, List, Text, TextInput } from "react-native-paper";
import CustomConfirmacion from "../components/CustomConfirmacion";
import TablaDetallePostulante from "../components/TablaDetallePostulante";
import ProcesosService from "../services/ProcesosService";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";
import Formato from "../utils/Formato";
import Alerta from "./modal/Alerta";
import "../styles/detalle.css";

const windowHeight = Dimensions.get("window").height;

const formato = Formato();

export default function DetalleProceso({ user, route, navigation }) {
  const { proceso } = route.params;
  const [visible, setVisible] = React.useState(false);
  const [comentario, setComentario] = React.useState(null);
  const [textoComentario, setTextoComentario] = React.useState("");
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);

  const actualizarComentario = () => {
    let comentarioAct = {
      idComentarioProceso: comentario.idComentario,
      comentario: textoComentario,
    };
    ProcesosService.actualizarComentario(user.token, comentarioAct).then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Comentario actualizado exitosamente.`);
          comentario.mensaje = textoComentario;
          comentario.fechaCreacion = formato.fecha_Texto_YYYY_MM_DD(
            new Date(),
            "-"
          );
        } else {
          setTipoAlerta("error");
          setMensaje(`Se ha producido un error al actualizar el comentario`);
        }
        setComentario(null);
        setAlertaVisible(true);
      }
    );
  };

  return (
    <View nativeID="detalle-contenedor">
      <TablaDetallePostulante
        lista={[
          {
            nombre: "Unidad",
            valor: proceso.unidad,
          },
          {
            nombre: "Cargo",
            valor: proceso.cargo,
          },
          {
            nombre: "Proceso",
            valor: proceso.nombre,
          },
          {
            nombre: "Fecha Inicio",
            valor: proceso.fecha_inicio,
          },
          {
            nombre: "Fecha Fin",
            valor: proceso.fecha_fin,
          },
          {
            nombre: "Fecha Fin",
            valor: proceso.fecha_inicio,
          },
        ]}
      />
      <List.Accordion
        titleStyle={{ color: ColoresPineal.azulOscuro }}
        descriptionStyle={{ color: ColoresPineal.azulOscuro }}
        style={{
          backgroundColor: ColoresPineal.grisAzulado,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16, color: ColoresPineal.azulOscuro }}>
              Etapas
            </Text>
          </View>
        }
      >
        {proceso.etapas.map((etapa, i) => {
          return (
            <TablaDetallePostulante
              titulo={`Etapa: ${etapa.nombre}`}
              lista={[
                {
                  nombre: `Total Postulantes`,
                  valor: etapa.total,
                },
                {
                  nombre: `Fecha Incio`,
                  valor: etapa.fechaInicio,
                },
                {
                  nombre: `Fecha Fin`,
                  valor: etapa.fechaFin,
                },
              ]}
            />
          );
        })}
      </List.Accordion>
      <List.Accordion
        titleStyle={{ color: ColoresPineal.azulOscuro }}
        descriptionStyle={{ color: ColoresPineal.azulOscuro }}
        style={{
          backgroundColor: ColoresPineal.grisAzulado,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16, color: ColoresPineal.azulOscuro }}>
              Comentarios
            </Text>
          </View>
        }
      >
        {proceso.comentarios.map((comentario, i) => {
          return (
            <TablaDetallePostulante
              titulo={`Comentario ${i + 1}`}
              icon={comentario.editable ? "pencil" : ""}
              onPressIcon={() => {
                setComentario(comentario);
                setTextoComentario(comentario.mensaje);
                setVisible(true);
              }}
              lista={[
                {
                  nombre: "Fecha",
                  valor: comentario.fechaCreacion,
                },
                {
                  nombre: "Creador",
                  valor: comentario.nombreUsuario,
                },
                {
                  nombre: "Mensaje",
                  valor: comentario.mensaje,
                },
              ]}
            />
          );
        })}
      </List.Accordion>
      <Dialog visible={visible} dismissable={false} style={styles.modal}>
        <Dialog.Title>{`Editar Comentario: ${proceso.nombre}`}</Dialog.Title>
        <Dialog.Content>
          <TextInput
            value={textoComentario}
            onChangeText={(text) => setTextoComentario(text)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <CustomConfirmacion
            onPressCancelar={() => setVisible(false)}
            onPressAceptar={() => {
              setVisible(false);
              actualizarComentario();
            }}
            disabled={comentario === null}
          />
        </Dialog.Actions>
      </Dialog>
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
