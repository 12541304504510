import React from "react";
import { View } from "react-native";
import { Text, List } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import CustomButton from "./CustomButton";
import TablaDetallePostulante from "./TablaDetallePostulante";

export default function AcordionEducacionPostulante({
  titulo,
  expanded,
  postulante,
  editar,
  nroAcordion,
  setNroAcordion,
  listaIdiomas,
  tiposEntidades,
  instituciones,
  carreras,
}) {
  const [certificaciones, setCertificaciones] = React.useState(
    postulante.certificaciones
  );
  const [cursos, setCursos] = React.useState(postulante.cursos);
  const [idiomas, setIdiomas] = React.useState(postulante.idiomas);
  const [educacion, setEducacion] = React.useState(postulante.educacion);
  const [actualizar, setActualizar] = React.useState(false);

  const agregaItem = (nombreLista, setLista = () => {}) => {
    let listaNueva = [];
    switch (nombreLista) {
      case "certificaciones":
        listaNueva = [
          ...certificaciones,
          {
            nombre: "",
          },
        ];
        break;
      case "cursos":
        listaNueva = [
          ...cursos,
          {
            nombre: "",
          },
        ];
        break;
      case "idiomas":
        listaNueva = [
          ...idiomas,
          {
            idioma: "",
            nivelEscrito: "",
            nivelOral: "",
          },
        ];
        break;
      case "educacion":
        listaNueva = [
          ...educacion,
          {
            tipoEntidadEducativa: 1,
          },
        ];
        break;
    }
    postulante[nombreLista] = listaNueva;
    setLista(listaNueva);
    setActualizar(!actualizar);
  };

  const eliminaItem = (nombreLista, indexEliminar, setLista = () => {}) => {
    let listaAux = [];
    switch (nombreLista) {
      case "certificaciones":
        certificaciones.map((certificacion, index) => {
          if (index !== indexEliminar) {
            listaAux.push(certificacion);
          }
        });
        break;
      case "cursos":
        cursos.map((curso, index) => {
          if (index !== indexEliminar) {
            listaAux.push(curso);
          }
        });
        break;
      case "idiomas":
        idiomas.map((idioma, index) => {
          if (index !== indexEliminar) {
            listaAux.push(idioma);
          }
        });
        break;
      case "educacion":
        educacion.map((item, index) => {
          if (index !== indexEliminar) {
            listaAux.push(item);
          }
        });
        break;
    }
    postulante[nombreLista] = listaAux;
    setLista(listaAux);
    setActualizar(!actualizar);
  };

  return (
    <List.Accordion
      titleStyle={{ color: ColoresPineal.blanco }}
      descriptionStyle={{ color: ColoresPineal.blanco }}
      expanded={expanded}
      onPress={() =>
        nroAcordion === 2 ? setNroAcordion(-1) : setNroAcordion(2)
      }
      style={{
        backgroundColor: ColoresPineal.azulOscuro,
        borderBottomColor: ColoresPineal.blanco,
        borderBottomWidth: 1,
      }}
      title={
        <View>
          <Text style={{ fontSize: 20, color: ColoresPineal.blanco }}>
            {titulo}
          </Text>
        </View>
      }
    >
      <List.Accordion
        titleStyle={{ color: ColoresPineal.blanco }}
        descriptionStyle={{ color: ColoresPineal.blanco }}
        style={{
          backgroundColor: ColoresPineal.azulRey,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16 }}>Certificaciones</Text>
          </View>
        }
      >
        {certificaciones.map((certificacion, i) => {
          return (
            <TablaDetallePostulante
              lista={[
                {
                  nombre: `Certificación Nº${i + 1}`,
                  valor: certificacion.nombre,
                  edit: editar,
                  campo: "certificaciones",
                  subCampo: "nombre",
                  index: i,
                },
              ]}
              iconRow={
                editar
                  ? [
                      {
                        title: "delete",
                        nombre: "delete",
                        onPressIconRow: () =>
                          eliminaItem("certificaciones", i, setCertificaciones),
                      },
                    ]
                  : undefined
              }
              postulante={postulante}
            />
          );
        })}
        {certificaciones.length === 0 && (
          <TablaDetallePostulante
            titulo={"Sin certificaciones"}
            style={{ backgroundColor: ColoresPineal.grisAzulado }}
            styleTitle={{ color: ColoresPineal.azulOscuro }}
          />
        )}
        {editar && (
          <CustomButton
            textoButton="Agregar Certificación"
            onPress={() => agregaItem("certificaciones", setCertificaciones)}
          />
        )}
      </List.Accordion>
      <List.Accordion
        titleStyle={{ color: ColoresPineal.blanco }}
        descriptionStyle={{ color: ColoresPineal.blanco }}
        style={{
          backgroundColor: ColoresPineal.azulRey,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16 }}>Cursos</Text>
          </View>
        }
      >
        {cursos.map((curso, i) => {
          return (
            <TablaDetallePostulante
              lista={[
                {
                  nombre: `Curso Nº${i + 1}`,
                  valor: curso.nombre,
                  edit: editar,
                  campo: "cursos",
                  subCampo: "nombre",
                  index: i,
                },
              ]}
              iconRow={
                editar
                  ? [
                      {
                        title: "delete",
                        nombre: "delete",
                        onPressIconRow: () =>
                          eliminaItem("cursos", i, setCursos),
                      },
                    ]
                  : undefined
              }
              postulante={postulante}
            />
          );
        })}

        {cursos.length === 0 && (
          <TablaDetallePostulante
            titulo={"Sin Cursos"}
            style={{ backgroundColor: ColoresPineal.grisAzulado }}
            styleTitle={{ color: ColoresPineal.azulOscuro }}
          />
        )}
        {editar && (
          <CustomButton
            textoButton="Agregar Curso"
            onPress={() => agregaItem("cursos", setCursos)}
          />
        )}
      </List.Accordion>
      <List.Accordion
        titleStyle={{ color: ColoresPineal.blanco }}
        descriptionStyle={{ color: ColoresPineal.blanco }}
        style={{
          backgroundColor: ColoresPineal.azulRey,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16 }}>Estudios</Text>
          </View>
        }
      >
        {educacion.map((ed, i) => {
          var tipoEntidad = tiposEntidades.find(
            (t) => parseInt(t.value) === ed.tipoEntidadEducativa
          );
          var lista = [];
          var notaEgreso = ed.notaEgreso;
          if (notaEgreso == null) {
            notaEgreso = "";
          } else if (notaEgreso.toString().indexOf(".") === -1) {
            notaEgreso = notaEgreso.toString();
            notaEgreso = notaEgreso.charAt(0) + "." + notaEgreso.charAt(1);
          }
          switch (ed.tipoEntidadEducativa) {
            case 1:
            case 5:
            case 4:
              lista.push({
                nombre: "Tipo Entidad Educativa",
                valor: tipoEntidad === undefined ? "" : tipoEntidad.label,
                lista: tiposEntidades,
                edit: editar,
                campo: "educacion",
                subCampo: "tipoEntidadEducativa",
                index: i,
              });
              lista.push({
                nombre: "Nombre Entidad Educativa",
                valor: ed.nombreEntEducativa,
                edit: editar,
                campo: "educacion",
                subCampo: "entEducativa",
                index: i,
                lista: instituciones,
              });
              if (ed.entEducativa === "205") {
                lista.push({
                  nombre: "Otra Entidad Educativa",
                  valor: ed.otro,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "otro",
                  index: i,
                });
              }
              lista.push({
                nombre: "Nombre Carrera",
                valor: ed.nombreCarrera,
                edit: editar,
                campo: "educacion",
                subCampo: "carrera",
                index: i,
                lista: carreras,
              });
              if (ed.carrera === "345") {
                lista.push({
                  nombre: "Otra Carrera",
                  valor: ed.otraCarrera,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "otraCarrera",
                  index: i,
                });
              }
              lista.push({
                nombre: "Nota de egreso",
                valor: ed.notaEgreso,
                edit: editar,
                campo: "educacion",
                subCampo: "notaEgreso",
                index: i,
                valueType: "nota",
                maxLength: 2,
              });
              if (ed.tipoEntidadEducativa === 4) {
                lista.push({
                  nombre: "Postgrado",
                  valor: ed.postGrado,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "postGrado",
                  index: i,
                });
              }
              break;
            case 2:
            case 3:
              lista = [
                {
                  nombre: "Tipo Entidad Educativa",
                  valor: tipoEntidad === undefined ? "" : tipoEntidad.label,
                  lista: tiposEntidades,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "tipoEntidadEducativa",
                  index: i,
                },
                {
                  nombre: "Liceo",
                  valor: ed.liceo,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "liceo",
                  index: i,
                },
                {
                  nombre: "Año de egreso",
                  valor: ed.anioEgreso,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "anioEgreso",
                  index: i,
                  valueType: "anio",
                  maxLength: 4,
                },
                {
                  nombre: "Nota de egreso",
                  valor: notaEgreso,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "notaEgreso",
                  index: i,
                  valueType: "nota",
                  maxLength: 2,
                },
                {
                  nombre: "Especialidad",
                  valor: ed.especialidad,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "especialidad",
                  index: i,
                },
              ];
              break;
            /* case 4:
              lista = [
                {
                  nombre: "Tipo Entidad Educativa",
                  valor: tipoEntidad === undefined ? "" : tipoEntidad.label,
                  lista: tiposEntidades,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "tipoEntidadEducativa",
                  index: i,
                },
                {
                  nombre: "Nombre Entidad Educativa",
                  valor: ed.nombreEntEducativa,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "nombreEntEducativa",
                  index: i,
                },
                {
                  nombre: "Nombre Entidad Educativa",
                  valor: ed.otro,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "otro",
                  index: i,
                },
                {
                  nombre: "Nombre Carrera",
                  valor: ed.nombreCarrera,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "nombreCarrera",
                  index: i,
                },
                {
                  nombre: "Nombre Carrera",
                  valor: ed.otraCarrera,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "otraCarrera",
                  index: i,
                },
                {
                  nombre: "Nota de egreso",
                  valor: notaEgreso,
                  edit: editar,
                  campo: "educacion",
                  subCampo: "notaEgreso",
                  index: i,
                  valueType: "nota",
                  maxLength: 2,
                },
                {
                },
              ];
              break; */
          }

          return (
            <TablaDetallePostulante
              key={`contenedot_estudio_${i}`}
              titulo={`Educación Nº${i + 1}`}
              lista={lista}
              icon={editar ? "delete" : undefined}
              onPressIcon={
                editar
                  ? () => eliminaItem("educacion", i, setEducacion)
                  : undefined
              }
              postulante={postulante}
              actualizarPadre={() => setActualizar(!actualizar)}
            />
          );
        })}

        {educacion.length === 0 && (
          <TablaDetallePostulante
            titulo={"Sin Educación"}
            style={{ backgroundColor: ColoresPineal.grisAzulado }}
            styleTitle={{ color: ColoresPineal.azulOscuro }}
          />
        )}
        {editar && (
          <CustomButton
            textoButton="Agregar Educación"
            onPress={() => agregaItem("educacion", setEducacion)}
          />
        )}
      </List.Accordion>

      <List.Accordion
        titleStyle={{ color: ColoresPineal.blanco }}
        descriptionStyle={{ color: ColoresPineal.blanco }}
        style={{
          backgroundColor: ColoresPineal.azulRey,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 16 }}>Idiomas</Text>
          </View>
        }
      >
        {idiomas.map((idioma, i) => {
          return (
            <TablaDetallePostulante
              key={`tabla_idioma_${i}`}
              titulo={`Idioma ${i + 1}`}
              postulante={postulante}
              lista={[
                {
                  nombre: "Nombre",
                  valor: idioma.nombreIdioma,
                  edit: editar,
                  campo: "idiomas",
                  subCampo: "idioma",
                  value: idioma.idioma,
                  lista: listaIdiomas,
                  index: i,
                },
                {
                  nombre: "Nivel Escrito",
                  valor: idioma.nivelEscrito,
                  edit: editar,
                  campo: "idiomas",
                  subCampo: "nivelEscrito",
                  value: idioma.nivelEscrito,
                  lista: [
                    { label: "Básico", value: "Básico" },
                    { label: "Intermedio", value: "Intermedio" },
                    { label: "Avanzado", value: "Avanzado" },
                  ],
                  index: i,
                },
                {
                  nombre: "Nivel Oral",
                  valor: idioma.nivelOral,
                  edit: editar,
                  campo: "idiomas",
                  subCampo: "nivelOral",
                  value: idioma.nivelOral,
                  lista: [
                    { label: "Básico", value: "Básico" },
                    { label: "Intermedio", value: "Intermedio" },
                    { label: "Avanzado", value: "Avanzado" },
                  ],
                  index: i,
                },
              ]}
              icon={editar ? "delete" : undefined}
              onPressIcon={
                editar ? () => eliminaItem("idiomas", i, setIdiomas) : undefined
              }
            />
          );
        })}

        {idiomas.length === 0 && (
          <TablaDetallePostulante
            titulo={"Sin Idiomas"}
            style={{ backgroundColor: ColoresPineal.grisAzulado }}
            styleTitle={{ color: ColoresPineal.azulOscuro }}
          />
        )}
        {editar && (
          <CustomButton
            textoButton="Agregar Idioma"
            onPress={() => agregaItem("idiomas", setIdiomas)}
          />
        )}
      </List.Accordion>
    </List.Accordion>
  );
}
