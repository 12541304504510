import React from "react";
import { View } from "react-native";
import CustomStepIndicator from "../components/CustomStepIndicator";

export default function Test({ navigation }) {
  return (
    <View>
      <CustomStepIndicator />
    </View>
  );
}
