import React, { useState } from "react";
import postulanteService from "../../../services/PostulanteService";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const useStyle = makeStyles({
  btnActivo: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#f47C06",
    variant: "outlined",
    fontSize: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover, &:focus": {
      color: "#20314A",
    },
  },
  tablaTh: {
    color: "#20314A",
    backgroundColor: "#C7D4DC",
    fontSize: 12,
    textAlign: "left",
  },
  tablaTd: {
    color: "#20314A",
    backgroundColor: "#FFF",
    fontSize: 12,
    textAlign: "center",
  },
});
const DetallePostulantes = ({
  children,
  NombreCompleto,
  DatProceso,
  DatIdPostulante,
  token,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [datosPostulantes, setDatosPostulantes] = React.useState();
  const obtienedetallePostulante = () => {
    postulanteService
      .obtienePostulanteId(token, DatProceso, DatIdPostulante)
      .then((resp) => {
        setDatosPostulantes([resp.entidad]);
      });
  };
  React.useEffect(() => {
    if (datosPostulantes === undefined) {
      obtienedetallePostulante();
    }
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="#f47C06"
        className={classes.btnActivo}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {children} de: {NombreCompleto}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {datosPostulantes !== undefined &&
              datosPostulantes.map((idioma) => {
                return (
                  idioma !== undefined &&
                  idioma.educacion !== undefined &&
                  idioma.educacion.map((user) => {
                    return (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.tablaTh}>
                                Año egreso: {user.anioEgreso}, Carrera:{" "}
                                {user.nombreCarrera}, Entidad:{" "}
                                {user.nombreEntEducativa}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    );
                  })
                );
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
export default DetallePostulantes;
