import DocumentosService from "../../../services/DocumentosService";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { IconButton } from "react-native-paper";

const useStyle = makeStyles({
  btnActivo: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#f47C06",
    variant: "outlined",
    fontSize: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover, &:focus": {
      color: "#20314A",
    },
  },
  tablaTh: {
    color: "#20314A",
    backgroundColor: "#C7D4DC",
    fontSize: 12,
    textAlign: "left",
  },
  tablaTd: {
    color: "#20314A",
    backgroundColor: "#FFF",
    fontSize: 12,
    textAlign: "center",
  },
});

const Adjunto = ({
  children,
  NombreCompleto,
  DatIdProceso,
  DatIdPostulante,
  token,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [adjuntos, setAdjuntos] = React.useState();

  const obtieneAdjuntos = () => {
    DocumentosService.listaDocumentos(token, DatIdPostulante).then((resp) => {
      setAdjuntos(resp);
    });
  };

  const envioDatosPostulanteInforme = (postulante) => {
    let competencias = {
      idPostulante: DatIdPostulante,
      idProceso: DatIdProceso,
      listDatosPostulante: [],
    };
    DocumentosService.obtieneReporteCompetencias(
      token,
      competencias,
      postulante
    ).then((resp) => console.log(resp));
  };

  React.useEffect(() => {
    if (adjuntos === undefined) {
      obtieneAdjuntos();
    }
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="#f47C06"
        className={classes.btnActivo}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {children} de: {NombreCompleto}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {adjuntos !== undefined &&
              adjuntos.map((item) => {
                return (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tablaTh}>
                            <IconButton
                              icon="file-download-outline"
                              size={20}
                              onPress={() =>
                                DocumentosService.obtieneDocumento(token, item)
                              }
                            />
                          </TableCell>
                          <TableCell className={classes.tablaTh}>
                            {item.fileName}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tablaTh}>
                      <IconButton
                        icon="file-download-outline"
                        size={20}
                        onPress={() => envioDatosPostulanteInforme("")}
                      />
                    </TableCell>
                    <TableCell className={classes.tablaTh}>
                      Resultado competencias
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tablaTh}>
                      <IconButton
                        icon="file-download-outline"
                        size={20}
                        onPress={() => {
                          DocumentosService.obtieneDocumentoTest(
                            token,
                            DatIdProceso,
                            DatIdPostulante
                          ).then((respuesta) => {
                            if (
                              respuesta.response.headers
                                .get("Content-Type")
                                .includes("application/json")
                            ) {
                            } else {
                              var a = document.createElement("a");
                              a.href = respuesta.url;
                              document.body.appendChild(a);
                              a.click();
                              a.remove();
                            }
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tablaTh}>
                      Resultado de test
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
export default Adjunto;
