import React from "react";
import { ScrollView, Text } from "react-native";
import { Card, DataTable } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import CustomButton from "./CustomButton";
import DialogoNuevoCampo from "./DialogoNuevoCampo";
import PreguntaEmpresa from "./PreguntaEmpresa";

export default function TablaPregunstasEmpresa({
  heigth,
  preguntas,
  empresa,
  alertaModal,
  user,
}) {
  const [visible, setVisible] = React.useState(false);
  const [preguntaSelec, setPreguntaSelec] = React.useState();

  return (
    <Card style={{ height: heigth }}>
      <Card.Title
        title="Campos Formulario Empresa"
        subtitle="Activar/Desactivar campos para una empresa"
        titleStyle={{ color: ColoresPineal.blanco }}
        subtitleStyle={{ color: ColoresPineal.blanco }}
        style={{ paddingHorizontal: 15, backgroundColor: ColoresPineal.grisAzulado }}
        right={(props) => (
          <CustomButton
            {...props}
            disabled={
              empresa === null || empresa === undefined || empresa.id === -1
            }
            icon="plus-circle-outline"
            onPress={() => {
              setPreguntaSelec({ idFormulario: -1 });
              setVisible(true);
            }}
            textoButton="Nuevo Campo"
          />
        )}
      />
      <Card.Content style={{ height: heigth - 72, paddingBottom: 0 }}>
        <ScrollView>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>ID</DataTable.Title>
              <DataTable.Title>Label</DataTable.Title>
              <DataTable.Title>Tipo</DataTable.Title>
              <DataTable.Title>Largo Máximo</DataTable.Title>
              <DataTable.Title>Obligatorio</DataTable.Title>
              <DataTable.Title>Mascara</DataTable.Title>
              <DataTable.Title>Activar</DataTable.Title>
              <DataTable.Title>Acción</DataTable.Title>
            </DataTable.Header>
            {preguntas.length === 0 && (
              <DataTable.Row>
                <DataTable.Cell>
                  <Text>Sin registros</Text>
                </DataTable.Cell>
              </DataTable.Row>
            )}
            {preguntas.length > 0 &&
              preguntas.map((item, index) => {
                return (
                  <div key={index}>
                    <PreguntaEmpresa
                      alertaModal={alertaModal}
                      user={user}
                      empresa={empresa}
                      pregunta={item}
                      onEdit={() => {
                        setPreguntaSelec(item);
                        setVisible(true);
                      }}
                    />
                  </div>
                );
              })}
          </DataTable>
        </ScrollView>
      </Card.Content>
      <DialogoNuevoCampo
        openModal={visible}
        closeModal={() => setVisible(false)}
        alertaModal={alertaModal}
        componenteId={empresa.id}
        glosaId={"idEmpresa"}
        user={user}
        pregunta={preguntaSelec}
        preguntas={preguntas}
        url="formularioEmpresa"
      />
    </Card>
  );
}
