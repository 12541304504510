import React from "react";
import { Dimensions, View } from "react-native";
import { Button, Dialog, Subheading, TextInput } from "react-native-paper";
import styles from "../styles/style";
import CustomDialog from "./CustomDialog";
import SelectAutocompletar from "./SelectAutocompletar";

const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

export default function CustomListSelect({
  lista,
  nombreLista,
  multiselect = false,
  label,
  onChange,
  nombreValue,
  nombreLabel,
  value,
  disabled,
  agregaElemento,
  idElementoOtro,
  componenteOtro,
  closeOnClick = false,
  showIcon = true,
  top = 80,
  height = "calc(100vh - 110px)",
  onClick = () => {},
  oldForm = false,
  olcutarAcciones = false,
  styleInput = {},
}) {
  const [itemsSeleccionados, setItemsSeleccionados] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [tieneError, setTieneError] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");

  const setItem = (item) => {
    let items = [];
    let valor = nombreValue === undefined ? item : item[nombreValue];
    valor = valor === undefined || valor === null ? "" : valor;
    if (!isItemCheck(valor)) {
      if (multiselect) {
        items = [...item];
      } else {
        items = [item];
      }
    } else {
      itemsSeleccionados.forEach((itemSelec) => {
        if (nombreValue !== undefined) {
          if (item[nombreValue] !== itemSelec[nombreValue]) {
            items.push(itemSelec);
          }
        } else {
          if (item !== itemSelec) {
            items.push(itemSelec);
          }
        }
      });
    }
    setItemsSeleccionados(items);
    onChange(items);
  };

  const isItemCheck = (itemId) => {
    let existeItem = false;
    itemsSeleccionados.forEach((itemSelec) => {
      if (
        itemSelec !== undefined &&
        itemSelec !== null &&
        itemId ===
          (nombreValue !== undefined ? itemSelec[nombreValue] : itemSelec)
      ) {
        existeItem = true;
      }
    });
    return existeItem;
  };

  const obtieneListaTexto = () => {
    if (itemsSeleccionados.length > 0) {
      let lista = "";
      itemsSeleccionados.forEach((item) => {
        if (item !== undefined && item !== null) {
          if (lista.length > 0) {
            lista = lista.concat(",");
          }
          let valor = nombreLabel === undefined ? item : item[nombreLabel];
          lista = lista.concat(valor === undefined ? "" : valor);
        }
      });
      return lista;
    }
    return nombreLista;
  };

  React.useEffect(() => {
    if (value !== undefined) {
      if (value instanceof Array) {
        setItemsSeleccionados(value);
      } else {
        if (typeof value === "string" && value.trim().length === 0) {
          setItemsSeleccionados([]);
        } else {
          setItemsSeleccionados([value]);
        }
      }
    }
  }, [value, visible]);

  return (
    <div
      id="contenedor-custom-list"
      onClick={() => {
        onClick();
        if (disabled === undefined || !disabled) {
          setVisible(true);
        }
      }}
    >
      <View>
        {label !== undefined && label.length > 0 && (
          <Subheading>{label}</Subheading>
        )}
        <TextInput
          style={
            disabled
              ? { backgroundColor: "#b7b5b5", ...styleInput }
              : { ...styleInput }
          }
          disabled={true}
          value={obtieneListaTexto()}
          error={tieneError}
        />
      </View>
      {tieneError && (
        <HelperText style={{ color: Colors.red100 }}>{textoError}</HelperText>
      )}
      <CustomDialog
        height={height}
        top={top}
        visible={visible}
        dismissable={true}
        onDismiss={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        olcutarAcciones={olcutarAcciones}
        oldForm={oldForm}
        titulo={nombreLista === undefined ? "Seleccione" : nombreLista}
        contenido={
          <SelectAutocompletar
            lista={lista}
            maxHeight={"calc(100vh - 411px)"}
            marginBottom={0}
            nombreLabel={nombreLabel}
            nombreValue={nombreValue}
            showIcon={showIcon}
            onSelect={(item) => {
              setItem(item);
              if (
                closeOnClick &&
                nombreValue !== undefined &&
                item[nombreValue] !== idElementoOtro
              ) {
                setVisible(false);
              }
              if (
                closeOnClick &&
                nombreValue === undefined &&
                item !== idElementoOtro
              ) {
                setVisible(false);
              }
            }}
            value={value}
            multiselect={multiselect}
            comoponente={(item) =>
              agregaElemento !== undefined &&
              item[nombreValue] === idElementoOtro &&
              isItemCheck(idElementoOtro) &&
              componenteOtro(nombreValue, idElementoOtro)
            }
          />
        }
        accion={() => setVisible(false)}
      />
    </div>
  );
}
