import React from "react";
import { View } from "react-native";
import { Text, List } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import CustomButton from "./CustomButton";
import TablaDetallePostulante from "./TablaDetallePostulante";

export default function AcordionReferenciasPostulante({
  titulo,
  expanded,
  postulante,
  editar,
  nroAcordion,
  setNroAcordion,
}) {
  const [referencias, setReferencias] = React.useState(postulante.referencias);
  const [actualizar, setActualizar] = React.useState(false);

  const agregarReferencia = () => {
    let listaNueva = [
      ...referencias,
      {
        nombre: "",
        cargo: "",
        email: "",
        telefono: "",
      },
    ];

    postulante["referencias"] = listaNueva;
    setReferencias(listaNueva);
    setActualizar(!actualizar);
  };

  const eliminarReferencia = (indexEliminar) => {
    let listaAux = [];
    referencias.map((referencia, index) => {
      if (index !== indexEliminar) {
        listaAux.push(referencia);
      }
    });

    postulante["referencias"] = listaAux;
    setReferencias(listaAux);
    setActualizar(!actualizar);
  };

  return (
    <List.Accordion
      titleStyle={{ color: ColoresPineal.blanco }}
      descriptionStyle={{ color: ColoresPineal.blanco }}
      expanded={expanded}
      onPress={() =>
        nroAcordion === 4 ? setNroAcordion(-1) : setNroAcordion(4)
      }
      style={{
        backgroundColor: ColoresPineal.azulOscuro,
        borderBottomColor: ColoresPineal.blanco,
        borderBottomWidth: 1,
      }}
      title={
        <View>
          <Text style={{ fontSize: 20, color: ColoresPineal.blanco }}>
            {titulo}
          </Text>
        </View>
      }
    >
      {referencias.map((ref, i) => (
        <TablaDetallePostulante
          key={`referencia_contenedor_${i}`}
          titulo={`Referencia ${i + 1}`}
          icon={editar ? "delete" : undefined}
          onPressIcon={editar ? () => eliminarReferencia(i) : undefined}
          postulante={postulante}
          lista={[
            {
              nombre: "Nombre",
              valor: ref.nombre,
              edit: editar,
              campo: "referencias",
              subCampo: "nombre",
              index: i,
            },
            {
              nombre: "Cargo",
              valor: ref.cargo,
              edit: editar,
              campo: "referencias",
              subCampo: "cargo",
              index: i,
            },
            {
              nombre: "Email",
              valor: ref.email,
              edit: editar,
              campo: "referencias",
              subCampo: "email",
              valueType: "email",
              index: i,
            },
            {
              nombre: "Teléfono",
              valor: ref.telefono,
              edit: editar,
              campo: "referencias",
              subCampo: "telefono",
              valueType: "telefono",
              maxLength: 9,
              index: i,
            },
          ]}
        />
      ))}

      {referencias.length === 0 && (
        <TablaDetallePostulante
          titulo={"Sin Referencias"}
          style={{ backgroundColor: ColoresPineal.grisAzulado }}
          styleTitle={{ color: ColoresPineal.azulOscuro }}
        />
      )}
      {editar && (
        <CustomButton
          textoButton="Agregar Referencia"
          onPress={() => agregarReferencia()}
        />
      )}
    </List.Accordion>
  );
}
