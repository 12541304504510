import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";
import { REACT_APP_URL_BASE_API_POSTULANTE } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtieneTiposReportes: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}datosadicionales/reportes`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listaItems;
          resolve(respuesta);
        });
    });
  },
  obtieneReporte: (token, idEmpresa, idProceso, idReporte) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}reporte/${idEmpresa}/${idProceso}/${idReporte}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = {
            datos: resp.listData,
            tipoGrafico: resp.tipoGrafico,
          };
          resolve(respuesta);
        });
    });
  },
  descargaReporte: (token, idEmpresa, idProceso, reporte) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}reporte/excel/${idEmpresa}/${idProceso}/${reporte.value}`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = reporte.label + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          resolve(respuesta);
        });
    });
  },
  obtieneReportePostulantes: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}reporte/postulantes`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.candidatos;
          resolve(respuesta);
        });
    });
  },

  obtieneReporteGraficoPostulantes: (token, ruts, procesos, graficos) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ruts: ruts,
        procesos: procesos,
        graficos: graficos,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}reporte/excel/grafico`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) throw Error(response.status);
          return response.blob();
        })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "Reporte_graficos.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          respuesta.codigoRespuesta = 200;
          resolve(respuesta);
        })
        .catch((error) => {
          respuesta.codigoRespuesta = -1;
          resolve(respuesta);
        });
    });
  },
};
