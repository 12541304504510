import React from "react";
import { View, Text } from "react-native";
import { Dialog, ActivityIndicator } from "react-native-paper";
import styles from "../../styles/style";

export default function Spinner({
  visible,
  mensaje,
}) {
  React.useEffect(() => {
  }, [mensaje, visible]);

  return (
    <Dialog
      visible={visible}
      dismissable={false}
      style={styles.modal}
    >
      <Dialog.Content>
      <View style={{ justifyContent: "center" }}>
            <ActivityIndicator
              animating={true}
              color="#f6a623"
              size={80}
              style={{ marginTop: 40, marginBottom: 20 }}
            />
            <Text
              style={{
                color: "#6b6b6b",
                textAlign: "center",
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              {mensaje}
            </Text>
          </View>
      </Dialog.Content>
    </Dialog>
  );
}
