import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtieneCargos: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  obtieneCargosTodos: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/todo/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  obtieneCargosTodosv2: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}cargo/todo`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  creaCargo: (token, cargo) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cargo),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}cargo`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  actualizaCargo: (token, cargo) => {
    console.log(JSON.stringify(cargo));
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cargo),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}cargo`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  eliminaCargo: (token, idProceso) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/${idProceso}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  obtienePreguntas: (token, idCargo) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/formulario/postulante/${idCargo}/all`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.preguntas;
          resolve(respuesta);
        });
    });
  },
  obtienePreguntasCargo: (token, idCargo) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/formulario/postulante/${idCargo}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.preguntas;
          resolve(respuesta);
        });
    });
  },
};
