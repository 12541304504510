import React from "react";
import { View } from "react-native";
import { HelperText, List, Subheading, Title } from "react-native-paper";
import CustomButton from "../components/CustomButton";
import CustomDatepicker from "../components/CustomDatepicker";
import CustomDialog from "../components/CustomDialog";
import CustomListSelect from "../components/CustomListSelect";
import CustomTextInput from "../components/CustomTextInput";
import SelectAutocompletar from "../components/SelectAutocompletar";
import ReunionService from "../services/ReunionService";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";
import Formato from "../utils/Formato";
import Alerta from "./modal/Alerta";
import Spinner from "./modal/Spinner";

const formato = Formato();

export default function Reunion({ user, navigation, route, setHeaderText }) {
  const { reunion, procesoIN, onUpdate } = route.params;
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [mensajeSpinner, setMensajeSpinner] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [candidatos, setCandidatos] = React.useState();
  const [usuarios, setUsuarios] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [titulo, setTitulo] = React.useState("");
  const [formulario, setFormulario] = React.useState(() => () => {});
  const [accion, setAccion] = React.useState(() => () => {});
  const [postulanteNuevo] = React.useState({
    id: -1,
    idPostulante: -1,
    rut: "",
    nombre: "",
    apPaterno: "",
    apMaterno: "",
    email: "",
    existe: false,
  });
  const [participanteNuevo] = React.useState({
    id: -1,
    idParticipante: -1,
    nombre: "",
    apPaterno: "",
    apMaterno: "",
    email: "",
    existe: false,
  });
  const [minimaFechaDatePicker, setMinimaFechaDatePicker] = React.useState();
  const [empresas, setEmpresas] = React.useState();
  const [empresa, setEmpresa] = React.useState(
    reunion.empresa === undefined
      ? {
          nombre: "Seleccione Empresa",
          id: -1,
        }
      : reunion.empresa
  );
  const [proceso, setProceso] = React.useState(
    reunion.proceso === undefined
      ? {
          nombre: "Seleccione Proceso",
          id: -1,
        }
      : reunion.proceso
  );

  const resetDatos = () => {
    setProceso({
      nombre: "Seleccione Proceso",
      id: -1,
    });
    setEmpresa({
      nombre: "Seleccione Empresa",
      id: -1,
    });
    setEmpresas(undefined);
    reunion.idReunion = -1;
    setActualizar(!actualizar);
    setMinimaFechaDatePicker(undefined);
  };

  const accionModal = () => {
    if (reunion.idReunion === -1) {
      setMensajeSpinner("Creando Reunión");
      setSpinnerVisible(true);
      delete reunion.idReunion;
      ReunionService.crearReunion(user.token, reunion).then((respuesta) => {
        setSpinnerVisible(false);
        if (respuesta.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Reunión creada exitosamente.`);
          resetDatos();
          if (onUpdate !== undefined) {
            onUpdate();
          }
        } else {
          setTipoAlerta("error");
          setMensaje(`Se ha producido un error al crear el reunión`);
        }
        setAlertaVisible(true);
      });
    } else {
      setMensajeSpinner("Actualizando Reunión");
      setSpinnerVisible(true);
      ReunionService.actualizarReunion(user.token, reunion).then((resp) => {
        setSpinnerVisible(false);
        if (resp.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(`Reunión actualizada exitosamente.`);
          resetDatos();
          if (onUpdate !== undefined) {
            onUpdate();
          }
        } else {
          setTipoAlerta("error");
          setMensaje(`Se ha producido un error al actualizar la reunión`);
        }
        setAlertaVisible(true);
      });
    }
  };

  const comparaRuts = (rut1, rut2) => {
    rut1 = rut1.replace(/[^0-9kK]/g, "");
    rut1 = rut1.toUpperCase();
    rut2 = rut2.replace(/[^0-9kK]/g, "");
    rut2 = rut2.toUpperCase();
    return rut1 === rut2;
  };

  const nuevoPostulante = () => {
    setTitulo("Nuevo Postulante");
    setAccion(() => () => {
      if (
        postulanteNuevo["existe"] !== undefined &&
        !postulanteNuevo["existe"]
      ) {
        if (reunion.postulantes === undefined) {
          reunion["postulantes"] = [
            { ...JSON.parse(JSON.stringify(postulanteNuevo)) },
          ];
        } else {
          reunion["postulantes"] = [
            ...reunion.postulantes,
            { ...JSON.parse(JSON.stringify(postulanteNuevo)) },
          ];
        }
        proceso["postulantes"].push({
          ...JSON.parse(JSON.stringify(postulanteNuevo)),
        });
        postulanteNuevo["id"] = postulanteNuevo["id"] - 1;
        postulanteNuevo["rut"] = "";
        postulanteNuevo["nombre"] = "";
        postulanteNuevo["apPaterno"] = "";
        postulanteNuevo["apMaterno"] = "";
        postulanteNuevo["email"] = "";
        postulanteNuevo["existe"] = false;
        console.table(proceso["postulantes"]);
      }
    });
    setFormulario(() => (actualizar) => (
      <View>
        <CustomTextInput
          value={
            postulanteNuevo["rut"] === undefined ? "" : postulanteNuevo["rut"]
          }
          style={styles.inputCargo}
          onChange={(text) => {
            if (text.trim().length > 0) {
              postulanteNuevo["rut"] = text;
              let existe = candidatos.find((candidato) =>
                comparaRuts(candidato.rut, text)
              );
              if (existe !== undefined) {
                postulanteNuevo["existe"] = true;
              } else {
                postulanteNuevo["existe"] = false;
              }
              setActualizar(!actualizar);
            }
          }}
          label="RUT"
          valueType="rut"
        />
        {postulanteNuevo["existe"] !== undefined &&
          postulanteNuevo["existe"] && (
            <HelperText style={{ color: ColoresPineal.rojoAlerta }}>
              El RUT ingresado ya existe como postulante.
            </HelperText>
          )}
        <CustomTextInput
          value={
            postulanteNuevo["nombre"] === undefined
              ? ""
              : postulanteNuevo["nombre"]
          }
          disabled={
            postulanteNuevo["existe"] === undefined || postulanteNuevo["existe"]
          }
          style={styles.inputCargo}
          onChange={(text) => (postulanteNuevo["nombre"] = text)}
          label="Nombre"
        />
        <CustomTextInput
          value={
            postulanteNuevo["apPaterno"] === undefined
              ? ""
              : postulanteNuevo["apPaterno"]
          }
          disabled={
            postulanteNuevo["existe"] === undefined || postulanteNuevo["existe"]
          }
          style={styles.inputCargo}
          onChange={(text) => (postulanteNuevo["apPaterno"] = text)}
          label="Apellido Paterno"
        />
        <CustomTextInput
          value={
            postulanteNuevo["apMaterno"] === undefined
              ? ""
              : postulanteNuevo["apMaterno"]
          }
          disabled={
            postulanteNuevo["existe"] === undefined || postulanteNuevo["existe"]
          }
          style={styles.inputCargo}
          onChange={(text) => (postulanteNuevo["apMaterno"] = text)}
          label="Apellido Materno"
        />
        <CustomTextInput
          value={
            postulanteNuevo["email"] === undefined
              ? ""
              : postulanteNuevo["email"]
          }
          disabled={
            postulanteNuevo["existe"] === undefined || postulanteNuevo["existe"]
          }
          style={styles.inputCargo}
          onChange={(text) => (postulanteNuevo["email"] = text)}
          label="Email"
          valueType="email"
        />
      </View>
    ));
    setActualizar(!actualizar);
  };

  const buscarPostulante = () => {
    setTitulo(
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 10,
        }}
      >
        <Title>Agregar Postulantes</Title>
        <CustomButton
          textoButton="Nuevo"
          icon="plus"
          onPress={nuevoPostulante}
        />
      </View>
    );
    setAccion(() => () => {
      proceso["postulantes"] = [
        ...(proceso["postulantes"] === undefined ? [] : proceso["postulantes"]),
        ...reunion.postulantesAgregar,
      ];
      if (reunion.postulantes === undefined) {
        reunion["postulantes"] = reunion.postulantesAgregar;
      } else {
        reunion["postulantes"] = [
          ...reunion.postulantes,
          ...reunion.postulantesAgregar,
        ];
      }
      reunion["postulantesAgregar"] = [];
    });
    setFormulario(() => () => (
      <View>
        <SelectAutocompletar
          lista={
            proceso !== undefined && proceso.id !== -1
              ? candidatos.filter(
                  (candidato) => candidato.idProceso !== proceso.id
                )
              : []
          }
          nombreLabel={["rut", "nombre", "apPaterno", "apMaterno", "email"]}
          nombreValue={"id"}
          onSelect={(item) => {
            reunion["postulantesAgregar"] = [];
            item.forEach((element) => {
              reunion["postulantesAgregar"].push({
                id: element.id,
                idPostulante: element.idPostulante,
                rut: element.rut,
                nombre: element.nombre,
                apMaterno: element.apMaterno,
                apPaterno: element.apPaterno,
                email: element.email,
              });
            });
          }}
          showIcon={true}
          multiselect={true}
          maxHeight={200}
          value={
            reunion["postulantesAgregar"] === undefined
              ? []
              : reunion["postulantesAgregar"]
          }
        />
      </View>
    ));
    setVisible(true);
  };

  const nuevoParticipante = () => {
    setTitulo("Participante");
    setAccion(() => () => {
      console.log(participanteNuevo);
      if (
        participanteNuevo["existe"] !== undefined &&
        !participanteNuevo["existe"]
      ) {
        if (reunion.participantes === undefined) {
          reunion["participantes"] = [
            { ...JSON.parse(JSON.stringify(participanteNuevo)) },
          ];
        } else {
          reunion["participantes"] = [
            ...reunion.participantes,
            { ...JSON.parse(JSON.stringify(participanteNuevo)) },
          ];
        }
        usuarios.push({ ...JSON.parse(JSON.stringify(participanteNuevo)) });
        participanteNuevo["id"] = participanteNuevo["id"] - 1;
        participanteNuevo["nombre"] = "";
        participanteNuevo["apPaterno"] = "";
        participanteNuevo["apMaterno"] = "";
        participanteNuevo["email"] = "";
        participanteNuevo["existe"] = false;
        console.table(usuarios);
      }
    });
    setFormulario(() => (actualizar) => (
      <View>
        <CustomTextInput
          value={
            participanteNuevo["email"] === undefined
              ? ""
              : participanteNuevo["email"]
          }
          style={styles.inputCargo}
          onChange={(text) => {
            if (text.trim().length > 0) {
              participanteNuevo["email"] = text;
              let existe = usuarios.find(
                (usuario) => usuario.email.toUpperCase() === text.toUpperCase()
              );
              if (existe !== undefined) {
                participanteNuevo["existe"] = true;
              } else {
                participanteNuevo["existe"] = false;
              }
              console.table(participanteNuevo);
              setActualizar(!actualizar);
            }
          }}
          label="Email"
          valueType="email"
        />
        {participanteNuevo["existe"] !== undefined &&
          participanteNuevo["existe"] && (
            <HelperText style={{ color: ColoresPineal.rojoAlerta }}>
              El email ingresado ya existe como participante.
            </HelperText>
          )}
        <CustomTextInput
          value={
            participanteNuevo["nombre"] === undefined
              ? ""
              : participanteNuevo["nombre"]
          }
          style={styles.inputCargo}
          onChange={(text) => {
            participanteNuevo["nombre"] = text;
            console.table(participanteNuevo);
            setActualizar(!actualizar);
          }}
          label="Nombre"
          disabled={
            participanteNuevo["existe"] === undefined ||
            participanteNuevo["existe"]
          }
        />
        <CustomTextInput
          value={
            participanteNuevo["apPaterno"] === undefined
              ? ""
              : participanteNuevo["apPaterno"]
          }
          style={styles.inputCargo}
          onChange={(text) => {
            participanteNuevo["apPaterno"] = text;
            console.table(participanteNuevo);
            setActualizar(!actualizar);
          }}
          label="Apellido Paterno"
          disabled={
            participanteNuevo["existe"] === undefined ||
            participanteNuevo["existe"]
          }
        />
        <CustomTextInput
          value={
            participanteNuevo["apMaterno"] === undefined
              ? ""
              : participanteNuevo["apMaterno"]
          }
          style={styles.inputCargo}
          onChange={(text) => {
            participanteNuevo["apMaterno"] = text;
            console.table(participanteNuevo);
            setActualizar(!actualizar);
          }}
          label="Apellido Materno"
          disabled={
            participanteNuevo["existe"] === undefined ||
            participanteNuevo["existe"]
          }
        />
      </View>
    ));
    setVisible(true);
  };

  const obtieneDatosGeneralesReunion = () => {
    setMensajeSpinner("Cargando Datos Generales.");
    setSpinnerVisible(true);
    ReunionService.obtieneDatosGeneralesReunion(user.token).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          setEmpresas(respuesta.entidad.empresas);
          setCandidatos(respuesta.entidad.postulantes);
          setUsuarios(respuesta.entidad.participantes);
          setActualizar(!actualizar);
        } else {
          setEmpresas(undefined);
          setTipoAlerta("error");
          setMensaje(`Se ha producido un error al obtener los datos.`);
          setAlertaVisible(true);
        }
        setSpinnerVisible(false);
      }
    );
  };

  const DateToString = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fecha = (day < 10 ? "0" : "") + day + "/";
    fecha = fecha + (month < 10 ? "0" : "") + month + "/";
    fecha = fecha + year;
    fecha = fecha + " ";
    let hora = date.getHours();
    fecha = fecha + (hora < 10 ? "0" : "") + hora + ":";
    let minutes = date.getMinutes();
    fecha = fecha + (minutes < 10 ? "0" : "") + minutes;
    return fecha;
  };

  const limpiaPostulanteNuevo = () => {
    postulanteNuevo["rut"] = "";
    postulanteNuevo["nombre"] = "";
    postulanteNuevo["apPaterno"] = "";
    postulanteNuevo["apMaterno"] = "";
    postulanteNuevo["email"] = "";
    postulanteNuevo["existe"] = false;
    participanteNuevo["nombre"] = "";
    participanteNuevo["apPaterno"] = "";
    participanteNuevo["apMaterno"] = "";
    participanteNuevo["email"] = "";
    participanteNuevo["existe"] = false;
  };

  React.useEffect(() => {
    setHeaderText(
      reunion.idReunion === -1 ? "Nueva Reunión" : "Editar Reunión"
    );
    if (empresas === undefined) {
      setEmpresas([]);
      obtieneDatosGeneralesReunion();
    }

    if (
      (procesoIN !== undefined || reunion.idReunion !== -1) &&
      empresas !== undefined &&
      empresa.id === -1 &&
      proceso.id === -1
    ) {
      let idEmpresa =
        procesoIN === undefined ? reunion.idEmpresa : procesoIN.id_empresa;
      let empresa = empresas.find((empresa) => empresa.id === idEmpresa);
      if (empresa !== undefined) {
        setEmpresa(empresa);
        reunion["empresa"] = empresa;
        let proceso = empresa.procesos.find(
          (proceso) =>
            proceso.id ===
            (procesoIN === undefined ? reunion.idProceso : procesoIN.id_proceso)
        );
        setProceso(proceso);
        reunion["idProceso"] = proceso.id;
        reunion["proceso"] = proceso;
        setActualizar(!actualizar);
      }
    }
    let fechaActual = new Date();
    fechaActual.setMinutes(fechaActual.getMinutes() + 150);
    if (
      minimaFechaDatePicker === undefined ||
      minimaFechaDatePicker.getTime() < fechaActual.getTime()
    ) {
      fechaActual.setMinutes(fechaActual.getMinutes() + 30);
      setMinimaFechaDatePicker(fechaActual);
      if (reunion.idReunion === -1 && reunion.setFechaInicio === undefined) {
        reunion.setFechaInicio = true;
        if (
          formato.dateToInteger(fechaActual) ===
          formato.dateToInteger(
            formato.stringToDate(reunion["fechaReunionTexto"], true)
          )
        ) {
          reunion["fechaReunionTexto"] = DateToString(fechaActual);
        }
      }
      setActualizar(!actualizar);
    }
  }, [
    reunion.idReunion,
    empresa?.id,
    proceso?.id,
    actualizar,
    spinnerVisible,
    reunion.postulantes?.length,
    reunion.participantes?.length,
    visible,
  ]);

  return (
    <View nativeID="reunion-container" style={{ padding: 20 }}>
      <CustomListSelect
        label="Empresa"
        nombreLista="Seleccione Empresa"
        nombreValue="id"
        nombreLabel="nombre"
        disabled={reunion.idReunion !== -1}
        value={reunion.empresa === undefined ? [] : reunion.empresa}
        lista={empresas}
        onChange={(items) => {
          if (items[0] !== undefined) {
            reunion["empresa"] = items[0];
            setEmpresa(items[0]);
            reunion["proceso"] = undefined;
            setActualizar(!actualizar);
          }
        }}
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      <CustomListSelect
        label="Proceso"
        nombreLista="Seleccione Proceso"
        nombreValue="id"
        nombreLabel="nombre"
        disabled={
          empresa === undefined || empresa.id === -1 || reunion.idReunion !== -1
        }
        value={reunion.proceso === undefined ? [] : reunion.proceso}
        lista={
          empresa !== undefined && empresa.id !== -1 ? empresa.procesos : []
        }
        onChange={(items) => {
          if (items[0] !== undefined) {
            reunion["proceso"] = items[0];
            reunion["idProceso"] = items[0].id;
            setProceso(items[0]);
            setActualizar(!actualizar);
          }
        }}
        showIcon={false}
        top={0}
        closeOnClick={true}
      />
      {(proceso === undefined || proceso.id === -1) && (
        <CustomTextInput
          style={styles.inputCargo}
          label="Postulantes"
          disabled={true}
        />
      )}
      {proceso !== undefined && proceso.id !== -1 && (
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginVertical: 10,
            }}
          >
            <Subheading>Postulantes</Subheading>
            <CustomButton
              textoButton="Nuevo Postulante"
              icon="plus"
              onPress={buscarPostulante}
            />
          </View>
          <SelectAutocompletar
            seleccionadosPrimero
            lista={
              proceso !== undefined && proceso.id !== -1
                ? proceso.postulantes
                : []
            }
            nombreLabel={["rut", "nombre", "apPaterno", "apMaterno", "email"]}
            nombreValue={"id"}
            onSelect={(item) => {
              reunion["postulantes"] = [];
              item.forEach((element) => {
                reunion["postulantes"].push({
                  id: element.id,
                  idPostulante: element.idPostulante,
                  rut: element.rut,
                  nombre: element.nombre,
                  apMaterno: element.apMaterno,
                  apPaterno: element.apPaterno,
                  email: element.email,
                });
              });
              setActualizar(!actualizar);
            }}
            showIcon={true}
            multiselect={true}
            maxHeight={200}
            value={
              reunion["postulantes"] === undefined ? [] : reunion["postulantes"]
            }
          />
        </View>
      )}
      {(proceso === undefined || proceso.id === -1) && (
        <CustomTextInput
          style={styles.inputCargo}
          label="Participantes"
          disabled={true}
        />
      )}
      {proceso !== undefined && proceso.id !== -1 && (
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginVertical: 10,
            }}
          >
            <Subheading>Participantes</Subheading>
            <CustomButton
              textoButton="Nuevo Participante"
              icon="plus"
              onPress={nuevoParticipante}
            />
          </View>
          <SelectAutocompletar
            seleccionadosPrimero
            lista={usuarios}
            nombreLabel={["nombre", "apPaterno", "apMaterno", "email"]}
            nombreValue={"id"}
            onSelect={(item) => {
              let participantes = [];
              item.forEach((element) => {
                participantes.push({
                  idParticipante: element.id,
                  id: element.id,
                  email: element.email,
                  nombre: element.nombre,
                  apPaterno: element.apPaterno,
                  apMaterno: element.apMaterno,
                });
              });
              reunion["participantes"] = participantes;
              setActualizar(!actualizar);
            }}
            showIcon={true}
            multiselect={true}
            maxHeight={200}
            value={
              reunion["participantes"] === undefined
                ? []
                : reunion["participantes"]
            }
          />
        </View>
      )}
      <CustomDatepicker
        disabled={proceso === undefined || proceso.id === -1}
        style={styles.inputProceso}
        label="Fecha Reunión"
        value={
          reunion.fechaReunionTexto === undefined
            ? DateToString(
                minimaFechaDatePicker === undefined
                  ? new Date()
                  : minimaFechaDatePicker
              )
            : reunion.fechaReunionTexto
        }
        onChange={(text) => (reunion.fechaReunionTexto = text)}
        requiered={true}
        incluyeHora={true}
        minDate={minimaFechaDatePicker}
      />
      <CustomTextInput
        disabled={proceso === undefined || proceso.id === -1}
        style={styles.inputCargo}
        label="Recordatorio Reunión (minutos)"
        value={reunion.idReunion === -1 ? "" : reunion.recordatorioMinutos}
        onChange={(text) => (reunion["recordatorioMinutos"] = text)}
        requiered={true}
        valueType="numeros"
      />
      <View>
        <Subheading>Recordatorio</Subheading>
        <List.Item
          disabled={
            reunion["participantes"] === undefined ||
            reunion["participantes"].length === 0
          }
          onPress={() => {
            if (reunion.recordarParticipantes === undefined) {
              reunion["recordarParticipantes"] = true;
            } else {
              reunion.recordarParticipantes = !reunion.recordarParticipantes;
            }
            setActualizar(!actualizar);
          }}
          title={"Participantes"}
          titleStyle={
            reunion["participantes"] === undefined ||
            reunion["participantes"].length === 0
              ? { color: "#b7b5b5" }
              : {}
          }
          left={(props) => {
            return (
              <List.Icon
                icon={
                  reunion.recordarParticipantes
                    ? "checkbox-marked-outline"
                    : "checkbox-blank-outline"
                }
              />
            );
          }}
        />
        <List.Item
          disabled={
            reunion["postulantes"] === undefined ||
            reunion["postulantes"].length === 0
          }
          onPress={() => {
            if (reunion.recordarPostulantes === undefined) {
              reunion["recordarPostulantes"] = true;
            } else {
              reunion.recordarPostulantes = !reunion.recordarPostulantes;
            }
            setActualizar(!actualizar);
          }}
          titleStyle={
            reunion["postulantes"] === undefined ||
            reunion["postulantes"].length === 0
              ? { color: "#b7b5b5" }
              : {}
          }
          title={"Postulantes"}
          left={(props) => {
            return (
              <List.Icon
                icon={
                  reunion.recordarPostulantes
                    ? "checkbox-marked-outline"
                    : "checkbox-blank-outline"
                }
              />
            );
          }}
        />
      </View>
      <CustomTextInput
        disabled={proceso === undefined || proceso.id === -1}
        style={styles.inputCargo}
        label="Comentarios"
        value={reunion.idReunion === -1 ? "" : reunion.comentarios}
        onChange={(text) => (reunion["comentarios"] = text)}
        requiered={true}
        multiline={true}
        numberOfLines={5}
      />
      <CustomButton
        disabled={
          reunion["postulantes"] === undefined ||
          reunion["participantes"] === undefined ||
          reunion["postulantes"].length === 0 ||
          reunion["participantes"].length === 0
        }
        style={{ marginTop: 20 }}
        onPress={accionModal}
        textoButton={reunion.idReunion === -1 ? "Guardar" : "Actualizar"}
      />
      <Spinner mensaje={mensajeSpinner} visible={spinnerVisible} />
      <Alerta
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() =>
          navigation.navigate({
            name: "Calendario",
            params: { reunion: reunion },
            merge: true,
          })
        }
        closeModal={() => setAlertaVisible(false)}
      />
      <CustomDialog
        contenido={formulario(actualizar)}
        visible={visible}
        titulo={titulo}
        onClose={() => {
          setVisible(false);
          limpiaPostulanteNuevo();
        }}
        accion={() => {
          setVisible(false);
          console.log(typeof accion);
          console.log(accion);
          if (typeof accion === "function") {
            accion();
          }
        }}
      />
    </View>
  );
}
