import React from "react";
import { Dimensions, ScrollView, View } from "react-native";
import { Dialog, List, Snackbar, TextInput } from "react-native-paper";
import BotonesEmpresaCargo from "../components/BotonesEmpresaCargo";
import CustomButton from "../components/CustomButton";
import EmpresaService from "../services/EmpresaService";
import ProcesosService from "../services/ProcesosService";
import styles from "../styles/style";

const windowHeight = Dimensions.get("window").height;

export default function EnvioAlertas({
  navigation,
  user,
  empresas,
  cargos,
  setCargos,
  empresa,
  setEmpresa,
  cargo,
  setCargo,
}) {
  const [tipoAlerta, setTipoAlerta] = React.useState({
    nombre: "Seleccione Tipo Alerta",
    id: -1,
  });
  const [etapa, setEtapa] = React.useState({
    nombre: "Seleccione Etapa",
    id: -1,
  });
  const [tiposAlertas, setTiposAlertas] = React.useState([]);
  const [etapas, setEtapas] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [opcionesModal] = React.useState({
    dismissable: true,
    tituloModal: null,
    contenidoModal: () => {},
    botonModal: () => {},
  });

  const closeModal = () => {
    setVisible(false);
    setActualizar(!actualizar);
  };

  const openModal = (opciones = undefined) => {
    if (opciones !== undefined) {
      if (opciones.tituloModal !== undefined) {
        opcionesModal.tituloModal = opciones.tituloModal;
      }
      if (opciones.contenidoModal !== undefined) {
        opcionesModal.contenidoModal = opciones.contenidoModal;
      }
      if (opciones.botonModal !== undefined) {
        opcionesModal.botonModal = opciones.botonModal;
      }
      if (opciones.dismissable !== undefined) {
        opcionesModal.dismissable = opciones.dismissable;
      }
    }
    setVisible(true);
    setActualizar(!actualizar);
  };

  const seleccionaTiposAlerta = (tipoAlerta) => {
    setTipoAlerta(tipoAlerta);
    closeModal();
  };

  const seleccionarEtapa = (etapa) => {
    setEtapa(etapa);
    closeModal();
  };
  const obtieneEtapas = () => {
    let etapas = [
      { nombre: "Postulacion", id: 1, icon: "" },
      { nombre: "Test", id: 2, icon: "" },
      { nombre: "Entrevista Psicolaboral", id: 3, icon: "" },
      { nombre: "Contratacion", id: 4, icon: "" },
    ];
    return etapas;
  };

  const obtieneAlertas = () => {
    let alertas = [
      { nombre: "Informativa", id: 1, icon: "check-circle-outline" },
      { nombre: "Advertencia", id: 2, icon: "information-outline" },
      { nombre: "Urgente", id: 3, icon: "close-circle-outline" },
    ];
    return alertas;
  };

  const comboboxEtapas = () => {
    setEtapas(obtieneEtapas());
    openModal({
      tituloModal: "Seleccione Etapa",
      contenidoModal: (opciones) => {
        return (
          <View>
            <ScrollView style={{ maxHeight: windowHeight - 300 }}>
              {opciones.etapas.map((item, index) => {
                return (
                  <List.Item
                    key={index}
                    title={item.nombre}
                    onPress={() => seleccionarEtapa(item)}
                    left={(props) => <List.Icon icon={item.icon} />}
                  />
                );
              })}
            </ScrollView>
          </View>
        );
      },
    });
  };

  const comboboxTipoAlertas = () => {
    setTiposAlertas(obtieneAlertas());
    openModal({
      tituloModal: "Seleccione Tipo de Alerta",
      contenidoModal: (opciones) => {
        return (
          <View>
            <ScrollView style={{ maxHeight: windowHeight - 300 }}>
              {opciones.tiposAlertas.map((item, index) => {
                return (
                  <List.Item
                    key={index}
                    title={item.nombre}
                    onPress={() => seleccionaTiposAlerta(item)}
                    left={(props) => <List.Icon icon={item.icon} />}
                  />
                );
              })}
            </ScrollView>
          </View>
        );
      },
    });
  };

  const selectEmpresa = (empresa) => {
    setEmpresa(empresa);
    obtieneCargos(empresa);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const selectCargo = (cargo) => {
    setCargo(cargo);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const obtieneCargos = (empresa) => {
    ProcesosService.obtieneProcesos(user.token, empresa.id).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          setCargos(respuesta.entidad);
          setActualizar(!actualizar);
        }
      }
    );
  };

  const enviarMensaje = () => {
    let data = {
      nivelAlerta: tipoAlerta.id,
      idEmpresa: empresa.id,
      idProceso: cargo.idProceso,
      textoAlerta: mensaje,
      etapa: etapa.id,
    };
    EmpresaService.enviaAlerta(user.token, data).then((resp) => {
      setShowSnackBar(true);
      setActualizar(!actualizar);
      setTimeout(() => {
        setShowSnackBar(false);
        setActualizar(!actualizar);
      }, 5000);
    });
  };
  React.useEffect(() => {}, [actualizar]);

  return (
    <View style={{height:"calc(100vh - 110px)"}}>
      <BotonesEmpresaCargo
        empresa={empresa}
        empresas={empresas}
        cargo={cargo}
        cargos={cargos}
        selectCargo={selectCargo}
        selectEmpresa={selectEmpresa}
      />
      <CustomButton
        disabled={
          cargo.idProceso === undefined ||
          cargo.idProceso === null ||
          cargo.idProceso === -1
        }
        onPress={() => comboboxEtapas()}
        contentStyle={{
          flexDirection: "row-reverse",
        }}
        icon="chevron-down"
        textoButton={etapa.nombre}
        style={styles.botonera}
        oscuro={true}
      />

      <CustomButton
        disabled={
          cargo.idProceso === undefined ||
          cargo.idProceso === null ||
          cargo.idProceso === -1
        }
        onPress={() => comboboxTipoAlertas()}
        contentStyle={{
          flexDirection: "row-reverse",
        }}
        icon="chevron-down"
        textoButton={tipoAlerta.nombre}
        style={styles.botonera}
        oscuro={true}
      />
      <TextInput
        label="Mensaje"
        multiline={true}
        numberOfLines={31}
        value={mensaje}
        onChangeText={(text) => setMensaje(text)}
      />
      <CustomButton
        onPress={() => enviarMensaje()}
        color="#0a5dc1"
        contentStyle={{
          flexDirection: "row-reverse",
        }}
        style={{bottom:0, position:"absolute", width: "100vw"}}
        icon="send"
        textoButton="Enviar"
      />
      <Dialog
        visible={visible}
        dismissable={opcionesModal.dismissable}
        onDismiss={closeModal}
        style={styles.modal}
      >
        <Dialog.Title>{opcionesModal.tituloModal}</Dialog.Title>
        <Dialog.Content>
          {opcionesModal.contenidoModal({
            tiposAlertas: tiposAlertas,
            etapas: etapas,
          })}
        </Dialog.Content>
        <Dialog.Actions>{opcionesModal.botonModal(true)}</Dialog.Actions>
      </Dialog>
      {
        <Snackbar style={{ backgroundColor: "#f6a623" }} visible={showSnackBar}>
          Alerta enviada con exito
        </Snackbar>
      }
    </View>
  );
}
