import React from "react";
import "react-native-gesture-handler";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Test from "./test/Test";
import RepGra from "./screens/ReportesProcesos";
import { Provider } from "react-native-paper";

const Stack = createStackNavigator();

export default function App() {
  return (
    <Provider>
      <NavigationContainer>
        <Stack.Navigator initialRouteName="Login">
          <Stack.Screen
            options={{ headerShown: false }}
            name="Login"
            component={Login}
          />
          <Stack.Screen
            options={{ headerShown: false }}
            name="Home"
            component={Home}
          />
          <Stack.Screen
            options={{ headerShown: false }}
            name="Test"
            component={Test}
          />
          <Stack.Screen
            options={{ headerShown: false }}
            name="Graficos"
            component={RepGra}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}
