import logoPrimario from '../assets/logo-dark.png'
import logoSecundario from '../assets/logo-blanco.png'
import logo from '../assets/logo.png'
import logoMobile from '../assets/logo_mobile.png'
import logoMobileWhite from '../assets/logo_mobile_white.png'
import logoNuevo from '../assets/Logos_v04_LOGO.png'

export default  {
   logoPrimario:  logoPrimario,
   logoSecundario: logoSecundario,
   logo: logo,
   logoMobile: logoMobile,
   logoMobileWhite: logoMobileWhite,
   logoNuevo: logoNuevo
}