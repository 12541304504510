import React from "react";
import { ScrollView, View } from "react-native";
import {
  ActivityIndicator,
  Checkbox,
  Colors,
  Dialog,
  IconButton,
  Text,
  TextInput,
} from "react-native-paper";
import XLSX from "xlsx";
import CustomConfirmacion from "../components/CustomConfirmacion";
import CustomDialog from "../components/CustomDialog";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import TablaDetallePostulante from "../components/TablaDetallePostulante";
import EmpresaService from "../services/EmpresaService";
import ProcesosService from "../services/ProcesosService";
import ReunionService from "../services/ReunionService";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";
import Formato from "../utils/Formato";
import Alerta from "./modal/Alerta";
import Spinner from "./modal/Spinner";

const formato = Formato();

export default function Procesos({
  navigation,
  user,
  //actualizar,
  //setActualizar,
}) {
  const [datos, setDatos] = React.useState();
  const [textoComentario, setTextoComentario] = React.useState("");
  const [proceso, setProceso] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [muestraDetalle, setMuestraDetalle] = React.useState(false);
  const [reuiones, setReuniones] = React.useState([]);
  const [postulantesFiltrados, setPostulantesFiltrados] = React.useState([]);
  const [actualizar, setActualizar] = React.useState(false);
  const [mensajeSpinner, setMensajeSpinner] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [filtrosIniciales] = React.useState({
    historico: "NO",
    estado: "Activo",
  });

  const datoColumna = (
    parametro,
    nombreColumna,
    opciones = {},
    fecha = false
  ) => {
    return {
      label: nombreColumna,
      name: parametro,
      type: fecha ? "fecha" : "",
      options: {
        filter: true,
        sort: true,
        display: true,
        customFilterListOptions: {
          render: (v) => `${nombreColumna}: ${v}`,
        },
        ...opciones,
      },
    };
  };

  const columnas = [
    {
      name: "Seleccionar",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        setCellProps: (value) => {
          return {
            style: {
              position: "sticky",
              left: 0,
              backgroundColor: "#ffffff",
            },
          };
        },
        setCellHeaderProps: (value) => {
          return {
            style: {
              position: "sticky",
              left: 0,
              maxWidth: 37,
              minWidth: 37,
              backgroundColor: "#839fb2",
              color: Colors.white,
              zIndex: 101,
            },
          };
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <View>
              <Checkbox
                uncheckedColor={"#ffffff"}
                color={"#0a5dc1"}
                status={
                  datos.length ===
                  datos.filter((dato) => dato["seleccionado"] === true).length
                    ? "checked"
                    : "unchecked"
                }
                onPress={() => {
                  if (
                    postulantesFiltrados !== undefined &&
                    postulantesFiltrados.length > 0
                  ) {
                    postulantesFiltrados.forEach((dato) => {
                      let proceso = datos[dato.dataIndex];
                      proceso["seleccionado"] = !proceso["seleccionado"];
                    });
                  } else {
                    datos.map((dato) => {
                      dato["seleccionado"] = !dato["seleccionado"];
                    });
                  }
                  setActualizar(!actualizar);
                }}
              />
            </View>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          let proceso = datos[dataIndex];
          return (
            <View>
              <Checkbox
                uncheckedColor={"#0a5dc1"}
                color={"#0a5dc1"}
                status={
                  proceso !== undefined && proceso.seleccionado
                    ? "checked"
                    : "unchecked"
                }
                onPress={() => {
                  proceso.seleccionado = !proceso.seleccionado;
                  setActualizar(!actualizar);
                }}
              />
            </View>
          );
        },
      },
    },
    datoColumna("empresa", "Empresa"),
    datoColumna("cargo", "Cargo"),
    datoColumna("gerarquia", "Jerarquía"),
    datoColumna("unidad", "Unidad"),
    datoColumna("nombre", "Proceso"),
    datoColumna("fechaInicio", "Fecha Inicio", {}, true),
    datoColumna("fechaFin", "Fecha Fin", {}, true),
    datoColumna("color", "Semáforo", {
      filterOptions: {
        names: ["Verde", "Amarillo", "Rojo"],
        logic: (location, filters, row) => {
          if (filters[0] !== undefined) {
            if (filters[0] === "Verde") {
              return location !== ColoresPineal.verde;
            }
            if (filters[0] === "Amarillo") {
              return location !== ColoresPineal.amarillo;
            }
            if (filters[0] === "Rojo") {
              return location !== ColoresPineal.rojo;
            }
          }
          return false;
        },
      },
      customBodyRenderLite: (dataIndex) => {
        const proceso = datos[dataIndex];
        return (
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <IconButton
              icon="circle-slice-8"
              color={proceso.color}
              size={30}
              style={{ alignSelf: "flex-end" }}
            />
          </View>
        );
      },
    }),
    datoColumna("total_postulantes", "Total Postulantes"),
    datoColumna("historico", "Histórico", {
      filterOptions: {
        logic: (location, filters, row) => {
          if (filters[0] === undefined) {
            filtrosIniciales.historico = "NO";
            if (filtrosIniciales.estado !== undefined) {
              return location !== filtrosIniciales.historico;
            } else {
              return false;
            }
          } else {
            filtrosIniciales.historico = undefined;
            return location !== filters[0];
          }
        },
      },
    }),
    datoColumna("activo", "Estado", {
      filterOptions: {
        names: ["Activo", "Inactivo"],
        logic: (location, filters, row) => {
          if (filters[0] === undefined) {
            filtrosIniciales.estado = "Activo";
            if (filtrosIniciales.historico !== undefined) {
              return location !== (filtrosIniciales.historico !== "Activo");
            } else {
              return false;
            }
          } else {
            filtrosIniciales.estado = undefined;
            return location !== (filters[0] === "Activo");
          }
        },
      },
      customBodyRenderLite: (dataIndex) => {
        const proceso = datos[dataIndex];
        if (proceso !== undefined) {
          return (
            <Text style={{ textTransform: "uppercase" }}>
              {proceso.activo ? "Activo" : "Inactivo"}
            </Text>
          );
        }
      },
    }),
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const proceso = datos[dataIndex];
          return (
            <View style={{ flexDirection: "row" }}>
              <IconButton
                icon="magnify"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  navigation.navigate("Detalle Proceso", { proceso: proceso });
                }}
              />
              <IconButton
                icon={
                  proceso.comentarios !== undefined &&
                  proceso.comentarios.length > 0
                    ? "message-text"
                    : "message-text-outline"
                }
                color={
                  proceso.comentarios !== undefined &&
                  proceso.comentarios.length > 0
                    ? "#f47c06"
                    : "#545454"
                }
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setProceso(proceso);
                  setVisible(true);
                }}
              />
              <IconButton
                icon="playlist-plus"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                disabled={proceso.activo === false}
                onPress={() => {
                  proceso["recarga"] = true;
                  if (proceso["historico"] === "SI") {
                    navigation.navigate("Procesos Históricos", {
                      proceso: proceso,
                    });
                  } else {
                    navigation.navigate("Procesos en Curso", {
                      proceso: proceso,
                    });
                  }
                }}
              />
              <IconButton
                icon="calendar"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  obtieneReuniones(proceso);
                  setProceso(proceso);
                }}
              />
            </View>
          );
        },
      },
    },
  ];

  const obtieneReuniones = (proceso) => {
    ReunionService.obtieneReunionIdProceso(user.token, proceso.id_proceso).then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setReuniones(resp.entidad);
          setVisibleDialog(true);
        } else {
          if (resp.codigoRespuesta === 204) {
            setTipoAlerta("warn");
            setMensaje(`No existen reuniones asignadas al proceso`);
          } else {
            setTipoAlerta("error");
            setMensaje(`Se ha producido un error al obtner las reuiones`);
          }
          setAlertaVisible(true);
        }
      }
    );
  };

  const obtieneProcesos = () => {
    ProcesosService.reporte(user.token).then((resp) => {
      resp.entidad.map((dato) => {
        dato["seleccionado"] = false;
        const fechaActual = new Date();
        const fechaTermino = formato.stringToDate(dato.etapas[3].fechaFin);
        dato["color"] = ColoresPineal.rojo;
        if (fechaActual.getTime() < fechaTermino.getTime()) {
          let dias =
            (fechaTermino.getTime() - fechaActual.getTime()) /
            (1000 * 60 * 60 * 24);
          if (dias > 3) {
            dato["color"] = ColoresPineal.verde;
          } else {
            dato["color"] = ColoresPineal.amarillo;
          }
        }
      });
      setDatos(resp.entidad);
      setActualizar(!actualizar);
    });
  };

  const guardarComentario = () => {
    let comentario = {
      idProceso: proceso.id_proceso,
      comentario: textoComentario,
    };
    ProcesosService.crearComentario(user.token, comentario).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Comentario creado exitosamente.`);
        obtieneProcesos();
      } else {
        setTipoAlerta("error");
        setMensaje(`Se ha producido un error al crear el comentario`);
      }
      setTextoComentario("");
      setAlertaVisible(true);
    });
  };

  const downloadFile = () => {
    var ws = XLSX.utils.json_to_sheet(datos);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ReporteProcesos");
    XLSX.writeFile(wb, "Reporte_Procesos.xlsx");
  };

  const obtieneDatosProceso = () => {
    let idProcesos = [];

    datos.map((dato) => {
      if (dato.seleccionado === true) {
        idProcesos.push(dato.id_proceso);
      }
    });

    setMensajeSpinner("Generando documento.");
    setSpinnerVisible(true);
    EmpresaService.obtieneDatosProceso(user.token, idProcesos).then((resp) => {
      setSpinnerVisible(false);
      setTipoAlerta("info");
      setMensaje(`Documento generado exitosamente.`);
      setAlertaVisible(true);
    });
  };

  React.useEffect(() => {
    if (datos === undefined) {
      obtieneProcesos();
    }
  }, []);

  return (
    <View>
      <View>
        {datos === undefined && (
          <View style={{ justifyContent: "center" }}>
            <ActivityIndicator
              animating={true}
              color="#f6a623"
              size={80}
              style={{ marginTop: 40, marginBottom: 20 }}
            />
            <Text
              style={{
                color: "#6b6b6b",
                textAlign: "center",
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              Cargando Procesos
            </Text>
          </View>
        )}
        {datos !== undefined && (
          <CustomMuiDataTable
            data={datos}
            columnas={columnas}
            onDataFilterChange={setPostulantesFiltrados}
          />
        )}
      </View>
      {proceso !== undefined && (
        <Dialog visible={visible} dismissable={false} style={styles.modal}>
          <Dialog.Title>{`Nuevo Comentario: ${proceso.nombre}`}</Dialog.Title>
          <Dialog.Content>
            <TextInput
              value={textoComentario}
              onChangeText={(text) => setTextoComentario(text)}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <CustomConfirmacion
              onPressAceptar={() => {
                setVisible(false);
                guardarComentario();
              }}
              onPressCancelar={() => setVisible(false)}
            />
          </Dialog.Actions>
        </Dialog>
      )}
      <CustomFabGroup
        visible={datos !== undefined && datos.length > 0}
        listaBotones={[
          {
            icon: "download",
            label: "Descargar",
            onPress: () => downloadFile(),
          },
          {
            icon: "refresh",
            label: "Recargar",
            onPress: () => obtieneProcesos(),
          },
          {
            icon: "file-download",
            label: "Descargar Datos Proceso",
            onPress: () => obtieneDatosProceso(),
          },
        ]}
      />
      <Spinner mensaje={mensajeSpinner} visible={spinnerVisible} />
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
      <CustomDialog
        titulo="Reuniones asignadas"
        visible={visibleDialog}
        contenido={
          <View>
            <ScrollView
              style={styles.reunionProcesoScrollView}
              testID="reunionProcesoScrollView"
            >
              {reuiones !== undefined &&
                reuiones.length > 0 &&
                reuiones.map((reunion, index) => {
                  return (
                    <View>
                      <TablaDetallePostulante
                        titulo={`Reunion N°${index + 1}`}
                        lista={[
                          {
                            nombre: "Fecha",
                            valor: reunion.fechaReunion,
                            style: {
                              maxWidth: 130,
                              backgroundColor: "#335275",
                              paddingLeft: 15,
                            },
                          },
                        ]}
                        iconRow={[
                          {
                            title: "Postulantes y Participantes",
                            nombre: "calendar-account",
                            onPressIconRow: () => {
                              reunion["muestraDetalle"] =
                                reunion.muestraDetalle !== undefined
                                  ? !reunion.muestraDetalle
                                  : true;
                              setMuestraDetalle(!muestraDetalle);
                            },
                          },
                          {
                            title: "Ir a la Reunión",
                            nombre: "calendar-arrow-right",
                            onPressIconRow: () => {
                              setVisibleDialog(false);
                              reunion["recargar"] = true;
                              navigation.navigate("Vista Panel", {
                                reunion: reunion,
                              });
                            },
                          },
                        ]}
                      />
                      {reunion.muestraDetalle !== undefined &&
                        reunion.muestraDetalle &&
                        reunion.postulantesInfo !== undefined &&
                        reunion.postulantesInfo.map((postulante, index) => {
                          return (
                            <TablaDetallePostulante
                              lista={[
                                {
                                  nombre: `Postulante ${index + 1}`,
                                  style: {
                                    maxWidth: 130,
                                    backgroundColor: "#f6a623",
                                    paddingLeft: 15,
                                  },
                                  valor:
                                    formato.formato_rut(
                                      postulante.rut?.replace(/[^0-9kK]/g, "")
                                    ) +
                                    " " +
                                    postulante.nombre +
                                    " " +
                                    postulante.apPaterno +
                                    " " +
                                    postulante.apMaterno,
                                },
                              ]}
                            />
                          );
                        })}
                      {reunion.muestraDetalle !== undefined &&
                        reunion.muestraDetalle &&
                        reunion.participantesInfo !== undefined &&
                        reunion.participantesInfo.map((participante, index) => {
                          return (
                            <TablaDetallePostulante
                              lista={[
                                {
                                  nombre: `Participante ${index + 1}`,
                                  style: {
                                    maxWidth: 130,
                                    backgroundColor: "#f6a623",
                                    paddingLeft: 15,
                                  },
                                  valor:
                                    participante.nombre +
                                    " " +
                                    participante.apPaterno +
                                    " " +
                                    participante.apMaterno,
                                },
                              ]}
                            />
                          );
                        })}
                    </View>
                  );
                })}
            </ScrollView>
          </View>
        }
        customAceptar="Crear Reunion"
        accion={() =>
          navigation.navigate("Reunion", {
            reunion: { idReunion: -1 },
            procesoIN: proceso,
          })
        }
        onClose={() => setVisibleDialog(false)}
      />
    </View>
  );
}
