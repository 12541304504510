import { Platform, StyleSheet, Dimensions } from "react-native";
import ColoresPineal from "./ColoresPineal";

const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: ColoresPineal.azulRey,
    justifyContent: "flex-start",
    paddingTop: 50,
  },
  titulo: {
    color: "#fff",
    fontSize: 32,
    alignSelf: "center",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    backgroundColor: "#000000a0",
  },
  text: {
    color: "white",
    fontSize: 42,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#000000a0",
  },
  errorLogin: {
    backgroundColor: "#f7b3b3",
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
    color: "#8a1b1b",
  },
  imagen: {
    alignSelf: "center",
    marginBottom: 30,
    maxWidth: 100,
    height: 61,
  },
  login: {
    minWidth: 350,
    textAlign: "center",
    padding: 30,
    marginTop: 50,
    ...Platform.select({
      web: {
        maxWidth: 400,
        alignSelf: "center",
      },
    }),
  },
  button: {
    marginTop: 20,
    backgroundColor: "#f47c06",
    color: "#000",
    padding: 8,
  },
  input: {
    fontSize: 14,
    marginTop: 20,
    backgroundColor: "#eaeaea",
    height: 50,
    color: "#000",
  },
  postulanteScrollview: {
    ...Platform.select({
      web: {
        height: "calc(100vh - 144.6px)", //windowHeight - 95,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
  },
  postulanteDetalleScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
  },
  mantenedorEmpresaScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  mantenedorUsuarioScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  mantenedorCargoScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  candidatosScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  reunionProcesoScrollView: {
    ...Platform.select({
      web: {
        height: windowHeight - 332,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  calendarioScrollview: {
    ...Platform.select({
      web: {
        height: "calc(100vh - 415px)",
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 0,
  },
  mantenedorProcesoScrollview: {
    ...Platform.select({
      web: {
        height: windowHeight - 80,
      },
      default: {
        height: windowHeight - 170,
      },
    }),
    padding: 20,
  },
  postulanteInfo: {
    fontSize: 14,
    color: "#19253c",
  },
  postulanteContainer: {
    flex: 10,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
  },
  postulanteDetalle: {
    flex: 1,
    flexDirection: "row",
    borderBottomColor: "#f3f3f3",
    borderBottomWidth: 1,
    padding: 0,
  },
  postulanteDetalle2: {
    flex: 1,
    height: 450,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  botonera: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: ColoresPineal.azulOscuro,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  modal: {
    flexDirection: "column",
    justifyContent: "flex-start",
    ...Platform.select({
      web: {
        width: "calc(100vw - 100px)",
        maxWidth: 700,
        maxHeight: (windowHeight * 4) / 5,
        alignSelf: "center",
      },
    }),
  },
  modalInput: {
    flexDirection: "column",
    justifyContent: "flex-start",
    zIndex: 3,
    elevation: 3,
    ...Platform.select({
      web: {
        maxWidth: (windowWidth * 4) / 5,
        minWidth: (windowWidth * 2) / 5,
        maxHeight: (windowHeight * 4) / 5,
        alignSelf: "center",
      },
    }),
  },
  botton: {
    backgroundColor: ColoresPineal.anaranjado,
    color: ColoresPineal.blanco,
    borderRadius: 0,
  },
  inputEmpresa: {
    marginBottom: 20,
  },
});

export default styles;
