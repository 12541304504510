import React from "react";
import { Button, IconButton } from "react-native-paper";
import CustomListSelect from "../../components/CustomListSelect";
import CustomTextInput from "../../components/CustomTextInput";
import styles from "../../styles/style";

export default function ValorVariable({ lista, item, removeItem }) {
  const [nuevoValor, setNuevoValor] = React.useState({});
  const [actualizar, setActualizar] = React.useState(false);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <CustomListSelect
        top={0}
        label="Nombre Dato del Cargo"
        nombreLista="Seleccione Dato del Cargo"
        nombreValue="value"
        nombreLabel="label"
        showIcon={false}
        closeOnClick={true}
        value={{ value: item.idVariable + "", label: item.nombre }}
        lista={lista}
        onChange={(items) => {
          console.log(items)
          item["nombre"] = items[0]?.label;
          item["idVariable"] = items[0] ? parseInt(items[0].value) : 0;
          setActualizar(!actualizar);
        }}
        idElementoOtro={"1"}
        agregaElemento
        componenteOtro={(nombreValue, idElementoOtro) => {
          return (
            <div>
              <CustomTextInput
                style={styles.inputCargo}
                label="Nombre Dato del Cargo"
                value={
                  nuevoValor["nombre"] === undefined ? "" : nuevoValor["nombre"]
                }
                onChange={(text) => {
                  nuevoValor["nombre"] = text;
                  nuevoValor["label"] = text;
                }}
                requiered={true}
              />
              <Button
                onPress={() => {
                  let maxValue = 0;
                  lista.forEach((element) => {
                    if (parseInt(element[nombreValue]) >= maxValue) {
                      maxValue = parseInt(element[nombreValue]) + 1;
                    }
                  });
                  nuevoValor[nombreValue] = maxValue * -1;
                  lista.push(JSON.parse(JSON.stringify(nuevoValor)));
                  setActualizar(!actualizar);
                  setNuevoValor({});
                }}
                color="#0a5dc1"
                contentStyle={{
                  backgroundColor: "#f6a623",
                }}
                style={{ marginVertical: 20 }}
                mode="contained"
              >
                Agregar Dato del Cargo
              </Button>
            </div>
          );
        }}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Valor"
        value={item["valor"] === undefined ? "" : item["valor"]}
        onChange={(text) => (item["valor"] = text)}
        requiered={true}
      />
      <IconButton
        style={{ alignSelf: "center" }}
        onPress={() => removeItem()}
        icon="close-circle-outline"
        color="rgb(246, 166, 35)"
        size={40}
      />
    </div>
  );
}
