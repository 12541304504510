import React from "react";
import { FunnelChart } from "react-funnel-pipeline";
import Chart from "react-google-charts";
import { ScrollView, View } from "react-native";
import { Card, Chip, Subheading, Text, Title } from "react-native-paper";
import CustomButton from "../components/CustomButton";
import CustomDialog from "../components/CustomDialog";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomListSelect from "../components/CustomListSelect";
import CustomTextInput from "../components/CustomTextInput";
import CustomDatepicker from "../components/CustomDatepicker";
import LoadingModal from "../components/LoadingModal";
import SelectAutocompletar from "../components/SelectAutocompletar";
import ReportesService from "../services/ReportesService";
import ColoresPineal from "../styles/ColoresPineal";
import listaGraficos from "./Graficos";
import Alerta from "./modal/Alerta";
import Formato from "../utils/Formato";

const formato = new Formato();
const nivelInicio = { inicio: 0 };

const generaFiltro = (campo, nombre, tipo = "lista") => {
  let nivel = nivelInicio.inicio++;
  return {
    nivel: nivel,
    nombre: nombre,
    campo: campo,
    valor: undefined,
    tipo: tipo,
    lista: [],
  };
};

export default function ReportesProcesos({ user = { token: "" } }) {
  const [muestraGraficosPostulante, setMuestraGraficosPostulante] =
    React.useState(true);
  const [graficos, setGraficos] = React.useState(listaGraficos.postulantes);
  const [graficosProcesos, setGraficosProcesos] = React.useState(
    listaGraficos.procesos
  );
  const [ruts, setRuts] = React.useState([]);
  const [procesos, setProcesos] = React.useState([]);
  const [candidatos, setCandidatos] = React.useState([]);
  const [totalCandidatos, setTotalCandidatos] = React.useState(0);
  const [totalProcesos, setTotalProcesos] = React.useState(0);
  const [actualizar, setActualizar] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [showSpinner2, setShowSpinner2] = React.useState(false);
  const [dialogoFiltros, setDialogoFiltros] = React.useState(false);
  const [dialogoLista, setDialogoLista] = React.useState(false);
  const [filtro, setFiltro] = React.useState(undefined);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [filtrosProcesos] = React.useState([
    generaFiltro("empresa", "Empresa"),
    generaFiltro("cargo", "Cargo"),
    generaFiltro("estadocargo", "Estado Cargo"),
    generaFiltro("proceso", "Proceso"),
    generaFiltro("estadoproceso", "Estado Proceso"),
    generaFiltro("historicoproceso", "Proceso Histórico"),
    generaFiltro("unidad", "Unidad"),
    generaFiltro("jerarquia", "Jerarquía"),
    generaFiltro("fecha_inicio", "Fecha Inicio", "fecha"),
    generaFiltro("fecha_termino", "Fecha Termino", "fecha"),
  ]);
  const [filtrosPostulantes] = React.useState([
    ...filtrosProcesos,
    generaFiltro("region", "Región"),
    generaFiltro("ciudad", "Ciudad"),
    generaFiltro("genero", "Género"),
    generaFiltro("edad", "Edad", "rango"),
    generaFiltro("ecivil", "Estado Civil"),
    generaFiltro("experiancia_laboral", "Experiencia Laboral Remunerada"),
    generaFiltro("empresa_experiencia", "Empresa Referencia"),
    generaFiltro("rubro", "Rubro de experiencia"),
    generaFiltro("entidad_educativa", "Entidad Educativa"),
    generaFiltro("liceo", "Liceo/Colegio"),
    generaFiltro("especialidad", "Especialidad"),
    generaFiltro("certificacion", "Certificación"),
    generaFiltro("curso", "Curso"),
    generaFiltro("licencia", "Licencia"),
    generaFiltro("idioma", "Idioma"),
    generaFiltro("nivel_escrito", "Nivel Escrito"),
    generaFiltro("nivel_oral", "Nivel Oral"),
    generaFiltro("nombre_ref", "Nombre Referido"),
    generaFiltro("resultado_panel", "Resultado test Panel"),
    generaFiltro(
      "resultado_examen_preocupacional",
      "Resultado test pre-ocupacional"
    ),
    generaFiltro(
      "resultado_examen_psicolaboral",
      "Resultado test psicolaboral"
    ),
    generaFiltro("compentencia", "Competencia"),
    generaFiltro("valor_compentencia", "Valor Competencia", "rango"),
  ]);

  // const user = {
  //   token: "",
  // };

  const obtieneReporteCandidatos = () => {
    ReportesService.obtieneReportePostulantes(user.token).then((resp) => {
      setCandidatos(resp.entidad);
      filtraCandidatos(resp.entidad);
      setShowSpinner(false);
      setActualizar(!actualizar);
    });
  };

  const filtraCandidatos = (candidatosFiltrados, nivel = -1) => {
    graficos.map((grafico) => {
      if (grafico.type === "Funel") {
        grafico["data"] = obtieneDatosFunel(candidatosFiltrados);
      } else {
        grafico["data"] = obtieneDatos(
          grafico.campoEjeX,
          grafico.campoEjeY,
          grafico.nombreLlave,
          grafico.nombreValor,
          grafico.groupBy,
          grafico.label,
          candidatosFiltrados
        );
      }
    });
    graficosProcesos.map((grafico) => {
      if (grafico.type === "Funel") {
        grafico["data"] = obtieneDatosFunel(candidatosFiltrados);
      } else {
        if (grafico.value === 2) {
          grafico["data"] = obtieneDatosPromedioProceso(candidatosFiltrados);
        } else {
          grafico["data"] = obtieneDatos(
            grafico.campoEjeX,
            grafico.campoEjeY,
            grafico.nombreLlave,
            grafico.nombreValor,
            grafico.groupBy,
            grafico.label,
            candidatosFiltrados
          );
        }
      }
    });
    filtrosPostulantes.map((filtro, index) => {
      if (index > nivel) {
        if (filtro.nombre === "Género") {
          console.log(filtro, index, nivel);
        }
        filtro["lista"] = obtieneLista(candidatosFiltrados, filtro.campo);
      }
    });
  };

  const aplicaFiltros = (nivel = -1) => {
    let candidatosFiltradosAux = [...candidatos];
    filtrosPostulantes.forEach((filtro) => {
      let candidatosFiltrados = [];
      if (filtro.valor !== undefined) {
        let valor = [];
        if (filtro.tipo === "lista") {
          filtro.valor.forEach((d) => {
            valor.push(d.label);
          });
        }
        candidatosFiltradosAux.forEach((candidato) => {
          if (
            filtro.tipo === "lista" &&
            valor.indexOf(candidato[filtro.campo]) !== -1
          ) {
            candidatosFiltrados.push(candidato);
          }
          if (filtro.tipo === "rango") {
            if (
              filtro.valor.min !== undefined &&
              filtro.valor.max !== undefined
            ) {
              if (
                filtro.valor.min <= candidato[filtro.campo] &&
                candidato[filtro.campo] <= filtro.valor.max
              ) {
                candidatosFiltrados.push(candidato);
              }
            } else {
              if (filtro.valor.min !== undefined) {
                if (filtro.valor.min <= candidato[filtro.campo]) {
                  candidatosFiltrados.push(candidato);
                }
              }

              if (filtro.valor.max !== undefined) {
                if (filtro.valor.max <= candidato[filtro.campo]) {
                  candidatosFiltrados.push(candidato);
                }
              }
            }
          }
          if (filtro.tipo === "fecha") {
            let fechaDato = formato.stringToDate(candidato[filtro.campo]);
            if (
              filtro.valor.min !== undefined &&
              filtro.valor.max !== undefined
            ) {
              if (
                formato.fecha_Texto(filtro.valor.min).getTime() <=
                  fechaDato.getTime() &&
                fechaDato.getTime() <= formato.fecha_Texto(filtro.valor.max)
              ) {
                candidatosFiltrados.push(candidato);
              }
            } else {
              if (filtro.valor.min !== undefined) {
                if (
                  formato.fecha_Texto(filtro.valor.min) <= fechaDato.getTime()
                ) {
                  candidatosFiltrados.push(candidato);
                }
              }

              if (filtro.valor.max !== undefined) {
                if (
                  formato.fecha_Texto(filtro.valor.max) <= fechaDato.getTime()
                ) {
                  candidatosFiltrados.push(candidato);
                }
              }
            }
          }
        });
        candidatosFiltradosAux = [...candidatosFiltrados];
      }
    });
    console.log(candidatos.length);
    if (candidatosFiltradosAux.length > 0) {
      filtraCandidatos(candidatosFiltradosAux, nivel);
    } else {
      filtraCandidatos(candidatos, nivel);
    }
  };

  const obtieneLista = (candidatosFiltrados, campo) => {
    let lista = [];
    let index = 0;
    let hash = new Set();
    let hashCandidatos = new Set();
    let hashProcesos = new Set();
    candidatosFiltrados.forEach((candidato) => {
      const label =
        candidato[campo] === undefined || candidato[campo] === null
          ? "Sin Dato"
          : candidato[campo];
      if (!hash.has(label)) {
        lista.push({
          label: label,
          value: label,
        });
        hash.add(label);
        index++;
      }
      if (!hashProcesos.has(candidato["idproceso"])) {
        hashProcesos.add(candidato["idproceso"]);
      }
      if (!hashCandidatos.has(candidato["rut"])) {
        hashCandidatos.add(candidato["rut"]);
      }
    });
    setRuts(Array.from(hashCandidatos));
    setProcesos(Array.from(hashProcesos));
    setTotalCandidatos(hashCandidatos.size);
    setTotalProcesos(hashProcesos.size);
    return lista;
  };

  const obtieneDatosFunel = (candidatosFiltrados) => {
    let postulacion = new Set();
    let test = new Set();
    let evaluacion = new Set();
    let contratacion = new Set();
    let etapas = ["Postulación", "Test", "Evaluaciones", "Contratación"];
    console.log(candidatosFiltrados.length);
    let cont = 0;
    candidatosFiltrados.forEach((candidato) => {
      cont++;
      if (etapas.indexOf(candidato["estado"]) === 0) {
        if (!postulacion.has(candidato["rut"])) {
          postulacion.add(candidato["rut"]);
        }
      }
      if (etapas.indexOf(candidato["estado"]) === 1) {
        if (!test.has(candidato["rut"])) {
          test.add(candidato["rut"]);
        }
      }
      // if (cont > 26663 && cont < 26672) {
      console.log(candidato);
      console.log(etapas.indexOf(candidato["estado"]) === 2);
      console.log(evaluacion.has(candidato["rut"]));
      // }
      if (etapas.indexOf(candidato["estado"]) === 2) {
        if (!evaluacion.has(candidato["rut"])) {
          evaluacion.add(candidato["rut"]);
        }
      }
      if (etapas.indexOf(candidato["estado"]) === 3) {
        if (!contratacion.has(candidato["rut"])) {
          contratacion.add(candidato["rut"]);
        }
      }
    });

    let salida = [
      { name: "Postulación", value: postulacion.size },
      { name: "Test", value: test.size },
      { name: "Evaluaciones", value: evaluacion.size },
      { name: "Contratación", value: contratacion.size },
    ];
    return salida;
  };

  const calcDiffDays = (dateInicio, dateFin) => {
    var diasdif = Math.abs(dateInicio.getTime() - dateFin.getTime());
    var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
    if (isNaN(contdias)) {
      console.log(dateInicio, dateFin, diasdif, contdias);
      return 0;
    }
    return contdias;
  };

  const obtieneDatosPromedioProceso = (candidatosFiltrados) => {
    let data = {
      postulacion: {
        suma: 0,
        ruts: new Set(),
      },
      seleccion: {
        suma: 0,
        ruts: new Set(),
      },
      contratacion: {
        suma: 0,
        ruts: new Set(),
      },
    };
    candidatosFiltrados.forEach((candidato) => {
      const fecha_inicio_proceso =
        candidato["fecha_inicio"] === undefined ||
        candidato["fecha_inicio"] === null
          ? null
          : formato.stringToDate(candidato["fecha_inicio"]);

      const fecha_postulacion =
        candidato["fecha_postulacion"] === undefined ||
        candidato["fecha_postulacion"] === null
          ? null
          : formato.stringBdToDate(candidato["fecha_postulacion"], true);

      if (
        fecha_inicio_proceso !== null &&
        !isNaN(fecha_inicio_proceso.getTime()) &&
        fecha_postulacion !== null &&
        !isNaN(fecha_postulacion.getTime()) &&
        !data["postulacion"].ruts.has(candidato["rut"])
      ) {
        data["postulacion"].suma =
          data["postulacion"].suma +
          calcDiffDays(fecha_inicio_proceso, fecha_postulacion);
        data["postulacion"].ruts.add(candidato["rut"]);
      }

      const fecha_psicolaboral =
        candidato["fecha_psicolaboral"] === undefined ||
        candidato["fecha_psicolaboral"] === null
          ? null
          : formato.stringBdToDate(candidato["fecha_psicolaboral"], true);

      if (
        fecha_psicolaboral !== null &&
        !isNaN(fecha_psicolaboral.getTime()) &&
        fecha_postulacion !== null &&
        !isNaN(fecha_postulacion.getTime()) &&
        !data["seleccion"].ruts.has(candidato["rut"])
      ) {
        data["seleccion"].suma =
          data["seleccion"].suma +
          calcDiffDays(fecha_psicolaboral, fecha_postulacion);
        data["seleccion"].ruts.add(candidato["rut"]);
      }

      const fecha_seleccion =
        candidato["fecha_seleccion"] === undefined ||
        candidato["fecha_seleccion"] === null
          ? null
          : formato.stringBdToDate(candidato["fecha_seleccion"], true);

      if (
        fecha_psicolaboral !== null &&
        !isNaN(fecha_psicolaboral.getTime()) &&
        fecha_seleccion !== null &&
        !isNaN(fecha_seleccion.getTime()) &&
        !data["contratacion"].ruts.has(candidato["rut"])
      ) {
        data["contratacion"].suma =
          data["contratacion"].suma +
          calcDiffDays(fecha_psicolaboral, fecha_seleccion);
        data["contratacion"].ruts.add(candidato["rut"]);
      }
    });
    let datos = [
      ["Fechas Proceso", "Promedio días"],
      [
        "Postulación",
        data["postulacion"].suma /
          (data["postulacion"].ruts.size === 0
            ? 1
            : data["postulacion"].ruts.size),
      ],
      [
        "Selección",
        data["seleccion"].suma /
          (data["seleccion"].ruts.size === 0 ? 1 : data["seleccion"].ruts.size),
      ],
      [
        "Contratación",
        data["contratacion"].suma /
          (data["contratacion"].ruts.size === 0
            ? 1
            : data["contratacion"].ruts.size),
      ],
    ];
    //console.table(data);
    //console.table(datos);
    return datos;
  };

  const obtieneDatos = (
    campoEjeX,
    campoEjeY,
    nombreLlave,
    nombreValor,
    groupBy = undefined,
    nombre,
    candidatosFiltrados
  ) => {
    let data = {};
    candidatosFiltrados.forEach((candidato) => {
      const llave =
        candidato[campoEjeX] === undefined || candidato[campoEjeX] === null
          ? "Sin Dato"
          : candidato[campoEjeX];
      if (data[llave] === undefined) {
        data[llave] = new Set();
      }
      data[llave].add(candidato[campoEjeY]);
    });
    let datosSinRepetidos = {};
    Object.keys(data).forEach((key) => {
      datosSinRepetidos[key] = data[key].size;
    });
    if (groupBy !== undefined) {
      datosSinRepetidos = groupBy(datosSinRepetidos);
    }
    let datos = [[nombreLlave, nombreValor]];
    return datos.concat(Object.entries(datosSinRepetidos));
  };

  const obtieneExcelReporteCandidatos = () => {
    let datosGraficos = [];
    graficos.forEach((grafico) => {
      let data = [];
      grafico.data.forEach((dato) => {
        data.push({
          label: dato[0],
          dato: dato[1],
        });
      });
      datosGraficos.push({
        tipoGrafico: grafico.tipoGrafico,
        nombreGrafico: grafico.label,
        listData: data,
      });
    });
    graficosProcesos.forEach((grafico) => {
      let data = [];
      if (grafico.tipoGrafico === 4) {
        data.push({
          label: "Etapas",
          dato: "Postulantes",
        });
      }
      grafico.data.forEach((dato) => {
        if (grafico.tipoGrafico === 4) {
          data.push({
            label: dato.name,
            dato: dato.value,
          });
        } else {
          data.push({
            label: dato[0],
            dato: dato[1],
          });
        }
      });
      datosGraficos.push({
        tipoGrafico: grafico.tipoGrafico,
        nombreGrafico: grafico.label,
        listData: data,
      });
    });
    setShowSpinner2(true);
    ReportesService.obtieneReporteGraficoPostulantes(
      user.token,
      ruts,
      procesos,
      datosGraficos
    ).then((resp) => {
      setShowSpinner2(false);
      if (resp.codigoRespuesta !== 200) {
        setTipoAlerta("error");
        setMensaje(`Se produjo un problema, intente más tarde`);
      } else {
        setTipoAlerta("info");
        setMensaje(`Documento generado`);
      }
      setAlertaVisible(true);
    });
  };

  React.useEffect(() => {
    if (candidatos.length === 0) {
      obtieneReporteCandidatos();
    }
  }, [actualizar, dialogoFiltros, alertaVisible]);

  return (
    <View>
      <CustomButton
        textoButton={
          muestraGraficosPostulante
            ? `Filtros - ${totalCandidatos} candidatos`
            : `Filtros - ${totalProcesos} procesos`
        }
        icon="filter"
        onPress={() => setDialogoFiltros(true)}
      />
      <View
        style={{
          backgroundColor: ColoresPineal.azulRey,
          paddingHorizontal: 2.5,
          paddingVertical: 5,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {!muestraGraficosPostulante &&
          filtrosProcesos.map((filtro, index) => {
            if (filtro.valor !== undefined) {
              let valor = "";
              if (filtro.tipo === "rango" || filtro.tipo === "fecha") {
                if (
                  filtro.valor.min !== undefined &&
                  filtro.valor.max !== undefined
                ) {
                  valor = `Entre ${filtro.valor.min} y ${filtro.valor.max}`;
                } else {
                  if (filtro.valor.min !== undefined) {
                    valor = `Mayor o igual a ${filtro.valor.min}`;
                  }
                  if (filtro.valor.max !== undefined) {
                    valor = `Menor o igual a ${filtro.valor.min}`;
                  }
                }
              } else {
                filtro.valor.forEach((d) => {
                  if (valor.length > 0) {
                    valor = valor.concat(", ");
                  }
                  valor = valor.concat(d.label);
                });
              }
              return (
                <Chip
                  key={`chip-contenedor-${index}`}
                  style={{
                    backgroundColor: ColoresPineal.anaranjado,
                    marginHorizontal: 2.5,
                    marginTop: 3.5,
                  }}
                  onClose={() => {
                    filtro.valor = undefined;
                    aplicaFiltros(filtro.nivel);
                    setActualizar(!actualizar);
                  }}
                >
                  <div
                    style={{
                      color: ColoresPineal.blanco,
                    }}
                  >
                    {filtro.nombre}: {valor}
                  </div>
                </Chip>
              );
            }
          })}
        {muestraGraficosPostulante &&
          filtrosPostulantes.map((filtro, index) => {
            if (filtro.valor !== undefined) {
              let valor = "";
              if (filtro.tipo === "rango" || filtro.tipo === "fecha") {
                if (
                  filtro.valor.min !== undefined &&
                  filtro.valor.max !== undefined
                ) {
                  valor = `Entre ${filtro.valor.min} y ${filtro.valor.max}`;
                } else {
                  if (filtro.valor.min !== undefined) {
                    valor = `Mayor o igual a ${filtro.valor.min}`;
                  }
                  if (filtro.valor.max !== undefined) {
                    valor = `Menor o igual a ${filtro.valor.min}`;
                  }
                }
              } else {
                filtro.valor.forEach((d) => {
                  if (valor.length > 0) {
                    valor = valor.concat(", ");
                  }
                  valor = valor.concat(d.label);
                });
              }
              return (
                <Chip
                  key={`chip-contenedor-${index}`}
                  style={{
                    backgroundColor: ColoresPineal.anaranjado,
                    marginHorizontal: 2.5,
                    marginTop: 3.5,
                  }}
                  onClose={() => {
                    filtro.valor = undefined;
                    aplicaFiltros(filtro.nivel);
                    setActualizar(!actualizar);
                  }}
                >
                  <div
                    style={{
                      color: ColoresPineal.blanco,
                    }}
                  >
                    {filtro.nombre}: {valor}
                  </div>
                </Chip>
              );
            }
          })}
      </View>
      <View
        style={{
          height: "calc(100vh - 155px)",
          backgroundColor: ColoresPineal.azulRey,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Title
            onPress={() => setMuestraGraficosPostulante(true)}
            style={
              muestraGraficosPostulante
                ? {
                    backgroundColor: ColoresPineal.anaranjado,
                    color: ColoresPineal.blanco,
                    paddingHorizontal: 20,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    marginLeft: 15,
                  }
                : {
                    color: ColoresPineal.blanco,
                    marginLeft: 15,
                    paddingHorizontal: 20,
                  }
            }
          >
            Gráficos Postulantes
          </Title>
          <Title
            onPress={() => setMuestraGraficosPostulante(false)}
            style={
              !muestraGraficosPostulante
                ? {
                    backgroundColor: ColoresPineal.anaranjado,
                    color: ColoresPineal.blanco,
                    paddingHorizontal: 20,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    marginLeft: 15,
                  }
                : {
                    color: ColoresPineal.blanco,
                    marginLeft: 15,
                    paddingHorizontal: 20,
                  }
            }
          >
            Gráficos Procesos
          </Title>
        </View>
        {muestraGraficosPostulante && (
          <View>
            <CustomListSelect
              multiselect={true}
              showIcon={true}
              nombreLista={"Seleccione Grafico"}
              lista={listaGraficos.postulantes}
              nombreValue="value"
              nombreLabel="label"
              value={graficos}
              closeOnClick={false}
              onChange={(item) => {
                setGraficos(item);
              }}
              top={0}
              height="100vh"
            />
            <ScrollView
              style={{
                height: "calc(100vh - 257px)",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingHorizontal: 15,
                  paddingBottom: 15,
                }}
              >
                {!showSpinner &&
                  graficos !== undefined &&
                  graficos !== null &&
                  graficos.length > 0 &&
                  graficos.map((grafico, index) => {
                    return (
                      <Card
                        key={`grafico-card-content-${index}`}
                        style={{
                          minWidth: 200,
                          width: "47.42vw",
                          borderRadius: 0,
                          backgroundColor: ColoresPineal.azulRey,
                          padding: 5,
                          shadowOffset: 0,
                          shadowOpacity: 0,
                          shadowRadius: 0,
                        }}
                      >
                        <Card.Content
                          style={{
                            backgroundColor: ColoresPineal.blanco,
                            padding: 10,
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {grafico.type === "Funel" && (
                            <FunnelChart
                              backgroundColor={ColoresPineal.blanco}
                              style={{
                                color: "#757575",
                                fontFamily: "Roboto",
                              }}
                              data={grafico.data}
                              pallette={grafico.options.pallette}
                              title={grafico.options.title}
                              showRunningTotal={true}
                            />
                          )}
                          {grafico.type !== "Funel" && (
                            <Chart
                              chartType={grafico.type}
                              width="100%"
                              height="500px"
                              data={grafico.data}
                              options={grafico.options}
                            />
                          )}
                        </Card.Content>
                      </Card>
                    );
                  })}
              </View>
            </ScrollView>
          </View>
        )}
        {!muestraGraficosPostulante && (
          <View>
            <CustomListSelect
              multiselect={true}
              showIcon={true}
              nombreLista={"Seleccione Grafico"}
              lista={listaGraficos.procesos}
              nombreValue="value"
              nombreLabel="label"
              value={graficosProcesos}
              closeOnClick={false}
              onChange={(item) => {
                setGraficosProcesos(item);
              }}
              top={0}
              height="100vh"
            />
            <ScrollView
              style={{
                height: "calc(100vh - 147px)",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingHorizontal: 15,
                  paddingBottom: 15,
                }}
              >
                {!showSpinner &&
                  graficosProcesos !== undefined &&
                  graficosProcesos !== null &&
                  graficosProcesos.length > 0 &&
                  graficosProcesos.map((grafico, index) => {
                    return (
                      <Card
                        key={`grafico-card-content-${index}`}
                        style={{
                          minWidth: 200,
                          width: "47.42vw",
                          borderRadius: 0,
                          backgroundColor: ColoresPineal.azulRey,
                          padding: 5,
                          shadowOffset: 0,
                          shadowOpacity: 0,
                          shadowRadius: 0,
                        }}
                      >
                        <Card.Content
                          style={{
                            backgroundColor: ColoresPineal.blanco,
                            padding: 10,
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {grafico.type === "Funel" && (
                            <FunnelChart
                              backgroundColor={ColoresPineal.blanco}
                              style={{
                                color: "#757575",
                                fontFamily: "Roboto",
                              }}
                              data={grafico.data}
                              pallette={grafico.options.pallette}
                              title={grafico.options.title}
                              showRunningTotal={true}
                            />
                          )}
                          {grafico.type !== "Funel" && (
                            <Chart
                              chartType={grafico.type}
                              width="100%"
                              height="500px"
                              data={grafico.data}
                              options={grafico.options}
                            />
                          )}
                        </Card.Content>
                      </Card>
                    );
                  })}
              </View>
            </ScrollView>
          </View>
        )}
      </View>
      <CustomFabGroup
        listaBotones={[
          {
            icon: "download",
            onPress: () => obtieneExcelReporteCandidatos(),
            label: "Descargar",
          },
        ]}
        visible={!showSpinner}
      />
      <LoadingModal visible={showSpinner} mensaje={"Cargando datos"} />
      <LoadingModal visible={showSpinner2} mensaje={"Generando Documento"} />
      <Alerta
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        closeModal={() => {
          setAlertaVisible(false);
          setActualizar(!actualizar);
        }}
        confirmacionModal={false}
      />
      <CustomDialog
        titulo={"Filtros"}
        visible={dialogoFiltros}
        dismissable={false}
        onClose={() => setDialogoFiltros(false)}
        contenido={
          <ScrollView style={{ height: "calc(100vh - 331px)" }}>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {muestraGraficosPostulante &&
                filtrosPostulantes.map((filtro, index) => {
                  let valor = `Seleccione ${filtro.nombre}`;
                  if (filtro.tipo === "lista") {
                    if (filtro.valor !== undefined) {
                      valor = "";
                      filtro.valor.forEach((d) => {
                        if (valor.length > 0) {
                          valor = valor.concat(", ");
                        }
                        valor = valor.concat(d.label);
                      });
                    }
                  }
                  return (
                    <View
                      style={{ width: "49%" }}
                      key={`contenedor-filtro-${index}`}
                    >
                      <Subheading>{filtro.nombre}</Subheading>
                      {filtro.tipo === "lista" && (
                        <div
                          onClick={() => {
                            setFiltro(filtro);
                            setDialogoFiltros(false);
                            setDialogoLista(true);
                          }}
                        >
                          <CustomTextInput disabled={true} value={valor} />
                        </div>
                      )}
                      {filtro.tipo === "fecha" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomDatepicker
                              key={`fecha-${index}`}
                              fechaInicial={false}
                              label="Fecha Mínima"
                              value={filtro.valor?.min}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["min"] = text;
                                aplicaFiltros(filtro.nivel);
                              }}
                              requiered={false}
                              incluyeHora={false}
                            />
                          </div>

                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomDatepicker
                              key={`fecha-${index}`}
                              fechaInicial={false}
                              label="Fecha Máxima"
                              value={filtro.valor?.max}
                              onChange={(text) => {
                                console.log(text);
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["max"] = text;
                                aplicaFiltros(filtro.nivel);
                              }}
                              requiered={false}
                              incluyeHora={false}
                            />
                          </div>
                        </div>
                      )}
                      {filtro.tipo === "rango" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomTextInput
                              valueType={"numeros"}
                              disabled={false}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["min"] = parseInt(text);
                                aplicaFiltros(filtro.nivel);
                              }}
                              label="Mínimo"
                              value={filtro.valor?.min}
                            />
                          </div>

                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomTextInput
                              valueType={"numeros"}
                              disabled={false}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["max"] = parseInt(text);
                                aplicaFiltros(filtro.nivel);
                              }}
                              label="Máximo'"
                              value={filtro.valor?.max}
                            />
                          </div>
                        </div>
                      )}
                    </View>
                  );
                })}
              {!muestraGraficosPostulante &&
                filtrosProcesos.map((filtro, index) => {
                  let valor = `Seleccione ${filtro.nombre}`;
                  if (filtro.tipo === "lista") {
                    if (filtro.valor !== undefined) {
                      valor = "";
                      filtro.valor.forEach((d) => {
                        if (valor.length > 0) {
                          valor = valor.concat(", ");
                        }
                        valor = valor.concat(d.label);
                      });
                    }
                  }
                  return (
                    <View
                      style={{ width: "49%" }}
                      key={`contenedor-filtro-${index}`}
                    >
                      <Subheading>{filtro.nombre}</Subheading>
                      {filtro.tipo === "lista" && (
                        <div
                          onClick={() => {
                            setFiltro(filtro);
                            setDialogoFiltros(false);
                            setDialogoLista(true);
                          }}
                        >
                          <CustomTextInput disabled={true} value={valor} />
                        </div>
                      )}
                      {filtro.tipo === "fecha" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomDatepicker
                              key={`fecha-${index}`}
                              fechaInicial={false}
                              label="Fecha Mínima"
                              value={filtro.valor?.min}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["min"] = text;
                                aplicaFiltros(filtro.nivel);
                              }}
                              requiered={false}
                              incluyeHora={false}
                            />
                          </div>

                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomDatepicker
                              key={`fecha-${index}`}
                              fechaInicial={false}
                              label="Fecha Máxima"
                              value={filtro.valor?.max}
                              onChange={(text) => {
                                console.log(text);
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["max"] = text;
                                aplicaFiltros(filtro.nivel);
                              }}
                              requiered={false}
                              incluyeHora={false}
                            />
                          </div>
                        </div>
                      )}
                      {filtro.tipo === "rango" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomTextInput
                              valueType={"numeros"}
                              disabled={false}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["min"] = parseInt(text);
                                aplicaFiltros(filtro.nivel);
                              }}
                              label="Mínimo"
                              value={filtro.valor?.min}
                            />
                          </div>

                          <div
                            style={{
                              width: "48%",
                            }}
                            onClick={() => {
                              setFiltro(filtro);
                            }}
                          >
                            <CustomTextInput
                              valueType={"numeros"}
                              disabled={false}
                              onChange={(text) => {
                                if (filtro.valor === undefined) {
                                  filtro.valor = {
                                    min: undefined,
                                    max: undefined,
                                  };
                                }
                                filtro.valor["max"] = parseInt(text);
                                aplicaFiltros(filtro.nivel);
                              }}
                              label="Máximo'"
                              value={filtro.valor?.max}
                            />
                          </div>
                        </div>
                      )}
                    </View>
                  );
                })}
            </View>
          </ScrollView>
        }
      />
      {filtro !== undefined && (
        <CustomDialog
          titulo={`Seleccione ${filtro.nombre}`}
          visible={dialogoLista && filtro !== undefined}
          dismissable={false}
          onClose={() => {
            setFiltro(undefined);
            setDialogoFiltros(true);
            setDialogoLista(false);
          }}
          contenido={
            <SelectAutocompletar
              lista={filtro.lista}
              nombreLabel="label"
              nombreValue="value"
              showIcon={true}
              onSelect={(items) => {
                if (items.length > 0) {
                  filtro.valor = items;
                } else {
                  filtro.valor = undefined;
                }
                aplicaFiltros(filtro.nivel);
              }}
              maxHeight="calc(100vh - 425px)"
              value={filtro.valor === undefined ? [] : filtro.valor}
              multiselect={true}
            />
          }
        />
      )}
    </View>
  );
}
