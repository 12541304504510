import { TableCell, TableFooter, TableRow } from "@material-ui/core";
import MuiTablePagination from "@material-ui/core/TablePagination";
import * as scale from "d3-scale";
import React from "react";
import { Dimensions, View } from "react-native";
import { Text } from "react-native-paper";
import Svg, { Circle, G, Text as TextSVG } from "react-native-svg";
import { BarChart, Grid, PieChart, YAxis } from "react-native-svg-charts";
import XLSX from "xlsx";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import CustomSwitch from "../components/CustomSwitch";
import LoadingIndicator from "../components/LoadingIndicator";
import LoadingModal from "../components/LoadingModal";
import ReportesService from "../services/ReportesService";
import Alerta from "./modal/Alerta";

const windowWidth = Dimensions.get("window").width;

export default function CandidatosReportes({ navigation, user }) {
  const [candidatos, setCandidatos] = React.useState([]);
  const [data] = React.useState({});
  const [actualizar, setActualizar] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [columnasView] = React.useState({});
  const [columnasNombre] = React.useState({});
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [loadingVisible, setLoadingVisible] = React.useState(false);
  const [mensajeLoading, setMensajeLoading] = React.useState();
  const [datos, setDatos] = React.useState();
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);

  const desseleccionaTodos = () => {
    candidatos
      .filter((candidato) => {
        return candidato.seleccionado;
      })
      .forEach((candidato) => {
        candidato.seleccionado = false;
      });
  };

  const datoColumna = (parametro, nombreColumna, opciones = {}) => {
    let mostrarColumna =
      columnasView[parametro] !== undefined ? columnasView[parametro] : false;
    columnasNombre[parametro] = nombreColumna;
    return {
      label: nombreColumna,
      name: parametro,
      options: {
        filter: true,
        sort: false,
        display: mostrarColumna,
        customFilterListOptions: {
          render: (v) => `${nombreColumna}: ${v}`,
        },
        ...opciones,
      },
    };
  };

  const columnas = () => {
    let columnas = [
      datoColumna("empresa", "Empresa"),
      datoColumna("cargo", "Cargo"),
      datoColumna("genero", "Género"),
      datoColumna("estado_civil", "Estado civil"),
      datoColumna("licencia", "Licencia"),
      datoColumna("eperiencia_laboral", "Experiencia Laboral"),
      datoColumna("region", "Region"),
      datoColumna("ciudad", "Ciudad"),
      datoColumna("carrera", "Carrera"),
      datoColumna("entidad_educativa", "Entidad educativa"),
      datoColumna("anio_egreso", "Año Egreso"),
      datoColumna("nota_egreso", "Nota Egreso"),
      datoColumna("especialidad", "Especialidad"),
      datoColumna("post_grado", "Postgrado"),
      datoColumna("liceo", "Liceo"),
      datoColumna("idioma", "Idioma"),
      datoColumna("nivel_escrito", "Nivel Escrito"),
      datoColumna("nivel_oral", "Nivel Oral"),
      datoColumna("certificacion", "Certificacion"),
      datoColumna("curso", "Curso"),
      datoColumna("empresa_experiencia", "Empresa experiencia"),
      datoColumna("periodo_ingreso", "Periodo Ingreso"),
      datoColumna("periodo_egreso", "Periodo Egreso"),
      datoColumna("rubro", "Rubro"),
      datoColumna("nombre_cargo", "Cargo Experiencia"),
      datoColumna("rol", "Rol"),
      datoColumna("funciones", "Funciones"),
      datoColumna("nombre_ref", "Nombre Referido"),
      datoColumna("telefono_ref", "Telefono Referido"),
      datoColumna("cargo_ref", "Cargo Referido"),
      datoColumna("email_ref", "Correo Referido"),
      datoColumna("empresa_ref", "Empresa Referido"),
    ];
    return columnas;
  };

  const obtieneData = () => {
    let values = {};
    let mapRut = {};

    data["xlsx"] = [];
    data["candidatos"].forEach((candidato) => {
      let llave = "";
      let dato = {};
      if (mapRut[candidato["rut"]] === undefined) {
        dato["RUT"] = candidato["rut"];
        dato["NOMBRE"] = candidato["nombrecompleto"];
        mapRut[candidato["rut"]] = candidato;
        for (const property in columnasView) {
          if (llave.length > 0) {
            llave = llave + " - ";
          }
          llave = llave + candidato[property];
          dato[columnasNombre[property]] = candidato[property];
        }
        data["xlsx"].push(dato);
        let valor = values[llave];
        if (valor === undefined) {
          values[llave] = 1;
        } else {
          values[llave] = valor + 1;
        }
      }
    });

    return values;
  };

  const randomColor = () =>
    ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(
      0,
      7
    );

  const getDatos = (datos) => {
    let datosAux = [];
    if (datos !== undefined) {
      let i = 1;
      for (var property in datos) {
        datosAux.push({
          key: i,
          value: datos[property],
          svg: {
            fill: randomColor(),
          },
        });
        i++;
      }
    }

    return datosAux;
  };

  const getLabels = (datos) => {
    let labelsAux = [];
    if (datos !== undefined) {
      for (var property in datos) {
        labelsAux.push(property);
      }
    }

    return labelsAux;
  };

  const Values = ({ x, y, bandwidth, data }) =>
    data.map((item, index) => {
      let value = item.value;
      return (
        <TextSVG
          key={index}
          x={x(value) - 40 > 0 ? x(value) - 20 : x(value) + 20}
          y={y(index) + bandwidth / 2}
          fontSize={14}
          fill={x(value) - 40 > 0 ? "#0a5dc1" : "black"}
          alignmentBaseline={"middle"}
          fontFamily={"Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif"}
          textAnchor={"middle"}
          fontWeight={"bold"}
        >
          {value}
        </TextSVG>
      );
    });

  const ValuesPie = ({ slices, height, width }) => {
    return slices.map((slice, index) => {
      const { labelCentroid, pieCentroid, data } = slice;
      return (
        <Text
          key={index}
          x={pieCentroid[0]}
          y={pieCentroid[1]}
          fill={"white"}
          textAnchor={"middle"}
          alignmentBaseline={"middle"}
          fontSize={24}
          stroke={"black"}
          strokeWidth={0.2}
        >
          {data.value}
        </Text>
      );
    });
  };

  const setDataChange = () => {
    let data = obtieneData();
    let labelsAux = getLabels(data);
    let valuesAux = getDatos(data);
    setDatos({
      labels: labelsAux,
      values: valuesAux,
    });

    setActualizar(!actualizar);
  };

  const downloadFile = () => {
    var ws = XLSX.utils.json_to_sheet(data["xlsx"]);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Postulantes");
    XLSX.writeFile(wb, "Reporte_Postulantes.xlsx");
  };

  const obtieneReporteCandidatos = () => {
    ReportesService.obtieneReportePostulantes(user.token).then((resp) => {
      setCandidatos(resp.entidad);
      data["candidatos"] = resp.entidad;
      setShowSpinner(false);
    });
  };

  React.useEffect(() => {
    if (candidatos.length === 0) {
      obtieneReporteCandidatos();
    }
  }, [candidatos.length, actualizar]);

  return (
    <View style={{ padding: 20 }}>
      {showSpinner && (
        <LoadingIndicator texto={"Cargando Base de datos Pineal"} />
      )}
      {!showSpinner && (
        <div>
          <CustomMuiDataTable
            data={candidatos}
            columnas={columnas()}
            padding={0}
            height={"auto"}
            options={{
              customFooter: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                textLabels
              ) => {
                let mapRut = {};
                let totalData = 0;
                data["candidatos"].forEach((candidato) => {
                  if (mapRut[candidato["rut"]] === undefined) {
                    mapRut[candidato["rut"]] = candidato;
                    totalData++;
                  }
                });

                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 24px 0px 24px",
                        }}
                        colSpan={1000}
                      >
                        <MuiTablePagination
                          component="div"
                          count={totalData}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          labelRowsPerPage={textLabels.rowsPerPage}
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} ${textLabels.displayRows} ${count}`
                          }
                          backIconButtonProps={{
                            "aria-label": textLabels.previous,
                          }}
                          nextIconButtonProps={{
                            "aria-label": textLabels.next,
                          }}
                          rowsPerPageOptions={[10, 20, 100]}
                          onChangePage={(_, page) => changePage(page)}
                          onChangeRowsPerPage={(event) =>
                            changeRowsPerPage(event.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              //customRowRender: (data, dataIndex, rowIndex) => {},
              onViewColumnsChange: (changedColumn, action) => {
                if (action === "add") {
                  columnasView[changedColumn] = true;
                } else {
                  delete columnasView[changedColumn];
                }
                setDataChange();
              },
              onFilterChange: (
                changedColumn,
                filterList,
                type,
                changedColumnIndex,
                displayData
              ) => {
                data["candidatos"] = [];
                if (displayData !== undefined) {
                  displayData.forEach((element) => {
                    data["candidatos"].push(candidatos[element.dataIndex]);
                  });
                } else {
                  obtieneReporteCandidatos();
                }
                setDataChange();
              },
            }}
          />
          <CustomSwitch onSwitch={(estado) => setIsSwitchOn(estado)} texto={isSwitchOn ? "Gráfico de Barras" : "Gráfico Circular"}/>
        </div>
      )}
      {datos && datos.values.length > 0 && !isSwitchOn && (
        <View
          style={{
            flexDirection: "row",
            width: (windowWidth * 3) / 5 + 20,
            height: 50 * datos.values.length,
            alignSelf: "center",
            marginVertical: 20,
            padding: 10,
            backgroundColor: "rgba(28,49,74,0.16)",
          }}
        >
          <YAxis
            style={{
              width: (windowWidth * 1) / 5,
            }}
            data={datos.values}
            yAccessor={({ index }) => index}
            scale={scale.scaleBand}
            contentInset={{ top: 30, bottom: 30 }}
            svg={{
              fontFamily:
                "Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
              textAnchor: "end",
              x: "100%",
              dx: "-10",
            }}
            spacing={0.2}
            formatLabel={(_, index) => datos.labels[index]}
          />
          <BarChart
            style={{
              width: (windowWidth * 2) / 5,
            }}
            data={datos.values}
            horizontal={true}
            svg={{ fill: "rgba(246, 166, 35, 0.8)" }}
            contentInset={{ top: 30, bottom: 30 }}
            yAccessor={({ item }) => item.value}
            spacing={0.2}
            gridMin={0}
          >
            <Grid direction={Grid.Direction.VERTICAL} />
            <Values />
          </BarChart>
        </View>
      )}
      {datos && datos.values.length > 0 && isSwitchOn && (
        <View
          style={{
            flexDirection: "column",
            width: (windowWidth * 3) / 5 + 20,
            height: (windowWidth * 2) / 5 + datos.values.length * 25 + 30,
            alignSelf: "center",
            marginVertical: 20,
            padding: 10,
            backgroundColor: "rgba(28,49,74,0.16)",
          }}
        >
          <PieChart
            style={{
              height: (windowWidth * 2) / 5,
              width: (windowWidth * 3) / 5,
            }}
            outerRadius={"70%"}
            innerRadius={10}
            data={datos.values}
            valueAccessor={({ item }) => item.value}
            spacing={0}
          >
            <ValuesPie />
          </PieChart>
          <Svg
            height={datos.values.length * 25 + 15}
            width={(windowWidth * 3) / 5}
          >
            {datos.values.map((item, index) => {
              let altoTotal = datos.values.length * 25 + 15;
              return (
                <G>
                  <Circle
                    cx="30"
                    cy={25 * index + 15}
                    r="10"
                    stroke="black"
                    strokeWidth="1"
                    fill={item.svg.fill}
                  />
                  <TextSVG
                    key={index}
                    x={50}
                    y={25 * index + 15}
                    fontSize={14}
                    fill={"black"}
                    alignmentBaseline={"middle"}
                    fontFamily={
                      "Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif"
                    }
                    //textAnchor={'middle'}
                    //fontWeight={"bold"}
                  >
                    {datos.labels[index]}
                  </TextSVG>
                </G>
              );
            })}
          </Svg>
        </View>
      )}
      <CustomFabGroup
        listaBotones={[
          {
            icon: "download",
            onPress: () => downloadFile(),
            label: "Descargar",
          },
        ]}
        visible={datos?.values?.length > 0}
      />
      <LoadingModal visible={loadingVisible} mensaje={mensajeLoading} />
      <Alerta
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          setAlertaVisible(false);
          desseleccionaTodos();
          setActualizar(!actualizar);
        }}
        closeModal={() => setAlertaVisible(false)}
      />
    </View>
  );
}
