import React from "react";
import { View } from "react-native";
import { Text, List } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import CustomButton from "./CustomButton";
import TablaDetallePostulante from "./TablaDetallePostulante";

export default function AcordionExperienciaPostulante({
  titulo,
  expanded,
  postulante,
  editar,
  nroAcordion,
  setNroAcordion,
  listaRubros,
}) {
  const [experienciaLaboral, setExperienciaLaboral] = React.useState(
    postulante.experienciaLaboral
  );
  const [actualizar, setActualizar] = React.useState(false);

  const agregaExperiencia = () => {
    let listaNueva = [
      ...experienciaLaboral,
      {
        nombreEmpresa: "",
        nombreCargo: "",
        rubro: "",
        rol: "",
        funciones: "",
        periodoIngreso: "",
        periodoEgreso: "",
      },
    ];

    postulante["experienciaLaboral"] = listaNueva;
    setExperienciaLaboral(listaNueva);
    setActualizar(!actualizar);
  };

  const eliminaExperiencia = (indexEliminar) => {
    let listaAux = [];
    experienciaLaboral.map((experiencia, index) => {
      if (index !== indexEliminar) {
        listaAux.push(experiencia);
      }
    });

    postulante["experienciaLaboral"] = listaAux;
    setExperienciaLaboral(listaAux);
    setActualizar(!actualizar);
  };

  const periodoTexto = (periodo) => {
    periodo = periodo.replace(/[^0-9]/g, "");
    var mes = periodo.substr(0, periodo.length - 4);
    var anio = periodo.substr(2, periodo.length - 1);

    return mes + "/" + anio;
  };

  return (
    <List.Accordion
      titleStyle={{ color: ColoresPineal.blanco }}
      descriptionStyle={{ color: ColoresPineal.blanco }}
      expanded={expanded}
      onPress={() =>
        nroAcordion === 3 ? setNroAcordion(-1) : setNroAcordion(3)
      }
      style={{
        backgroundColor: ColoresPineal.azulOscuro,
        borderBottomColor: ColoresPineal.blanco,
        borderBottomWidth: 1,
      }}
      title={
        <View>
          <Text style={{ fontSize: 20, color: ColoresPineal.blanco }}>
            {titulo}
          </Text>
        </View>
      }
    >
      {experienciaLaboral.map((experiencia, i) => {
        let lista = [
          {
            nombre: "Nombre Empresa",
            valor: experiencia.nombreEmpresa,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "nombreEmpresa",
            index: i,
          },
          {
            nombre: "Nombre Cargo",
            valor: experiencia.nombreCargo,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "nombreCargo",
            index: i,
          },
          {
            nombre: "Rubro",
            valor: experiencia.nombreRubro,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "rubro",
            lista: listaRubros,
            index: i,
          },
          {
            nombre: "Rol",
            valor: experiencia.rol,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "rol",
            index: i,
          },
          {
            nombre: "Funciones",
            valor: experiencia.funciones,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "funciones",
            index: i,
          },
        ];
        if (editar) {
          lista.push({
            nombre: "Periodo Ingreso",
            valor: experiencia.periodoIngreso,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "periodoIngreso",
            valueType: "periodo",
            maxLength: 6,
            index: i,
          });
          lista.push({
            nombre: "Periodo Egreso",
            valor: experiencia.periodoEgreso,
            edit: editar,
            campo: "experienciaLaboral",
            subCampo: "periodoEgreso",
            valueType: "periodo",
            maxLength: 6,
            index: i,
          });
        } else {
          lista.push({
            nombre: "Periodo",
            valor:
              periodoTexto(experiencia.periodoIngreso) +
              " - " +
              (experiencia.periodoEgreso === null
                ? "A la fecha"
                : periodoTexto(experiencia.periodoEgreso)),
          });
        }
        return (
          <TablaDetallePostulante
            titulo={`Experiencia Nº${i + 1}`}
            lista={lista}
            icon={editar ? "delete" : undefined}
            onPressIcon={editar ? () => eliminaExperiencia(i) : undefined}
            postulante={postulante}
          />
        );
      })}

      {experienciaLaboral.length === 0 && (
        <TablaDetallePostulante
          titulo={"Sin Experiencias"}
          style={{ backgroundColor: ColoresPineal.grisAzulado }}
          styleTitle={{ color: ColoresPineal.azulOscuro }}
        />
      )}
      {editar && (
        <CustomButton
          textoButton="Agregar Experiencia"
          onPress={() => agregaExperiencia()}
        />
      )}
    </List.Accordion>
  );
}
