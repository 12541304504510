import React from "react";
import {  
  Tooltip,
} from "@material-ui/core";
//Para Generar Signature de Zoom
const crypto = require("crypto");
function generateSignature(apiKey, apiSecret, meetingNumber, role) {
  const timestamp = new Date().getTime() - 30000;
  const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
    "base64"
  );
  const hash = crypto
    .createHmac("sha256", apiSecret)
    .update(msg)
    .digest("base64");
  const signature = Buffer.from(
    `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
  ).toString("base64");
  return signature;
}
      //IdZoom por internoIdZoom, son 2 
      //var internoIdZoom="9101836676";
const Zoom = ({children, IdZoom, ClaveZoom, UsuarioZoom} ) =>{
    return(
        <div>
         <Tooltip title="Entorno Videollamada.">
          <iframe
            src={"https://1253.cl/meeting.html?name=" + UsuarioZoom + "&mn=" + IdZoom + "&email=&pwd="+ ClaveZoom +"&role=0&lang=es-ES&signature=" + generateSignature('5X-79FfwRYSwdl1_RIU-Aw', 'UGCnXQZHu6I4dEmZHYah6XC6zdER3J0fPZOZ', IdZoom, 1) + "&china=0&apiKey=5X-79FfwRYSwdl1_RIU-Aw"}
              allow="microphone; camera"
              frameborder="0"
              allowfullscreen
              width="100%"
              height="600"
          >
        </iframe>
        </Tooltip>
      </div>

    );
}
export default Zoom;