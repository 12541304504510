import React from "react";
import { View } from "react-native";
import CustomDialog from "../components/CustomDialog";
import styles from "../styles/style";
import CustomButton from "./CustomButton";
import SelectAutocompletar from "./SelectAutocompletar";

export default function BotonesEmpresaCargo({
  empresas,
  empresa,
  cargos,
  cargo,
  selectEmpresa = (item) => {},
  selectCargo = (item) => {},
}) {
  const [visible, setVisible] = React.useState(false);
  const [esDialogEmpresa, setEsDialogEmpresa] = React.useState(true);
  return (
    <View style={styles.botonera}>
      <CustomButton
        disabled={empresas.length === 0}
        onPress={() => {
          setEsDialogEmpresa(true);
          setVisible(true);
        }}
        contentStyle={{
          flexDirection: "row-reverse",
        }}
        icon="chevron-down"
        textoButton={empresa.nombre}
        style={styles.botonera}
        oscuro={true}
      />
      <CustomButton
        disabled={empresa.id === -1 && cargos.length === 0}
        onPress={() => {
          setEsDialogEmpresa(false);
          setVisible(true);
        }}
        contentStyle={{
          flexDirection: "row-reverse",
        }}
        icon="chevron-down"
        textoButton={cargo.cargoProceso}
        style={styles.botonera}
        oscuro={true}
      />
      <CustomDialog
        visible={visible}
        dismissable={true}
        titulo={`Seleccione ${esDialogEmpresa ? "Empresa" : "Cargo"}`}
        olcutarAcciones={true}
        onDismiss={() => setVisible(false)}
        contenido={
          <SelectAutocompletar
            lista={esDialogEmpresa ? empresas : cargos}
            nombreLabel={esDialogEmpresa ? "nombre" : "cargoProceso"}
            nombreValue={esDialogEmpresa ? "id" : "idCargo"}
            onSelect={(item) => {
              if (esDialogEmpresa) {
                selectEmpresa(item);
              } else {
                selectCargo(item);
              }
              setVisible(false);
            }}
            showIcon={false}
          />
        }
      />
    </View>
  );
}
