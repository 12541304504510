import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtienePostulantesReunion: (token, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/postulantes/${id}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  crearComentario: (token, comentario) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comentario),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/comentario`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        });
    });
  },


  obtieneDatosReunion: (token, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/${id}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
};
