import React from "react";
import { Button, IconButton } from "react-native-paper";
import CustomListSelect from "../../components/CustomListSelect";
import CustomTextInput from "../../components/CustomTextInput";
import styles from "../../styles/style";

export default function Competencia({ competencias, competencia, removeItem }) {
  const [nuevaCompetencia, setNuevaCompetencia] = React.useState({});
  const [actualizar, setActualizar] = React.useState(false);

  const obtieneCompetenciaValue = () => {
    let value = {
      value: competencia.idCompetencia + "",
      label: competencia.nombreCompetencia,
    };

    return value;
  };

  const seleccionaCompetencia = (items) => {
    competencia["nombreCompetencia"] = items[0]?.label;
    competencia["idCompetencia"] = items[0] ? parseInt(items[0].value) : 0;
    setActualizar(!actualizar);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <CustomListSelect
        top={0}
        label="Nombre"
        nombreLista="Seleccione Competencia"
        nombreValue="value"
        nombreLabel="label"
        showIcon={false}
        closeOnClick={true}
        value={obtieneCompetenciaValue()}
        lista={competencias}
        onChange={(items) => seleccionaCompetencia(items)}
        idElementoOtro={"1"}
        agregaElemento
        componenteOtro={(nombreValue, idElementoOtro) => {
          return (
            <div>
              <CustomTextInput
                style={styles.inputCargo}
                label="Nombre Competencia"
                value={
                  nuevaCompetencia.nombreCompetencia === undefined
                    ? ""
                    : nuevaCompetencia.nombreCompetencia
                }
                onChange={(text) => {
                  nuevaCompetencia["nombreCompetencia"] = text;
                  nuevaCompetencia["label"] = text;
                }}
                requiered={true}
              />
              <Button
                onPress={() => {
                  let maxValue = 0;
                  competencias.forEach((element) => {
                    if (parseInt(element[nombreValue]) >= maxValue) {
                      maxValue = parseInt(element[nombreValue]) + 1;
                    }
                  });
                  nuevaCompetencia[nombreValue] = maxValue * -1;
                  competencias.push(
                    JSON.parse(JSON.stringify(nuevaCompetencia))
                  );
                  setActualizar(!actualizar);
                  setNuevaCompetencia({});
                }}
                color="#0a5dc1"
                contentStyle={{
                  backgroundColor: "#f6a623",
                }}
                style={{ marginVertical: 20 }}
                mode="contained"
              >
                Agregar Competencia
              </Button>
            </div>
          );
        }}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Rango minimo"
        valueType="numeros"
        value={
          competencia.escalaMenor === undefined ? "" : competencia.escalaMenor
        }
        onChange={(text) => (competencia["escalaMenor"] = parseInt(text))}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Rango maximo"
        valueType="numeros"
        value={
          competencia.escalaMayor === undefined ? "" : competencia.escalaMayor
        }
        onChange={(text) => (competencia["escalaMayor"] = parseInt(text))}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Valor minimo requerido"
        valueType="numeros"
        value={
          competencia.minimoRequerido === undefined
            ? ""
            : competencia.minimoRequerido
        }
        onChange={(text) => (competencia["minimoRequerido"] = parseInt(text))}
        requiered={true}
      />
      <IconButton
        style={{ alignSelf: "center" }}
        onPress={() => removeItem()}
        icon="close-circle-outline"
        color="rgb(246, 166, 35)"
        size={40}
      />
    </div>
  );
}
