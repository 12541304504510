import React from "react";
import { ScrollView, Text, View } from "react-native";
import { IconButton, List, TextInput } from "react-native-paper";
import AcordionEducacionPostulante from "../components/AcordionEducacionPostulante";
import AcordionExperienciaPostulante from "../components/AcordionExperienciaPostulante";
import AcordionReferenciasPostulante from "../components/AcordionReferenciasPostulante";
import CustomButton from "../components/CustomButton";
import CustomDialog from "../components/CustomDialog";
import CustomFabGroup from "../components/CustomFabGroup";
import TablaDetallePostulante from "../components/TablaDetallePostulante";
import Alerta from "../screens/modal/Alerta";
import CargoService from "../services/CargoService";
import DatosServices from "../services/DatosServices";
import PostulanteService from "../services/PostulanteService";
import UserService from "../services/UserService";
import DocumentosService from "../services/DocumentosService";
import ColoresPineal from "../styles/ColoresPineal";
import "../styles/detalle.css";
import Formato from "../utils/Formato";
import Roles from "../utils/Roles";
import ProcesosService from "../services/ProcesosService";
import EmpresaService from "../services/EmpresaService";

const usuerTest = {
  user: "admin_test@sidigroup.cl",
  token:
    "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiYWRtaW5fdGVzdEBzaWRpZ3JvdXAuY2wiLCJhdXRob3JpdGllcyI6W10sImlhdCI6MTY2MDY4ODg0MSwiZXhwIjoxNjYwNjk0ODQxfQ.y0mmW7zRIhgHHyYQi70aQBj1a-FnlwXJXL7BD1CROp12vIQPG1zPd5LKFC_F0MeHVBTqdYpf1qF7O7L8TTmEXQ",
  imagen: null,
  rol: {
    id: 3,
    description: "Súper Administrador",
  },
};

export default function DetallePostulante({
  user = usuerTest,
  route,
  navigation,
}) {
  const { cargo } = route.params;
  const { postulante } = route.params;
  const [comentarios, setComentarios] = React.useState([]);
  const [comentario, setComentario] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [editar, setEditar] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [regiones, setRegiones] = React.useState([]);
  const [comunas, setComunas] = React.useState([]);
  const [generos, setGeneros] = React.useState([]);
  const [idiomas, setIdiomas] = React.useState([]);
  const [licenciaconducir, setLicenciaConducir] = React.useState([]);
  const [tiposEntidades, seTiposEntidades] = React.useState([]);
  const [instituciones, setIntituciones] = React.useState([]);
  const [carreras, setCarreras] = React.useState([]);
  const [rubros, setRubros] = React.useState([]);
  const [estadosCiviles, setEstadosCiviles] = React.useState([]);
  const [panel, setPanel] = React.useState(null);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [preguntas, setPreguntas] = React.useState([]);
  const [documentos, setDocumentos] = React.useState([]);
  const [nroAcordion, setNroAcordion] = React.useState(1);
  const [aceptarButton, setAceptarButton] = React.useState(() => () => {});
  const [preguntasCargo, setPreguntasCargo] = React.useState([]);
  const [preguntasEmpresa, setPreguntasEmpresa] = React.useState([]);
  const [proceso, setProceso] = React.useState();
  const formato = new Formato();

  const closeModal = () => {
    setVisible(false);
  };

  const agregaComentario = () => {
    PostulanteService.agregarComentario(
      user,
      postulante.idPostulante,
      cargo.idProceso,
      comentario
    ).then((resp) => {
      obtieneComentariosPostulante();
      setTipoAlerta("info");
      setMensaje(`Comentario ingresado exitosamente.`);
      setComentario(null);
      setAlertaVisible(true);
    });
  };

  const obtieneComentariosPostulante = () => {
    PostulanteService.listaComentariosPostulante(
      user.token,
      postulante.idPostulante,
      cargo.idProceso
    ).then((resp) => {
      setComentarios(resp.comentariosPostulante);
      setActualizar(!actualizar);
    });
  };

  const obtieneListaIdiomas = () => {
    DatosServices.obtieneListaDatos(user.token, "idiomas").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        resp.entidad.map((idioma) => {
          idioma.value = parseInt(idioma.value);
        });
        setIdiomas(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneListaRegiones = () => {
    DatosServices.obtieneListaDatos(user.token, "region").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setRegiones(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneLicenciasConducir = () => {
    DatosServices.obtieneListaDatos(user.token, "licenciaconducir").then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setLicenciaConducir(resp.entidad);
          setActualizar(!actualizar);
        }
      }
    );
  };

  const obtieneInstituciones = () => {
    DatosServices.obtieneListaDatos(
      user.token,
      "INSTUCIONESEDUCACIONALES"
    ).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setIntituciones(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneListaComunas = () => {
    DatosServices.obtieneListaDatos(
      user.token,
      `ciudad/${postulante.region}`
    ).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setComunas(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneListaGeneros = () => {
    DatosServices.obtieneListaDatos(user.token, "genero").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setGeneros(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneTiposEntidadEducativa = () => {
    DatosServices.obtieneListaDatos(user.token, "tipoentidadeducativa").then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          seTiposEntidades(resp.entidad);
          setActualizar(!actualizar);
        }
      }
    );
  };

  const obtieneEstadosCiviles = () => {
    DatosServices.obtieneListaDatos(user.token, "estadocivil").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setEstadosCiviles(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneCarreras = () => {
    DatosServices.obtieneListaDatos(user.token, "carreras").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setCarreras(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneRubros = () => {
    DatosServices.obtieneListaDatos(user.token, "rubro").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setRubros(resp.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneProceso = () => {
    ProcesosService.obtieneProcesoUuid(user.token, postulante.uuIdProceso).then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setProceso(resp.entidad);
          setActualizar(!actualizar);
        }
      }
    );
  };

  const obtieneDatosPanel = () => {
    PostulanteService.obtieneResultadoPanel(
      user.token,
      cargo.idProceso,
      postulante.idPostulante
    ).then((resp) => {
      setPanel(resp.informe);
      setActualizar(!actualizar);
    });
  };

  const obtieneFormularioCargo = () => {
    CargoService.obtienePreguntasCargo(user.token, cargo.idCargo).then(
      (resp) => {
        setPreguntas(resp.entidad);
        setActualizar(!actualizar);
      }
    );
  };

  const obtieneLiencias = () => {
    let licencias = [];
    postulante.licencias.forEach((licencia) => {
      licencias.push(licencia.clase);
    });

    return licencias.join();
  };

  const obtieneDocumentos = () => {
    DocumentosService.listaDocumentos(user.token, postulante.idPostulante).then(
      (resp) => {
        setDocumentos(resp);
        setActualizar(!actualizar);
      }
    );
  };

  const obtienePreguntasCargoEmpresa = () => {
    EmpresaService.obtieneFormularioCargoEmpresa(
      user.token,
      postulante.uuIdProceso
    ).then((resp) => {
      setPreguntasCargo(resp.entidad.listPreguntasCargo);
      setPreguntasEmpresa(resp.entidad.listPreguntaEmpresa);
      setActualizar(!actualizar);
    });
  };

  function AcordionDetallePostulante({ index, lista, titulo, expanded }) {
    return (
      <List.Accordion
        titleStyle={{ color: ColoresPineal.blanco }}
        descriptionStyle={{ color: ColoresPineal.blanco }}
        expanded={expanded}
        onPress={() =>
          nroAcordion === 1 ? setNroAcordion(-1) : setNroAcordion(1)
        }
        style={{
          backgroundColor: ColoresPineal.azulOscuro,
          borderBottomColor: ColoresPineal.blanco,
          borderBottomWidth: 1,
        }}
        title={
          <View>
            <Text style={{ fontSize: 20 }}>{titulo}</Text>
          </View>
        }
      >
        <TablaDetallePostulante
          key={`detalle_postulante_contenedor_${index}`}
          lista={lista}
          edicion={editar}
          postulante={postulante}
          setRegion={setRegion}
        />
      </List.Accordion>
    );
  }

  const setRegion = (region) => {
    //postulante.region = region;
    obtieneListaComunas();
  };

  const actualizarPostulante = () => {
    let expectativaRenta = postulante["expectativaRenta"];
    if (
      expectativaRenta === null ||
      expectativaRenta === undefined ||
      expectativaRenta.trim().length === 0
    ) {
      expectativaRenta = "0";
    } else {
      expectativaRenta = expectativaRenta.replace(/[^0-9]/g, "");
    }
    postulante["expectativaRenta"] = expectativaRenta;
    PostulanteService.actualizarPostulante(
      user.token,
      postulante,
      documentos
    ).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Postulante actualizado correctamente.`);
        setAceptarButton(() => {
          setAceptarButton(undefined);
          setTimeout(() => {
            navigation.navigate("Procesos en Curso", {
              proceso: {
                recarga: true,
                id_empresa: proceso.empresa.id,
                id_proceso: proceso.idProceso,
              },
            });
          }, 2000);
        });
      } else {
        setTipoAlerta("error");
        setMensaje(`Se produjo un error al actulizar el postulante.`);
      }
      setEditar(false);
      setAlertaVisible(true);
    });
  };

  React.useEffect(() => {
    if (regiones.length === 0) {
      obtieneListaRegiones();
    }
  }, [regiones.length]);

  React.useEffect(() => {
    if (comunas.length === 0) {
      obtieneListaComunas();
    }
  }, [comunas.length]);

  React.useEffect(() => {
    if (generos.length === 0) {
      obtieneListaGeneros();
    }
  }, [generos.length]);

  React.useEffect(() => {
    if (idiomas.length === 0) {
      obtieneListaIdiomas();
    }
  }, [idiomas.length]);

  React.useEffect(() => {
    if (proceso === undefined) {
      obtieneProceso();
    }
  }, [proceso]);

  React.useEffect(() => {
    if (estadosCiviles.length === 0) {
      obtieneEstadosCiviles();
    }
  }, [estadosCiviles.length]);

  React.useEffect(() => {
    if (instituciones.length === 0) {
      obtieneInstituciones();
    }
  }, [instituciones.length]);

  React.useEffect(() => {
    if (licenciaconducir.length === 0) {
      obtieneLicenciasConducir();
    }
  }, [licenciaconducir.length]);

  React.useEffect(() => {
    if (carreras.length === 0) {
      obtieneCarreras();
    }
  }, [carreras.length]);

  React.useEffect(() => {
    if (rubros.length === 0) {
      obtieneRubros();
    }
  }, [rubros.length]);

  React.useEffect(() => {
    if (tiposEntidades.length === 0) {
      obtieneTiposEntidadEducativa();
    }
  }, [tiposEntidades.length]);

  React.useEffect(() => {
    if (preguntasCargo.length === 0) {
      obtienePreguntasCargoEmpresa();
    }
  }, [preguntasCargo.length]);

  React.useEffect(() => {
    if (postulante.recargar) {
      setNroAcordion(1);
      obtieneComentariosPostulante();
      obtieneDatosPanel();
      obtieneFormularioCargo();
      obtieneDocumentos();
      postulante.recargar = false;
    }
  }, [actualizar, postulante.recargar]);

  return (
    <View nativeID="detalle-contenedor-view">
      <ScrollView
        style={{ height: "calc(-110px + 100vh)" }}
        nativeID="detalle-contenedor"
      >
        {preguntas.length > 0 &&
          preguntas.map((grupo, index) => {
            let lista = [];
            if (grupo.idTipoFormulario === 1) {
              lista.push({
                nombre: "Nombre",
                valor: postulante.nombre,
                campo: "nombre",
                edit: editar,
              });
              lista.push({
                nombre: "Apellido Paterno",
                valor: postulante.apPaterno,
                campo: "apPaterno",
                edit: editar,
              });
              lista.push({
                nombre: "Apellido Materno",
                valor: postulante.apMaterno,
                campo: "apMaterno",
                edit: editar,
              });
              if (
                UserService.tieneRol(user, [
                  Roles.ADMINISTRADOR,
                  Roles.SUPER_ADMIN,
                ])
              ) {
                lista.push({
                  nombre: "R. U. T. ",
                  valor: postulante.rut,
                });
              }
              lista.push({
                nombre: "Nombre Etapa",
                valor: postulante.nombreEtapa,
              });
              lista.push({
                nombre: "Valoración",
                valor: (
                  <View style={{ flexDirection: "row" }}>
                    {Array.from("x".repeat(5)).map((element, rating) => (
                      <IconButton
                        key={rating}
                        icon={
                          rating <
                          (postulante.estrellas !== null
                            ? postulante.estrellas
                            : 0)
                            ? "star"
                            : "star-outline"
                        }
                        color={ColoresPineal.anaranjado}
                        size={22}
                        style={{ margin: 0 }}
                      />
                    ))}
                  </View>
                ),
              });

              grupo.preguntas.map((pregunta) => {
                if (
                  UserService.tieneRol(user, [
                    Roles.ADMIN_EMPRESA,
                    Roles.ENTREVISTADOR,
                    Roles.PSICOLOGO,
                    Roles.VISUALIZADOR,
                  ]) &&
                  (pregunta.name == "direccion" ||
                    pregunta.name == "email" ||
                    pregunta.name == "ntelefonoMovil" ||
                    pregunta.name == "ntelefonoFijo")
                ) {
                  return null;
                }
                lista.push({
                  nombre: pregunta.label,
                  campo: pregunta.name,
                  value: postulante[pregunta.name],
                  valor:
                    pregunta.name === "licencias"
                      ? obtieneLiencias()
                      : pregunta.name === "genero"
                      ? postulante["nombreGenero"]
                      : pregunta.name === "region"
                      ? postulante["nombreRegion"]
                      : pregunta.name === "ciudad"
                      ? postulante["nombreCiudad"]
                      : pregunta.name === "estadoCivil"
                      ? postulante["nombreEstadoCivil"]
                      : postulante[pregunta.name],
                  edit: editar,
                  lista:
                    pregunta.name === "genero"
                      ? generos
                      : pregunta.name === "region"
                      ? regiones
                      : pregunta.name === "ciudad"
                      ? comunas
                      : pregunta.name === "estadoCivil"
                      ? estadosCiviles
                      : pregunta.name === "licencias"
                      ? licenciaconducir
                      : undefined,
                  valueType:
                    pregunta.name === "expectativaRenta"
                      ? "moneda"
                      : pregunta.name === "ntelefonoMovil"
                      ? "telefono"
                      : pregunta.name === "ntelefonoFijo"
                      ? "telefono"
                      : pregunta.name === "fechaNacimiento"
                      ? "fecha"
                      : "",
                  maxLength:
                    pregunta.name === "ntelefonoMovil"
                      ? 9
                      : pregunta.name === "ntelefonoFijo"
                      ? 9
                      : undefined,
                  multiselect: pregunta.name === "licencias" ? true : undefined,
                });
              });
              return (
                <AcordionDetallePostulante
                  key={`pregunta-${index}`}
                  expanded={nroAcordion === 1}
                  lista={lista}
                  titulo={grupo.descripcion}
                  index={index}
                />
              );
            }
            if (grupo.idTipoFormulario === 2) {
              return (
                <AcordionEducacionPostulante
                  key={`pregunta-${index}`}
                  expanded={nroAcordion === 2}
                  titulo={grupo.descripcion}
                  postulante={postulante}
                  editar={editar}
                  nroAcordion={nroAcordion}
                  setNroAcordion={setNroAcordion}
                  listaIdiomas={idiomas}
                  tiposEntidades={tiposEntidades}
                  instituciones={instituciones}
                  carreras={carreras}
                />
              );
            }
            if (grupo.idTipoFormulario === 3) {
              return (
                <AcordionExperienciaPostulante
                  expanded={nroAcordion === 3}
                  key={`pregunta-${index}`}
                  titulo={grupo.descripcion}
                  postulante={postulante}
                  setNroAcordion={setNroAcordion}
                  nroAcordion={nroAcordion}
                  editar={editar}
                  listaRubros={rubros}
                />
              );
            }
            if (grupo.idTipoFormulario === 4) {
              return (
                <AcordionReferenciasPostulante
                  expanded={nroAcordion === 4}
                  key={`pregunta-${index}`}
                  titulo={grupo.descripcion}
                  postulante={postulante}
                  setNroAcordion={setNroAcordion}
                  nroAcordion={nroAcordion}
                  editar={editar}
                />
              );
            }
          })}
        <List.Accordion
          titleStyle={{ color: ColoresPineal.blanco }}
          descriptionStyle={{ color: ColoresPineal.blanco }}
          expanded={nroAcordion === 5}
          onPress={() =>
            nroAcordion === 5 ? setNroAcordion(-1) : setNroAcordion(5)
          }
          style={{
            backgroundColor: ColoresPineal.azulOscuro,
            borderBottomColor: ColoresPineal.blanco,
            borderBottomWidth: 1,
          }}
          title={
            <View>
              <Text style={{ fontSize: 20 }}>
                Preguntas asociadas al proceso de postulación
              </Text>
            </View>
          }
        >
          {postulante.respuestasPreguntasEmpresa !== undefined &&
            postulante.respuestasPreguntasEmpresa !== null &&
            preguntasEmpresa.length > 0 &&
            postulante.respuestasPreguntasEmpresa.map((preguntas, i) => {
              if (preguntas !== null && preguntas !== undefined) {
                let dato = preguntasEmpresa.find(
                  (p) => p.idFormulario === preguntas.idPregunta
                );
                let respuesta = preguntas.respuesta;
                if (
                  dato.list !== null &&
                  dato.list !== undefined &&
                  dato.list.length > 0
                ) {
                  let respAux = dato.list.find((r) => r.value === respuesta);
                  if (respAux !== undefined) {
                    respuesta = respAux.label;
                  }
                }
                return (
                  <TablaDetallePostulante
                    key={`preguntas_empresa_contenedor_${i}`}
                    postulante={postulante}
                    lista={[
                      {
                        edit: editar,
                        campo: "respuestasPreguntasEmpresa",
                        subCampo: "respuesta",
                        value: preguntas.respuesta,
                        lista:
                          dato.list !== null && dato.list !== undefined
                            ? dato.list
                            : undefined,
                        index: i,
                        nombre: preguntas.glosa,
                        valor: respuesta,
                      },
                    ]}
                  />
                );
              }
            })}
          {postulante.respuestasPreguntasCargo !== undefined &&
            postulante.respuestasPreguntasCargo !== null &&
            preguntasCargo.length > 0 &&
            postulante.respuestasPreguntasCargo.map((preguntas, i) => {
              if (preguntas !== null && preguntas !== undefined) {
                let dato = preguntasCargo.find(
                  (p) => p.idFormulario === preguntas.idPregunta
                );
                let respuesta = preguntas.respuesta;
                if (
                  dato.list !== null &&
                  dato.list !== undefined &&
                  dato.list.length > 0
                ) {
                  let respAux = dato.list.find((r) => r.value === respuesta);
                  if (respAux !== undefined) {
                    respuesta = respAux.label;
                  }
                }
                return (
                  <TablaDetallePostulante
                    key={`preguntas_cargo_contenedor_${i}`}
                    postulante={postulante}
                    lista={[
                      {
                        edit: editar,
                        campo: "respuestasPreguntasCargo",
                        subCampo: "respuesta",
                        value: preguntas.respuesta,
                        lista:
                          dato.list !== null && dato.list !== undefined
                            ? dato.list
                            : undefined,
                        index: i,
                        nombre: preguntas.glosa,
                        valor: respuesta,
                      },
                    ]}
                  />
                );
              }
            })}
        </List.Accordion>
        <List.Accordion
          titleStyle={{ color: ColoresPineal.blanco }}
          descriptionStyle={{ color: ColoresPineal.blanco }}
          expanded={nroAcordion === 7}
          onPress={() =>
            nroAcordion === 7 ? setNroAcordion(-1) : setNroAcordion(7)
          }
          style={{
            backgroundColor: ColoresPineal.azulOscuro,
            borderBottomColor: ColoresPineal.blanco,
            borderBottomWidth: 1,
          }}
          title={
            <View>
              <Text style={{ fontSize: 20 }}>Evaluaciones</Text>
            </View>
          }
        >
          {panel !== undefined && panel !== null && (
            <div style={{ backgroundColor: "#f2f2f2" }}>
              <List.Accordion
                titleStyle={{ color: ColoresPineal.blanco }}
                descriptionStyle={{ color: ColoresPineal.blanco }}
                style={{
                  backgroundColor: ColoresPineal.azulRey,
                  borderBottomColor: ColoresPineal.blanco,
                  borderBottomWidth: 1,
                }}
                title={
                  <View>
                    <Text style={{ fontSize: 16 }}>Test's</Text>
                  </View>
                }
              >
                <TablaDetallePostulante
                  lista={[
                    {
                      nombre: "Estado TEST Disc",
                      valor: postulante.estadoTestDisc,
                    },
                    {
                      nombre: "Estado TEST Wonderlic",
                      valor: postulante.estadoTestWonderlic,
                    },
                    {
                      nombre: "Estado TEST PRP",
                      valor: postulante.estadoTestPrp,
                    },
                    {
                      nombre: "Estado TEST Barrat",
                      valor: postulante.estadoTestBarrat,
                    },
                  ]}
                />
              </List.Accordion>
              <List.Accordion
                titleStyle={{ color: ColoresPineal.blanco }}
                descriptionStyle={{ color: ColoresPineal.blanco }}
                style={{
                  backgroundColor: ColoresPineal.azulRey,
                  borderBottomColor: ColoresPineal.blanco,
                  borderBottomWidth: 1,
                }}
                title={
                  <View>
                    <Text style={{ fontSize: 16 }}>Panel</Text>
                  </View>
                }
              >
                <TablaDetallePostulante
                  lista={[
                    {
                      nombre: "Panelistas",
                      valor:
                        panel.panelistas !== null
                          ? panel.panelistas.join()
                          : "",
                    },
                    {
                      nombre: "Fecha-Hora",
                      valor: panel.fechaHora !== null ? panel.fechaHora : "",
                    },
                    {
                      nombre: "Resultado",
                      valor: panel.resultado,
                    },
                    {
                      nombre: "Comentario",
                      valor: panel.comentario !== null ? panel.comentario : "",
                    },
                  ]}
                />
              </List.Accordion>
              <List.Accordion
                titleStyle={{ color: ColoresPineal.blanco }}
                descriptionStyle={{ color: ColoresPineal.blanco }}
                style={{
                  backgroundColor: ColoresPineal.azulRey,
                  borderBottomColor: ColoresPineal.blanco,
                  borderBottomWidth: 1,
                }}
                title={
                  <View>
                    <Text style={{ fontSize: 16 }}>
                      Entrevista Psicolaboral
                    </Text>
                  </View>
                }
              >
                <TablaDetallePostulante
                  lista={[
                    {
                      nombre: "Resultado",
                      valor: panel.entrevistaPsicolaboral,
                    },
                    {
                      nombre: "Comentario",
                      valor:
                        panel.comentarioEntrevistaPsicolaboral !== null
                          ? panel.comentarioEntrevistaPsicolaboral
                          : "",
                    },
                  ]}
                />
              </List.Accordion>
              <List.Accordion
                titleStyle={{ color: ColoresPineal.blanco }}
                descriptionStyle={{ color: ColoresPineal.blanco }}
                style={{
                  backgroundColor: ColoresPineal.azulRey,
                  borderBottomColor: ColoresPineal.blanco,
                  borderBottomWidth: 1,
                }}
                title={
                  <View>
                    <Text style={{ fontSize: 16 }}>Examen Preocupacional</Text>
                  </View>
                }
              >
                <TablaDetallePostulante
                  lista={[
                    {
                      nombre: "Resultado",
                      valor: panel.examenPreocupacional,
                    },
                    {
                      nombre: "Comentario",
                      valor:
                        panel.comentarioExamenPreocupacional !== null
                          ? panel.comentarioExamenPreocupacional
                          : "",
                    },
                  ]}
                />
              </List.Accordion>
            </div>
          )}
        </List.Accordion>
        <List.Accordion
          titleStyle={{ color: ColoresPineal.blanco }}
          descriptionStyle={{ color: ColoresPineal.blanco }}
          expanded={nroAcordion === 8}
          onPress={() =>
            nroAcordion === 8 ? setNroAcordion(-1) : setNroAcordion(8)
          }
          style={{
            backgroundColor: ColoresPineal.azulOscuro,
            borderBottomColor: ColoresPineal.blanco,
            borderBottomWidth: 1,
          }}
          title={
            <View>
              <Text style={{ fontSize: 20 }}>Comentarios</Text>
            </View>
          }
        >
          {comentarios !== null &&
            comentarios !== undefined &&
            comentarios.map((comentario, i) => {
              console.log(comentario);
              return (
                <TablaDetallePostulante
                  titulo={`Comentario ${i + 1}`}
                  icon={
                    user.nombreUsuario === comentario.nombreUsuario
                      ? "pencil"
                      : undefined
                  }
                  lista={[
                    {
                      nombre: "Fecha",
                      valor: comentario.fechaCreacion,
                    },
                    {
                      nombre: "Creador",
                      valor: comentario.nombreUsuario,
                    },
                    {
                      nombre: "Mensaje",
                      valor: comentario.comentario,
                    },
                  ]}
                />
              );
            })}
        </List.Accordion>
      </ScrollView>
      {editar && (
        <CustomButton
          onPress={actualizarPostulante}
          textoButton="Actualizar Postulante"
        />
      )}
      <CustomDialog
        titulo="Ingresar Comentarios"
        dismissable={false}
        visible={visible}
        contenido={
          <TextInput
            value={comentario}
            onChangeText={(text) => setComentario(text)}
          />
        }
        onClose={closeModal}
        accion={agregaComentario}
      />

      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.PSICOLOGO,
      ]) && (
        <CustomFabGroup
          visible={true}
          listaBotones={[
            {
              icon: "chat-plus",
              label: "Ingresar Comentario",
              onPress: () => setVisible(true),
            },
            {
              icon: "pencil",
              label: "Editar Postulante",
              onPress: () => {
                setEditar(true);
              },
            },
          ]}
        />
      )}
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        closeModal={() => {
          setAlertaVisible(false);
        }}
        aceptarButton={aceptarButton}
      />
    </View>
  );
}
