export default function Formato() {
  return {
    validaLargo: (text, largo) => {
      if (text.length < largo) return true;
      return false;
    },

    DateToString: (date, incluyeHora = false) => {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fecha = (day < 10 ? "0" : "") + day + "/";
      fecha = fecha + (month < 10 ? "0" : "") + month + "/";
      fecha = fecha + year;
      if (incluyeHora) {
        fecha = fecha + " ";
        let hora = date.getHours();
        fecha = fecha + (hora < 10 ? "0" : "") + hora + ":";
        let minutes = date.getMinutes();
        fecha = fecha + (minutes < 10 ? "0" : "") + minutes;
      }
      return fecha;
    },

    soloNumeros: (charCode) => {
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      ) {
        return true;
      }
      return false;
    },
    formato_nombre: (nombre) => {
      console.log(nombre);
      var letra = nombre.substr(0, 1);
      var resto = nombre.substr(1);
      return letra.toUpperCase() + resto;
    },
    fecha_Texto: (fechoInput) => {
      let date = new Date(fechoInput);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fecha = (day < 10 ? "0" : "") + day + "/";
      fecha = fecha + (month < 10 ? "0" : "") + month + "/";
      fecha = fecha + year;
      return fecha;
    },
    stringToDate: (dateText, incluyeHora = false) => {
      let valueSplit = dateText.split(/\/|\:| /);
      let date = new Date(+valueSplit[2], valueSplit[1] - 1, +valueSplit[0]);
      if (incluyeHora) {
        date.setHours(parseInt(valueSplit[3]));
        date.setMinutes(parseInt(valueSplit[4]));
      }
      return date;
    },
    stringBdToDate: (dateText, incluyeHora = false) => {
      let valueSplit = dateText.split(/\-|\:| /);
      let date = new Date(+valueSplit[0], valueSplit[1] - 1, +valueSplit[2]);
      if (incluyeHora) {
        date.setHours(parseInt(valueSplit[3]));
        date.setMinutes(parseInt(valueSplit[4]));
      }
      return date;
    },
    dateToInteger: (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fecha = year;
      fecha = fecha + (day < 10 ? "0" : "") + day;
      fecha = fecha + (month < 10 ? "0" : "") + month;
      return parseInt(fecha);
    },
    fecha_Texto_YYYY_MM_DD: (fechoInput, separador = "/") => {
      let date = new Date(fechoInput);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fecha = year + separador;
      fecha = fecha + (month < 10 ? "0" : "") + month + separador;
      fecha = fecha + (day < 10 ? "0" : "") + day;
      return fecha;
    },
    formato_periodo: (periodo) => {
      var anio = periodo.substr(2, periodo.length - 1);
      var mes = periodo.substr(0, periodo.length - 4);
      return mes + "-" + anio;
    },

    formato_nota: (nota) => {
      var sRut1 = nota; //contador de para saber cuando insertar el . o la -
      var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
      var sInvertido = ""; //Guarda el resultado final del rut como debe ser
      var sRut = "";
      for (var i = sRut1.length - 1; i >= 0; i--) {
        sInvertido += sRut1.charAt(i);
        nPos++;
        if (nPos === 1 && i > 0) {
          sInvertido += ".";
          nPos = 0;
        }
      }
      for (var j = sInvertido.length - 1; j >= 0; j--) {
        if (sInvertido.charAt(sInvertido.length - 1) !== ".")
          sRut += sInvertido.charAt(j);
        else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
      }
      //Pasamos al campo el valor formateado
      return sRut.toUpperCase();
    },

    formato_telefono: (telefono) => {
      var sRut1 = telefono; //contador de para saber cuando insertar el . o la -
      var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
      var sInvertido = ""; //Guarda el resultado final del rut como debe ser
      var sRut = "";
      for (var i = sRut1.length - 1; i >= 0; i--) {
        sInvertido += sRut1.charAt(i);
        nPos++;
        if (nPos === 4) {
          sInvertido += " ";
          nPos = 0;
        }
      }
      for (var j = sInvertido.length - 1; j >= 0; j--) {
        if (sInvertido.charAt(sInvertido.length - 1) !== ".")
          sRut += sInvertido.charAt(j);
        else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
      }
      //Pasamos al campo el valor formateado
      return sRut.toUpperCase();
    },
    formato_rut: (rut) => {
      var sRut1 = rut; //contador de para saber cuando insertar el . o la -
      var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
      var sInvertido = ""; //Guarda el resultado final del rut como debe ser
      var sRut = "";
      for (var i = sRut1.length - 1; i >= 0; i--) {
        sInvertido += sRut1.charAt(i);
        if (i === sRut1.length - 1) sInvertido += "-";
        else if (nPos === 3) {
          sInvertido += ".";
          nPos = 0;
        }
        nPos++;
      }
      for (var j = sInvertido.length - 1; j >= 0; j--) {
        if (sInvertido.charAt(sInvertido.length - 1) !== ".")
          sRut += sInvertido.charAt(j);
        else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
      }
      //Pasamos al campo el valor formateado
      return sRut.toUpperCase();
    },
    formato_moneda: (moneda) => {
      let aux = "";
      let cont = 0;
      let decimales = "";
      if (moneda.indexOf(".") > 0) {
        decimales = moneda.substring(moneda.indexOf(".") + 1);
      }
      moneda = parseInt(moneda) + "";
      for (let i = moneda.length - 1; i >= 0; i--) {
        if (cont === 3) {
          aux = "." + aux;
          cont = 0;
        }
        aux = moneda.charAt(i) + aux;
        cont++;
      }
      moneda = aux;
      if (decimales.length > 0) {
        moneda = moneda + "," + decimales;
      }
      return "$ " + moneda;
    },
  };
}
