import React from "react";
import { Button, IconButton } from "react-native-paper";
import CustomListSelect from "../../components/CustomListSelect";
import CustomTextInput from "../../components/CustomTextInput";
import styles from "../../styles/style";

export default function UnidadesCargo({
  gerarquias,
  unidades,
  item,
  removeItem,
}) {
  const [nuevaUnidad, setNuevaUnidad] = React.useState({});
  const [actualizar, setActualizar] = React.useState(false);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <CustomListSelect
        top={0}
        label="Nombre Jerarquía"
        nombreLista="Seleccione Jerarquía"
        nombreValue="value"
        nombreLabel="label"
        showIcon={false}
        closeOnClick={true}
        value={{ value: item.gerarquia, label: item.nombreGerarquia }}
        lista={gerarquias}
        onChange={(items) => {
          item["nombreGerarquia"] = items[0]?.label;
          item["gerarquia"] = items[0] ? parseInt(items[0].value) : 0;
          setActualizar(!actualizar);
        }}
      />
      <CustomListSelect
        top={0}
        label="Nombre Unidad"
        nombreLista="Seleccione Unidad"
        nombreValue="value"
        nombreLabel="label"
        showIcon={false}
        closeOnClick={true}
        value={{ value: item.idUnidad + "", label: item.nombreUnidad }}
        lista={unidades}
        onChange={(items) => {
          item["nombreUnidad"] = items[0]?.label;
          item["idUnidad"] = items[0] ? parseInt(items[0].value) : 0;
          setActualizar(!actualizar);
        }}
        idElementoOtro={"1"}
        agregaElemento
        componenteOtro={(nombreValue, idElementoOtro) => {
          return (
            <div>
              <CustomTextInput
                style={styles.inputCargo}
                label="Nombre Unidad"
                value={
                  nuevaUnidad["nombreUnidad"] === undefined
                    ? ""
                    : nuevaUnidad["nombreUnidad"]
                }
                onChange={(text) => {
                  nuevaUnidad["nombreUnidad"] = text;
                  nuevaUnidad["label"] = text;
                }}
                requiered={true}
              />
              <Button
                onPress={() => {
                  let maxValue = 0;
                  unidades.forEach((element) => {
                    if (parseInt(element[nombreValue]) >= maxValue) {
                      maxValue = parseInt(element[nombreValue]) + 1;
                    }
                  });
                  nuevaUnidad[nombreValue] = maxValue * -1;
                  unidades.push(JSON.parse(JSON.stringify(nuevaUnidad)));
                  setActualizar(!actualizar);
                  setNuevaUnidad({});
                }}
                color="#0a5dc1"
                contentStyle={{
                  backgroundColor: "#f6a623",
                }}
                style={{ marginVertical: 20 }}
                mode="contained"
              >
                Agregar Unidad
              </Button>
            </div>
          );
        }}
      />
      <CustomTextInput
        style={styles.inputCargo}
        label="Nivel"
        valueType="numeros"
        value={item["orden"] === undefined ? "" : item["orden"]}
        onChange={(text) => (item["orden"] = parseInt(text))}
        requiered={true}
      />
      <IconButton
        style={{ alignSelf: "center" }}
        onPress={() => removeItem()}
        icon="close-circle-outline"
        color="rgb(246, 166, 35)"
        size={40}
      />
    </div>
  );
}
