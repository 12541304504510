import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { Dialog } from "react-native-paper";
import styles from "../styles/style";

export default function LoadingModal({ visible, mensaje }) {
  React.useEffect(() => {}, [visible]);
  return (
    <Dialog visible={visible} dismissable={false} style={styles.modal}>
      <Dialog.Title>Procesando</Dialog.Title>
      <Dialog.Content>
        <View style={{ justifyContent: "center" }}>
          <ActivityIndicator
            animating={true}
            color="#f6a623"
            size={80}
            style={{ marginTop: 40, marginBottom: 20 }}
          />
          <Text
            style={{
              color: "#6b6b6b",
              textAlign: "center",
              fontSize: 20,
              textTransform: "uppercase",
            }}
          >
            {mensaje}
          </Text>
        </View>
      </Dialog.Content>
    </Dialog>
  );
}
