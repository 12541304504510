import React from "react";
import { View } from "react-native";
import CustomButton from "../../components/CustomButton";
import CustomListSelect from "../../components/CustomListSelect";
import CustomTextInput from "../../components/CustomTextInput";
import EmpresaService from "../../services/EmpresaService";
import UserService from "../../services/UserService";
import styles from "../../styles/style";
import Alerta from "../modal/Alerta";
import Roles from "../../utils/Roles";

export default function Usuario({ user, route, navigation, setHeaderText }) {
  const { usuario, roles } = route.params;
  const [empresas, setEmpresas] = React.useState([]);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);

  const accionModal = () => {
    if (usuario.id === -1) {
      delete usuario.id;
      UserService.creaUsuario(user.token, usuario).then((respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(
            `Usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM} creado exitosamente.`
          );
          usuario["agregar"] = true;
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al crear el usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM}`
          );
        }
        setAlertaVisible(true);
      });
    } else {
      UserService.actualizaUsuario(user.token, usuario).then((resp) => {
        if (resp.codigoRespuesta === 200) {
          setTipoAlerta("info");
          setMensaje(
            `Usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM} actualizado exitosamente.`
          );
        } else {
          setTipoAlerta("error");
          setMensaje(
            `Se ha producido un error al actualizar el usuario ${usuario.firstName} ${usuario.lastName} ${usuario.lastNameM}`
          );
        }
        setAlertaVisible(true);
      });
    }
  };

  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresasTodas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setEmpresas(respuesta.entidad);
        }
      }
    });
  };

  const tieneTodosLosDatos = () => {
    if (
      usuario["firstName"] === undefined ||
      usuario["firstName"] === null ||
      usuario["firstName"].trim().length === 0
    ) {
      return false;
    }
    if (
      usuario["lastName"] === undefined ||
      usuario["lastName"] === null ||
      usuario["lastName"].trim().length === 0
    ) {
      return false;
    }
    if (
      usuario["lastNameM"] === undefined ||
      usuario["lastNameM"] === null ||
      usuario["lastNameM"].trim().length === 0
    ) {
      return false;
    }
    if (
      usuario["email"] === undefined ||
      usuario["email"] === null ||
      usuario["email"].trim().length === 0
    ) {
      return false;
    }
    if (
      usuario.id === -1 &&
      (usuario["passwordSinHash"] === undefined ||
        usuario["passwordSinHash"] === null ||
        usuario["passwordSinHash"].trim().length === 0)
    ) {
      return false;
    }
    if (
      usuario["userRole"] === undefined ||
      usuario["userRole"] === null ||
      usuario["userRole"].length === 0
    ) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    setHeaderText(usuario.id === -1 ? "Nuevo Usuario" : "Editar Usuario");
    if (empresas.length === 0) {
      obtieneEmpresas();
    }
    console.log(JSON.parse(JSON.stringify(usuario)));
  }, [usuario.id, actualizar]);

  return (
    <View style={{ padding: 20 }}>
      <CustomTextInput
        style={styles.inputUsuario}
        label="Nombre"
        value={usuario["firstName"] === undefined ? "" : usuario["firstName"]}
        onChange={(text) => (usuario["firstName"] = text)}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputUsuario}
        label="Apellido Paterno"
        value={usuario["lastName"] === undefined ? "" : usuario["lastName"]}
        onChange={(text) => (usuario["lastName"] = text)}
        requiered={true}
      />
      <CustomTextInput
        style={styles.inputUsuario}
        label="Apellido Materno"
        value={usuario["lastNameM"] === undefined ? "" : usuario["lastNameM"]}
        onChange={(text) => (usuario["lastNameM"] = text)}
        requiered={true}
      />
      {usuario["id"] === -1 && (
        <CustomTextInput
          style={styles.inputUsuario}
          label="Email"
          valueType="email"
          value={usuario["email"] === undefined ? "" : usuario["email"]}
          onChange={(text) => (usuario["email"] = text)}
          requiered={true}
        />
      )}
      <CustomTextInput
        style={styles.inputUsuario}
        label="Contraseña"
        valueType="password"
        value={
          usuario["passwordSinHash"] === undefined
            ? ""
            : usuario["passwordSinHash"]
        }
        onChange={(text) => (usuario["passwordSinHash"] = text)}
        requiered={false}
      />
      <CustomListSelect
        label="Rol"
        lista={roles}
        nombreLabel="description"
        nombreValue="id"
        value={usuario["userRole"] === undefined ? [] : usuario["userRole"]}
        onChange={(items) => {
          usuario["userRole"] = items[0];
          usuario["empresas"] = [];
          setActualizar(!actualizar);
        }}
        nombreLista="Seleccione rol"
        showIcon={false}
        closeOnClick={true}
        top={0}
      />
      {usuario["userRole"] !== undefined &&
        usuario["userRole"].id == Roles.VISUALIZADOR && (
          <CustomListSelect
            label="Empresa"
            nombreLista="Seleccione Empresa"
            nombreValue="id"
            nombreLabel="nombre"
            value={
              usuario["empresas"] === undefined ? [] : usuario["empresas"][0]
            }
            lista={empresas}
            onChange={(items) => {
              usuario["empresas"] = [items[0]];
              setActualizar(!actualizar);
            }}
            showIcon={false}
            closeOnClick={true}
            top={0}
          />
        )}
      {usuario["userRole"] !== undefined &&
        (usuario["userRole"].id == Roles.ADMINISTRADOR ||
          usuario["userRole"].id == Roles.ADMIN_EMPRESA) && (
          <CustomListSelect
            label="Empresa"
            multiselect
            nombreLista="Seleccione Empresa"
            nombreValue="id"
            nombreLabel="nombre"
            value={usuario["empresas"] === undefined ? [] : usuario["empresas"]}
            lista={empresas}
            onChange={(items) => {
              usuario["empresas"] = items;
              setActualizar(!actualizar);
            }}
            top={0}
          />
        )}
      <CustomButton
        style={{ marginTop: 30 }}
        textoButton="Aceptar"
        disabled={!tieneTodosLosDatos()}
        onPress={() => accionModal()}
      />
      <Alerta
        confirmacionModal={false}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() =>
          navigation.navigate({
            name: "Mantenedor Usuario",
            params: { usuario: usuario },
            merge: true,
          })
        }
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
