import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from "date-fns/locale";
import MUIDataTable from "mui-datatables";
import React from "react";
import { Text, View } from "react-native";
import ColoresPineal from "../styles/ColoresPineal";
import CustomListSelect from "./CustomListSelect";

export default function CustomMuiDataTable({
  data,
  columnas,
  options = {},
  height,
  padding,
  onDataFilterChange = () => {},
}) {
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiToolbar: {
          regular: {
            backgroundColor: ColoresPineal.grisAzulado,
          },
        },
        MUIDataTable: {
          paper: {
            backgroundColor: ColoresPineal.grisAzulado,
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: ColoresPineal.grisAzulado,
            color: ColoresPineal.blanco,
          },
          data: {
            color: ColoresPineal.blanco,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: 120,
            fontWeight: "bold",
          },
          sortActive: {
            color: ColoresPineal.anaranjado,
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: `${ColoresPineal.anaranjado} !important`,
          },
        },
        MuiTableBody: {
          root: {
            backgroundColor: ColoresPineal.blanco,
          },
        },
        MUIDataTableSearch: {
          searchIcon: {
            color: ColoresPineal.blanco,
          },
          searchText: {
            "& .MuiInput-root": {
              color: ColoresPineal.blanco,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: ColoresPineal.anaranjado,
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: ColoresPineal.blanco,
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: ColoresPineal.blanco,
              borderBottomWidth: 2,
            },
          },
          clearIcon: {
            color: ColoresPineal.blanco,
          },
        },
        MUIDataTableToolbar: {
          icon: {
            color: ColoresPineal.blanco,
            "&:hover": {
              color: ColoresPineal.anaranjado,
            },
          },
          iconActive: {
            color: ColoresPineal.anaranjado,
          },
        },
        MUIDataTableFilterList: {
          chip: {
            backgroundColor: ColoresPineal.anaranjado,
          },
          root: {
            margin: 0,
            backgroundColor: ColoresPineal.grisAzulado,
            paddingInline: 16,
            paddingBottom: 5,
          },
        },
        MUIDataTableFooter: {
          root: {
            position: "sticky",
            bottom: 0,
            backgroundColor: ColoresPineal.grisAzulado,
          },
        },
        MuiTableCell: {
          root: {
            padding: 0,
            paddingInline: 4,
          },
        },
        MUIDataTable: {
          responsiveBase: {
            //height: "calc(100% - 34px) !important",
            backgroundColor: ColoresPineal.blanco,
          },
        },
        MuiTablePagination: {
          root: {
            color: ColoresPineal.blanco,
          },
          actions: {
            "& .MuiIconButton-root.Mui-disabled": {
              color: "#ffffff57",
            },
          },
          selectIcon: {
            color: "#ffffff8a",
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            maxHeight: "calc(100vh - 210px)",
            top: "100px !important",
          },
        },
      },
    });

  const fechaTexto = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fecha = (day < 10 ? "0" : "") + day + "/";
    fecha = fecha + (month < 10 ? "0" : "") + month + "/";
    fecha = fecha + year;
    return fecha;
  };
  const obtieneListaFiltro = (nombreCampo) => {
    let lista = [];
    data.forEach((element) => {
      let valor = element[nombreCampo];
      if (valor === null || valor === undefined) {
        valor = "Sin Dato";
      }
      if (typeof valor === "string" && valor.trim().length === 0) {
        valor = "Sin Dato";
      }
      lista.push(valor);
    });
    let valoresUnicos = lista.filter((c, index) => {
      return lista.indexOf(c) === index;
    });
    return valoresUnicos;
  };

  const customFilros = (columnas) => {
    columnas.forEach((element) => {
      if (element.type !== undefined && element.type === "fecha") {
        element.options["filterType"] = "custom";
        element.options["customFilterListOptions"] = {
          render: (v) => {
            if (v[0] && v[1]) {
              return `${element.label} entre: ${v[0].texto} - ${v[1].texto}`;
            }
            if (v[0]) {
              return `${element.label} mayor o igual a: ${v[0].texto}`;
            }
            if (v[1]) {
              return `${element.label} menor o igual  a: ${v[1].texto}`;
            }
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        };
        element.options["filterOptions"] = {
          logic: (location, filters, row) => {
            if (filters[0] || filters[1]) {
              try {
                let fecha = null;
                if (location.indexOf("/") !== -1) {
                  var date = location.split("/");
                  fecha = new Date(
                    Date.parse(date[1] + "/" + date[0] + "/" + date[2])
                  );
                } else {
                  var date2 = location.split(" ");
                  date2 = date2[0].split("-");
                  fecha = new Date(
                    Date.parse(date2[1] + "/" + date2[2] + "/" + date2[0])
                  );
                }
                if (
                  location === null ||
                  location === undefined ||
                  location === "Sin Dato" ||
                  location.trim().length === 0
                ) {
                  return true;
                }
                if (filters[0] && filters[1]) {
                  return (
                    fecha.getTime() < filters[0].fecha.getTime() ||
                    fecha.getTime() > filters[1].fecha.getTime()
                  );
                }
                if (filters[0]) {
                  return fecha.getTime() < filters[0].fecha.getTime();
                }
                if (filters[1]) {
                  return fecha.getTime() > filters[1].fecha.getTime();
                }
              } catch (_error) {
                return true;
              }
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <Text style={{ color: "#0000008a", fontSize: 12 }}>
                {element.label}
              </Text>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <DatePicker
                  style={{ marginBlock: 10, width: 110 }}
                  inputVariant="outlined"
                  label="Inicio"
                  value={
                    filterList[index][0] !== undefined
                      ? filterList[index][0].fecha
                      : new Date()
                  }
                  format="dd/MM/yyyy"
                  minDate={new Date(Date.parse("01/01/1800"))}
                  maxDate={
                    filterList[index][1] !== undefined
                      ? filterList[index][1].fecha
                      : new Date()
                  }
                  minDateMessage={`La fecha ingresada debe ser mayor o igual a ${fechaTexto(
                    new Date(Date.parse("01/01/1800"))
                  )}`}
                  maxDateMessage={`La fecha ingresada debe ser menor o igual a ${fechaTexto(
                    filterList[index][1] !== undefined
                      ? filterList[index][1].fecha
                      : new Date()
                  )}`}
                  onChange={(date) => {
                    //setFechaInicio(date);
                    date.setHours(0, 0, 0, 0);
                    filterList[index][0] = {
                      fecha: date,
                      texto: fechaTexto(date),
                    };
                    onChange(filterList[index], index, column);
                  }}
                />
                <DatePicker
                  style={{ marginBlock: 10, width: 110 }}
                  inputVariant="outlined"
                  label="Fin"
                  value={
                    filterList[index][1] !== undefined
                      ? filterList[index][1].fecha
                      : new Date()
                  }
                  format="dd/MM/yyyy"
                  minDate={
                    filterList[index][0] !== undefined
                      ? filterList[index][0].fecha
                      : new Date(Date.parse("01/01/1800"))
                  }
                  maxDate={new Date()}
                  minDateMessage={`La fecha ingresada debe ser mayor o igual a ${fechaTexto(
                    filterList[index][0] !== undefined
                      ? filterList[index][0].fecha
                      : new Date(Date.parse("01/01/1800"))
                  )}`}
                  maxDateMessage={`La fecha ingresada debe ser menor o igual a ${fechaTexto(
                    filterList[index][1] !== undefined
                      ? filterList[index][1].fecha
                      : new Date()
                  )}`}
                  onChange={(date) => {
                    //setFechaFin(date);
                    date.setHours(0, 0, 0, 0);
                    filterList[index][1] = {
                      fecha: date,
                      texto: fechaTexto(date),
                    };
                    onChange(filterList[index], index, column);
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
          ),
        };
      } else {
        if (
          element.options !== undefined &&
          element.options.filter &&
          element.options.filterType === undefined
        ) {
          if (element.options.customFilterListOptions !== undefined) {
            element.options["filterType"] = "custom";
            element.options.customFilterListOptions["update"] = (
              filterList,
              filterPos,
              index
            ) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            };
            if (element.options["filterOptions"] === undefined) {
              element.options["filterOptions"] = {
                logic: (location, filters, row) => {
                  if (filters[0] && filters[0] === "Sin Dato") {
                    if (typeof location === "string") {
                      return (
                        location !== null &&
                        location !== undefined &&
                        location.trim().length > 0
                      );
                    } else {
                      return location !== null && location !== undefined;
                    }
                  }
                  if (
                    typeof filters[0] === "number" &&
                    element.options["mayorA"] !== undefined &&
                    element.options["mayorA"]
                  ) {
                    return parseInt(location) < parseInt(filters[0]);
                  }
                  if (filters[0] || typeof filters[0] === "number") {
                    return location !== filters[0];
                  }
                  return false;
                },
              };
            }
            element.options.filterOptions["display"] = (
              filterList,
              onChange,
              index,
              column
            ) => {
              return (
                <CustomListSelect
                  oldForm={true}
                  label={column.label}
                  nombreLista={`Seleccione ${column.label}`}
                  value={
                    filterList[index][0] === undefined
                      ? ""
                      : filterList[index][0]
                  }
                  showIcon={false}
                  olcutarAcciones={true}
                  lista={
                    element.options.filterOptions["names"] !== undefined
                      ? element.options.filterOptions["names"]
                      : obtieneListaFiltro(column.name)
                  }
                  closeOnClick={true}
                  onChange={(text) => {
                    filterList[index][0] = text;
                    onChange(filterList[index][0], index, column);
                  }}
                />
              );
            };
          }
        }
      }
    });
    return columnas;
  };

  return (
    <View
      style={padding !== undefined ? { padding: padding } : { padding: 20 }}
    >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={data}
          columns={customFilros(columnas)}
          options={{
            filter: true,
            filterType: "dropdown",
            responsive: "vertical",
            tableBodyHeight:
              height !== undefined ? height : "calc(100vh - 267px)",
            print: false,
            download: false,
            search: true,
            onFilterChange: (
              column,
              filterList,
              type,
              changedColumnIndex,
              displayData
            ) => {
              onDataFilterChange(displayData);
            },
            onTableChange: (action, tableState) => {
              if (action === "search") {
                onDataFilterChange(tableState.displayData);
              }
            },
            selectableRows: "none",
            textLabels: {
              body: {
                noMatch: "No existen datos",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenado por ${column.label}`,
              },
              pagination: {
                next: "Página Siguiente",
                previous: "Página Previa",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
              },
              toolbar: {
                search: "Buscar",
                viewColumns: "Ocultar/Mostrar Columnas",
                filterTable: "Filtrar Datos",
              },
              filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpiar",
              },
              viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Ocultar/Mostrar Columnas",
              },
            },
            ...options,
          }}
        />
      </MuiThemeProvider>
    </View>
  );
}
