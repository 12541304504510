import React, { useEffect } from "react";
import { Dimensions, ScrollView, Text } from "react-native";
import {
  DataTable,
  Dialog,
  HelperText,
  IconButton,
  Portal,
  Switch,
  TextInput,
} from "react-native-paper";
import EmpresaService from "../services/EmpresaService";
import CustomConfirmacion from "./CustomConfirmacion";
import CustomRadioButton from "./CustomRadioButton";
import InputLabelValue from "./InputLabelValue";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

export default function DialogoNuevoCampo({
  openModal,
  componenteId,
  glosaId,
  closeModal,
  alertaModal,
  user,
  preguntas,
  pregunta,
  url,
}) {
  const [formularioErrores, setFormularioErrores] = React.useState({
    name: "",
    label: "",
    type: "",
    mask: "",
    list: "",
    maxLength: "",
    required: "",
  });
  const [valido, setValido] = React.useState(true);
  const [actualizar, setActualizar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onToggleSwitch = () => {
    if (pregunta.required === undefined) {
      pregunta["required"] = false;
    }
    pregunta["required"] = !pregunta["required"];
    setActualizar(!actualizar);
  };

  const addElementoLista = () => {
    if (pregunta.list === undefined) {
      pregunta["list"] = [];
    }
    pregunta["list"].push({ label: null, value: null });
    setActualizar(!actualizar);
  };

  const removeElementLista = (index) => {
    if (pregunta["list"].length > 1) {
      pregunta["list"] = pregunta["list"].splice(index, 1);
    } else {
      pregunta["list"] = [];
    }
    setActualizar(!actualizar);
  };

  const handlerDropDown = (selected) => {
    pregunta["type"] = selected;

    formularioErrores.mask = "";
    formularioErrores.maxLength = "";
    formularioErrores.list = "";

    //pregunta["list"] = [];
    setActualizar(!actualizar);
  };

  const hideModal = () => {
    setValido(true);
    closeModal();

    setFormularioErrores({
      name: "",
      label: "",
      type: "",
      mask: "",
      list: "",
      maxLength: "",
      required: "",
    });
  };

  const validationForm = () => {
    let valid = true;
    for (const property in pregunta) {
      formularioErrores[property] = "";
    }

    if (pregunta.name == null) {
      valid = false;
      formularioErrores.name = "Campo requerido";
    }

    if (pregunta.label == null) {
      valid = false;
      formularioErrores.label = "Campo requerido";
    }

    if (pregunta.type == null) {
      valid = false;
      formularioErrores.type = "Campo requerido";
    }

    if (pregunta.type !== null && pregunta.type !== "TextInput") {
      if (pregunta.list.length === 0) {
        valid = false;
        formularioErrores.list = "La lista de campos no puede estar vacia";
      } else {
        pregunta.list.forEach((value) => {
          if (objectIsEmpty(value)) {
            valid = false;
            formularioErrores.list = "La lista posee campos sin completar";
          }
        });
      }
    }
    if (valid) {
      pregunta[glosaId] = componenteId;
      setLoading(true);
      if (pregunta.idFormulario !== -1) {
        sendPut();
      } else {
        sendPost();
      }
    }
    setValido(!valido);
  };

  const sendPost = () => {
    EmpresaService.nuevaPreguntaFormulario(user.token, pregunta, url).then(
      (resp) => {
        console.log(resp);
        hideModal();
        setLoading(false);
        if (resp.entidad !== null && resp.entidad !== undefined) {
          preguntas.push(resp.entidad);
          alertaModal(
            "info",
            "El campo '" + pregunta.label + "' fue creado correctamente."
          );
        } else {
          alertaModal(
            "error",
            "El campo '" + pregunta.label + "' no fue creado."
          );
        }
      }
    );
  };

  const sendPut = () => {
    EmpresaService.actualizaPreguntaFormulario(user.token, pregunta, url).then(
      (resp) => {
        console.log(resp);
        hideModal();
        setLoading(false);
        if (resp.entidad !== null && resp.entidad !== undefined) {
          alertaModal(
            "info",
            "El campo '" + pregunta.label + "' fue actualizado correctamente."
          );
        } else {
          alertaModal(
            "error",
            "El campo '" + pregunta.label + "' no fue actualizado."
          );
        }
      }
    );
  };

  const objectIsEmpty = (object) => {
    for (const property in object) {
      if (object[property] == null) {
        return true;
      }
    }
    return false;
  };

  const setDataList = (index, campo, valor) => {
    pregunta.list[index][campo] = valor;
  };

  useEffect(() => {}, [openModal, valido, actualizar, loading]);

  const obtieneCampos = () => {
    console.log("obtieneCampos", pregunta);
    let campos;
    switch (pregunta?.type) {
      case null:
        campos = null;
        break;
      case "TextInput":
        campos = (
          <div>
            <TextInput
              mode="outlined"
              label="Largo Maximo"
              value={pregunta?.maxLength === null ? "" : pregunta?.maxLength}
              onChangeText={(text) => {
                pregunta.maxLength = text;
                setValido(!valido);
              }}
            />
            <HelperText type="error" visible style={{ marginBottom: 10 }}>
              {formularioErrores.maxLength}
            </HelperText>
            <TextInput
              mode="outlined"
              label="Mascara"
              value={pregunta?.mask === null ? "" : pregunta?.mask}
              onChangeText={(text) => {
                pregunta.mask = text;
                setValido(!valido);
              }}
            />
            <HelperText type="error" visible style={{ marginBottom: 10 }}>
              {formularioErrores.maxLength}
            </HelperText>
          </div>
        );
        break;
      case "Radio":
      case "DropDown":
        campos = (
          <div>
            <Text>Lista</Text>
            <IconButton
              icon="plus-circle-outline"
              color="rgb(246, 166, 35)"
              size={20}
              onPress={addElementoLista}
            />
            <HelperText type="error" visible style={{ marginBottom: 10 }}>
              {formularioErrores.list}
            </HelperText>
            <DataTable>
              <DataTable.Header>
                <DataTable.Title>label</DataTable.Title>
                <DataTable.Title>value</DataTable.Title>
                <DataTable.Title>eliminar</DataTable.Title>
              </DataTable.Header>
              {pregunta?.list?.length === 0 && (
                <DataTable.Row>
                  <DataTable.Cell>
                    <Text>Sin registros</Text>
                  </DataTable.Cell>
                </DataTable.Row>
              )}
              {pregunta?.list?.length > 0 &&
                pregunta.list.map((item, index) => {
                  return (
                    <InputLabelValue
                      key={index}
                      onChangeValue={setDataList}
                      index={index}
                      item={item}
                      removeElementLista={removeElementLista}
                    />
                  );
                })}
            </DataTable>
          </div>
        );
        break;
      default:
        campos = undefined;
        break;
    }
    return campos;
  };

  return (
    <Portal>
      <Dialog
        visible={openModal}
        onDismiss={hideModal}
        style={{ width: (windowWidth * 3) / 4, alignSelf: "center" }}
      >
        <Dialog.Title>{`${
          pregunta?.idFormulario !== -1 ? "Editar" : "Nuevo"
        } Campo`}</Dialog.Title>
        <Dialog.Content>
          <ScrollView style={{ height: (windowHeight * 3) / 4 - 200 }}>
            <TextInput
              mode="outlined"
              label="Label"
              value={pregunta?.label}
              onChangeText={(text) => {
                pregunta.label = text;
                pregunta.name = text;
                setValido(!valido);
              }}
            />
            <HelperText type="error" visible style={{ marginBottom: 10 }}>
              {formularioErrores.label}
            </HelperText>
            <CustomRadioButton
              label="Tipo Campo"
              list={[
                { label: "TextInput", value: "TextInput" },
                { label: "DropDown", value: "DropDown" },
                { label: "Radio", value: "Radio" },
              ]}
              onChangeValue={handlerDropDown}
              valueIni={pregunta?.type}
            />
            <HelperText type="error" visible style={{ marginBottom: 10 }}>
              {formularioErrores.type}
            </HelperText>
            {obtieneCampos()}
            <div style={{ marginBottom: 20 }}>
              <Text>Requerido</Text>
              <Switch
                trackColor={{
                  true: "#f5c16d",
                  false: "#CACACA",
                }}
                thumbColor="#888888"
                color="#f6a623"
                value={pregunta?.required}
                onValueChange={onToggleSwitch}
              />
            </div>
          </ScrollView>
        </Dialog.Content>
        <Dialog.Actions>
          <CustomConfirmacion
            onPressAceptar={validationForm}
            onPressCancelar={hideModal}
            loading={loading}
            disabled={loading}
          />
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
