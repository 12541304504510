import React, { useState } from "react";
import { View } from "react-native";
import Header from "./Header";

import ZoomService from "../services/ZoomService";

import ModalZoom from "../screens/modal/zoom/Zoom";
import ModalDetalleReunion from "../screens/modal/zoom/DetalleReunion";
import ModalDetallePostulanteIdioma from "../screens/modal/zoom/DetallePostulanteidioma";
import ModalDetallePostulanteCertificaciones from "../screens/modal/zoom/DetallePostulanteCertificaciones";
import ModalDetallePostulanteCursos from "../screens/modal/zoom/DetallePostulanteCursos";
import ModalDetallePostulanteEducacion from "../screens/modal/zoom/DetallePostulanteEducacion";
import ModalDetallePostulanteLicencia from "../screens/modal/zoom/DetallePostulantelicencias";
import ModalDetallePostulanteReferencia from "../screens/modal/zoom/DetallePostulanteReferencia";
import ModalDetallePostulantePExperiencia from "../screens/modal/zoom/DetallePostulanteExperiencia";

import ModalDetallePostulantePEmpresa from "../screens/modal/zoom/DetallePostulantePEmpresa";
import ModalDetallePostulanteCargo from "../screens/modal/zoom/DetallePostulantePCargo";

import ModalDetallePostulante from "../screens/modal/zoom/DetPos";
import ModalComentarios from "../screens/modal/zoom/Comentarios";
import ModalAdjuntos from "../screens/modal/zoom/Adjunto";

import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {
  Box,
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Typography,
  Tooltip,
} from "@material-ui/core";
//
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
const useStyle = makeStyles({
  btnActivo: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#f47C06",
    variant: "outlined",
    fontSize: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover, &:focus": {
      color: "#20314A",
    },
  },
  btnActivo2: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#20314A",
    variant: "outlined",
    fontSize: 10,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover, &:focus": {
      color: "#f47C06",
    },
  },
  btnGrupo: {
    width: "100%",
  },
  centrarMenu: {
    textAlign: "center",
  },
});

export default function VistaPanel({ navigation, user, route }) {
  const [postulantes, setPostulantes] = React.useState();
  const { reunion } = route.params;

  const obtienePostulantesR = () => {
    ZoomService.obtienePostulantesReunion(user.token, reunion.idReunion).then(
      (resp) => {
        setPostulantes(resp.entidad);
      }
    );
  };
  //
  const classes = useStyle();
  //
  React.useEffect(() => {
    if (reunion.recargar) {
      obtienePostulantesR();
      reunion.recargar = false;
    }
  }, [reunion.recargar]);

  return (
    <View>
      <View>
        <div background-color="#f47C06">
          <ModalDetalleReunion
            DatEmpresa={reunion.empresa}
            DatCargo={reunion.cargo}
            DatFCreacion={reunion.fechaCreacion}
            DatFReunion={reunion.fechaReunion}
            DatProceso={reunion.proceso}
            DatLinkZoom={reunion.linkZoom}
            DatIdZoom={reunion.idZoom}
            DatPassZoom={reunion.passZoom}
          ></ModalDetalleReunion>
          <div>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={10}
                rowSpacing={1000}
                columnSpacing={3000}
              >
                <ModalZoom
                  IdZoom={reunion.idZoom}
                  ClaveZoom={reunion.passZoom}
                  UsuarioZoom={user.user.toString("base64")}
                />
                <Box border={0}></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                {postulantes !== undefined &&
                  postulantes.map((nombre) => {
                    if (nombre.idReunion === reunion.idReunion) {
                      return (
                        <Box border={0}>
                          <Accordion alignContent="center">
                            <Tooltip title="Haga clic en el nombre para desplegar y ver detalles del postulante.">
                              <AccordionSummary
                                expandIcon={<ArrowDropUp />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.txtPostulantes}>
                                  {nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno}
                                </Typography>
                              </AccordionSummary>
                            </Tooltip>
                            <AccordionDetails>
                              <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                                textAlign="center"
                                className={classes.btnGrupo}
                              >
                                <ModalDetallePostulante
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Detalles
                                </ModalDetallePostulante>
                                <ModalDetallePostulanteIdioma
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Idiomas
                                </ModalDetallePostulanteIdioma>
                                <ModalDetallePostulanteCertificaciones
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Certificaciones
                                </ModalDetallePostulanteCertificaciones>
                                <ModalDetallePostulanteCursos
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Cursos
                                </ModalDetallePostulanteCursos>
                                <ModalDetallePostulanteEducacion
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Educación
                                </ModalDetallePostulanteEducacion>
                                <ModalDetallePostulanteLicencia
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Licencia
                                </ModalDetallePostulanteLicencia>
                                <ModalDetallePostulanteReferencia
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Referencias
                                </ModalDetallePostulanteReferencia>
                                <ModalDetallePostulantePEmpresa
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Preguntas empresa
                                </ModalDetallePostulantePEmpresa>
                                <ModalDetallePostulanteCargo
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Preguntas cargo
                                </ModalDetallePostulanteCargo>
                                <ModalDetallePostulantePExperiencia
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Experiencia laboral
                                </ModalDetallePostulantePExperiencia>
                                <ModalAdjuntos
                                  className={classes.btnActivo}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatIdProceso={reunion.idProceso}
                                  DatIdPostulante={nombre.idPostulante}
                                  token={user.token}
                                >
                                  Adjuntos
                                </ModalAdjuntos>
                                <ModalComentarios
                                  className={classes.btnActivo}
                                  token={user.token}
                                  NombreCompleto={
                                    nombre.nombre +
                                    " " +
                                    nombre.apPaterno +
                                    " " +
                                    nombre.apMaterno
                                  }
                                  DatIdPostulante={nombre.idPostulante}
                                  DatIdProceso={reunion.idProceso}
                                  DatIdReunion={nombre.idReunion}
                                  usuario={user.user}
                                />
                              </ButtonGroup>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      );
                    }
                  })}
              </Grid>
            </Grid>
          </div>
        </div>
      </View>
    </View>
  );
}
