const theme = {
  colors: {
    primary: "#f47c06",
    //secondary: "",
    //background: "",
    //card: "",
    text: "#000",
    //border: "",
    //notification: "",*/
  },
};

export default theme;
