import { createDrawerNavigator } from "@react-navigation/drawer";
import React from "react";
import "react-native-gesture-handler";
import EmpresaService from "../services/EmpresaService";
import PostulanteService from "../services/PostulanteService";
import ReportesService from "../services/ReportesService";
import UserService from "../services/UserService";
import styles from "../styles/style";
import Roles from "../utils/Roles";
import Calendario from "./Calendario";
import Candidatos from "./Candidatos";
import CandidatosReportes from "./CandidatosReportes";
import DetallePostulante from "./DetallePostulante";
import DetalleProceso from "./DetalleProceso";
import DocumentViewer from "./DocumentViewer";
import EnvioAlertas from "./EnvioAlertas";
import Cargo from "./mantenedor/Cargo";
import Empresa from "./mantenedor/Empresa";
import MantenedorCargo from "./mantenedor/MantenedorCargo";
import MantenedorEmpresa from "./mantenedor/MantenedorEmpresa";
import MantenedorHistorial from "./mantenedor/MantenedorHistorial";
import MantenedorProceso from "./mantenedor/MantenedorProceso";
import MantenedorUsuario from "./mantenedor/MantenedorUsuario";
import Proceso from "./mantenedor/Proceso";
import Usuario from "./mantenedor/Usuario";
import Menu from "./Menu";
import Postulantes from "./Postulantes";
import PostulantesHistorico from "./PostulantesHistorico";
import PreguntasEmpresaCargo from "./PreguntasEmpresaCargo";
import Procesos from "./Procesos";
import RepGra from "./RepGra";
import ReportesProcesos from "./ReportesProcesos";
import Reunion from "./Reunion";
import ScreenTemplate from "./ScreenTemplate";
import VistaPanel from "./VistaPanel";

const Drawer = createDrawerNavigator();

export default function Home({ route, navigation }) {
  const { user } = route.params;
  const [postulantes, setPostulantes] = React.useState([]);
  const [postulantesHistorico, setPostulantesHistorico] = React.useState([]);
  const [empresas, setEmpresas] = React.useState([]);
  const [empresasHistorico, setEmpresasHistorico] = React.useState([]);
  const [cargos, setCargos] = React.useState([]);
  const [cargosHistorico, setCargosHistorico] = React.useState([]);
  const [empresa, setEmpresa] = React.useState({
    nombre: "Seleccione Empresa",
    id: -1,
  });
  const [empresaHistorico, setEmpresaHistorico] = React.useState({
    nombre: "Seleccione Empresa",
    id: -1,
  });
  const [cargo, setCargo] = React.useState({
    cargoProceso: "Seleccione Cargo / Proceso",
    idCargo: -1,
  });
  const [cargoHistorico, setCargoHistorico] = React.useState({
    cargoProceso: "Seleccione Cargo / Proceso",
    idCargo: -1,
  });
  const [psicologos, setPsicologos] = React.useState([]);
  const [psicologosFiltrados, setPsicologosFiltrados] = React.useState([]);
  const [actualizar, setActualizar] = React.useState(false);
  const [reportes, setReportes] = React.useState([]);
  const [test, setTest] = React.useState([]);
  const [inicio, setInicio] = React.useState(true);
  const [actualizarProceso, setActualizarProceso] = React.useState(false);

  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setEmpresas(respuesta.entidad);
          setEmpresasHistorico(respuesta.entidad);
        } else {
          setEmpresas([
            {
              id: -1,
              nombre: "Sin Empresas Asignadas",
            },
          ]);
          setEmpresasHistorico([
            {
              id: -1,
              nombre: "Sin Empresas Asignadas",
            },
          ]);
        }
        setActualizar(!actualizar);
      }
    });
  };

  const obtienePsicologos = () => {
    UserService.listaUsuariosRol(user.token, 1).then((resp) => {
      let psicologos = [];

      resp.forEach((element) => {
        psicologos.push({
          nombre: `${element.firstName} ${element.lastName} ${element.lastNameM}`,
          id: element.id,
        });
      });

      psicologos.sort((a, b) => {
        return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
      });

      setPsicologos(psicologos);
      setPsicologosFiltrados(psicologos);
    });
  };

  const obtieneReportes = () => {
    ReportesService.obtieneTiposReportes(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setReportes(respuesta.entidad);
        setActualizar(!actualizar);
      }
    });
  };

  const obtieneTest = () => {
    PostulanteService.obtieneListaTest(user.token).then((respuesta) => {
      if (respuesta.codeResponse === 200) {
        setTest(respuesta.listaTest);
        setActualizar(!actualizar);
      }
    });
  };

  const filtraPsicologos = (text) => {
    let psicologosAux = psicologos.filter((psicologo) => {
      return text === undefined || text.length === 0
        ? true
        : psicologo.nombre.toLowerCase().includes(text.toLowerCase());
    });
    setPsicologosFiltrados(psicologosAux);
    setActualizar(!actualizar);
  };

  const resetButton = () => {
    setEmpresa({
      nombre: "Seleccione Empresa",
      id: -1,
    });
    setCargo({
      cargoProceso: "Seleccione Cargo / Proceso",
      idCargo: -1,
    });
  };

  const getActualizaProceso = () => {
    return actualizarProceso;
  };

  React.useEffect(() => {
    if (inicio) {
      obtieneEmpresas();
      obtienePsicologos();
      obtieneReportes();
      obtieneTest();
      setInicio(false);
    }
  }, [actualizar]);

  const obtieneLabels = (historico = false) => {
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ])
    )
      return [
        {
          label: "Total Postulantes",
          value: `Procesos${historico ? " Históricos" : " en Curso"}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
        {
          label: "Postulación",
          value: `Postulación${historico ? " Históricos" : ""}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
        {
          label: "Test",
          value: `Test${historico ? " Históricos" : ""}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
        {
          label: "Evaluaciones",
          value: `Evaluaciones${historico ? " Históricos" : ""}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
        {
          label: "Contratación",
          value: `Contratación${historico ? " Históricos" : ""}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
      ];
    if (UserService.tieneRol(user, [Roles.PSICOLOGO]))
      return [
        {
          label: "Total Postulantes",
          value: `Procesos${historico ? " Históricos" : " en Curso"}`,
          header: `Procesos${historico ? " Históricos" : " en Curso"}`,
        },
        {
          label: "Test",
          value: `Test${historico ? " Históricos" : ""}`,
        },
        {
          label: "Evaluaciones",
          value: `Evaluaciones${historico ? " Históricos" : ""}`,
        },
      ];
  };

  const getDrawerScreenPostulante = (
    nombre,
    esEtapa = false,
    nombreSigEtapa = null,
    nombreAntEtapa = null,
    estado = 0
  ) => {
    if (esEtapa) {
      return (
        <Drawer.Screen name={nombre}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <Postulantes
                  {...props}
                  etapa
                  nombreSigEtapa={nombreSigEtapa}
                  nombreAntEtapa={nombreAntEtapa}
                  user={user}
                  estado={estado}
                  setPostulantes={setPostulantes}
                  postulantes={postulantes}
                  empresas={empresas}
                  cargos={cargos}
                  setCargos={setCargos}
                  empresa={empresa}
                  setEmpresa={setEmpresa}
                  cargo={cargo}
                  setCargo={setCargo}
                  filtraPsicologos={filtraPsicologos}
                  psicologosFiltrados={psicologosFiltrados}
                  psicologos={psicologos}
                  test={test}
                  obtieneLabels={obtieneLabels}
                  setPsicologosFiltrados={setPsicologosFiltrados}
                />
              )}
            />
          )}
        </Drawer.Screen>
      );
    } else {
      return (
        <Drawer.Screen name={nombre} style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <Postulantes
                  {...props}
                  user={user}
                  estado={estado}
                  setPostulantes={setPostulantes}
                  postulantes={postulantes}
                  empresas={empresas}
                  cargos={cargos}
                  setCargos={setCargos}
                  empresa={empresa}
                  setEmpresa={setEmpresa}
                  cargo={cargo}
                  setCargo={setCargo}
                  psicologosFiltrados={psicologosFiltrados}
                  obtieneLabels={obtieneLabels}
                  setPsicologosFiltrados={setPsicologosFiltrados}
                />
              )}
            />
          )}
        </Drawer.Screen>
      );
    }
  };
  const getDrawerScreenPostulante2 = (
    nombre,
    esEtapa = false,
    nombreSigEtapa = null,
    nombreAntEtapa = null,
    estado = 0
  ) => {
    if (esEtapa) {
      return (
        <Drawer.Screen name={nombre}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <PostulantesHistorico
                  {...props}
                  etapa
                  nombreSigEtapa={nombreSigEtapa}
                  nombreAntEtapa={nombreAntEtapa}
                  user={user}
                  estado={estado}
                  setPostulantes={setPostulantesHistorico}
                  postulantes={postulantesHistorico}
                  empresas={empresas}
                  cargos={cargos}
                  setCargos={setCargosHistorico}
                  empresa={empresaHistorico}
                  setEmpresa={setEmpresaHistorico}
                  cargo={cargoHistorico}
                  setCargo={setCargoHistorico}
                  filtraPsicologos={filtraPsicologos}
                  psicologosFiltrados={psicologosFiltrados}
                  psicologos={psicologos}
                  test={test}
                  obtieneLabels={() => obtieneLabels(true)}
                  setPsicologosFiltrados={setPsicologosFiltrados}
                />
              )}
            />
          )}
        </Drawer.Screen>
      );
    } else {
      return (
        <Drawer.Screen name={nombre} style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <PostulantesHistorico
                  {...props}
                  user={user}
                  estado={estado}
                  setPostulantes={setPostulantesHistorico}
                  postulantes={postulantesHistorico}
                  empresas={empresasHistorico}
                  cargos={cargosHistorico}
                  setCargos={setCargosHistorico}
                  empresa={empresaHistorico}
                  setEmpresa={setEmpresaHistorico}
                  cargo={cargoHistorico}
                  setCargo={setCargoHistorico}
                  psicologosFiltrados={psicologosFiltrados}
                  obtieneLabels={() => obtieneLabels(true)}
                  setPsicologosFiltrados={setPsicologosFiltrados}
                />
              )}
            />
          )}
        </Drawer.Screen>
      );
    }
  };

  return (
    <Drawer.Navigator
      initialRouteName="Lista de Procesos"
      drawerContent={(props) => (
        <Menu
          {...props}
          navigationStack={navigation}
          hide={[
            "Detalle Postulante",
            "Documento",
            "Detalle Proceso",
            "Empresa",
            "Cargo",
            "Usuario",
            "Proceso",
            "Test",
            "Postulación",
            "Evaluaciones",
            "Contratación",
            "Test Históricos",
            "Postulación Históricos",
            "Evaluaciones Históricos",
            "Contratación Históricos",
            "Test",
            "Postulación",
            "Evaluaciones",
            "Contratación",
            "Reunion",
            "Vista Panel",
          ]}
        />
      )}
    >
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
      ]) && (
        <Drawer.Screen name="Lista de Procesos" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <Procesos
                  {...props}
                  user={user}
                  actualizar={getActualizaProceso}
                  setActualizar={setActualizar}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
        Roles.PSICOLOGO,
      ]) && getDrawerScreenPostulante("Procesos en Curso", false, "", "", 0)}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ]) && getDrawerScreenPostulante2("Procesos Históricos")}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Envio de Alertas" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <EnvioAlertas
                  {...props}
                  user={user}
                  empresas={empresas}
                  cargos={cargos}
                  setCargos={setCargos}
                  empresa={empresa}
                  setEmpresa={setEmpresa}
                  cargo={cargo}
                  setCargo={setCargo}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Preguntas Empresa/Proceso" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <PreguntasEmpresaCargo
                  {...props}
                  user={user}
                  empresas={empresas}
                  cargos={cargos}
                  setCargos={setCargos}
                  empresa={empresa}
                  setEmpresa={setEmpresa}
                  cargo={cargo}
                  setCargo={setCargo}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Mantenedor Empresa" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <MantenedorEmpresa
                  {...props}
                  user={user}
                  recargaEmpresas={obtieneEmpresas}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Mantenedor Cargo" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <MantenedorCargo
                  {...props}
                  user={user}
                  onUpdate={resetButton}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Mantenedor Proceso" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <MantenedorProceso
                  {...props}
                  user={user}
                  onUpdate={() => {
                    resetButton();
                    setActualizarProceso(true);
                    setActualizar(!actualizar);
                  }}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Mantenedor Usuario" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => <MantenedorUsuario {...props} user={user} />}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Base de datos Pineal" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => <Candidatos {...props} user={user} />}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Reportes Postulantes" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => <CandidatosReportes {...props} user={user} />}
            />
          )}
        </Drawer.Screen>
      )}
      {/*UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Historial Usuarios" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <MantenedorHistorial {...props} user={user} />
              )}
            />
          )}
        </Drawer.Screen>
              )*/}
      {UserService.tieneRol(user, [Roles.TODOS]) && (
        <Drawer.Screen name="Calendario" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <Calendario {...props} user={user} empresas={empresas} />
              )}
            />
          )}
        </Drawer.Screen>
      )}

      {/* {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Reportes/Gráficos" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <RepGra
                  {...props}
                  user={user}
                  onUpdate={() => {
                    setActualizarProceso(true);
                    setActualizar(!actualizar);
                  }}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )} */}

      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Reportes Predeterminados" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => <ReportesProcesos user={user} />}
            />
          )}
        </Drawer.Screen>
      )}

      {/* Desde aqui van las paginas que no se muestran en el menu*/}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ]) && getDrawerScreenPostulante("Postulación", true, "Test", "", 1)}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
        Roles.PSICOLOGO,
      ]) &&
        getDrawerScreenPostulante(
          "Test",
          true,
          "Evaluaciones",
          "Postulación",
          2
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
        Roles.PSICOLOGO,
      ]) &&
        getDrawerScreenPostulante(
          "Evaluaciones",
          true,
          "Contratación",
          "Test",
          3
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ]) &&
        getDrawerScreenPostulante("Contratación", true, "", "Evaluaciones", 4)}
      {UserService.tieneRol(user, [Roles.TODOS]) && (
        <Drawer.Screen name="Vista Panel" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              user={user}
              content={(props) => (
                <VistaPanel {...props} user={user} empresas={empresas} />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ]) &&
        getDrawerScreenPostulante2(
          "Postulación Históricos",
          true,
          "Test Históricos",
          "",
          1
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
        Roles.PSICOLOGO,
      ]) &&
        getDrawerScreenPostulante2(
          "Test Históricos",
          true,
          "Evaluaciones Históricos",
          "Postulación Históricos",
          2
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
        Roles.PSICOLOGO,
      ]) &&
        getDrawerScreenPostulante2(
          "Evaluaciones Históricos",
          true,
          "Contratación Históricos",
          "Test",
          3
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.ADMIN_EMPRESA,
        Roles.VISUALIZADOR,
      ]) &&
        getDrawerScreenPostulante2(
          "Contratación Históricos",
          false,
          "",
          "Evaluaciones Históricos",
          4
        )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.VISUALIZADOR,
        Roles.ADMIN_EMPRESA,
        Roles.PSICOLOGO,
      ]) && (
        <Drawer.Screen name="Detalle Postulante" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <DetallePostulante {...props} user={user} cargo={cargo} />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.VISUALIZADOR,
        Roles.ADMIN_EMPRESA,
        Roles.PSICOLOGO,
      ]) && (
        <Drawer.Screen name="Detalle Proceso" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <DetalleProceso {...props} user={user} cargo={cargo} />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.VISUALIZADOR,
        Roles.ADMIN_EMPRESA,
        Roles.PSICOLOGO,
      ]) && (
        <Drawer.Screen name="Documento" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <DocumentViewer {...props} user={user} cargo={cargo} />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Empresa" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <Empresa
                  {...props}
                  user={user}
                  recargaEmpresas={obtieneEmpresas}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Cargo" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <Cargo {...props} user={user} onUpdate={resetButton} />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Proceso" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <Proceso
                  {...props}
                  user={user}
                  onUpdate={() => {
                    resetButton();
                    setActualizarProceso(true);
                    setActualizar(!actualizar);
                  }}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Usuario" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => <Usuario {...props} user={user} />}
            />
          )}
        </Drawer.Screen>
      )}
      {UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) && (
        <Drawer.Screen name="Reunion" style={styles.menuItem}>
          {(props) => (
            <ScreenTemplate
              {...props}
              back={true}
              user={user}
              content={(props) => (
                <Reunion
                  {...props}
                  user={user}
                  onUpdate={() => {
                    setActualizarProceso(true);
                    setActualizar(!actualizar);
                  }}
                />
              )}
            />
          )}
        </Drawer.Screen>
      )}
    </Drawer.Navigator>
  );
}
