import React from "react";
import { View } from "react-native";
import ColoresPineal from "../styles/ColoresPineal";
import CustomButton from "./CustomButton";

export default function CustomConfirmacion({
  onPressAceptar = () => {},
  textAceptar = "Aceptar",
  onPressCancelar = () => {},
  textCancelar = "Cancelar",
  loading = false,
  disabled = false,
}) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        //width: 250,
      }}
    >
      <CustomButton
        onPress={() => onPressCancelar()}
        textoButton={textCancelar}
        style={{ backgroundColor: ColoresPineal.grisAzulado }}
      />
      <CustomButton
        onPress={() => onPressAceptar()}
        textoButton={textAceptar}
        loading={loading}
        disabled={disabled}
        style={{marginLeft:20}}
      />
    </View>
  );
}
