import React from "react";
import { View } from "react-native";
import { Dialog, Portal } from "react-native-paper";
import styles from "../styles/style";
import CustomConfirmacion from "./CustomConfirmacion";

export default function CustomDialog({
  visible,
  titulo,
  contenido,
  accion = () => {},
  dismissable = false,
  onClose = () => {},
  customAceptar,
  olcutarAcciones = false,
  onDismiss = () => {},
  oldForm = false,
  top = 80,
  height = "calc(100vh - 110px)",
}) {
  React.useEffect(() => {}, [visible]);

  const handlerClose = () => {
    onClose();
  };
  const handlerDismiss = () => {
    onDismiss();
  };

  const DialogoComponente = () => {
    return (
      <Dialog
        visible={visible}
        dismissable={dismissable}
        style={styles.modal}
        onDismiss={handlerDismiss}
      >
        <Dialog.Title>{titulo}</Dialog.Title>
        <Dialog.Content>{contenido}</Dialog.Content>
        {!olcutarAcciones && (
          <Dialog.Actions>
            <CustomConfirmacion
              onPressAceptar={() => {
                new Promise((resolve, reject) => {
                  accion();
                  resolve("ok");
                }).then((ok) => {
                  handlerClose();
                });
              }}
              textAceptar={
                customAceptar === undefined ? "Aceptar" : customAceptar
              }
              onPressCancelar={() => handlerClose()}
            />
          </Dialog.Actions>
        )}
      </Dialog>
    );
  };

  return (
    <View
      nativeID="custom_dialog"
      style={
        visible && oldForm
          ? {
              position: "fixed",
              width: "100vw",
              height: height,
              zIndex: 3,
              elevation: 3,
              top: top,
              left: 0,
            }
          : {}
      }
    >
      {oldForm ? (
        DialogoComponente()
      ) : (
        <Portal key={"custom_dialog_key"}>{DialogoComponente()}</Portal>
      )}
    </View>
  );
}
