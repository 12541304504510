import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { es } from "date-fns/locale";
import React from "react";
import { ScrollView, View } from "react-native";
import { Badge, IconButton, List, Text } from "react-native-paper";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import ReunionService from "../services/ReunionService";
import UserService from "../services/UserService";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";
import Formato from "../utils/Formato";
import Roles from "../utils/Roles";
import Alerta from "./modal/Alerta";
import Spinner from "./modal/Spinner";

const formato = Formato();

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: { backgroundColor: ColoresPineal.anaranjado },
      "daySelected:hover": { backgroundColor: ColoresPineal.anaranjado },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: "76%",
        minWidth: "76%",
      },
    },
    MuiPickersToolbar: {
      toolbarLandscape: {
        maxWidth: "20%",
        minWidth: "20%",
      },
      toolbar: { backgroundColor: ColoresPineal.anaranjado },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        justifyContent: "space-around",
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: "space-around",
      },
    },
  },
});

export default function Calendario({ navigation, user }) {
  const [date, changeDate] = React.useState(new Date());
  const [reuniones, setReuniones] = React.useState();
  const [mensajeSpinner, setMensajeSpinner] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [accionAceptar, setAccionAceptar] = React.useState(() => () => {});

  const obtieneReuniones = () => {
    setMensajeSpinner("Cargando Reuniones.");
    setSpinnerVisible(true);
    ReunionService.obtieneReuniones(user.token).then((respuesta) => {
      setSpinnerVisible(false);
      setReuniones([]);
      if (respuesta.codigoRespuesta === 200) {
        setReuniones(respuesta.entidad);
      } else {
        if (respuesta.codigoRespuesta === 204) {
          setTipoAlerta("warn");
          setMensaje(`No existen reuniones.`);
        } else {
          setTipoAlerta("error");
          setMensaje(`Se ha producido un error al obtener las empresas.`);
        }
        setAlertaVisible(true);
      }
    });
  };

  const nuevaReunion = () => {
    navigation.navigate("Reunion", {
      reunion: {
        idReunion: -1,
        fechaReunionTexto: formato.DateToString(date, true),
        onUpdate: () => {
          obtieneReuniones();
        },
      },
    });
  };

  const editarReunion = (reunion) => {
    setMensajeSpinner("Cargando datos de la reunión");
    setSpinnerVisible(true);
    ReunionService.obtieneReunionIdReunionFull(
      user.token,
      reunion.idReunion
    ).then((respuesta) => {
      setSpinnerVisible(false);
      if (respuesta.codigoRespuesta === 200) {
        let reunionFull = respuesta.entidad;
        reunionFull["idEmpresa"] = reunion.idEmpresa;
        reunionFull["fechaReunionTexto"] = reunion.fechaReunion;
        if (
          reunionFull["postulantes"] !== undefined &&
          reunionFull["postulantes"].length > 0
        ) {
          reunionFull["postulantes"].forEach((postulante) => {
            postulante["id"] = postulante["idPostulante"];
          });
        }
        if (
          reunionFull["participantes"] !== undefined &&
          reunionFull["participantes"].length > 0
        ) {
          reunionFull["participantes"].forEach((participante) => {
            participante["id"] = participante["idParticipante"];
          });
        }
        navigation.navigate("Reunion", {
          reunion: reunionFull,
          onUpdate: () => {
            obtieneReuniones();
          },
        });
      } else {
        setTipoAlerta("error");
        setMensaje("Error al obtener datos de la reunión.");
        setAlertaVisible(true);
      }
    });
  };

  const eliminarReunion = (reunion) => {
    setAccionAceptar(() => () => {});
    setMensajeSpinner("Eliminando reunión.");
    setSpinnerVisible(true);
    ReunionService.eliminarReunion(user.token, reunion).then((respuesta) => {
      setSpinnerVisible(false);
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje("La reunión fue eliminada exitosamente.");
        let reunionesAux = [];
        reuniones.forEach((data) => {
          if (data.idReunion !== reunion.idReunion) {
            reunionesAux.push(data);
          }
        });
        setReuniones(reunionesAux);
      } else {
        setTipoAlerta("error");
        setMensaje("Error al eliminar la reunión.");
      }
      setAlertaVisible(true);
      obtieneReuniones();
    });
  };

  const confirmarEliminar = (reunion) => {
    setTipoAlerta("warn");
    setMensaje("Desea eliminar la reunión.");
    setAccionAceptar(() => () => eliminarReunion(reunion));
    setAlertaVisible(true);
  };

  const irReunion = (reunion) => {
    reunion["recargar"] = true;
    navigation.navigate("Vista Panel", { reunion: reunion });
  };

  const datoColumna = (
    parametro,
    nombreColumna,
    opciones = {},
    fecha = false
  ) => {
    return {
      label: nombreColumna,
      name: parametro,
      type: fecha ? "fecha" : "",
      options: {
        filter: true,
        sort: true,
        display: true,
        //display: "excluded",
        customFilterListOptions: {
          render: (v) => `${nombreColumna}: ${v}`,
        },
        //customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {},
        ...opciones,
      },
    };
  };

  const filtraReuniones = (date) => {
    let reunionesFiltradas = [];

    if (reuniones !== undefined) {
      reuniones.forEach((reunion) => {
        if (reunion.fechaReunion !== undefined) {
          var fechaReunion = reunion.fechaReunion.split(" ");
          fechaReunion = fechaReunion[0].split("/");
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let fecha = (day < 10 ? "0" : "") + day;
          fecha = fecha + (month < 10 ? "0" : "") + month;
          fecha = fecha + year;
          let fechaReunionText =
            fechaReunion[0] + fechaReunion[1] + fechaReunion[2];
          if (fechaReunionText === fecha) {
            reunionesFiltradas.push(reunion);
          }
        }
      });
    }
    return reunionesFiltradas;
  };

  const valoresPosibles = () => {
    let postulantes = [];
    let reuniones = filtraReuniones(date);
    reuniones.forEach((reunion) => {
      reunion.postulantesInfo.forEach((postulante) => {
        postulantes.push(
          `${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`
        );
      });
    });
    postulantes = postulantes.filter((item, index) => {
      return postulantes.indexOf(item) === index;
    });
    return postulantes;
  };

  const estados = { 1: "INGRESADA", 2: "ELIMINADA", 3: "EN PROGRESO" };

  const columnas = [
    datoColumna("empresa", "Empresa"),
    datoColumna("cargo", "Cargo"),
    datoColumna("proceso", "Proceso"),
    datoColumna("fechaReunion", "Fecha", {
      filter: false,
    }),
    datoColumna("estado", "Estado", {
      customBodyRenderLite: (dataIndex) => {
        let reunion = reuniones[dataIndex];
        if (reunion !== undefined && reunion.estado === 1) {
          return <Text>{estados[reunion.estado]}</Text>;
        }
      },
      filterOptions: {
        names: [estados[1], estados[2], estados[3]],
        logic: (location, filters, row) => {
          if (filters[0]) {
            return filters[0] !== estados[location];
          }
          return false;
        },
      },
    }),
    datoColumna("postulantesInfo", "Postulantes", {
      filterOptions: {
        names: valoresPosibles(),
        logic: (location, filters, row) => {
          let existe = false;
          if (filters[0]) {
            location.forEach((postulante) => {
              let nombrePostulante = `${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`;
              if (nombrePostulante !== filters[0]) {
                existe = true;
              } else {
                existe = false;
              }
            });
          }
          return existe;
        },
      },
      customBodyRenderLite: (dataIndex) => {
        let reunion = filtraReuniones(date)[dataIndex];
        if (
          reunion !== undefined &&
          reunion.postulantesInfo !== undefined &&
          reunion.postulantesInfo.length > 0
        ) {
          return (
            <div>
              {reunion.postulantesInfo.map((postulante, index) => {
                return (
                  <List.Item
                    style={{ padding: 0 }}
                    key={index}
                    title={`${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`}
                    left={(props) => (
                      <List.Icon
                        {...props}
                        icon="circle-small"
                        style={{ height: 10, width: 10 }}
                      />
                    )}
                  />
                );
              })}
            </div>
          );
        }
      },
    }),
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let reunion = filtraReuniones(date)[dataIndex];
          if (reunion !== undefined) {
            return (
              <View style={{ flexDirection: "row" }}>
                <IconButton
                  disabled={
                    reunion.estado !== 1 ||
                    formato.dateToInteger(new Date()) >
                      formato.dateToInteger(date)
                  }
                  icon="calendar-arrow-right"
                  color={"#545454"}
                  onPress={() => irReunion(reunion)}
                />
                {!UserService.tieneRol(user, [Roles.ENTREVISTADOR]) && (
                  <IconButton
                    disabled={
                      reunion.estado !== 1 ||
                      formato.dateToInteger(new Date()) >
                        formato.dateToInteger(date)
                    }
                    icon="pencil"
                    onPress={() => editarReunion(reunion)}
                    color={"#545454"}
                  />
                )}
                {!UserService.tieneRol(user, [Roles.ENTREVISTADOR]) && (
                  <IconButton
                    disabled={
                      reunion.estado !== 1 ||
                      formato.dateToInteger(new Date()) >
                        formato.dateToInteger(date)
                    }
                    icon="delete"
                    onPress={() => confirmarEliminar(reunion)}
                    color={"#545454"}
                  />
                )}
              </View>
            );
          }
        },
      },
    },
  ];

  React.useEffect(() => {
    if (reuniones === undefined) {
      obtieneReuniones();
    }
  }, []);

  const obtieneBotones = () => {
    let botones = [];

    if (formato.dateToInteger(new Date()) <= formato.dateToInteger(date)) {
      botones.push({
        icon: "plus",
        label: "Nueva Reunión",
        onPress: () => nuevaReunion(),
      });
    }
    botones.push({
      icon: "refresh",
      label: "Recargar",
      onPress: () => {
        obtieneReuniones();
      },
    });

    return botones;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    var data = filtraReuniones(date);
    return (
      <button
        disabled={!dayInCurrentMonth}
        className="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day"
        style={
          selectedDate.getTime() === date.getTime()
            ? {
                backgroundColor: ColoresPineal.anaranjado,
                color: "#ffffff",
                height: 40,
                width: 40,
              }
            : dayInCurrentMonth
            ? { height: 40, width: 40 }
            : { height: 40, width: 40, color: "#d5d5d5" }
        }
        tabIndex="0"
        type="button"
      >
        <span className="MuiIconButton-label">
          <p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">
            {(date.getDate() < 10 ? "0" : "") + date.getDate()}
          </p>
        </span>
        {selectedDate.getTime() !== date.getTime() && data.length > 0 && (
          <Badge
            style={{
              position: "absolute",
              height: 5,
              minWidth: 5,
              bottom: 3,
              paddingHorizontal: 0,
              backgroundColor: ColoresPineal.anaranjado,
            }}
          ></Badge>
        )}
        <span className="MuiTouchRipple-root"></span>
      </button>
    );
  };

  return (
    <View>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
            fullWidth={true}
            renderDay={renderWrappedWeekDay}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <ScrollView style={styles.calendarioScrollview}>
        {reuniones !== undefined && (
          <CustomMuiDataTable
            data={filtraReuniones(date)}
            columnas={columnas}
            padding={0}
            height={"calc(-531.9px + 100vh)"}
          />
        )}
      </ScrollView>
      <CustomFabGroup listaBotones={obtieneBotones()} />
      <Spinner mensaje={mensajeSpinner} visible={spinnerVisible} />
      <Alerta
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => accionAceptar()}
        closeModal={() => setAlertaVisible(false)}
      />
    </View>
  );
}
