import React from "react";
import { Text, View } from "react-native";
import ColoresPineal from "../styles/ColoresPineal";

export default function Footer() {
  return (
    <View
      style={{
        backgroundColor: ColoresPineal.azulRey,
        height: 30,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Text style={{ margin: "auto", color: ColoresPineal.blanco }}>
        Si necesita apoyo, contáctese a&nbsp;
        <a href="mailto: mesaayudapineal@pineal.cl" style={{color: ColoresPineal.blanco}}>
          mesaayudapineal@pineal.cl
        </a>
      </Text>
    </View>
  );
}
