import React, { useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { ScrollView, Dimensions } from "react-native";
import { Divider, HelperText, Subheading, TextInput, RadioButton, Colors} from "react-native-paper";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";

const windowHeight = Dimensions.get('window').height;

const obtieneFecha = (fechaTexto) => {
  var arrayDate = fechaTexto.split(" ");
  var date = arrayDate[0].split("/");

  return date[1]+"/"+date[0]+"/"+date[2]+" "+arrayDate[1];
}

export default function Evaluaciones({datosPanel}) {

    const [actualizar, setActualizar] = React.useState(false);
    const [fecha, setFecha] = React.useState(datosPanel.fechaHora !== "" ? new Date(Date.parse(obtieneFecha(datosPanel.fechaHora))) : new Date());
    const [hora, setHora] = React.useState(datosPanel.fechaHora !== "" ? new Date(Date.parse(obtieneFecha(datosPanel.fechaHora))) : new Date());

    useEffect(() => {
        if(datosPanel.fecha === null){
          fechaTexto(fecha);
        }
        if(datosPanel.hora === null){
          horaTexto(hora);
        }
    },[actualizar]);

    const fechaTexto = (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      datosPanel.fecha = (day<10?'0':'')+day+'/';
      datosPanel.fecha = datosPanel.fecha + (month<10?'0':'')+month+'/';
      datosPanel.fecha = datosPanel.fecha + year;
    }

    const horaTexto = (date) => {
      let hora = date.getHours();
      let minutos = date.getMinutes();
      datosPanel.hora = (hora<10?'0':'')+hora+':';
      datosPanel.hora = datosPanel.hora + (minutos<10?'0':'')+minutos;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <ScrollView  style={{height:windowHeight*4/5 - 200}}>
                <Subheading>Entrevista Psicolaboral</Subheading>
                <TextInput mode="outlined" label="Resultado Entrevista Psicolaboral" value=" " style={{backgroundColor:Colors.white, marginVertical:10}}
                  render = { () =>
                  <RadioButton.Group 
                    onValueChange={value => {
                      datosPanel.entrevistaPsicolaboral=value;
                      setActualizar(!actualizar);
                    }} 
                    value={datosPanel.entrevistaPsicolaboral}>
                    <RadioButton.Item label="No Aplica" value="No Aplica"/>
                    <RadioButton.Item label="Apto" value="Apto"/>
                    <RadioButton.Item label="Apto con Observaciones" value="Apto con Observaciones"/>
                    <RadioButton.Item label="No Apto" value="No Apto"/>
                  </RadioButton.Group>
                  }
                />
                <TextInput mode="outlined" multiline={true} numberOfLines={5} maxLength={2000} label="Comentario Entrevista Psicolaboral" value={datosPanel.comentarioEntrevistaPsicolaboral} onChangeText={ text => {datosPanel.comentarioEntrevistaPsicolaboral = text; setActualizar(!actualizar);} } style={{backgroundColor:Colors.white}}/>
                <Divider style={{ marginVertical:15}}/>
                <Subheading>Panel</Subheading>
                <TextInput mode="outlined" label="Participante" value={datosPanel.panelistas.join(",")} onChangeText={ text => {datosPanel.panelistas = text.split(','); setActualizar(!actualizar);} } style={{backgroundColor:Colors.white, marginBottom:10}}/>
                <HelperText>Ej: Nombre Participante 1, Nombre Participante 2, ..., Nombre Participante N</HelperText>
                {
                  <DatePicker
                    style={{marginBlock:10}}
                    inputVariant="outlined" 
                    label="Fecha"
                    value={fecha}
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      fechaTexto(date);
                      setFecha(date);
                      setActualizar(!actualizar);
                    }
                  }
                  />
                }
                {
                  <TimePicker
                    clearable
                    style={{marginBlock:10}}
                    ampm={false}  
                    inputVariant="outlined" 
                    label="Hora"
                    value={hora}
                    onChange={(date) => {
                      horaTexto(date);
                      setHora(date);
                      setActualizar(!actualizar);
                    }
                  } 
                  />  
                }
                <TextInput mode="outlined" label="Resultado Panel" value=" " style={{backgroundColor:Colors.white, marginVertical:10}}
                  render = { () =>
                  <RadioButton.Group 
                    onValueChange={value => {
                      datosPanel.resultado=value;
                      setActualizar(!actualizar);
                    }} 
                    value={datosPanel.resultado}>
                    <RadioButton.Item label="No Aplica" value="No Aplica"/>
                    <RadioButton.Item label="Apto" value="Apto"/>
                    <RadioButton.Item label="Apto con Observaciones" value="Apto con Observaciones"/>
                    <RadioButton.Item label="No Apto" value="No Apto"/>
                  </RadioButton.Group>
                  }
                />
                <TextInput mode="outlined" multiline={true} numberOfLines={5} maxLength={2000} label="Comentario Panel" value={datosPanel.comentario} onChangeText={ text => {datosPanel.comentario = text; setActualizar(!actualizar);} } style={{backgroundColor:Colors.white}}/>
                <Divider style={{ marginVertical:15}}/>
                <Subheading>Examen Preocupacional</Subheading>
                <TextInput mode="outlined" label="Resultado Examen Preocupacional" value=" " style={{backgroundColor:Colors.white, marginVertical:10}}
                  render = { () =>
                  <RadioButton.Group 
                    onValueChange={value => {
                      datosPanel.examenPreocupacional=value;
                      setActualizar(!actualizar);
                    }} 
                    value={datosPanel.examenPreocupacional}>
                    <RadioButton.Item label="No Aplica" value="No Aplica"/>
                    <RadioButton.Item label="Apto" value="Apto"/>
                    <RadioButton.Item label="Apto con Observaciones" value="Apto con Observaciones"/>
                    <RadioButton.Item label="No Apto" value="No Apto"/>
                  </RadioButton.Group>
                  }
                />
                <TextInput mode="outlined" multiline={true} numberOfLines={5} maxLength={2000} label="Comentario Examen Preocupacional" value={datosPanel.comentarioExamenPreocupacional} onChangeText={ text => {datosPanel.comentarioExamenPreocupacional = text; setActualizar(!actualizar);} } style={{backgroundColor:Colors.white, marginBottom:15}}/>
              </ScrollView>
            </MuiPickersUtilsProvider>
    );
}