import React from "react";
import { DataTable, IconButton, Subheading, Title } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import CustomTextInput from "./CustomTextInput";
import CustomListSelect from "./CustomListSelect";
import CustomDatepicker from "./CustomDatepicker";

const TablaDetallePostulante = ({
  titulo,
  lista,
  icon,
  iconRow,
  onPressIcon,
  style,
  styleTitle,
  postulante,
  setRegion,
  actualizarPadre,
}) => {
  const [actualizar, setActualizar] = React.useState(false);

  return (
    <DataTable>
      {titulo !== undefined && (
        <DataTable.Header
          style={
            style !== undefined
              ? style
              : { backgroundColor: ColoresPineal.azulOscuro, paddingLeft: 15 }
          }
        >
          <DataTable.Title>
            <Title
              style={
                styleTitle !== undefined
                  ? styleTitle
                  : { color: ColoresPineal.blanco }
              }
            >
              <div>
                {titulo}
                {icon !== undefined && icon.length > 0 && (
                  <IconButton
                    style={{
                      margin: 0,
                    }}
                    icon={icon}
                    color={ColoresPineal.blanco}
                    size={20}
                    onPress={() => onPressIcon()}
                  />
                )}
              </div>
            </Title>
          </DataTable.Title>
        </DataTable.Header>
      )}
      {lista !== undefined &&
        lista.map((dato, index) => {
          let valueLista;
          if (dato.lista !== undefined) {
            if (dato.campo === "licencias") {
              valueLista = [];
              dato.lista.forEach((data) => {
                let result = dato.value.map((a) => a.idLicencia + "");
                if (result.includes(data.value)) {
                  valueLista.push(data);
                }
              });
            } else {
              valueLista = dato.lista.filter(
                (data) => data.value == dato.value
              );
            }
          }
          return (
            <DataTable.Row
              style={{ paddingHorizontal: 0 }}
              key={`contenedor_tabla_fila_${index}`}
            >
              <DataTable.Cell
                style={
                  dato.style !== undefined
                    ? dato.style
                    : {
                        backgroundColor: ColoresPineal.grisAzulado,
                        paddingLeft: 15,
                      }
                }
              >
                <Subheading
                  style={{ color: ColoresPineal.azulOscuro }}
                  numberOfLines={2}
                >
                  {dato.nombre}
                </Subheading>
              </DataTable.Cell>
              <DataTable.Cell
                style={{
                  paddingLeft: 15,
                  backgroundColor: ColoresPineal.blanco,
                }}
              >
                {(dato.edit === undefined || !dato.edit) && (
                  <Subheading
                    numberOfLines={40}
                    style={{
                      whiteSpace: "normal",
                      color: ColoresPineal.azulOscuro,
                    }}
                  >
                    {dato.valor !== null && dato.valor !== undefined
                      ? dato.valor
                      : ""}
                  </Subheading>
                )}
                {dato.edit !== undefined &&
                  dato.edit &&
                  dato.lista === undefined &&
                  dato.valueType !== "fecha" && (
                    <CustomTextInput
                      value={dato.valor}
                      valueType={dato.valueType}
                      onChange={(data) => {
                        switch (dato.campo) {
                          case "certificaciones":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "idiomas":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "experienciaLaboral":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "referencias":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "cursos":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "educacion":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "respuestasPreguntasEmpresa":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          case "respuestasPreguntasCargo":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data;
                            break;
                          default:
                            postulante[dato.campo] = data;
                            break;
                        }
                        setActualizar(!actualizar);
                      }}
                      styleInput={{ height: 28 }}
                      maxLength={dato.maxLength}
                    />
                  )}
                {dato.edit !== undefined &&
                  dato.edit &&
                  dato.lista === undefined &&
                  dato.valueType === "fecha" && (
                    <CustomDatepicker
                      fechaInicial={false}
                      format="yyyy-MM-dd"
                      value={dato.value}
                      onChange={(text, date) => {
                        let day = date.getDate();
                        let month = date.getMonth() + 1;
                        let year = date.getFullYear();
                        let fecha = year + "-";
                        fecha = fecha + (month < 10 ? "0" : "") + month + "-";
                        fecha = fecha + (day < 10 ? "0" : "") + day;
                        postulante[dato.campo] = fecha;
                      }}
                      incluyeHora={false}
                      customInput={(props) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <CustomTextInput
                            styleInput={{ height: 28 }}
                            disabled={true}
                            value={props.value}
                          />
                          <IconButton
                            icon={"calendar"}
                            onPress={props.onClick}
                          />
                        </div>
                      )}
                    />
                  )}
                {dato.edit !== undefined &&
                  dato.edit &&
                  dato.lista !== undefined && (
                    <CustomListSelect
                      value={
                        dato.multiselect === undefined
                          ? valueLista[0]
                          : valueLista
                      }
                      showIcon={dato.multiselect === undefined ? false : true}
                      closeOnClick={
                        dato.multiselect === undefined ? true : false
                      }
                      olcutarAcciones={
                        dato.multiselect === undefined ? true : false
                      }
                      nombreLabel={"label"}
                      nombreValue={"value"}
                      multiselect={dato.multiselect}
                      onChange={(data) => {
                        dato.valor = data[0].label;
                        dato.value = data[0].value;
                        switch (dato.campo) {
                          case "certificaciones":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "idiomas":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "respuestasPreguntasEmpresa":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "respuestasPreguntasCargo":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "cursos":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "experienciaLaboral":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "referencias":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            break;
                          case "educacion":
                            postulante[dato.campo][dato.index][dato.subCampo] =
                              data[0].value;
                            if (dato.subCampo === "tipoEntidadEducativa") {
                              actualizarPadre();
                              postulante[dato.campo][dato.index][
                                dato.subCampo
                              ] = parseInt(data[0].value);
                            }
                            if (
                              dato.subCampo === "entEducativa" ||
                              dato.subCampo === "carrera"
                            ) {
                              actualizarPadre();
                            }
                            break;
                          case "licencias":
                            postulante[dato.campo] = [];
                            data.map((licencia) => {
                              postulante[dato.campo].push({
                                idLicencia: licencia.value,
                                clase: licencia.label,
                              });
                            });

                            dato.valor = data.map((a) => a.label);
                            dato.value = data.map((a) => a.value);
                            break;
                          default:
                            postulante[dato.campo] = data[0].value;
                            break;
                        }
                        if (dato.campo === "region") {
                          setRegion(data[0].value);
                        }
                        setActualizar(!actualizar);
                      }}
                      lista={dato.lista}
                      styleInput={{ height: 28 }}
                    />
                  )}
              </DataTable.Cell>
              {iconRow !== undefined &&
                iconRow.length > 0 &&
                iconRow.map((icon, index) => {
                  return (
                    <DataTable.Cell
                      key={`contenedor_tabla_columna_${index}`}
                      style={{
                        paddingHorizontal: 15,
                        justifyContent: "flex-end",
                        minWidth: 50,
                        maxWidth: 50,
                        backgroundColor: ColoresPineal.blanco,
                      }}
                    >
                      <div title={icon.title === undefined ? "" : icon.title}>
                        <IconButton
                          default
                          style={{
                            margin: 0,
                          }}
                          icon={icon.nombre}
                          color={ColoresPineal.azulOscuro}
                          size={20}
                          onPress={() => icon.onPressIconRow()}
                        />
                      </div>
                    </DataTable.Cell>
                  );
                })}
            </DataTable.Row>
          );
        })}
    </DataTable>
  );
};

export default TablaDetallePostulante;
