import React from "react";
import { View } from "react-native";
import { TextInput, HelperText, Subheading, Colors } from "react-native-paper";
import Validador from "../utils/Validador";
import Formato from "../utils/Formato";
import ColoresPineal from "../styles/ColoresPineal";

export default function CustomTextInput({
  label,
  value,
  onChange,
  valueType = "rut" |
    "nota" |
    "anio" |
    "periodo" |
    "email" |
    "telefono" |
    "nombre" |
    "numeros",
  requiered,
  maxLength,
  maxValue,
  minValue,
  style,
  styleInput = {},
  disabled,
  multiline = false,
  numberOfLines = 1,
}) {
  const [valor, setValor] = React.useState(value === null ? "" : value);
  const [tieneError, setTieneError] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");
  const [password, setPassword] = React.useState(
    valueType !== undefined && valueType === "password"
  );
  const [datoValido, setDatoValido] = React.useState("");
  const validador = Validador();
  const formato = Formato();

  const validarInput = () => {
    var date = new Date();
    setDatoValido("");
    if (requiered === true && valor.length === 0) {
      agregaError("El campo es requerido.");
      return;
    }
    if (valor !== undefined && valor !== null && valor.length !== 0) {
      switch (valueType) {
        case "rut":
          if (validador.rut(valor)) {
            setDatoValido("success");
          } else {
            agregaError("RUT ingresado no valido");
          }
          break;
        case "nota":
          if (validador.nota(valor)) {
            setDatoValido("success");
          } else {
            agregaError("Nota debe estar entre 2.0 y 7.0");
          }
          break;
        case "anio":
          if (validador.anio(valor, date)) {
            setDatoValido("success");
          } else {
            agregaError("Año debe estar entre 1900 y " + date.getFullYear());
          }
          break;
        case "periodo":
          let periodo = valor.replace(/[^0-9kK]/g, "");
          let respuesta = validador.periodo(periodo, date);
          if (respuesta.valido) {
            setDatoValido("success");
          } else {
            let mensajeError = "";
            if (respuesta.anio)
              mensajeError +=
                "Año debe estar entre 1900 y " + date.getFullYear();
            if (respuesta.anio && respuesta.mes) mensajeError += " - ";
            if (respuesta.mes) mensajeError += "Mes debe estar entre 01 y 12";
            agregaError(mensajeError);
          }
          break;
        case "email":
          console.log(validador.email(value));
          if (validador.email(valor)) {
            setDatoValido("success");
          } else {
            agregaError("Email con formato incorrecto, Ej: nombre@domio.cl");
          }
          break;
        case "telefono":
          let numeros = valor.replace(/[^0-9kK]/g, "");
          console.log(value, numeros.length);
          if (numeros.length === 9) {
            setDatoValido("success");
          } else {
            agregaError("El largo del numero ingresado deber igual a 9");
          }
          break;
        case "numeros":
          let msj = null;
          if (minValue !== undefined && valor < minValue) {
            msj = `El valor del numero ingresado debe ser mayor o igual a ${minValue}`;
          }
          if (maxValue !== undefined && valor > maxValue) {
            msj = `El valor del numero ingresado debe ser menor o igual a ${maxValue}`;
          }
          if (msj === null) {
            setDatoValido("success");
          } else {
            agregaError(msj);
          }
          break;
        default:
          setDatoValido("success");
          break;
      }
    }
  };

  const agregaError = (glosa) => {
    setTieneError(true);
    setTextoError(glosa);
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 8 || charCode === 37 || charCode === 39) {
      return;
    }
    if (
      maxLength !== undefined &&
      !formato.validaLargo(e.target.value, maxLength)
    ) {
      e.preventDefault();
      return;
    }
    switch (valueType) {
      case "numeros":
        if (!formato.soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "moneda":
        if (!formato.soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "rut":
        if (
          !formato.soloNumeros(charCode) &&
          charCode !== 75 &&
          charCode !== 107
        ) {
          e.preventDefault();
        }
        break;
      case "telefono":
        if (!formato.soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "nota":
        if (!formato.soloNumeros(charCode) && charCode !== 46) {
          e.preventDefault();
        }
        break;
      case "anio":
        if (!formato.soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "periodo":
        if (!formato.soloNumeros(charCode) && charCode !== 45) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };
  const onBlur = () => {
    let valueFormat;
    validarInput();
    switch (valueType) {
      case "rut":
        valueFormat = valor.replace(/[^0-9kK]/g, "");
        valueFormat = formato.formato_rut(valueFormat);
        break;
      case "moneda":
        valueFormat = valor.replace(/[^0-9]/g, "");
        valueFormat = formato.formato_moneda(valueFormat);
        break;
      case "telefono":
        valueFormat = valor.replace(/[^0-9]/g, "");
        valueFormat = formato.formato_telefono(valueFormat);
        break;
      case "nota":
        valueFormat = valor.replace(/[^0-9]/g, "");
        valueFormat = formato.formato_nota(valueFormat);
        break;
      case "periodo":
        valueFormat = valor.replace(/[^0-9]/g, "");
        valueFormat = formato.formato_periodo(valueFormat);
        break;
      case "nombre":
        valueFormat = formato.formato_nombre(value);
        break;
      default:
        valueFormat = valor;
        break;
    }
    setValor(valueFormat);
    if (onChange !== undefined) {
      onChange(valueFormat);
    }
  };
  const onFocus = () => {
    setTieneError(false);
    if (valor !== undefined && valor !== null) {
      switch (valueType) {
        case "rut":
          setValor(valor.replace(/[^0-9kK]/g, ""));
          break;
        case "telefono":
        case "moneda":
        case "periodo":
        case "nota":
          setValor(valor.replace(/[^0-9]/g, ""));
          break;
        default:
          break;
      }
    }
  };

  React.useEffect(() => {
    setValor(value === null ? "" : value);
  }, [value]);

  return (
    <View
      style={{ flexDirection: "column", display: "flex", ...style }}
      nativeID={"contenedor_custom_text_input"}
    >
      {label !== undefined && label.length > 0 && (
        <Subheading>{label}</Subheading>
      )}
      <TextInput
        style={
          disabled
            ? { backgroundColor: "#b7b5b5", ...styleInput }
            : { ...styleInput }
        }
        multiline={multiline}
        numberOfLines={numberOfLines}
        disabled={disabled}
        secureTextEntry={password}
        value={valor}
        error={tieneError}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={(event) => handleKeyPress(event)}
        onChangeText={(text) => {
          setValor(text);
        }}
        right={
          valueType !== undefined &&
          valueType === "password" && (
            <TextInput.Icon name="eye" onPress={() => setPassword(!password)} />
          )
        }
      />
      {tieneError && (
        <HelperText style={{ color: ColoresPineal.rojoAlerta }}>
          {textoError}
        </HelperText>
      )}
    </View>
  );
}
