import React from "react";
import { View } from "react-native";
import {
  IconButton,
  Paragraph
} from "react-native-paper";
import CustomFabGroup from "../../components/CustomFabGroup";
import CustomMuiDataTable from "../../components/CustomMuiDataTable";
import CustomSwitch from "../../components/CustomSwitch";
import LoadingIndicator from "../../components/LoadingIndicator";
import EmpresaService from "../../services/EmpresaService";
import Alerta from "../modal/Alerta";

export default function MantenedorEmpresa({
  navigation,
  route,
  user,
  recargaEmpresas,
}) {
  const [empresas, setEmpresas] = React.useState([]);
  const [empresa, setEmpresa] = React.useState();
  const [confirmacion, setConfirmacion] = React.useState(false);
  const [funcion] = React.useState({
    aceptaModal: () => {},
  });
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [state, setState] = React.useState({ open: false });
  const { open } = state;

  const onStateChange = ({ open }) => {
    setState({ open });
  };

  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresasTodas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setEmpresas(respuesta.entidad);
        }
      }
    });
  };

  const eliminaEmpresa = (empresa) => {
    setEmpresa(undefined);
    EmpresaService.eliminaEmpresa(user.token, empresa.id).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Empresa ${empresa.nombre} eliminada exitosamente.`);
        recargaEmpresas();
      } else {
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al eliminar la empresa ${empresa.nombre}.`
        );
      }
      setConfirmacion(false);
      setAlertaVisible(true);
    });
  };

  const actualizaEmpresa = (empresa) => {
    setEmpresa(undefined);
    empresa.activa = !empresa.activa;
    EmpresaService.actualizaEmpresa(user.token, empresa).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Empresa ${empresa.nombre} actualizada exitosamente.`);
        recargaEmpresas();
      } else {
        empresa.activa = !empresa.activa;
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al actualizar la empresa ${empresa.nombre}`
        );
      }
      setConfirmacion(false);
      setAlertaVisible(true);
    });
  };

  const columnas = [
    {
      name: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Nombre: ${v}`,
        },
      },
    },
    {
      name: "rut",
      label: "RUT",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `RUT: ${v}`,
        },
      },
    },
    {
      name: "contactoEmpresa",
      label: "Contacto",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Contacto: ${v}`,
        },
      },
    },
    {
      name: "diasPostulacion",
      label: "Días Postulación",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Días Postulación: ${v}`,
        },
      },
    },
    {
      name: "diasTest",
      label: "Días Test",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Días Test: ${v}`,
        },
      },
    },
    {
      name: "diasEvaluacion",
      label: "Días Evaluación",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Días Evaluación: ${v}`,
        },
      },
    },

    {
      name: "diasContratacion",
      label: "Días Contración",
      options: {
        filter: true,
        sort: false,
        display: true,
        customFilterListOptions: {
          render: (v) => `Días Contratación: ${v}`,
        },
      },
    },

    {
      name: "Activa",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let empresa = empresas[dataIndex];
          return (
            <CustomSwitch
              onSwitch={() => {
                setTipoAlerta("warn");
                setMensaje(
                  `¿Desea ${
                    empresa.activa ? "Desactivar" : "Acticar"
                  } la empresa: ${empresa.nombre}?`
                );
                setEmpresa(empresa);
                setConfirmacion(true);
                funcion.aceptaModal = actualizaEmpresa;
                setAlertaVisible(true);
              }}
              estado={empresa.activa}
              texto={empresa.activa ? "Activada" : "Desactivada"}
            />
          );
        },
      },
    },
    {
      name: "Imagen",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let empresa = empresas[dataIndex];
          return (
            <View>
              <Paragraph style={{ marginLeft: 20 }}>
                {empresa.imagen !== null ? "Si" : "No"}
              </Paragraph>
            </View>
          );
        },
      },
    },
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let empresa = empresas[dataIndex];
          return (
            <View style={{ flexDirection: "row" }}>
              <IconButton
                icon="pencil"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  navigation.navigate("Empresa", { empresa: empresa });
                }}
              />
              <IconButton
                icon="delete"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setTipoAlerta("warn");
                  setMensaje(`¿Desea eliminar la empresa: ${empresa.nombre}?`);
                  setEmpresa(empresa);
                  setConfirmacion(true);
                  funcion.aceptaModal = eliminaEmpresa;
                  setAlertaVisible(true);
                }}
              />
            </View>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    if (empresas.length === 0) {
      obtieneEmpresas();
    }
    if (route.params?.empresa) {
      obtieneEmpresas();
    }
  }, [route.params?.empresa]);

  return (
    <View>
      {empresas.length === 0 && (
        <LoadingIndicator texto={"Cargando Empresas"} />
      )}
      {empresas.length > 0 && (
        <CustomMuiDataTable data={empresas} columnas={columnas} />
      )}
      <CustomFabGroup
        visible={empresas.length > 0}
        listaBotones={[
          {
            icon: "plus",
            label: "Nueva empresa",
            onPress: () => {
              navigation.navigate("Empresa", { empresa: { id: -1 } });
            },
          },
          {
            icon: "refresh",
            label: "Recargar empresas",
            onPress: () => {
              obtieneEmpresas();
            },
          },
        ]}
      />
      <Alerta
        confirmacionModal={confirmacion}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          if (empresa !== undefined) {
            funcion.aceptaModal(empresa);
          }
        }}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
