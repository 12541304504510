import React from "react";
import { Text, View } from "react-native";
import { Colors, RadioButton } from "react-native-paper";

export default function CustomRadioButton({
  label,
  list,
  onChangeValue,
  valueIni,
}) {
  const [value, setValue] = React.useState();

  const handlerChange = (value) => {
    setValue(value);
    onChangeValue(value);
  };

  React.useEffect(() => {
    handlerChange(valueIni);
  }, []);

  return (
    <View
      style={{
        borderColor: "#717171",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        paddingTop: 5,
      }}
    >
      <Text
        style={{
          position: "absolute",
          top: -11,
          left: 11,
          backgroundColor: Colors.white,
        }}
      >
        {label}
      </Text>
      <RadioButton.Group onValueChange={handlerChange} value={value}>
        {list.map((elemento, index) => {
          return (
            <View
              key={index}
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <RadioButton value={elemento.value} color="#f6a623" />
              <Text>{elemento.label}</Text>
            </View>
          );
        })}
      </RadioButton.Group>
    </View>
  );
}
