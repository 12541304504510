import React from "react";
import { Dialog, List, Button, Portal } from "react-native-paper";
import CustomButton from "../../components/CustomButton";
import CustomConfirmacion from "../../components/CustomConfirmacion";
import styles from "../../styles/style";

export default function Alerta({
  visible,
  tipoAlerta,
  mensaje,
  closeModal = () => {},
  aceptarButton,
  confirmacionModal = undefined,
}) {
  const [icon, setIcon] = React.useState();

  const iconoTipoAlerta = () => {
    let icon;
    switch (tipoAlerta) {
      case "info":
        icon = {
          name: "check-circle-outline",
          color: "#44B42E",
        };
        break;
      case "warn":
        icon = {
          name: "information-outline",
          color: "#D8B62A",
        };
        break;
      case "error":
        icon = {
          name: "close-circle-outline",
          color: "#a53737",
        };
        break;
    }
    setIcon(icon);
  };

  React.useEffect(() => {
    iconoTipoAlerta();
  }, [tipoAlerta, mensaje, visible]);

  return (
    <Portal>
      <Dialog
        visible={visible}
        dismissable={true}
        onDismiss={closeModal}
        style={styles.modal}
      >
        <Dialog.Title></Dialog.Title>
        <Dialog.Content>
          <List.Item
            titleNumberOfLines={2}
            title={mensaje}
            left={() => (
              <List.Icon
                icon={icon.name}
                color={icon.color}
                style={{ transform: [{ scale: 3 }], marginRight: 30 }}
              />
            )}
          />
        </Dialog.Content>
        <Dialog.Actions>
          {confirmacionModal !== undefined && confirmacionModal === true && (
            <CustomConfirmacion
              onPressAceptar={() => {
                if (aceptarButton !== undefined) {
                  aceptarButton();
                }
                closeModal();
              }}
              onPressCancelar={closeModal}
            />
          )}
          {confirmacionModal === undefined ||
            (confirmacionModal === false && (
              <CustomButton
                onPress={() => {
                  if (aceptarButton !== undefined) {
                    aceptarButton();
                  }
                  closeModal();
                }}
                textoButton="Aceptar"
              />
            ))}
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
