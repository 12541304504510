const etapas = [
    {
        label:"Total Postulantes",
        estado: 0
    },
    {
        label:"Postulación",
        estado: 1
    },
    {
        label:"Test",
        estado: 2
    },
    {
        label:"Evaluaciones",
        estado: 3
    },
    {
        label:"Contratación",
        estado: 4
    },
    {
        label:"Postulantes Históricos",
        estado: 0
    },
    {
        label:"Postulación Históricos",
        estado: 1
    },
    {
        label:"Test Históricos",
        estado: 2
    },
    {
        label:"Evaluaciones Históricos",
        estado: 3
    },
    {
        label:"Contratación Históricos",
        estado: 4
    }
]

export default () => {
    return {
            obtieneEtapa : (nombreEtapa) => {
            let etapaOut = etapas.find( (etapa) => {
                return etapa.label === nombreEtapa;
            })
            return etapaOut;
        }
    }
}