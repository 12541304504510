import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtieneDatosGeneralesReunion: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/datos/generales`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  obtieneReuniones: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  obtieneReunionIdProceso: (token, idProceso) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/proceso/${idProceso}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  obtieneReunionIdReunionFull: (token, idReunion) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/${idReunion}/full`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  crearReunion: (token, reunion) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reunion),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  actualizarReunion: (token, reunion) => {
    delete reunion.fechaCreacion;
    delete reunion.fechaReunion;
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reunion),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
  eliminarReunion: (token, reunion) => {
    reunion.estado = 2;
    delete reunion.fechaCreacion;
    delete reunion.fechaReunion;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reunion),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}reunion/meeting/elimina`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codigoRespuesta;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.entidad;
          resolve(respuesta);
        });
    });
  },
};
