import React from "react";
import { Dimensions, ScrollView } from "react-native";
import { Dialog, List, TextInput } from "react-native-paper";
import EmpresaService from "../services/EmpresaService";
import ProcesosService from "../services/ProcesosService";
import CustomConfirmacion from "./CustomConfirmacion";
import styles from "../styles/style";

const windowHeight = Dimensions.get("window").height;

export default function EnvioMasivoDialogo({
  visible,
  user,
  closeModal,
  aceptarModal,
}) {
  const [procesos, setProcesos] = React.useState([]);
  const [procesosFiltrados, setProcesosFiltrados] = React.useState([]);
  const [proceso, setProceso] = React.useState();

  const filtrarProcesos = (text) => {
    let procesosAux = procesos.filter((proceso) => {
      return text === undefined || text.length === 0
        ? true
        : proceso.nombreCargo.toLowerCase().includes(text.toLowerCase());
    });
    setProcesosFiltrados(procesosAux);
  };

  const cierraModal = () => {
    setProceso(undefined);
    closeModal();
  };
  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          let procesosEmpresa;
          let totalProcesos = [];
          respuesta.entidad.forEach(async (empresa) => {
            procesosEmpresa = await obtieneProcesos(empresa);
            if (procesosEmpresa.length > 0) {
              totalProcesos = [...totalProcesos, ...procesosEmpresa];
              setProcesos(totalProcesos);
              //console.table(totalProcesos);
              setProcesosFiltrados(totalProcesos);
            }
          });
        }
      }
    });
  };

  const obtieneProcesos = async (empresa) => {
    let procesosEmpresa = [];
    await ProcesosService.obtieneProcesos(user.token, empresa.id).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          procesosEmpresa = respuesta.entidad;
        }
      }
    );

    return procesosEmpresa;
  };

  React.useEffect(() => {
    if (procesos.length === 0) {
      obtieneEmpresas();
    }
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      dismissable={true}
      onDismiss={() => cierraModal()}
      style={styles.modal}
    >
      <Dialog.Title>Envio Masivo</Dialog.Title>
      <Dialog.Content>
        <TextInput
          right={<TextInput.Icon name="magnify" />}
          onChangeText={(text) => filtrarProcesos(text)}
        />
        <ScrollView style={{ maxHeight: (windowHeight * 4) / 5 - 300 }}>
          {procesosFiltrados.map((item, index) => {
            return (
              <List.Item
                onPress={() => setProceso(item)}
                key={index}
                title={item.nombreCargo}
                left={(props) => (
                  <List.Icon
                    icon={
                      proceso !== undefined &&
                      proceso.idProceso === item.idProceso
                        ? "check"
                        : "account"
                    }
                  />
                )}
              />
            );
          })}
        </ScrollView>
      </Dialog.Content>
      <Dialog.Actions>
        <CustomConfirmacion
          onPressAceptar={() => {
            aceptarModal(proceso);
            cierraModal();
          }}
          onPressCancelar={() => cierraModal()}
          disabled={proceso === undefined}
        />
      </Dialog.Actions>
    </Dialog>
  );
}
