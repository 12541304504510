import React from "react";
import { Dimensions, View } from "react-native";
import { Badge, Checkbox, List, Text } from "react-native-paper";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import EnvioMasivoDialogo from "../components/EnvioMasivoDialogo";
import LoadingIndicator from "../components/LoadingIndicator";
import LoadingModal from "../components/LoadingModal";
import PostulanteService from "../services/PostulanteService";
import ColoresPineal from "../styles/ColoresPineal";
import Alerta from "./modal/Alerta";

const windowHeight = Dimensions.get("window").height;

export default function Candidatos({ navigation, user }) {
  const [candidatos, setCandidatos] = React.useState([]);
  const [actualizar, setActualizar] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [columnasView] = React.useState([]);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);
  const [envioVisible, setEnvioVisible] = React.useState(false);
  const [loadingVisible, setLoadingVisible] = React.useState(false);
  const [mensajeLoading, setMensajeLoading] = React.useState();

  const obtieneCandidatos = () => {
    PostulanteService.obtienePostulantesTodos(user.token).then((resp) => {
      setShowSpinner(false);
      if (resp.codigoRespuesta === 200) {
        resp.entidad.forEach((candidato) => {
          candidato["seleccionado"] = false;
          if (
            candidato["educacion"] !== undefined &&
            candidato["educacion"] !== null &&
            candidato["educacion"].length > 0
          ) {
            candidato["educacion"].forEach((educacion) => {
              candidato["nombreEntEducativa"] = educacion.nombreEntEducativa;
              candidato["nombreCarrera"] = educacion.nombreCarrera;
              candidato["anioEgreso"] = educacion.anioEgreso;
              candidato["notaEgreso"] = educacion.notaEgreso;
              candidato["especialidad"] = educacion.especialidad;
              candidato["postGrado"] = educacion.postGrado;
              candidato["liceo"] = educacion.liceo;
            });
          }
          if (
            candidato["idiomas"] !== undefined &&
            candidato["idiomas"] !== null &&
            candidato["idiomas"].length > 0
          ) {
            candidato["idiomas"].forEach((idioma) => {
              candidato["nombreIdioma"] = idioma.nombreIdioma;
              candidato["nivelOral"] = idioma.nivelOral;
              candidato["nivelEscrito"] = idioma.nivelEscrito;
            });
          }
          if (
            candidato["certificaciones"] !== undefined &&
            candidato["certificaciones"] !== null &&
            candidato["certificaciones"].length > 0
          ) {
            candidato["certificaciones"].forEach((certificacion) => {
              candidato["nombreCertificacion"] = certificacion.nombre;
            });
          }
          if (
            candidato["cursos"] !== undefined &&
            candidato["cursos"] !== null &&
            candidato["cursos"].length > 0
          ) {
            candidato["cursos"].forEach((curso) => {
              candidato["nombreCurso"] = curso.nombre;
            });
          }
          if (
            candidato["experienciaLaboral"] !== undefined &&
            candidato["experienciaLaboral"] !== null &&
            candidato["experienciaLaboral"].length > 0
          ) {
            candidato["experienciaLaboral"].forEach((experiencia) => {
              candidato["nombreEmpresaExp"] = experiencia.nombreEmpresa;
              candidato["periodoIngresoExp"] = experiencia.periodoIngreso;
              candidato["periodoEgresoExp"] = experiencia.periodoEgreso;
              candidato["nombreRubroExp"] = experiencia.nombreRubro;
              candidato["nombreCargoExp"] = experiencia.nombreCargo;
              candidato["rolExp"] = experiencia.rol;
              candidato["funcionesExp"] = experiencia.funciones;
            });
          }
          if (
            candidato["referencias"] !== undefined &&
            candidato["referencias"] !== null &&
            candidato["referencias"].length > 0
          ) {
            candidato["referencias"].forEach((referencia) => {
              candidato["nombreRef"] = referencia.nombre;
              candidato["telefonoRef"] = referencia.telefono;
              candidato["cargoRef"] = referencia.cargo;
              candidato["emailRef"] = referencia.email;
            });
          }
        });
        setCandidatos(resp.entidad);
      }
    });
  };

  const totalSeleccionados = () => {
    return candidatos.filter((candidato) => {
      return candidato.seleccionado;
    }).length;
  };

  const desseleccionaTodos = () => {
    candidatos
      .filter((candidato) => {
        return candidato.seleccionado;
      })
      .forEach((candidato) => {
        candidato.seleccionado = false;
      });
  };

  const envioMailMasivo = (proceso) => {
    setMensajeLoading("Enviando mail a postulantes...");
    setLoadingVisible(true);
    let datos = {
      idProceso: proceso.idProceso,
      listPostulante: [],
    };
    candidatos.forEach((candidato) => {
      if (candidato.seleccionado) {
        datos.listPostulante.push(candidato.idPostulante);
      }
    });
    PostulanteService.envioMasivoMail(user.token, datos).then((resp) => {
      if (resp.codeResponse === 200) {
        setTipoAlerta("info");
        setMensaje(`Invitaciones enviadas correctamente`);
      } else {
        setTipoAlerta("error");
        setMensaje(`Se produjo un problema, intente más tarde`);
      }
      setLoadingVisible(false);
      setAlertaVisible(true);
    });
  };

  const opciones = {
    filter: true,
    sort: true,
  };

  const opcionesDisplay = {
    filter: true,
    sort: true,
    display: false,
  };

  const valoresPosibles = (
    lista,
    nombreColumna,
    esArreglo = false,
    nombreCampo = null
  ) => {
    let valores = [];
    lista.forEach((elemento) => {
      if (esArreglo) {
        if (nombreCampo !== null) {
          elemento[nombreColumna].forEach((campo) => {
            valores.push(campo[nombreCampo]);
          });
        } else {
          valores = valores.concat(elemento[nombreColumna]);
        }
      } else {
        if (nombreCampo !== null) {
          valores.push(elemento[nombreColumna][nombreCampo]);
        } else {
          valores.push(elemento[nombreColumna]);
        }
      }
    });
    valores = valores.filter((item, index) => {
      return valores.indexOf(item) === index;
    });
    return valores;
  };
  const columnas = () => {
    let columnas = [
      {
        name: "seleccionado",
        label: "Seleccionar",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            let candidato = candidatos[dataIndex];
            return (
              <View>
                <Checkbox
                  status={
                    candidato !== undefined && candidato.seleccionado
                      ? "checked"
                      : "unchecked"
                  }
                  onPress={() => {
                    candidato.seleccionado = !candidato.seleccionado;
                    setActualizar(!actualizar);
                  }}
                />
              </View>
            );
          },
        },
      },
      {
        name: "nombre",
        label: "Nombre",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Nombre: ${v}`,
          },
        },
      },
      {
        name: "apPaterno",
        label: "Apellido Paterno",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Apellido Paterno: ${v}`,
          },
        },
      },
      {
        name: "apMaterno",
        label: "Apellido Materno",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Apellido Materno: ${v}`,
          },
        },
      },
      {
        name: "rut",
        label: "R. U. T. ",
        options: {
          ...opciones,
          filter: true,
          setCellProps: (value) => {
            return {
              style: {
                //borderRight: '2px solid blue'
                minWidth: "90px",
              },
            };
          },
          customFilterListOptions: {
            render: (v) => `RUT: ${v}`,
          },
        },
      },
      {
        name: "nombreGenero",
        label: "Género",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Género: ${v}`,
          },
        },
      },
      {
        name: "nombreEstadoCivil",
        label: "Estado civil",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Estado civil: ${v}`,
          },
        },
      },
      {
        name: "fechaNacimiento",
        label: "Fecha Nacimiento",
        type: "fecha",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Fecha Nacimiento: ${v}`,
          },
        },
      },
      {
        name: "ntelefonoMovil",
        label: "Teléfono Móvil",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Teléfono Móvil: ${v}`,
          },
        },
      },
      {
        name: "ntelefonoFijo",
        label: "Teléfono Fijo",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Teléfono Fijo: ${v}`,
          },
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Email: ${v}`,
          },
        },
      },
      {
        name: "procesosLista",
        label: "Procesos",
        options: {
          filter: true,
          sort: false,
          display: true,
          filterOptions: {
            names: valoresPosibles(
              candidatos,
              "procesosLista",
              true,
              "descripcion"
            ),
            logic: (location, filters, row) => {
              if (location !== undefined && filters[0]) {
                let procesoAux = location.find(
                  (proceso) => proceso.descripcion === filters[0]
                );
                return procesoAux === undefined ? true : false;
              }
              return false;
            },
          },
          customFilterListOptions: {
            render: (v) => `Proceso: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let postulante = candidatos[dataIndex];
            return (
              <div>
                {postulante.procesosLista.map((proceso, index) => {
                  let procesoAux = {
                    recarga: true,
                    id_empresa: proceso.idEmpresa,
                    id_proceso: proceso.idProceso,
                  };
                  return (
                    <List.Item
                      style={{ padding: 0 }}
                      key={index}
                      title={proceso.descripcion}
                      titleStyle={{
                        color: `${
                          proceso.activo === false ||
                          proceso.historico === undefined ||
                          proceso.historico === null
                            ? ColoresPineal.grisAzulado
                            : ColoresPineal.azulRey
                        }`,
                        textDecorationLine: "underline",
                        cursor: `${proceso.activo ? "pointer" : "default"}`,
                        fontSize: 14,
                      }}
                      disabled={
                        proceso.activo === false ||
                        proceso.historico === undefined ||
                        proceso.historico === null
                      }
                      onPress={() => {
                        if (proceso["historico"] === "SI") {
                          navigation.navigate("Procesos Históricos", {
                            proceso: procesoAux,
                          });
                        } else {
                          navigation.navigate("Procesos en Curso", {
                            proceso: procesoAux,
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            );
          },
        },
      },
      {
        name: "licencias",
        label: "Licencia de conducir",
        options: {
          filter: true,
          sort: false,
          display: false,
          filterOptions: {
            names: valoresPosibles(candidatos, "licencias", true, "clase"),
            logic: (location, filters, row) => {
              if (row[12] !== undefined && filters[0]) {
                let licencia = row[12].find(
                  (licencia) => licencia.clase === filters[0]
                );
                return licencia === undefined ? true : false;
              }
              return false;
            },
          },
          customFilterListOptions: {
            render: (v) => `Licencia de conducir: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let licencias = [];
            let postulante = candidatos[dataIndex];
            if (postulante !== undefined) {
              postulante.licencias.forEach((licencia) => {
                licencias.push(licencia.clase);
              });
            }
            return (
              <div>
                {licencias.map((licencia, index) => {
                  return (
                    <List.Item
                      style={{ padding: 0 }}
                      key={index}
                      title={licencia}
                    />
                  );
                })}
              </div>
            );
          },
        },
      },
      {
        name: "experienciaLaboralRemunerada",
        label: "Experiencia Laboral Remunerada",
        options: {
          ...opcionesDisplay,
          filter: true,
          filterOptions: {
            names: valoresPosibles(candidatos, "experienciaLaboralRemunerada"),
            logic: (location, filters, row) => {
              if (filters[0]) {
                return row[13] !== filters[0];
              }
              return false;
            },
          },
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 =
                obj1.rowData[14] === null || obj1.rowData[14] === undefined
                  ? 0
                  : obj1.rowData[14];
              let val2 =
                obj2.rowData[14] === null || obj2.rowData[14] === undefined
                  ? 0
                  : obj2.rowData[14];
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          customFilterListOptions: {
            render: (v) => `Experiencia Laboral Remunerada: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let postulante = candidatos[dataIndex];
            if (postulante !== undefined) {
              return (
                <Text style={{ textTransform: "uppercase" }}>
                  {postulante.experienciaLaboralRemunerada}
                </Text>
              );
            }
          },
        },
      },
      {
        name: "direccion",
        label: "Dirección",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Dirección: ${v}`,
          },
        },
      },
      {
        name: "nombreCiudad",
        label: "Ciudad",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Ciudad: ${v}`,
          },
        },
      },
      {
        name: "nombreRegion",
        label: "Región",
        options: {
          ...opcionesDisplay,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Región: ${v}`,
          },
        },
      },
      {
        name: "expectativaRenta",
        label: "Expectativa Renta",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Expectativa Renta: ${v}`,
          },
        },
      },
      {
        name: "nombreEntEducativa",
        label: "Entidad Educativa",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Entidad Educativa: ${v}`,
          },
        },
      },
      {
        name: "nombreCarrera",
        label: "Carrera",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Carrera: ${v}`,
          },
        },
      },
      {
        name: "anioEgresa",
        label: "Año de Egresa",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Año de Egresa: ${v}`,
          },
        },
      },
      {
        name: "notaEgreso",
        label: "Nota de Egreso",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Nota de Egreso: ${v}`,
          },
        },
      },
      {
        name: "especialidad",
        label: "Especialidad",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Especialidad: ${v}`,
          },
        },
      },
      {
        name: "postgrado",
        label: "Postgrado",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Postgrado: ${v}`,
          },
        },
      },
      {
        name: "liceo",
        label: "Liceo",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Liceo: ${v}`,
          },
        },
      },
      {
        name: "nombreIdioma",
        label: "Idioma",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Idioma: ${v}`,
          },
        },
      },
      {
        name: "nivelOral",
        label: "Nivel Oral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Nivel Oral: ${v}`,
          },
        },
      },
      {
        name: "nivelEscrito",
        label: "Nivel Escrito",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Nivel Escrito: ${v}`,
          },
        },
      },
      {
        name: "nombreCertificacion",
        label: "Certificación",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Certificacion: ${v}`,
          },
        },
      },
      {
        name: "nombreCurso",
        label: "Curso",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Curso: ${v}`,
          },
        },
      },
      {
        name: "nombreEmpresaExp",
        label: "Empresa Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Empresa Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "periodoIngresoExp",
        label: "Periodo Ingreso Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Periodo Ingreso Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "periodoEgresoExp",
        label: "Periodo Egreso Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Periodo Egreso Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "nombreCargoExp",
        label: "Rubro Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Rubro Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "rolExp",
        label: "Rol Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Rol Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "funcionesExp",
        label: "Funciones Experiencia Laboral",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Funciones Experiencia Laboral: ${v}`,
          },
        },
      },
      {
        name: "nombreRef",
        label: "Nombre Referencia",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Nombre Referencia: ${v}`,
          },
        },
      },
      {
        name: "telefonoRef",
        label: "Telefono Referencia",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Telefono Referencia: ${v}`,
          },
        },
      },
      {
        name: "cargoRef",
        label: "Cargo Referencia",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Cargo Referencia: ${v}`,
          },
        },
      },
      {
        name: "emailRef",
        label: "Email Referencia",
        options: {
          ...opciones,
          filter: true,
          customFilterListOptions: {
            render: (v) => `Email Referencia: ${v}`,
          },
        },
      },
      //...tablaEducacion(opciones),
    ];
    return columnas;
  };

  const tablaEducacion = (opciones) => {
    let preguntas = [];
    console.table(candidatos);
    if (candidatos.length > 0) {
      let candidato = candidatos.find(
        (candidato) =>
          candidato.educacion !== undefined &&
          candidato.educacion !== null &&
          candidato.educacion.length > 0
      );
      let parametros = Object.entries(candidato.educacion[0]);
      console.table(parametros);
      parametros.forEach((element) => {
        if (element !== null) {
          preguntas.push({
            name: `${element[0]}`,
            options: {
              ...opciones,
              customBodyRender: (value, tableMeta, updateValue) => {
                let candidato = candidatos[tableMeta.rowIndex];
                if (
                  candidato.educacion !== undefined &&
                  candidato.educacion !== null &&
                  candidato.educacion.length > 0
                ) {
                  return candidato.educacion[0][element[0]];
                } else {
                  return "";
                }
              },
            },
          });
        }
      });
    }

    return preguntas;
  };

  React.useEffect(() => {
    if (candidatos.length === 0) {
      obtieneCandidatos();
    }
  }, [candidatos.length]);

  return (
    <View>
      {showSpinner && (
        <LoadingIndicator texto={"Cargando Base de datos Pineal"} />
      )}
      {!showSpinner && (
        <CustomMuiDataTable
          data={candidatos}
          columnas={columnas()}
          options={{
            onViewColumnsChange: (changedColumn, action) => {
              columnasView.push({
                name: changedColumn,
                action: action,
              });
            },
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
              candidatos.forEach((candidato) => {
                candidato["seleccionado"] = false;
              });

              allRowsSelected.forEach((row) => {
                let index = row.index;
                candidato[index]["seleccionado"] = true;
              });
            },
          }}
        />
      )}
      {totalSeleccionados() > 0 && (
        <CustomFabGroup
          listaBotones={[
            {
              icon: "email-send-outline",
              label: "Enviar Invitacion",
              onPress: () => setEnvioVisible(true),
            },
          ]}
          visible={candidatos.length > 0}
        />
      )}
      {totalSeleccionados() > 0 && (
        <Badge
          style={{
            position: "absolute",
            margin: 20,
            right: 15,
            top: windowHeight - 100,
          }}
        >
          {totalSeleccionados()}
        </Badge>
      )}
      <EnvioMasivoDialogo
        user={user}
        visible={envioVisible}
        closeModal={() => setEnvioVisible(false)}
        aceptarModal={(proceso) => envioMailMasivo(proceso)}
      />
      <LoadingModal visible={loadingVisible} mensaje={mensajeLoading} />
      <Alerta
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          setAlertaVisible(false);
          desseleccionaTodos();
          setActualizar(!actualizar);
        }}
        closeModal={() => setAlertaVisible(false)}
      />
    </View>
  );
}
