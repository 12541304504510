import { REACT_APP_URL_BASE_API_USUARIO } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

const obtieneToken = (user, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      password: password,
      user: user,
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(REACT_APP_URL_BASE_API_USUARIO + "authenticate", requestOptions)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.codeResponse === 200) {
          respuesta.codigoRespuesta = 0;
          respuesta.mensaje = "Usuario obtenido con exito";
          respuesta.entidad["token"] = json.token;
          respuesta.entidad["imagen"] = json.imagen;
        } else {
          respuesta.codigoRespuesta = json.codeResponse;
          switch (json.codeResponse) {
            case 103:
              respuesta.mensaje = "Usuario sin permisos";
              break;
            case 104:
              respuesta.mensaje = "Usuario sin funcionalidades";
              break;
            case 105:
              respuesta.mensaje = "Usuario sin roles";
              break;
            case 106:
            case 101:
              respuesta.mensaje = "Usuario y/o Contraseña incorrecta";
              break;
            case 109:
              respuesta.mensaje = "Usuario logueado sin roles";
              break;
          }
        }
        resolve();
      });
  });
};

const obtieneRoles = (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "*/*",
      Authorization: token,
    },
  };
  return new Promise((resolve, reject) => {
    fetch(REACT_APP_URL_BASE_API_USUARIO + "roles", requestOptions)
      .then((resp) => resp.json())
      .then((json) => {
        respuesta.entidad["rol"] = json.listRoles[0];
        resolve();
      });
  });
};

export default {
  autenticate: (user, password) => {
    return new Promise((resolve, reject) => {
      respuesta.entidad["user"] = user;
      obtieneToken(user, password).then(() => {
        if (respuesta.codigoRespuesta === 0) {
          obtieneRoles(respuesta.entidad.token).then(() => resolve(respuesta));
        } else {
          respuesta["mensaje"] = "Usuario o password incorrecto.";
          resolve(respuesta);
        }
      });
    });
  },
  listaUsuariosRol: (token, idRol) => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_USUARIO}user/${idRol}`,
        requestOptions
      ).then((resp) => resolve(resp.json()));
    });
  },
  tieneRol: (user, ids = []) => {
    let tieneRol = false;
    ids.forEach((id) => {
      if (user.rol.id === id || id === 0) {
        tieneRol = true;
      }
    });
    return tieneRol;
  },
  obtieneUsuarios: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_USUARIO}user`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = 200;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
  creaUsuario: (token, user) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_USUARIO}user`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listUsuario;
          resolve(respuesta);
        });
    });
  },
  actualizaUsuario: (token, user) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_USUARIO}user`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listUsuario;
          resolve(respuesta);
        });
    });
  },
  eliminaUsuario: (token, idProceso) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_USUARIO}user/${idProceso}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.empresaTO;
          resolve(respuesta);
        });
    });
  },
  obtieneRoles: (token) => {
    let respuesta = {
      codigoRespuesta: null,
      mensaje: null,
      entidad: {},
    };
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_USUARIO}roles/all`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listRoles;
          resolve(respuesta);
        });
    });
  },
};
