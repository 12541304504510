export default {
  blanco: "#FFFFFF",
  negro: "#000000",
  azulOscuro: "#033260",
  azulRey: "#0a5dc1",
  grisAzulado: "#839FB2",
  anaranjado: "#F47C06",
  anaranjadoDisabled: "#e1aa74",
  rojoAlerta: "#fb4456",
  rojo: "#FF0000",
  verde: "#008000",
  amarillo: "#FFFF00",
  gris: "#888888",
};
