import {
  REACT_APP_URL_BASE_API_POSTULANTE,
  REACT_APP_URL_BASE_APP_POSTULANTE,
} from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  resultadoReferido: null,
  entidad: {},
};

export default {
  obtieneUrl: (uuid) => {
    return `${REACT_APP_URL_BASE_APP_POSTULANTE}?uuIdProceso=${uuid}#paso_1`;
  },

  actualizarPostulante: (token, postulanteForm, documentos) => {
    let postulante = new FormData();

    postulanteForm["archivos"] = [];
    for (const index in documentos) {
      if (documentos[index].curriculum === 1) {
        postulanteForm["archivos"].push({
          idDocumento: documentos[index].idDocumento,
          name: documentos[index].name,
        });
      }
    }
    postulante.append(
      "postulante",
      new Blob([JSON.stringify(postulanteForm)], { type: "application/json" })
    );

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "*",
      },
      body: postulante,
      redirect: "follow",
    };

    return new Promise((resolve, reject) => {
      var url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante`;
      console.log(url);
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          resolve(respuesta);
        });
    });
  },
  obtienePostulanteRut: (token, proceso, rut) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      var url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/rut/${rut}/proceso/${proceso}`;
      console.log(url);
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listPostulante;
          resolve(respuesta);
        });
    });
  },

  obtienePostulanteId: (token, proceso, idPostulante) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/proceso/${proceso}/id/${idPostulante}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.postulante;
          resolve(respuesta);
        });
    });
  },

  obtieneDatosPostulante: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      var url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante`;
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listPostulante;
          resolve(respuesta);
        });
    });
  },
  obtienePostulantesTodos: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      var url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante`;
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listPostulante;
          resolve(respuesta);
        });
    });
  },
  obtienePostulantesProceso: (token, proceso, estado) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      var url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/proceso/${proceso}`;
      if (estado !== 0) {
        url = `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/proceso/${proceso}/estado/${estado}`;
      }
      fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listPostulante;
          resolve(respuesta);
        });
    });
  },
  modificarPtoPostulante: (token, ranking, idPostulante, idProceso, etapa) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
      },
    };

    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}evaluacion/${idProceso}/postulante/${idPostulante}/etapa/${etapa}/evaluacion/${ranking}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  asignarPsicologo: (token, idProceso, idPsicologo, idsPostulantes) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idPsicologo: idPsicologo,
        idsPostulantes: idsPostulantes,
      }),
    };

    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}test/asignar/psicologo/${idProceso}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  agregarComentario: (user, idPostulante, idProceso, comentario) => {
    var d = new Date();
    var mm = d.getMonth() + 1; // getMonth() is zero-based
    var dd = d.getDate();
    var hh = d.getHours();
    var mi = d.getMinutes();
    var ss = d.getSeconds();

    let dateString = [
      [
        d.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("/"),
      " ",
      [
        (hh > 9 ? "" : "0") + hh,
        (mi > 9 ? "" : "0") + mi,
        (ss > 9 ? "" : "0") + ss,
      ].join(":"),
    ].join("");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comentario: comentario,
        fechaCreacion: dateString,
        idPostulante: idPostulante,
        idProceso: idProceso,
        nombreUsuario: user.user,
      }),
    };

    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/comentario`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  listaComentariosPostulante: (token, idPostulante, idProceso) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };

    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/comentario/${idProceso}/${idPostulante}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  agregarResultadoPanel: (token, datosPanel) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosPanel),
    };

    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_POSTULANTE}informepanel`, requestOptions)
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  cambioEstadoPostulante: (token, idProceso, idPostulante, estado) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/cambioestado/${idProceso}/postulante/${idPostulante}/estado/${estado}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  obtieneListaTest: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_POSTULANTE}test/getAll`, requestOptions)
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  enviarTest: (token, idPostulante, idProceso, idTest) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}test/${idProceso}/postulante/${idPostulante}/test/${idTest}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  reenviarCorreo: (token, idProceso, idPostulante) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}test/reenviomail/${idProceso}/idPostulante/${idPostulante}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  obtieneResultadoPanel: (token, idProceso, idPostulante) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}informepanel/${idProceso}/${idPostulante}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
  obtieneCompetencias: (token, idProceso, idPostulante) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}evaluacion/competenciasCargoPostunate/${idProceso}/${idPostulante}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listaCompetencias;
          resolve(respuesta);
        });
    });
  },
  grabaEvaluacionCompetencia: (token, data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}evaluacion/guardarEnvaluacionCompetencia`,
        requestOptions
      )
        .then((response) => response.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          resolve(respuesta);
        });
    });
  },
  guardarReferido: (token, idProceso, idPostulante) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/referido/${idPostulante}/${idProceso}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.resultadoReferido = resp.resultadoReferido;
          resolve(respuesta);
        });
    });
  },
  seleccionaPostulante: (token, idProceso, idPostulante) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/seleccion/${idPostulante}/${idProceso}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.resultadoSeleccionado = resp.resultadoReferido;
          resolve(respuesta);
        });
    });
  },
  envioMasivoMail: (token, datos) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}postulante/enviomailmasivo`,
        requestOptions
      )
        .then((response) => response.json())
        .then((json) => resolve(json));
    });
  },
};
