import React from "react";
import { View } from "react-native";
import { IconButton, List } from "react-native-paper";
import CustomFabGroup from "../../components/CustomFabGroup";
import CustomMuiDataTable from "../../components/CustomMuiDataTable";
import CustomSwitch from "../../components/CustomSwitch";
import LoadingIndicator from "../../components/LoadingIndicator";
import CargoService from "../../services/CargoService";
import EmpresaService from "../../services/EmpresaService";
import Alerta from "../modal/Alerta";

export default function MantenedorCargo({ navigation, route, user, onUpdate }) {
  const [empresas, setEmpresas] = React.useState([]);
  const [cargos, setCargos] = React.useState([]);
  const [cargo, setCargo] = React.useState();
  const [funcion] = React.useState({
    aceptaModal: () => {},
  });
  const [confirmacion, setConfirmacion] = React.useState(false);
  const [tipoAlerta, setTipoAlerta] = React.useState("info");
  const [mensaje, setMensaje] = React.useState("Prueba");
  const [alertaVisible, setAlertaVisible] = React.useState(false);

  const obtieneEmpresas = () => {
    EmpresaService.obtieneEmpresasTodas(user.token).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          setEmpresas(respuesta.entidad);
        }
      }
    });
  };

  const obtieneCargos = () => {
    CargoService.obtieneCargosTodosv2(user.token).then(async (respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        if (respuesta.entidad.length > 0) {
          await respuesta.entidad.forEach((cargo) => {
            cargo["empresaNombre"] = cargo.empresa.nombre;
          });
          setCargos(respuesta.entidad);
        }
      }
    });
  };

  const eliminaCargo = (cargo, index) => {
    setCargo(undefined);
    CargoService.eliminaCargo(user.token, cargo.idCargo).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Cargo ${cargo.nombre} eliminada exitosamente.`);
        let listaCargos = cargos.filter((cargoItem) => {
          return cargoItem.idCargo !== cargo.idCargo;
        });
        setCargos(listaCargos);
        onUpdate();
      } else {
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al eliminar la cargo ${cargo.nombre}.`
        );
      }
      setAlertaVisible(true);
    });
  };

  const actualizaCargo = (cargo) => {
    setCargo(undefined);
    cargo.activa = !cargo.activa;
    CargoService.actualizaCargo(user.token, cargo).then((respuesta) => {
      if (respuesta.codigoRespuesta === 200) {
        setTipoAlerta("info");
        setMensaje(`Cargo ${cargo.nombre} actualizada exitosamente.`);
        onUpdate();
      } else {
        cargo.activa = !cargo.activa;
        setTipoAlerta("error");
        setMensaje(
          `Se ha producido un error al actualizar la cargo ${cargo.nombre}`
        );
      }
      setConfirmacion(false);
      setAlertaVisible(true);
    });
  };

  const columnas = [
    {
      name: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return dataIndex + 1;
        },
      },
    },
    {
      label: "Nombre Empresa",
      name: "empresaNombre",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Empresa: ${v}`,
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre Cargo",
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `Cargo: ${v}`,
        },
      },
    },
    {
      name: "listaCompetencias",
      label: "Competencias",
      options: {
        filter: false,
        customFilterListOptions: {
          render: (v) => `Competencia: ${v}`,
        },
        customBodyRenderLite: (dataIndex) => {
          let cargo = cargos[dataIndex];
          return (
            <div>
              {cargo.listaCompetencias !== null &&
                cargo.listaCompetencias.map((competencia, index) => {
                  return (
                    <List.Item
                      style={{ padding: 0 }}
                      key={index}
                      title={competencia.nombreCompetencia}
                    />
                  );
                })}
            </div>
          );
        },
      },
    },
    {
      name: "activa",
      label: "Activa",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: ["Activada", "Desactivada"],
          logic: (location, filters, row) => {
            if (filters[0]) {
              return row[4] !== (filters[0] === "Activada");
            }
            return false;
          },
        },
        customFilterListOptions: {
          render: (v) => `Estado: ${v}`,
        },
        customBodyRenderLite: (dataIndex) => {
          let cargo = cargos[dataIndex];
          return (
            <CustomSwitch
              onSwitch={() => {
                setTipoAlerta("warn");
                setMensaje(
                  `¿Desea ${
                    cargo.activa ? "Desactivar" : "Acticar"
                  } la cargo: ${cargo.nombre}?`
                );
                setCargo(cargo);
                funcion.aceptaModal = actualizaCargo;
                setConfirmacion(true);
                setAlertaVisible(true);
              }}
              estado={cargo.activa}
              texto={cargo.activa ? "Activada" : "Desactivada"}
            />
          );
        },
      },
    },
    {
      name: "Acción",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let cargo = cargos[dataIndex];
          return (
            <View style={{ flexDirection: "row" }}>
              <IconButton
                icon="pencil"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  cargo["recargar"] = true;
                  navigation.navigate("Cargo", {
                    cargo: cargo,
                    empresas: empresas,
                  });
                }}
              />
              <IconButton
                icon="delete"
                color={"#545454"}
                size={20}
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setTipoAlerta("warn");
                  setMensaje(`¿Desea eliminar la cargo: ${cargo.nombre}?`);
                  setCargo(cargo);
                  funcion.aceptaModal = eliminaCargo;
                  setConfirmacion(true);
                  setAlertaVisible(true);
                }}
              />
            </View>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    if (empresas.length === 0) {
      obtieneEmpresas();
    }
    if (cargos.length === 0) {
      obtieneCargos();
    }
    if (route.params?.cargo) {
      obtieneCargos();
    }
  }, [route.params?.cargo]);

  return (
    <View>
      {cargos.length === 0 && <LoadingIndicator texto={"Cargando Cargos"} />}
      {cargos.length > 0 && (
        <CustomMuiDataTable data={cargos} columnas={columnas} />
      )}
      <CustomFabGroup
        visible={cargos.length > 0}
        listaBotones={[
          {
            icon: "plus",
            label: "Nuevo cargo",
            onPress: () => {
              navigation.navigate("Cargo", {
                cargo: { idCargo: -1, recargar: true },
                empresas: empresas,
              });
            },
          },
          {
            icon: "refresh",
            label: "Recargar cargos",
            onPress: () => {
              obtieneEmpresas();
            },
          },
        ]}
      />
      <Alerta
        confirmacionModal={confirmacion}
        tipoAlerta={tipoAlerta}
        mensaje={mensaje}
        visible={alertaVisible}
        aceptarButton={() => {
          if (cargo !== undefined) {
            funcion.aceptaModal(cargo);
          }
        }}
        closeModal={() => {
          setAlertaVisible(false);
        }}
      />
    </View>
  );
}
