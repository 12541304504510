import React from "react";
import { View } from "react-native";
import { Colors, FAB, IconButton } from "react-native-paper";
import '../styles/fab-group.css'

export default function CustomFabGroup({ listaBotones = [], visible = true }) {
  const [state, setState] = React.useState({ open: false });
  const { open } = state;

  const onStateChange = ({ open }) => {
    setState({ open });
  };

  const obtieneBotones = () => {
    let listAux = [];
    listaBotones.forEach((boton, index) => {
      listAux.push({
        icon: boton.icon,
        label: boton.label,
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => boton.onPress(),
        testID: `label-fab-group-${index}`,
      });
    });
    return listAux;
  };

  return (
    <View nativeID="fab-container" style={{ position: "initial" }}>
      <FAB.Group
        testID="fab-test"
        visible={visible}
        style={{ right: 0, top: 0, paddingBottom: 45, paddingRight: 20 }}
        open={open}
        icon={open ? "close" : "plus"}
        color={Colors.white}
        fabStyle={{ backgroundColor: "#f47c06" }}
        actions={obtieneBotones()}
        onStateChange={onStateChange}
      />
    </View>
  );
}
