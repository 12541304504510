import { REACT_APP_URL_BASE_API_EMPRESA } from "@env";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

export default {
  obtieneProcesoUuid: (token, uuid) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}proceso/${uuid}`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.proceso;
          resolve(respuesta);
        });
    });
  },
  obtieneProcesosTodos: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/getProcesos/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listProcesoTO;
          resolve(respuesta);
        });
    });
  },
  obtieneProcesosTodosV2: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}proceso/todos`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listProcesoTO;
          resolve(respuesta);
        });
    });
  },
  obtieneProcesos: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/getProcesos/activos/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listProcesoTO;
          resolve(respuesta);
        });
    });
  },
  obtieneProcesosHistoricos: (token, idEmpresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/getProcesos/historicos/${idEmpresa}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.listProcesoTO;
          resolve(respuesta);
        });
    });
  },
  obtienePreguntasProceso: (token, cargo) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}cargo/get/${cargo}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  actualizaEstadoPregunta: (token, idCargo, idFormulario, habilita) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        activa: habilita,
        idCargo: idCargo,
        idFormulario: idFormulario,
      }),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}formularioEmpresa`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
  creaProceso: (token, proceso) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(proceso),
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}proceso`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.message;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  actualizaProceso: (token, proceso) => {
    console.log(JSON.stringify(proceso));
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(proceso),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/${proceso.idProceso}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.message;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  eliminaProceso: (token, proceso) => {
    console.log(JSON.stringify(proceso));
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(proceso),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/${proceso}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.message;
          respuesta.entidad = resp.listCargo;
          resolve(respuesta);
        });
    });
  },
  simulaFechas: (token, empresa) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/simular/${empresa.id}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.message;
          respuesta.entidad = resp.simular;
          resolve(respuesta);
        });
    });
  },
  reporte: (token) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_URL_BASE_API_EMPRESA}proceso/reporte`, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        });
    });
  },
  crearComentario: (token, comentario) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comentario),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/comentario`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        });
    });
  },
  actualizarComentario: (token, comentario) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comentario),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_EMPRESA}proceso/comentario`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp);
        });
    });
  },
};
