import React from "react";
import { Dimensions, View } from "react-native";
import { Button, Dialog, List } from "react-native-paper";
import BotonesEmpresaCargo from "../components/BotonesEmpresaCargo";
import TablaPreguntasCargo from "../components/TablaPreguntasCargo";
import TablaPregunstasEmpresa from "../components/TablaPreguntasEmpresa";
import EmpresaService from "../services/EmpresaService";
import ProcesosService from "../services/ProcesosService";
import styles from "../styles/style";

const windowHeight = Dimensions.get("window").height;

export default function PreguntasEmpresaCargo({
  navigation,
  user,
  empresas,
  cargos,
  setCargos,
  empresa,
  setEmpresa,
  cargo,
  setCargo,
}) {
  const [listaPreguntasEmpresa, setListaPreguntasEmpresa] = React.useState([]);
  const [listaPreguntasCargo, setListaPreguntasCargo] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [opcionesModal] = React.useState({
    dismissable: true,
    tituloModal: null,
    contenidoModal: () => {},
    botonModal: () => {},
  });

  const closeModal = () => {
    setVisible(false);
    setActualizar(!actualizar);
  };

  const openModal = (opciones = undefined) => {
    if (opciones !== undefined) {
      if (opciones.tituloModal !== undefined) {
        opcionesModal.tituloModal = opciones.tituloModal;
      }
      if (opciones.contenidoModal !== undefined) {
        opcionesModal.contenidoModal = opciones.contenidoModal;
      }
      if (opciones.botonModal !== undefined) {
        opcionesModal.botonModal = opciones.botonModal;
      }
      if (opciones.dismissable !== undefined) {
        opcionesModal.dismissable = opciones.dismissable;
      }
    }
    setVisible(true);
    setActualizar(!actualizar);
  };

  const selectEmpresa = (empresa) => {
    setEmpresa(empresa);
    setCargo({ cargoProceso: "Seleccione Cargo / Proceso", idCargo: -1 });
    obtienePreguntasEmpresa(empresa);
    obtieneCargos(empresa);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const selectCargo = (cargo) => {
    setCargo(cargo);
    obtienePreguntasProceso(cargo);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const obtieneCargos = (empresa) => {
    ProcesosService.obtieneProcesos(user.token, empresa.id).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          setCargos(respuesta.entidad);
          setActualizar(!actualizar);
        }
      }
    );
  };

  const obtienePreguntasEmpresa = (empresa) => {
    EmpresaService.obtienePreguntasEmpresa(user.token, empresa.id).then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setListaPreguntasEmpresa(resp.entidad == null ? [] : resp.entidad);
        }
      }
    );
  };

  const obtienePreguntasProceso = (cargo) => {
    ProcesosService.obtienePreguntasProceso(user.token, cargo.idCargo).then(
      (resp) => {
        if (resp.codigoRespuesta === 200) {
          setListaPreguntasCargo(
            resp.entidad[0].listPreguntas == null
              ? []
              : resp.entidad[0].listPreguntas
          );
        }
      }
    );
  };

  const alertaModal = (tipoAlerta, mensaje) => {
    let icon;
    switch (tipoAlerta) {
      case "info":
        icon = {
          name: "check-circle-outline",
          color: "#44B42E",
        };
        break;
      case "warn":
        icon = {
          name: "information-outline",
          color: "#D8B62A",
        };
        break;
      case "error":
        icon = {
          name: "close-circle-outline",
          color: "#a53737",
        };
        break;
    }
    openModal({
      tituloModal: "",
      contenidoModal: () => (
        <List.Item
          titleNumberOfLines={2}
          title={mensaje}
          left={() => (
            <List.Icon
              icon={icon.name}
              color={icon.color}
              style={{ transform: [{ scale: 3 }], marginRight: 30 }}
            />
          )}
        />
      ),
      botonModal: () => <Button onPress={() => closeModal()}>Aceptar</Button>,
    });
  };

  React.useEffect(() => {
    if (empresa.id !== -1 && listaPreguntasEmpresa.length === 0) {
      obtienePreguntasEmpresa(empresa);
    }
    if (cargo.idProceso !== -1 && listaPreguntasCargo.length === 0) {
      obtienePreguntasProceso(cargo);
    }
  }, []);

  return (
    <View>
      <BotonesEmpresaCargo
        empresas={empresas}
        empresa={empresa}
        cargos={cargos}
        cargo={cargo}
        selectEmpresa={selectEmpresa}
        selectCargo={selectCargo}
      />
      <TablaPregunstasEmpresa
        heigth={(windowHeight - 95) / 2}
        preguntas={listaPreguntasEmpresa}
        empresa={empresa}
        alertaModal={alertaModal}
        user={user}
      />
      <TablaPreguntasCargo
        heigth={(windowHeight - 95) / 2}
        preguntas={listaPreguntasCargo}
        cargo={cargo}
        alertaModal={alertaModal}
        user={user}
      />
      <Dialog
        visible={visible}
        dismissable={opcionesModal.dismissable}
        onDismiss={closeModal}
        style={styles.modal}
      >
        <Dialog.Title>{opcionesModal.tituloModal}</Dialog.Title>
        <Dialog.Content>{opcionesModal.contenidoModal({})}</Dialog.Content>
        <Dialog.Actions>{opcionesModal.botonModal(true)}</Dialog.Actions>
      </Dialog>
    </View>
  );
}
