import React from "react";
import { View } from "react-native";
import { Switch, Text } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";

export default function CustomSwitch({
  onSwitch = () => {},
  estado = false,
  disabled = false, 
  texto,
}) {
  const [isSwitchOn, setIsSwitchOn] = React.useState(estado);
  const onToggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
    onSwitch(!isSwitchOn);
  };
  React.useEffect(()=>{
    if(estado !== isSwitchOn){
      setIsSwitchOn(estado);
    }
  },[isSwitchOn, estado]);
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        width: 135,
        alignItems: "center",
        marginVertical: 20,
      }}
    >
      <Switch
        trackColor={{
          true: ColoresPineal.anaranjado,
          false: ColoresPineal.grisAzulado,
        }}
        disabled={disabled}
        thumbColor={ColoresPineal.gris}
        color={ColoresPineal.anaranjadoDisabled}
        value={isSwitchOn}
        onValueChange={onToggleSwitch}
      />
      {texto !== undefined && <Text style={{marginLeft: 10}}>{texto}</Text>}
    </View>
  );
}
