import React from "react";
import { View, ScrollView, ActivityIndicator, Dimensions } from "react-native";
import { Text, Switch, Button, FAB, Colors } from "react-native-paper";
import Header from "./Header";
import styles from "../styles/style";
import { Chart } from "react-google-charts";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import XLSX from "xlsx";
import ReportesService from "../services/ReportesService";
import { TableFooter, TableRow, TableCell } from "@material-ui/core";
import MuiTablePagination from "@material-ui/core/TablePagination";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import { Grid, Select, MenuItem, InputLabel } from "@material-ui/core";

const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

export const options00 = {
  chart: {
    title: "postulantes por estado de procesos",
  },
  title: "Estado de proceso",
  fontSize: 15,
  bold: true,
  colors: ["#0a5dc1"],
  legend: { position: "top", fontSize: 15, bold: true },
};
export const options01 = {
  title: "Género",
  fontSize: 15,
  bold: true,
  legend: { position: "top", bold: true },
  pieSliceTextStyle: {
    color: "black",
    fontSize: 15,
  },
  slices: {
    0: { color: "#f6a623" },
    1: { color: "#fbdfb1" },
    2: { color: "#dcdcdc", offset: 0.1 },
  },
};
export const options02 = {
  chart: {
    title: "postulantes por rango de edades",
  },

  hAxis: {
    title: "Rango de edad",
    fontSize: 15,
    bold: true,
  },
  vAxis: {
    title: "Postulantes",
    fontSize: 15,
    bold: true,
  },
  series: {
    0: { curveType: "function", color: "#0a5dc1" },
  },
  legend: { position: "top", fontSize: 15, bold: true },
};
export const options02xx = {
  colors: ["#f6a623", "#fbdfb1", "#dcdcdc"],
  chart: {
    title: "postulantes por rango de edades por género",
  },

  hAxis: {
    title: "Rango de edad",
    fontSize: 15,
    bold: true,
  },
  vAxis: {
    title: "Postulantes",
    fontSize: 15,
    bold: true,
  },
  legend: { position: "top", fontSize: 15, bold: true },
};
export const options02x = {
  isStacked: true,
  colors: ["#f6a623", "#fbdfb1", "#dcdcdc"],
  chart: {
    title: "Estado de procesos por género",
  },
};

export const options03 = {
  title: "Región",
  fontSize: 15,
  bold: true,
  colors: ["#f6a623"],
  legend: {
    position: "top",
    fontSize: 15,
    bold: true,
    backgroundColor: "#f2f2f2",
  },
  bars: "horizontal",
};
export const options04 = {
  title: "Ciudad",
  fontSize: 15,
  bold: true,
  backgroundColor: "#f2f2f2",

  colors: ["#0a5dc1"],
  bars: "horizontal",
  legend: { position: "top", fontSize: 15, bold: true },
};

export default function CandidatosReportes({ navigation, user }) {
  const [candidatos, setCandidatos] = React.useState([]);
  const [data] = React.useState({});
  const [actualizar, setActualizar] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [columnasView] = React.useState({});
  const [columnasNombre] = React.useState({});
  const [datos, setDatos] = React.useState();
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);

  const datoColumna = (parametro, nombreColumna, opciones = {}) => {
    let mostrarColumna =
      columnasView[parametro] !== undefined ? columnasView[parametro] : false;
    columnasNombre[parametro] = nombreColumna;
    return {
      label: nombreColumna,
      name: parametro,
      options: {
        filter: true,
        sort: true,
        display: mostrarColumna,
        customFilterListOptions: {
          render: (v) => `${nombreColumna}: ${v}`,
        },
        ...opciones,
      },
    };
  };

  const columnas = () => {
    let columnas = [
      datoColumna("empresa", "Empresa del Proceso"),
      datoColumna("cargo", "Cargo del Proceso"),
      datoColumna("estado", "Estado del Proceso"),
      datoColumna("nombre", "Nombre"),
      datoColumna("appaterno", "Apellido Paterno"),
      datoColumna("apmaterno", "Apellido Materno"),
      datoColumna("rut", "R. U. T."),
      datoColumna("email", "E-Mail"),
      datoColumna("genero", "Género"),
      datoColumna("ecivil", "Estado Civil"),
      datoColumna("edad", "Edad"),
      datoColumna("region", "Región"),
      datoColumna("ciudad", "Ciudad"),
    ];
    return columnas;
  };
  const obtieneData = () => {
    let values = {};
    let mapRut = {};

    data["xlsx"] = [];
    data["candidatos"].forEach((candidato) => {
      let llave = "";
      let dato = {};
      if (mapRut[candidato["rut"]] === undefined) {
        mapRut[candidato["rut"]] = candidato;
        for (const property in columnasView) {
          if (llave.length > 0) {
            llave = llave + " - ";
          }
          llave = llave + candidato[property];
          dato[columnasNombre[property]] = candidato[property];
        }
        data["xlsx"].push(dato);
        let valor = values[llave];
        if (valor === undefined) {
          values[llave] = 1;
        } else {
          values[llave] = valor + 1;
        }
      }
    });

    return values;
  };
  //********************* */
  const paragrafico = () => {
    let values = {};
    let masculino = 0;
    let femenino = 0;
    let D20 = 0;
    let D30 = 0;
    let D40 = 0;
    let D50 = 0;
    let D60 = 0;
    let D70 = 0;
    let D100 = 0;
    let otro = 0;
    let v1 = 0;
    let v2 = 0;
    let v3 = 0;
    let v4 = 0;
    let v5 = 0;

    let vm1 = 0;
    let vf1 = 0;
    let vo1 = 0;
    let vm2 = 0;
    let vf2 = 0;
    let vo2 = 0;
    let vm3 = 0;
    let vf3 = 0;
    let vo3 = 0;
    let vm4 = 0;
    let vf4 = 0;
    let vo4 = 0;
    let vm5 = 0;
    let vf5 = 0;
    let vo5 = 0;

    let D20m = 0;
    let D20f = 0;
    let D20o = 0;

    let D30m = 0;
    let D30f = 0;
    let D30o = 0;

    let D40m = 0;
    let D40f = 0;
    let D40o = 0;

    let D50m = 0;
    let D50f = 0;
    let D50o = 0;

    let D60m = 0;
    let D60f = 0;
    let D60o = 0;

    let D70m = 0;
    let D70f = 0;
    let D70o = 0;

    let D100m = 0;
    let D100f = 0;
    let D100o = 0;

    let cuenta1 = 0;
    let cuenta2 = 0;
    const region = [
      { region: "Arica y Parinacota", valor: 0 },
      { region: "Tarapacá", valor: 0 },
      { region: "Antofagasta", valor: 0 },
      { region: "Atacama", valor: 0 },
      { region: "Coquimbo", valor: 0 },
      { region: "Valparaíso", valor: 0 },
      { region: "Metropolitana de Santiago", valor: 0 },
      { region: "Del Libertador Gral. Bernardo O’Higgins", valor: 0 },
      { region: "Del Maule", valor: 0 },
      { region: "Del Biobío", valor: 0 },
      { region: "De la Araucanía", valor: 0 },
      { region: "De los Ríos", valor: 0 },
      { region: "De los Lagos", valor: 0 },
      { region: "Aysén del Gral. Carlos Ibáñez del Campo", valor: 0 },
      { region: "Magallanes y de la Antártica Chilena", valor: 0 },
      { region: "Ñuble", valor: 0 },
      { region: null, valor: 0 },
    ];
    const ciudad = [
      { ciudad: "Arica", valor: 0 },
      { ciudad: "Camarones", valor: 0 },
      { ciudad: "General Lagos", valor: 0 },
      { ciudad: "Putre", valor: 0 },
      { ciudad: "Alto Hospicio", valor: 0 },
      { ciudad: "Camiña", valor: 0 },
      { ciudad: "Colchane", valor: 0 },
      { ciudad: "Huara", valor: 0 },
      { ciudad: "Iquique", valor: 0 },
      { ciudad: "Pica", valor: 0 },
      { ciudad: "Pozo Almonte", valor: 0 },
      { ciudad: "Antofagasta", valor: 0 },
      { ciudad: "Calama", valor: 0 },
      { ciudad: "María Elena", valor: 0 },
      { ciudad: "Mejillones", valor: 0 },
      { ciudad: "Ollagüe", valor: 0 },
      { ciudad: "San Pedro de Atacama", valor: 0 },
      { ciudad: "Sierra Gorda", valor: 0 },
      { ciudad: "Taltal", valor: 0 },
      { ciudad: "Tocopilla", valor: 0 },
      { ciudad: "Alto del Carmen", valor: 0 },
      { ciudad: "Caldera", valor: 0 },
      { ciudad: "Chañaral", valor: 0 },
      { ciudad: "Copiapó", valor: 0 },
      { ciudad: "Diego de Almagro", valor: 0 },
      { ciudad: "Freirina", valor: 0 },
      { ciudad: "Huasco", valor: 0 },
      { ciudad: "Tierra Amarilla", valor: 0 },
      { ciudad: "Vallenar", valor: 0 },
      { ciudad: "Andacollo", valor: 0 },
      { ciudad: "Canela", valor: 0 },
      { ciudad: "Combarbalá", valor: 0 },
      { ciudad: "Coquimbo", valor: 0 },
      { ciudad: "Illapel", valor: 0 },
      { ciudad: "La Higuera", valor: 0 },
      { ciudad: "La Serena", valor: 0 },
      { ciudad: "Los Vilos", valor: 0 },
      { ciudad: "Monte Patria", valor: 0 },
      { ciudad: "Ovalle", valor: 0 },
      { ciudad: "Paiguano", valor: 0 },
      { ciudad: "Punitaqui", valor: 0 },
      { ciudad: "Río Hurtado", valor: 0 },
      { ciudad: "Salamanca", valor: 0 },
      { ciudad: "Vicuña", valor: 0 },
      { ciudad: "Algarrobo", valor: 0 },
      { ciudad: "Cabildo", valor: 0 },
      { ciudad: "Calera", valor: 0 },
      { ciudad: "Calle Larga", valor: 0 },
      { ciudad: "Cartagena", valor: 0 },
      { ciudad: "Casablanca", valor: 0 },
      { ciudad: "Catemu", valor: 0 },
      { ciudad: "Concón", valor: 0 },
      { ciudad: "El Quisco", valor: 0 },
      { ciudad: "El Tabo", valor: 0 },
      { ciudad: "Hijuelas", valor: 0 },
      { ciudad: "Isla de Pascua", valor: 0 },
      { ciudad: "Juan Fernández", valor: 0 },
      { ciudad: "La Cruz", valor: 0 },
      { ciudad: "La Ligua", valor: 0 },
      { ciudad: "Limache", valor: 0 },
      { ciudad: "Llaillay", valor: 0 },
      { ciudad: "Los Andes", valor: 0 },
      { ciudad: "Nogales", valor: 0 },
      { ciudad: "Olmué", valor: 0 },
      { ciudad: "Panquehue", valor: 0 },
      { ciudad: "Papudo", valor: 0 },
      { ciudad: "Petorca", valor: 0 },
      { ciudad: "Puchuncaví", valor: 0 },
      { ciudad: "Putaendo", valor: 0 },
      { ciudad: "Quillota", valor: 0 },
      { ciudad: "Quilpué", valor: 0 },
      { ciudad: "Quintero", valor: 0 },
      { ciudad: "Rinconada", valor: 0 },
      { ciudad: "San Antonio", valor: 0 },
      { ciudad: "San Esteban", valor: 0 },
      { ciudad: "San Felipe", valor: 0 },
      { ciudad: "Santa María", valor: 0 },
      { ciudad: "Santo Domingo", valor: 0 },
      { ciudad: "Valparaíso", valor: 0 },
      { ciudad: "Villa Alemana", valor: 0 },
      { ciudad: "Viña del Mar", valor: 0 },
      { ciudad: "Zapallar", valor: 0 },
      { ciudad: "Alhué", valor: 0 },
      { ciudad: "Buin", valor: 0 },
      { ciudad: "Calera de Tango", valor: 0 },
      { ciudad: "Cerrillos", valor: 0 },
      { ciudad: "Cerro Navia", valor: 0 },
      { ciudad: "Colina", valor: 0 },
      { ciudad: "Conchalí", valor: 0 },
      { ciudad: "Curacaví", valor: 0 },
      { ciudad: "El Bosque", valor: 0 },
      { ciudad: "El Monte", valor: 0 },
      { ciudad: "Estación Central", valor: 0 },
      { ciudad: "Huechuraba", valor: 0 },
      { ciudad: "Independencia", valor: 0 },
      { ciudad: "Isla de Maipo", valor: 0 },
      { ciudad: "La Cisterna", valor: 0 },
      { ciudad: "La Florida", valor: 0 },
      { ciudad: "La Granja", valor: 0 },
      { ciudad: "La Pintana", valor: 0 },
      { ciudad: "La Reina", valor: 0 },
      { ciudad: "Lampa", valor: 0 },
      { ciudad: "Las Condes", valor: 0 },
      { ciudad: "Lo Barnechea", valor: 0 },
      { ciudad: "Lo Espejo", valor: 0 },
      { ciudad: "Lo Prado", valor: 0 },
      { ciudad: "Macul", valor: 0 },
      { ciudad: "Maipú", valor: 0 },
      { ciudad: "María Pinto", valor: 0 },
      { ciudad: "Melipilla", valor: 0 },
      { ciudad: "Ñuñoa", valor: 0 },
      { ciudad: "Padre Hurtado", valor: 0 },
      { ciudad: "Paine", valor: 0 },
      { ciudad: "Pedro Aguirre Cerda", valor: 0 },
      { ciudad: "Peñaflor", valor: 0 },
      { ciudad: "Peñalolén", valor: 0 },
      { ciudad: "Pirque", valor: 0 },
      { ciudad: "Providencia", valor: 0 },
      { ciudad: "Pudahuel", valor: 0 },
      { ciudad: "Puente Alto", valor: 0 },
      { ciudad: "Quilicura", valor: 0 },
      { ciudad: "Quinta Normal", valor: 0 },
      { ciudad: "Recoleta", valor: 0 },
      { ciudad: "Renca", valor: 0 },
      { ciudad: "San Bernardo", valor: 0 },
      { ciudad: "San Joaquín", valor: 0 },
      { ciudad: "San José de Maipo", valor: 0 },
      { ciudad: "San Miguel", valor: 0 },
      { ciudad: "San Pedro", valor: 0 },
      { ciudad: "San Ramón", valor: 0 },
      { ciudad: "Santiago Centro", valor: 0 },
      { ciudad: "Talagante", valor: 0 },
      { ciudad: "Tiltil", valor: 0 },
      { ciudad: "Vitacura", valor: 0 },
      { ciudad: "Chépica", valor: 0 },
      { ciudad: "Chimbarongo", valor: 0 },
      { ciudad: "Codegua", valor: 0 },
      { ciudad: "Coinco", valor: 0 },
      { ciudad: "Coltauco", valor: 0 },
      { ciudad: "Doñihue", valor: 0 },
      { ciudad: "Graneros", valor: 0 },
      { ciudad: "La Estrella", valor: 0 },
      { ciudad: "Las Cabras", valor: 0 },
      { ciudad: "Litueche", valor: 0 },
      { ciudad: "Lolol", valor: 0 },
      { ciudad: "Machalí", valor: 0 },
      { ciudad: "Malloa", valor: 0 },
      { ciudad: "Marchihue", valor: 0 },
      { ciudad: "Mostazal", valor: 0 },
      { ciudad: "Nancagua", valor: 0 },
      { ciudad: "Navidad", valor: 0 },
      { ciudad: "Olivar", valor: 0 },
      { ciudad: "Palmilla", valor: 0 },
      { ciudad: "Paredones", valor: 0 },
      { ciudad: "Peralillo", valor: 0 },
      { ciudad: "Peumo", valor: 0 },
      { ciudad: "Pichidegua", valor: 0 },
      { ciudad: "Pichilemu", valor: 0 },
      { ciudad: "Placilla", valor: 0 },
      { ciudad: "Pumanque", valor: 0 },
      { ciudad: "Quinta de Tilcoco", valor: 0 },
      { ciudad: "Rancagua", valor: 0 },
      { ciudad: "Rengo", valor: 0 },
      { ciudad: "Requínoa", valor: 0 },
      { ciudad: "San Fernando", valor: 0 },
      { ciudad: "San Vicente", valor: 0 },
      { ciudad: "Santa Cruz", valor: 0 },
      { ciudad: "Cauquenes", valor: 0 },
      { ciudad: "Chanco", valor: 0 },
      { ciudad: "Colbún", valor: 0 },
      { ciudad: "Constitución", valor: 0 },
      { ciudad: "Curepto", valor: 0 },
      { ciudad: "Curicó", valor: 0 },
      { ciudad: "Empedrado", valor: 0 },
      { ciudad: "Hualañé", valor: 0 },
      { ciudad: "Licantén", valor: 0 },
      { ciudad: "Linares", valor: 0 },
      { ciudad: "Longaví", valor: 0 },
      { ciudad: "Maule", valor: 0 },
      { ciudad: "Molina", valor: 0 },
      { ciudad: "Parral", valor: 0 },
      { ciudad: "Pelarco", valor: 0 },
      { ciudad: "Pelluhue", valor: 0 },
      { ciudad: "Pencahue", valor: 0 },
      { ciudad: "Rauco", valor: 0 },
      { ciudad: "Retiro", valor: 0 },
      { ciudad: "Río Claro", valor: 0 },
      { ciudad: "Romeral", valor: 0 },
      { ciudad: "Sagrada Familia", valor: 0 },
      { ciudad: "San Clemente", valor: 0 },
      { ciudad: "San Javier", valor: 0 },
      { ciudad: "San Rafael", valor: 0 },
      { ciudad: "Talca", valor: 0 },
      { ciudad: "Teno", valor: 0 },
      { ciudad: "Vichuquén", valor: 0 },
      { ciudad: "Villa Alegre", valor: 0 },
      { ciudad: "Yerbas Buenas", valor: 0 },
      { ciudad: "Alto Biobío", valor: 0 },
      { ciudad: "Antuco", valor: 0 },
      { ciudad: "Arauco", valor: 0 },
      { ciudad: "Cabrero", valor: 0 },
      { ciudad: "Cañete", valor: 0 },
      { ciudad: "Chiguayante", valor: 0 },
      { ciudad: "Concepción", valor: 0 },
      { ciudad: "Contulmo", valor: 0 },
      { ciudad: "Coronel", valor: 0 },
      { ciudad: "Curanilahue", valor: 0 },
      { ciudad: "Florida", valor: 0 },
      { ciudad: "Hualpén", valor: 0 },
      { ciudad: "Hualqui", valor: 0 },
      { ciudad: "Laja", valor: 0 },
      { ciudad: "Lebu", valor: 0 },
      { ciudad: "Los Álamos", valor: 0 },
      { ciudad: "Los Ángeles", valor: 0 },
      { ciudad: "Lota", valor: 0 },
      { ciudad: "Mulchén", valor: 0 },
      { ciudad: "Nacimiento", valor: 0 },
      { ciudad: "Negrete", valor: 0 },
      { ciudad: "Penco", valor: 0 },
      { ciudad: "Quilaco", valor: 0 },
      { ciudad: "Quilleco", valor: 0 },
      { ciudad: "San Pedro de la Paz", valor: 0 },
      { ciudad: "San Rosendo", valor: 0 },
      { ciudad: "Santa Bárbara", valor: 0 },
      { ciudad: "Santa Juana", valor: 0 },
      { ciudad: "Talcahuano", valor: 0 },
      { ciudad: "Tirúa", valor: 0 },
      { ciudad: "Tomé", valor: 0 },
      { ciudad: "Tucapel", valor: 0 },
      { ciudad: "Yumbel", valor: 0 },
      { ciudad: "Angol", valor: 0 },
      { ciudad: "Carahue", valor: 0 },
      { ciudad: "Cholchol", valor: 0 },
      { ciudad: "Collipulli", valor: 0 },
      { ciudad: "Cunco", valor: 0 },
      { ciudad: "Curacautín", valor: 0 },
      { ciudad: "Curarrehue", valor: 0 },
      { ciudad: "Ercilla", valor: 0 },
      { ciudad: "Freire", valor: 0 },
      { ciudad: "Galvarino", valor: 0 },
      { ciudad: "Gorbea", valor: 0 },
      { ciudad: "Lautaro", valor: 0 },
      { ciudad: "Loncoche", valor: 0 },
      { ciudad: "Lonquimay", valor: 0 },
      { ciudad: "Los Sauces", valor: 0 },
      { ciudad: "Lumaco", valor: 0 },
      { ciudad: "Melipeuco", valor: 0 },
      { ciudad: "Nueva Imperial", valor: 0 },
      { ciudad: "Padre las Casas", valor: 0 },
      { ciudad: "Perquenco", valor: 0 },
      { ciudad: "Pitrufquén", valor: 0 },
      { ciudad: "Pucón", valor: 0 },
      { ciudad: "Purén", valor: 0 },
      { ciudad: "Renaico", valor: 0 },
      { ciudad: "Saavedra", valor: 0 },
      { ciudad: "Temuco", valor: 0 },
      { ciudad: "Teodoro Schmidt", valor: 0 },
      { ciudad: "Toltén", valor: 0 },
      { ciudad: "Traiguén", valor: 0 },
      { ciudad: "Victoria", valor: 0 },
      { ciudad: "Vilcún", valor: 0 },
      { ciudad: "Villarrica", valor: 0 },
      { ciudad: "Corral", valor: 0 },
      { ciudad: "Futrono", valor: 0 },
      { ciudad: "La Unión", valor: 0 },
      { ciudad: "Lago Ranco", valor: 0 },
      { ciudad: "Lanco", valor: 0 },
      { ciudad: "Los Lagos", valor: 0 },
      { ciudad: "Máfil", valor: 0 },
      { ciudad: "Mariquina", valor: 0 },
      { ciudad: "Paillaco", valor: 0 },
      { ciudad: "Panguipulli", valor: 0 },
      { ciudad: "Río Bueno", valor: 0 },
      { ciudad: "Valdivia", valor: 0 },
      { ciudad: "Ancud", valor: 0 },
      { ciudad: "Calbuco", valor: 0 },
      { ciudad: "Castro", valor: 0 },
      { ciudad: "Chaitén", valor: 0 },
      { ciudad: "Chonchi", valor: 0 },
      { ciudad: "Cochamó", valor: 0 },
      { ciudad: "Curaco de Vélez", valor: 0 },
      { ciudad: "Dalcahue", valor: 0 },
      { ciudad: "Fresia", valor: 0 },
      { ciudad: "Frutillar", valor: 0 },
      { ciudad: "Futaleufú", valor: 0 },
      { ciudad: "Hualaihué", valor: 0 },
      { ciudad: "Llanquihue", valor: 0 },
      { ciudad: "Los Muermos", valor: 0 },
      { ciudad: "Maullín", valor: 0 },
      { ciudad: "Osorno", valor: 0 },
      { ciudad: "Palena", valor: 0 },
      { ciudad: "Puerto Montt", valor: 0 },
      { ciudad: "Puerto Octay", valor: 0 },
      { ciudad: "Puerto Varas", valor: 0 },
      { ciudad: "Puqueldón", valor: 0 },
      { ciudad: "Purranque", valor: 0 },
      { ciudad: "Puyehue", valor: 0 },
      { ciudad: "Queilén", valor: 0 },
      { ciudad: "Quellón", valor: 0 },
      { ciudad: "Quemchi", valor: 0 },
      { ciudad: "Quinchao", valor: 0 },
      { ciudad: "Río Negro", valor: 0 },
      { ciudad: "San Juan de la Costa", valor: 0 },
      { ciudad: "San Pablo", valor: 0 },
      { ciudad: "Aysén", valor: 0 },
      { ciudad: "Chile Chico", valor: 0 },
      { ciudad: "Cisnes", valor: 0 },
      { ciudad: "Cochrane", valor: 0 },
      { ciudad: "Coihaique", valor: 0 },
      { ciudad: "Guaitecas", valor: 0 },
      { ciudad: "Lago Verde", valor: 0 },
      { ciudad: "O’Higgins", valor: 0 },
      { ciudad: "Río Ibáñez", valor: 0 },
      { ciudad: "Tortel", valor: 0 },
      { ciudad: "Antártica", valor: 0 },
      { ciudad: "Cabo de Hornos", valor: 0 },
      { ciudad: "Laguna Blanca", valor: 0 },
      { ciudad: "Natales", valor: 0 },
      { ciudad: "Porvenir", valor: 0 },
      { ciudad: "Primavera", valor: 0 },
      { ciudad: "Punta Arenas", valor: 0 },
      { ciudad: "Río Verde", valor: 0 },
      { ciudad: "San Gregorio", valor: 0 },
      { ciudad: "Timaukel", valor: 0 },
      { ciudad: "Torres del Paine", valor: 0 },
      { ciudad: "Bulnes", valor: 0 },
      { ciudad: "Chillán", valor: 0 },
      { ciudad: "Chillán Viejo", valor: 0 },
      { ciudad: "Cobquecura", valor: 0 },
      { ciudad: "Coelemu", valor: 0 },
      { ciudad: "Coihueco", valor: 0 },
      { ciudad: "El Carmen", valor: 0 },
      { ciudad: "Ninhue", valor: 0 },
      { ciudad: "Ñiquén", valor: 0 },
      { ciudad: "Pemuco", valor: 0 },
      { ciudad: "Pinto", valor: 0 },
      { ciudad: "Portezuelo", valor: 0 },
      { ciudad: "Quillón", valor: 0 },
      { ciudad: "Quirihue", valor: 0 },
      { ciudad: "Ránquil", valor: 0 },
      { ciudad: "San Carlos", valor: 0 },
      { ciudad: "San Fabián", valor: 0 },
      { ciudad: "San Ignacio", valor: 0 },
      { ciudad: "San Nicolás", valor: 0 },
      { ciudad: "Treguaco", valor: 0 },
      { ciudad: "Yungay", valor: 0 },
      { ciudad: null, valor: 0 },
    ];
    data["grafico00"] = [];
    data["grafico01"] = [];
    data["grafico02"] = [];
    data["grafico02xx"] = [];
    data["grafico03"] = [];
    data["grafico04"] = [];
    data["cuenta01"] = [];
    data["cuenta02"] = [];
    data["graficoEstadoGenero"] = [];
    data["GraficoProcesosPorGenero"] = [];

    data["candidatos"].forEach((candidato) => {
      if (candidato["genero"] === "Masculino") {
        masculino = masculino + 1;
      }
      if (candidato["genero"] === "Femenino") {
        femenino = femenino + 1;
      }
      if (candidato["genero"] === "Sin especificar") {
        otro = otro + 1;
      }
      /////////////////////////////////
      if (candidato["estado"] === "Postulación") {
        v1 = v1 + 1;
        if (candidato["genero"] === "Masculino") {
          vm1 = vm1 + 1;
        }
        if (candidato["genero"] === "Femenino") {
          vf1 = vf1 + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          vo1 = vo1 + 1;
        }
      }
      if (candidato["estado"] === "Test") {
        v2 = v2 + 1;
        if (candidato["genero"] === "Masculino") {
          vm2 = vm2 + 1;
        }
        if (candidato["genero"] === "Femenino") {
          vf2 = vf2 + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          vo2 = vo2 + 1;
        }
      }
      if (candidato["estado"] === "Evaluaciones") {
        v3 = v3 + 1;
        if (candidato["genero"] === "Masculino") {
          vm3 = vm3 + 1;
        }
        if (candidato["genero"] === "Femenino") {
          vf3 = vf3 + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          vo3 = vo3 + 1;
        }
      }
      if (candidato["estado"] === "Contratación") {
        v4 = v4 + 1;
        if (candidato["genero"] === "Masculino") {
          vm4 = vm4 + 1;
        }
        if (candidato["genero"] === "Femenino") {
          vf4 = vf4 + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          vo4 = vo4 + 1;
        }
      }
      if (candidato["estado"] === "Sin especificar") {
        v5 = v5 + 1;

        if (candidato["genero"] === "Masculino") {
          vm5 = vm5 + 1;
        }
        if (candidato["genero"] === "Femenino") {
          vf5 = vf5 + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          vo5 = vo5 + 1;
        }
      }
      /////////////////////////////////
      if ((candidato["edad"] >= 0) & (candidato["edad"] <= 20)) {
        D20 = D20 + 1;
        if (candidato["genero"] === "Masculino") {
          D20m = D20m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D20f = D20f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D20o = D20o + 1;
        }
      }
      if ((candidato["edad"] > 20) & (candidato["edad"] <= 30)) {
        D30 = D30 + 1;
        if (candidato["genero"] === "Masculino") {
          D30m = D30m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D30f = D30f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D30o = D30o + 1;
        }
      }
      if ((candidato["edad"] > 30) & (candidato["edad"] <= 40)) {
        D40 = D40 + 1;
        if (candidato["genero"] === "Masculino") {
          D40m = D40m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D40f = D40f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D40o = D40o + 1;
        }
      }
      if ((candidato["edad"] > 40) & (candidato["edad"] <= 50)) {
        D50 = D50 + 1;
        if (candidato["genero"] === "Masculino") {
          D50m = D50m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D50f = D50f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D50o = D50o + 1;
        }
      }
      if ((candidato["edad"] > 50) & (candidato["edad"] <= 60)) {
        D60 = D60 + 1;
        if (candidato["genero"] === "Masculino") {
          D60m = D60m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D60f = D60f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D60o = D60o + 1;
        }
      }
      if ((candidato["edad"] > 60) & (candidato["edad"] <= 70)) {
        D70 = D70 + 1;
        if (candidato["genero"] === "Masculino") {
          D70m = D70m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D70f = D70f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D70o = D70o + 1;
        }
      }
      if (candidato["edad"] > 70) {
        D100 = D100 + 1;
        if (candidato["genero"] === "Masculino") {
          D100m = D100m + 1;
        }
        if (candidato["genero"] === "Femenino") {
          D100f = D100f + 1;
        }
        if (candidato["genero"] === "Sin especificar") {
          D100o = D100o + 1;
        }
      }
      //////////////////////////////
      region.forEach((r) => {
        if (r.region === candidato["region"]) {
          r.valor = r.valor + 1;
        }
      });
      /////////////////////////////
      ciudad.forEach((c) => {
        if (c.ciudad === candidato["ciudad"]) {
          c.valor = c.valor + 1;
        }
        /////////////////////////////
      });
    });
    data["grafico03"].push(["Región", "Postulantes"]);
    region.forEach((r) => {
      if (r.valor !== 0) {
        data["grafico03"].push([r.region, r.valor]);
        cuenta1 = cuenta1 + 1;
      }
    });

    data["grafico04"].push(["Ciudad", "Postulantes"]);
    ciudad.forEach((c) => {
      if (c.valor !== 0) {
        data["grafico04"].push([c.ciudad, c.valor]);
        cuenta2 = cuenta2 + 1;
      }
    });

    data["cuenta01"].push(cuenta1);
    data["cuenta02"].push(cuenta2);
    data["grafico00"].push(
      ["Estado", "Postulantes"],
      ["Postulación", v1],
      ["Test", v2],
      ["Evaluaciones", v3],
      ["Contratación", v4],
      ["Sin especificar", v5]
    );
    data["graficoEstadoGenero"].push(
      ["Estado", "Masculino", "Femenino", "Sin especificar"],
      ["Postulación", vm1, vf1, vo1],
      ["Test", vm2, vf2, vo2],
      ["Evaluaciones", vm3, vf3, vo3],
      ["Contratación", vm4, vf4, vo4],
      ["Sin especificar", vm5, vf5, vo5]
    );
    data["grafico01"].push(
      ["Género", "Postulantes"],
      ["Masculino", masculino],
      ["Femenino", femenino],
      ["Sin especificar", otro]
    );
    data["grafico02"].push(
      ["Rango de edades", "Postulantes"],
      ["0 a 20", D20],
      ["21 a 30", D30],
      ["31 a 40", D40],
      ["41 a 50", D50],
      ["51 a 60", D60],
      ["61 a 70", D70],
      ["Más de 70", D100]
    );
    data["grafico02xx"].push(
      ["Rango de edades", "Masculino", "Femenino", "Sin especificar"],
      ["0 a 20", D20m, D20f, D20o],
      ["21 a 30", D30m, D30f, D30o],
      ["31 a 40", D40m, D40f, D40o],
      ["41 a 50", D50m, D50f, D50o],
      ["51 a 60", D60m, D60f, D60o],
      ["61 a 70", D70m, D70f, D70o],
      ["Más de 70", D100m, D100f, D100o]
    );

    data["grafico05"] = [
      { name: "Postulación", value: v1 },
      { name: "Test", value: v2 },
      { name: "Evaluaciones", value: v3 },
      { name: "Contratación", value: v4 },
    ];
    return values;
  };
  //******** */

  const getDatos = (datos) => {
    let datosAux = [];
    if (datos !== undefined) {
      let i = 1;
      for (var property in datos) {
        datosAux.push({
          key: i,
          value: datos[property],
        });
        i++;
      }
    }

    return datosAux;
  };

  const getLabels = (datos) => {
    let labelsAux = [];
    if (datos !== undefined) {
      for (var property in datos) {
        labelsAux.push(property);
      }
    }

    return labelsAux;
  };

  const setDataChange = () => {
    let data = obtieneData();
    let grafico = paragrafico();
    let labelsAux = getLabels(data);
    let valuesAux = getDatos(data);
    setDatos({
      labels: labelsAux,
      values: valuesAux,
    });

    setActualizar(!actualizar);
  };

  const downloadFile = () => {
    var ws = XLSX.utils.json_to_sheet(data["xlsx"]);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    XLSX.writeFile(wb, "Detalle.xlsx");
  };

  const obtieneReporteCandidatos = () => {
    ReportesService.obtieneReportePostulantes(user.token).then((resp) => {
      setCandidatos(resp.entidad);
      data["candidatos"] = resp.entidad;
      setShowSpinner(false);
    });
  };

  React.useEffect(() => {
    if (candidatos.length === 0) {
      //obtieneEmpresas();
      obtieneReporteCandidatos();
    }
  }, [candidatos.length, actualizar]);

  return (
    <View>
      <ScrollView
        nativeID="candidatosScrollview"
        style={styles.candidatosScrollview}
      >
        <View
          style={{
            backgroundColor: "#0a5dc1",
            color: "fff",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            style={{
              color: "#ffffff",
              textAlign: "center",
              //fontSize: 20,
              textTransform: "uppercase",
            }}
          >
            Vistas de gráficos :{" "}
          </InputLabel>
          <Select
            style={{
              color: "#ffffff",
              textAlign: "center",
              //fontSize: 20,
              textTransform: "uppercase",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            //value={age}
            label="Vistas de gráficos"
            //onChange={handleChange}
            defaultValue={1}
          >
            <MenuItem value={1}> Predeterminados</MenuItem>
            <MenuItem value={2}> Libres</MenuItem>
          </Select>
        </View>

        {showSpinner && (
          <View style={{ justifyContent: "center" }}>
            <ActivityIndicator
              animating={true}
              color="#f6a623"
              size={80}
              style={{ marginTop: 40, marginBottom: 20 }}
            />
            <Text
              style={{
                color: "#6b6b6b",
                textAlign: "center",
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              Cargando Base de datos Pineal
            </Text>
          </View>
        )}
        {!showSpinner && (
          <div>
            <CustomMuiDataTable
              data={candidatos}
              columnas={columnas()}
              padding={0}
              height={"auto"}
              options={{
                customFooter: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage,
                  textLabels
                ) => {
                  let mapRut = {};
                  let totalData = 0;
                  data["candidatos"].forEach((candidato) => {
                    if (mapRut[candidato["rut"]] === undefined) {
                      mapRut[candidato["rut"]] = candidato;
                      totalData++;
                    }
                  });

                  return (
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "0px 24px 0px 24px",
                          }}
                          colSpan={1000}
                        >
                          <MuiTablePagination
                            component="div"
                            count={totalData}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={textLabels.rowsPerPage}
                            labelDisplayedRows={({ from, to, count }) =>
                              `${from}-${to} ${textLabels.displayRows} ${count}`
                            }
                            backIconButtonProps={{
                              "aria-label": textLabels.previous,
                            }}
                            nextIconButtonProps={{
                              "aria-label": textLabels.next,
                            }}
                            rowsPerPageOptions={[10, 20, 100]}
                            onChangePage={(_, page) => changePage(page)}
                            onChangeRowsPerPage={(event) =>
                              changeRowsPerPage(event.target.value)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                },
                //customRowRender: (data, dataIndex, rowIndex) => {},
                onViewColumnsChange: (changedColumn, action) => {
                  if (action === "add") {
                    columnasView[changedColumn] = true;
                  } else {
                    delete columnasView[changedColumn];
                  }
                  setDataChange();
                },
                onFilterChange: (
                  changedColumn,
                  filterList,
                  type,
                  changedColumnIndex,
                  displayData
                ) => {
                  data["candidatos"] = [];
                  if (displayData !== undefined) {
                    displayData.forEach((element) => {
                      data["candidatos"].push(candidatos[element.dataIndex]);
                    });
                  } else {
                    obtieneReporteCandidatos();
                  }
                  setDataChange();
                },
              }}
            />
          </div>
        )}
        <Grid container backgroundColor="#ffffff">
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FunnelChart
                backgroundColor="#ffffff"
                data={data["grafico05"]}
                pallette={["#0a5dc1", "#14487c", "#3169a0", "#5389be"]}
                title={"Etapas del proceso"}
                showRunningTotal={true}
              />
            </Grid>
          )}
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data["grafico01"]}
                options={options01}
              />
            </Grid>
          )}
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <hr />

              <Chart
                chartType="Bar"
                width="100%"
                height="100%"
                data={data["grafico00"]}
                options={options00}
              />
            </Grid>
          )}
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <hr />

              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data["graficoEstadoGenero"]}
                options={options02x}
              />
            </Grid>
          )}

          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <hr />
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data["grafico02"]}
                options={options02}
              />
            </Grid>
          )}
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <hr />
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data["grafico02xx"]}
                options={options02xx}
              />
            </Grid>
          )}

          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <hr />

              <Chart
                chartType="Bar"
                width="100%"
                height="300px"
                data={data["grafico03"]}
                options={options03}
              />
            </Grid>
          )}
          {datos && datos.values.length > 0 && !isSwitchOn && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <hr />

              <Chart
                chartType="Bar"
                width="100%"
                height="620px"
                data={data["grafico04"]}
                options={options04}
              />
            </Grid>
          )}
        </Grid>
      </ScrollView>
      {datos?.values?.length > 0 && (
        <FAB
          style={{
            position: "absolute",
            margin: 16,
            marginBottom: 40,
            marginRight: 40,
            right: 0,
            bottom: 0,
            backgroundColor: "#f47c06",
          }}
          color={Colors.white}
          icon="download"
          onPress={() => downloadFile()}
        />
      )}
    </View>
  );
}
