import React from "react";
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from "@react-navigation/drawer";
import { Image, Platform, SafeAreaView } from "react-native";
import Logos from "../utils/imagenes";
import { Divider } from "react-native-paper";

export default function Menu(props) {
  const { state, ...rest } = props;
  const newState = { ...state }; //copy from state before applying any filter. do not change original state
  props.hide.forEach((element) => {
    newState.routes = newState.routes.filter((item) => item.name !== element);
    newState.routeNames = newState.routeNames.filter(
      (item) => item !== element
    );
  });

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#0a5dc1" }}>
      {Platform.OS === "web" && (
        <div style={{ textAlign: "center" }}>
          <img src={Logos.logoNuevo} style={{width: 150, margin:30}}/>
        </div>
      )}
      {Platform.OS !== "web" && (
        <Image style={{ marginTop: 50 }} source={Logos.logoNuevo} />
      )}
      <Divider />
      <DrawerContentScrollView {...props}>
        <DrawerItemList
          state={newState}
          {...rest}
          activeTintColor="#0a5dc1"
          activeBackgroundColor="#10243de6"
          inactiveTintColor="rgba(0, 0, 0, .87)"
          inactiveBackgroundColor="transparent"
          style={{ backgroundColor: "#000000" }}
          labelStyle={{ color: "#ffffff" }}
        />
      </DrawerContentScrollView>
      <Divider />
      <DrawerItem
        label="Cerrar sesión"
        inactiveTintColor="#f6a623"
        onPress={() => props.navigationStack.navigate("Login")}
      />
    </SafeAreaView>
  );
}
