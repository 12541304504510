import React, { Children, DatReunion } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@material-ui/core";
import CalendarIcon from "@material-ui/icons/CalendarToday";

const useStyle = makeStyles({
  btnReunion: {
    color: "#fff",
    backgroundColor: "#20314A",
    variant: "outlined",
    fontSize: 12,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#20314A",
    },
    "&:focus": {
      color: "#fff",
    },
  },
  tablaTh: {
    color: "#20314A",
    backgroundColor: "#C7D4DC",
    fontSize: 12,
    textAlign: "left",
  },
  tablaTd: {
    color: "#20314A",
    backgroundColor: "#FFF",
    fontSize: 12,
    textAlign: "center",
  },
});

const DetalleReunion = ({
  children,
  DatEmpresa,
  DatCargo,
  DatFCreacion,
  DatFReunion,
  DatProceso,
  DatLinkZoom,
  DatIdZoom,
  DatPassZoom,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function createData(descripcion, datos) {
    return { descripcion, datos };
  }
  const rows = [
    createData("Empresa", DatEmpresa),
    createData("Cargo", DatCargo),
    createData("Proceso", DatProceso),
    createData("Fecha Creación de reunión", DatFCreacion),
    createData("Fecha Reunión", DatFReunion),
    createData("Link Zoom", DatLinkZoom),
    createData("Id Reunión", DatIdZoom),
    createData("Código de acceso", DatPassZoom),
  ];

  return (
    <div>
      <Tooltip title="Al hacer clic en este botón, podrá ver los detalles de la reunión.">
        <Button
          variant="contained"
          color="#f47C06"
          startIcon={<CalendarIcon />}
          className={classes.btnReunion}
          variant="outlined"
          onClick={handleClickOpen}
        >
          Detalle Reunión
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Detalle Reunión"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className={classes.tablaTh}>
                        {row.descripcion}
                      </TableCell>
                      <TableCell className={classes.tablaTd}>
                        {row.datos}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
export default DetalleReunion;
