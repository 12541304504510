import React from "react";
import { View } from "react-native";
import { Colors, HelperText, Subheading } from "react-native-paper";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

export default function CustomDatepicker({
  label,
  style,
  value,
  onChange = () => {},
  required,
  format = "dd/MM/yyyy",
  incluyeHora = false,
  minDate,
  disabled,
  fechaInicial = true,
  customInput,
}) {
  const [fecha, setFecha] = React.useState();
  const [tieneError, setTieneError] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");

  const fechaTexto = (date) => {
    let fecha = DateToString(date);
    onChange(fecha, date);
  };

  const DateToString = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fecha = (day < 10 ? "0" : "") + day + "/";
    fecha = fecha + (month < 10 ? "0" : "") + month + "/";
    fecha = fecha + year;
    if (incluyeHora) {
      fecha = fecha + " ";
      let hora = date.getHours();
      fecha = fecha + (hora < 10 ? "0" : "") + hora + ":";
      let minutes = date.getMinutes();
      fecha = fecha + (minutes < 10 ? "0" : "") + minutes;
    }
    return fecha;
  };

  const textoFecha = (dateText) => {
    let date = new Date();
    let valueSplit = dateText.split(/\/|\:| /);
    date = new Date(+valueSplit[2], valueSplit[1] - 1, +valueSplit[0]);
    if (incluyeHora) {
      date.setHours(parseInt(valueSplit[3]));
      date.setMinutes(parseInt(valueSplit[4]));
    }
    return date;
  };

  React.useEffect(() => {
    if (value !== null && value !== undefined && value !== "") {
      let dateValue = textoFecha(value);
      setFecha(dateValue);
      fechaTexto(dateValue);
    } else {
      if (fechaInicial) {
        let date = new Date();
        setFecha(date);
        fechaTexto(date);
      }
    }
  }, [value, tieneError]);

  const obtieneEstilos = () => {
    if (disabled) {
      return { ...style, backgroundColor: "#b7b5b5" };
    } else {
      return style;
    }
  };

  return (
    <View>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        {label !== undefined && label.length > 0 && (
          <Subheading>{label}</Subheading>
        )}
        {!incluyeHora && (
          <DatePicker
            disabled={disabled}
            required={required}
            style={obtieneEstilos()}
            inputVariant="outlined"
            value={fecha}
            format={format}
            onChange={(date) => {
              fechaTexto(date);
              setFecha(date);
            }}
            TextFieldComponent={customInput}
          />
        )}
        {incluyeHora && (
          <DateTimePicker
            disabled={disabled}
            minDateMessage={"La fecha minima es:" + minDate}
            required={required}
            ampm={false}
            style={obtieneEstilos()}
            inputVariant="outlined"
            value={fecha}
            format={`${format} HH:mm`}
            onChange={(date) => {
              if (date.getTime() >= minDate.getTime()) {
                fechaTexto(date);
                setFecha(date);
                setTieneError(false);
              } else {
                setTextoError(
                  "La fecha ingresada es menor a " + DateToString(minDate)
                );
                setTieneError(true);
              }
            }}
          />
        )}
        {tieneError && (
          <HelperText style={{ color: Colors.red400 }}>{textoError}</HelperText>
        )}
      </MuiPickersUtilsProvider>
    </View>
  );
}
